import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import "./ChangePasswordForm.scss";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import axios from "axios";
import { useAuth } from "../../context/auth";
import { userChangePassword } from "../../utils/ApiRoutes";
import { toast } from "react-toastify"
import {toastOptions} from "../../utils/ToastOptions"
import { useNavigate } from "react-router-dom";

const schema = yup.object({
  old_password: yup.string().required("Old Password is required"),
  new_password: yup.string().required("New Password is required").min(8),
  confirm_password: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("new_password"), null], "Passwords must match"),
});

const ChangePasswordForm = () => {
  const [auth] = useAuth();

  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(schema), mode: "all" });

  const [paswordInputStates, setPaswordInputStates] = useState({
    old_password: false,
    new_password: false,
    confirm_password: false,
  });

  const onSubmit = async (data) => {
    try {
      const { old_password, new_password } = data;
      const { data: res } = await axios.post(userChangePassword, {
        user_id: auth.user.user_id,
        old_password,
        new_password,
      });
      if (res.error === false) {
        toast.success(res.message , toastOptions);
        reset();
        setTimeout(() => {
          navigate("/profile")
        }, 2000);
      }else if (res.error === true) {
        toast.error(res.message , toastOptions)
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div className="chanage-password-user">
      <div className="change-password-card">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label htmlFor="old_password">Old Password</label>
            <section>
              <input
                type={paswordInputStates.old_password ? "text" : "password"}
                id="old_password"
                {...register("old_password")}
              />
              <span
                onClick={() =>
                  setPaswordInputStates((prev) => ({
                    ...prev,
                    old_password: !prev.old_password,
                  }))
                }
                className="icon"
              >
                {paswordInputStates.old_password ? (
                  <AiOutlineEye />
                ) : (
                  <AiOutlineEyeInvisible />
                )}
              </span>
            </section>
            {errors.old_password && (
              <span className="error">{errors.old_password.message}</span>
            )}
          </div>
          <div>
            <label htmlFor="new_password">New Password</label>
            <section>
            <input
              type={paswordInputStates.new_password ? "text" : "password"}
              id="new_password"
              {...register("new_password")}
            />
            <span
              onClick={() =>
                setPaswordInputStates((prev) => ({
                  ...prev,
                  new_password: !prev.new_password,
                }))
              }
              className="icon"
            >
              {paswordInputStates.new_password ? (
                <AiOutlineEye />
              ) : (
                <AiOutlineEyeInvisible />
              )}
            </span>
            </section>
            {errors.new_password && (
              <span className="error">{errors.new_password.message}</span>
            )}
            
          </div>
          <div>
            <label htmlFor="confirm_password">Confirm Password</label>
           <section>
           <input
              type={paswordInputStates.confirm_password ? "text" : "password"}
              id="confirm_password"
              {...register("confirm_password")}
            />
             <span
              onClick={() =>
                setPaswordInputStates((prev) => ({
                  ...prev,
                  confirm_password: !prev.confirm_password,
                }))
              }
              className="icon"
            >
              {paswordInputStates.confirm_password ? (
                <AiOutlineEye />
              ) : (
                <AiOutlineEyeInvisible />
              )}
            </span>
           </section>
            {errors.confirm_password && (
              <span className="error">{errors.confirm_password.message}</span>
            )}
           
          </div>
          <div>
            <button
              type="submit"
              className="submit-btn"
              disabled={isSubmitting}
            >
              Change
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePasswordForm;
