import "./Footer.scss";

import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div className="footer">
      <div className="upper-footer">
        <ul>
          <li className="footer-brand">Company</li>
          <li>About Us</li>
          <li>Why Choose us</li>
          <Link to={"/pricing"}>Pricing</Link>
          <li>Testimonial</li>
        </ul>
        <ul>
          <li className="footer-brand">Resources</li>
          <li>Privacy Policy</li>
          <li>Terms and Condition</li>
          <Link to={"/blog"}>Blog</Link>
          <li>Contact Us</li>
        </ul>
        <ul>
          <li className="footer-brand">Product</li>
          <li>Project managment</li>
          <li>Time tracker</li>
          <li>Time schedule</li>
          <li>Lead generate</li>
          <li>Remote Collaboration</li>
        </ul>
        <div className="contact-section">
          <h2>Site Title</h2>
          <p>Subscribe to our Newsletter</p>
          <form onSubmit={(e)  => e.preventDefault()}>
            <input type="text" placeholder="Enter your Email" />
            <button type="submit">Subscribe</button>
          </form>
        </div>
      </div>
      <div className="bottom-footer">
        <h4>Copyright @2022</h4>
        <div>
        <FacebookIcon/>
        <TwitterIcon/>
        <InstagramIcon/>
        <LinkedInIcon/>
        </div>
      </div>
    </div>
  );
};

export default Footer;
