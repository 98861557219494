import React, { useState } from "react";

import "./PromotionSelectionComponent.scss";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import {
  handleCounterClick,
  handleLocationClick,
} from "../../utils/ReuseFunction";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { updateAdsStatus } from "../../utils/ApiRoutes";
import { useAuth } from "../../context/auth";

const PromotionSelectionComponent = () => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedService, setSelectedService] = useState([]);
  const [location, setLocation] = useState("");

  const navigate = useNavigate();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 200,
      },
    },
  };

  const categories = ["Engine", "Windows", "Tire", "Steering"];
  const services = ["Parts", "Workshop", "Renting", "Buy/Sell"];

  const handleCategoriesChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCategories(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleServicesChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedService(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handlePaymentClick = (e) => {
    e.preventDefault();
    navigate("/payment");
  };
  const [counter, setCounter] = useState(0);
  const [price, setprice] = useState(0);
  const prodCode = localStorage.getItem("prodCode");
  const [auth] = useAuth()
  const handlePromote = async (e) => {
    e.preventDefault();
    const response = await axios.post(updateAdsStatus, {
      code: prodCode,
      maker_id: auth.user.user_id,
      make_by: 1,
      days: counter,
    });
    if (response.data.error === false) {
      navigate("/dashboard/my-ads");
    }
  };
  return (
    <div className="promotion-selection">
      <h1>Promote Your Product</h1>

      <div className="days-counter">
        <h4>Days</h4>
        <div>
          <button
            onClick={() =>
              handleCounterClick("-", setCounter, setprice, counter)
            }
          >
            <RemoveIcon />
          </button>
          <h5>{counter}</h5>
          <button
            onClick={() =>
              handleCounterClick("+", setCounter, setprice, counter)
            }
          >
            <AddIcon />
          </button>
        </div>
      </div>
      <div className="btn-section">
        <button onClick={handlePromote}>Promote</button>
      </div>
      {/* <form>
        <div className="section">
          <label htmlFor="startDate">Start Duration</label>
          <input type="date" name="startDate" id="startDate" />
        </div>
        <div className="section">
          <label htmlFor="endDate">End Duration</label>
          <input type="date" name="endDate" id="endDate" />
        </div>
        <div className="section">
        <InputLabel id="category">Services</InputLabel>
          <Select
            labelId="category"
            id="category"
            multiple
            value={selectedService}
            onChange={handleServicesChange}
            input={<OutlinedInput label="Tag" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
            sx={{height:"36px"}}
          >
            {services.map((service) => (
              <MenuItem key={service} value={service}>
                <Checkbox checked={selectedService.indexOf(service) > -1} />
                <ListItemText primary={service} />
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="section">
          <InputLabel id="category">Category</InputLabel>
          <Select
            labelId="category"
            id="category"
            multiple
            value={selectedCategories}
            onChange={handleCategoriesChange}
            input={<OutlinedInput label="Tag" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
            sx={{height:"36px"}}
          >
            {categories.map((category) => (
              <MenuItem key={category} value={category}>
                <Checkbox checked={selectedCategories.indexOf(category) > -1} />
                <ListItemText primary={category} />
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="section">
            <input type="text" name="location" id="location" placeholder="Location" value={location} onChange={(e)=>setLocation(e.target.value)}/>
        </div>
        <div className="section">
            <button onClick={(e)=>handleLocationClick(e,setLocation)}><RoomOutlinedIcon/> Current Location</button>
        </div>
        <div className="btn-section">
            <button onClick={(e)=>handlePaymentClick(e)}>Proceed to payment</button>
        </div>
      </form> */}
    </div>
  );
};

export default PromotionSelectionComponent;
