import React, { useState, useEffect, useMemo } from "react";
import DashboardLayout from "../../Layouts/DashboardLayout";
import BusinessBadge from "../../components/BusinessBadge/BusinessBadge";
import "./DashboardBussinessDealsGifts.scss";
import axios from "axios";
import { vendorBadgesGetRoute } from "../../utils/ApiRoutes";
import { useAuth } from "../../context/auth";
// import NoBadge from "."
const DashboardBussinessDealsGifts = () => {
  const [auth] = useAuth();
  const [badges, setBadges] = useState([]);

  const controller = useMemo(() => new AbortController(), []);

  useEffect(() => {
    const getBadges = async () => {
      const { data } = await axios.get(vendorBadgesGetRoute);
      setBadges(data.badge);
    };
    getBadges();
    return () => controller.abort();
  }, [controller]);

  const mybadge = JSON.parse(auth.user.badge);
  return (
    <DashboardLayout>
      {auth.user.badge === null ? (
        <div className="flex justify-center item-center">
          <img src="" alt=""/>
        </div>
      ) : (
        <div className="gifts-main">
          {badges?.map((badge, i) => (
            <BusinessBadge key={i} badge={badge} />
          ))}
        </div>
      )}
    </DashboardLayout>
  );
};

export default DashboardBussinessDealsGifts;
