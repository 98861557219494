import React, { useState } from "react";

import "./NewPasswordPage.scss";
import axios from "axios";
import { changePasswordRoute } from "../../utils/ApiRoutes";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { toastOptions } from "../../utils/ToastOptions";
import { useAuth } from "../../context/auth";
import { Helmet } from "react-helmet";
const NewPasswordPage = () => {
  const [password, setpassword] = useState("");
  const [cPassword, setCPassword] = useState("");

  const navigate = useNavigate();

  const [auth] = useAuth();

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (formValidate()) {
      const response = await axios.post(changePasswordRoute, {
        password,
        code: localStorage.getItem("code"),
      });
      if (response.data.error === false) {
        navigate("/login");
      } else {
        toast.error("Error occured in password updation", toastOptions);
      }
    }
  };

  const formValidate = () => {
    if (password !== cPassword) {
      toast.error("password and confirm password must be same", toastOptions);
      return false;
    }
    if (
      !/[a-z]/.test(password) ||
      !/[A-Z]/.test(password) ||
      !/\d/.test(password) ||
      !/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password) ||
      password.length < 8
    ) {
      toast.error(
        "Password must contain 8 character with uppercase ,lowercase ,number and special Character.",
        toastOptions
      );
      return false;
    }
    return true;
  };

  return (
    <>
      <Helmet>
        <title>New Password | CARS'R'US</title>
      </Helmet>
      <div className="new-password-page">
        <div className="logo">CARS'R'US</div>
        <div className="new-password-card">
          <form>
            <h1>Create New Password</h1>
            <input
              type="password"
              placeholder="New Password"
              value={password}
              onChange={(e) => setpassword(e.target.value)}
            />
            <input
              type="password"
              placeholder="Confirm Password"
              value={cPassword}
              onChange={(e) => setCPassword(e.target.value)}
            />
            <button onClick={(e) => handlePasswordChange(e)}>Done</button>
          </form>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default NewPasswordPage;
