import {
  useState,
  useEffect,
  useRef,
  useContext,
  createContext,
  useMemo,
  useCallback,
} from "react";
import { vendorNotificationsRoute } from "../utils/ApiRoutes";
import axios from "axios";

const NotificationContext = createContext();

const NotificationProvider = ({ children }) => {
  const user = localStorage.getItem("auth");
  const timeoutRef = useRef();
  const [notifications, setNotifications] = useState([]);
  const parsedData = useMemo(() => JSON.parse(user), [user]);
  const getData = useCallback(async () => {
    const { data } = await axios.get(
      `${vendorNotificationsRoute}/${parsedData?.user?.code}`
    );
    if (data.error === false) {
      setNotifications(data.notification);
    }
  }, [parsedData?.user?.code]);


  useEffect(() => {
    if (parsedData?.userType === 1) {
      timeoutRef.current = setInterval(() => {
        getData();
      }, 10000);
    }
    return () => clearInterval(timeoutRef.current);
  }, [parsedData, getData]);

  return (
    <NotificationContext.Provider value={[notifications, setNotifications]}>
      {children}
    </NotificationContext.Provider>
  );
};

const useNoti = () => useContext(NotificationContext);

export { useNoti, NotificationProvider };
