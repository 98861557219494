import axios from "axios";
import React, { useState, Fragment } from "react";
import {
  liveSearchImageRoute,
  vendorNotificationsUpdate,
  vendorRequestStatusUpdate,
} from "../../utils/ApiRoutes";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import CampaignIcon from "@mui/icons-material/Campaign";

import { Badge, IconButton } from "@mui/material";
import { useNoti } from "../../context/notification";
import "./NotificationMenu.scss";

const NotificationMenu = () => {
  const [auth] = useAuth();
  const [menu, setMenu] = useState(false);
  const [notifications] = useNoti();
  const navigate = useNavigate();
  const handleRequestClick = async (e, current, code, user_id) => {
    e.preventDefault();
    const { data } = await axios.post(vendorRequestStatusUpdate, {
      code,
      vendor_id: auth.user.user_id,
      status: current === "accept" ? 1 : current === "reject" ? 2 : 0,
    });
    if (data.error === false) {
      axios.get(`${vendorNotificationsUpdate}/${code}`);
      if (current === "accept") {
        sessionStorage.setItem("chat_user_id", user_id);
        setMenu(false);
        navigate("/dashboard/business-chats");
      } else if (current === "reject") {
        setMenu(false);
      }
    }
  };

  const handleNotificationClick = (code, user_id) => {
    axios.get(`${vendorNotificationsUpdate}/${code}`);
    sessionStorage.setItem("chat_user_id", user_id);
    navigate("/dashboard/business-chats");
  };

  const handleClick = (event) => {
    setMenu((prev) => !prev);
  };
  return (
    <Fragment>
      {menu === true && (
        <div className="dashboard-wrapper" onClick={handleClick}></div>
      )}
      <div style={{ position: "relative" }}>
        <IconButton onClick={handleClick}>
          <Badge badgeContent={notifications.length} color="primary">
            <NotificationsNoneOutlinedIcon sx={{color:"white"}} />
          </Badge>
        </IconButton>
        {menu && (
          <div className="notification-menu">
            {notifications?.map((item, i) => (
              <div key={i} className="notification">
                <div
                  className="notification-icon"
                  onClick={() =>
                    handleNotificationClick(item.code, item.user_id)
                  }
                >
                  <CampaignIcon />
                </div>
                <div>
                  <h5
                    onClick={() =>
                      handleNotificationClick(item.code, item.user_id)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {item.message}
                  </h5>
                  <div
                    onClick={() =>
                      handleNotificationClick(item.code, item.user_id)
                    }
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      cursor: "pointer",
                    }}
                  >
                    <h6>{item.date}</h6>
                    <h6>{item.time}</h6>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingTop: ".2rem",
                    }}
                  >
                    <button
                      className="accept"
                      onClick={(e) =>
                        handleRequestClick(e, "accept", item.code, item.user_id)
                      }
                    >
                      Accept
                    </button>
                    <button
                      className="pending"
                      onClick={(e) =>
                        handleRequestClick(e, "reject", item.code, item.user_id)
                      }
                    >
                      Reject
                    </button>
                  </div>
                </div>
                {item?.image && (
                  <a
                    href={`${liveSearchImageRoute}/${item?.image}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="notification-image">
                      <img
                        src={`${liveSearchImageRoute}/${item?.image}`}
                        alt="Product"
                      />
                    </div>
                  </a>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default NotificationMenu;
