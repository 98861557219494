import React, { useState, useEffect, useContext } from "react";

import "./CarRentingMainArea.scss";
import MenuIcon from "@mui/icons-material/Menu";
import axios from "axios";
import {
  getVendors,
  vendorFilterRoute,
} from "../../utils/ApiRoutes";


import MerchantCard from "../MerchantCard/MerchantCard";
import { useNavigate } from "react-router-dom";
import { merchantDetailsPage } from "../../utils/Routes";
import { Pagination } from "@mui/material";
import FilterWithCheckBox from "../FilterWithCheckBox/FilterWithCheckBox";
import moment from "moment";
import ProgressCircle from "../ProgressCIrcle/ProgressCircle";
import { dataContext } from "../../App";
const CarRentingMainArea = () => {
  const navigate = useNavigate();
  const {countries} =  useContext(dataContext)
  const [vendors, setVendor] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageVendors, setCurrentPageVendors] = useState([]);
  const [badges, setbadges] = useState([]);
  const [vendorFilter, setVendorFilter] = useState({
    badge_id: 0,
    country_id: 0,
    search: "",
    city_id: 0,
    service_id: 6,
  });

  useEffect(() => {
    FilterChange();
    // eslint-disable-next-line
  }, [vendorFilter.badge_id, vendorFilter.search, vendorFilter.city_id ,vendorFilter.country_id,]);

  const FilterChange = async () => {
    if (
      vendorFilter.badge_id !== 0 ||
      vendorFilter.search !== "" ||
      vendorFilter.city_id !== 0 ||
      vendorFilter.country_id !== 0
    ) {
      try {
        setisLoading(true)
        const { data } = await axios.post(vendorFilterRoute, vendorFilter);
      if (data.error === false) {
        setVendor(data.vendor);
        const itemsPerPage = 9; // Number of items to display per page
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const currentPageItems = [...data.vendor?.slice(startIndex, endIndex)];
        setCurrentPageVendors(currentPageItems);
      }
      } catch (error) {
        console.error(error.message) 
      } finally{
        setisLoading(false)
      }
    } else {
      getData();
    }
  };

  useEffect(() => {
    const uae = countries.find(
      (data) => data.country_code.toLowerCase() === "AE".toLowerCase()
    );
    setVendorFilter((prev) => ({ ...prev, country_id: uae.country_id }));
    getData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const itemsPerPage = 5; // Number of items to display per page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentPageItems = [...vendors?.slice(startIndex, endIndex)];
    setCurrentPageVendors(currentPageItems);
    // eslint-disable-next-line
  }, [currentPage]);

  const getData = async () => {
    setisLoading(true);
    try {
      const response = await axios.get(`${getVendors}/6`);
      setVendor(response.data.vendor);
      setbadges(response.data.Badge);
      const itemsPerPage = 5; // Number of items to display per page
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const currentPageItems = [
        ...response.data.vendor?.slice(startIndex, endIndex),
      ];
      setCurrentPageVendors(currentPageItems);
    } catch (error) {
      console.log(error);
    }finally{
      setisLoading(false);
    }
  };

  const handleCardClick = (url) => {
    navigate(`${merchantDetailsPage}/${url}`);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  const handleMenuClick = () => {
    document.getElementById("filters_menu").style.display = "block";
  };

  return (
    <div className="car-renting">
      <FilterWithCheckBox
        vendorFilter={vendorFilter}
        setVendorFilter={setVendorFilter}
      />
      <div className="car-renting-right">
       {!isLoading && ( <>
        <div className="menu-bar" >
          <MenuIcon onClick={handleMenuClick}/>
        </div>
        <div className="Header">
          <h1>Renting</h1>
          {/* <h2>Tire Repair in Dubai, Deria</h2> */}
          <p>
            {vendors.length} results • {moment().format('DD/MM/YYYY')}
          </p>
        </div>
        <div className="car-renting-cards">
          {currentPageVendors.flat(1)?.map((vendor, i) => (
            <div
              key={i}
              style={{ cursor: "pointer" }}
              // onClick={() => handleCardClick(vendor?.url)}
            >
              <MerchantCard
                key={vendor.vendor_id}
                badges={badges}
                badgeId={JSON.parse(vendor.badge)}
                vendor={vendor}
                handleCardClick={handleCardClick}
              />
            </div>
          ))}
          {/* {vendors?.length === 0 && (<img/>)} */}
        </div>
        <div className="pagination-center">
          {Math.ceil(vendors?.length / 5) > 1 && (
            <Pagination
              variant="outlined"
              page={currentPage}
              onChange={handlePageChange}
              count={vendors?.length !== 0 ? Math.ceil(vendors?.length / 5) : 1}
            />
          )}
        </div>
        </>)}
        {isLoading && (<ProgressCircle height={"80vh"} />)}
      </div>
    </div>
  );
};

export default CarRentingMainArea;
