import React from 'react'
import Footer from '../../components/Footer/Footer'
import ColorNavbar from '../../components/ColorNavbar/ColorNavbar'
import ResultsMain from '../../components/ResultsMain/ResultsMain'


const AllResults = () => {
  return (
    <div>
        <ColorNavbar color={"#ED5565"} textColor={"black"}/>
        <ResultsMain/>
        <Footer/>
    </div>
  )
}

export default AllResults