import React from 'react'
import DashboardLayout from '../../Layouts/DashboardLayout'
import DashboardTopbar from '../../components/DashboardTopbar/DashboardTopbar'

const DashboardDataUploading = () => {
  return (
    <DashboardLayout>
   
        <DashboardTopbar/>
        
    </DashboardLayout>
  )
}

export default DashboardDataUploading