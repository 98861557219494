import "./VendorLogin.scss";
import "react-toastify/dist/ReactToastify.css";

import { useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";

import iphoneLogo from "../../assets/icons/iphone-logo.png";
import googleLogo from "../../assets/icons/google-logo.png";
import facebookLogo from "../../assets/icons/facebook-logo.png";

import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

import { LoginVendorRoute } from "../../utils/ApiRoutes";
import { dataContext } from "../../App";
import { toastOptions } from "../../utils/ToastOptions";
import { vendorProfileRoute } from "../../utils/Routes";
import ProgressCircle from "../../components/ProgressCIrcle/ProgressCircle";
import { useAuth } from "../../context/auth";
import { Helmet } from "react-helmet";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const VendorLogin = () => {
  // Auth
  const [auth, setAuth] = useAuth();
  // Navigation Hook
  const navigate = useNavigate();
  // States
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [passwordShow, setPasswordShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // destructuring Context variable
  const { setVendorOTP, setVendorCode } = useContext(dataContext);
  // destructuring state
  const { email, password } = formData;

  // handle state Change function
  const handleFormChange = (e) => {
    const { value, id } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const loginFormValidate = (email, password) => {
    if (email === "") {
      toast.error("email is required", toastOptions);
      return false;
    }
    if (password === "") {
      toast.error("Password is required", toastOptions);
      return false;
    }
    return true;
  };

  // Handle form Submit Funtion
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (loginFormValidate(email, password)) {
      setIsLoading(true);
      // sending data to the server
      const response = await axios.post(LoginVendorRoute, { email, password });
      // deststructuring response
      const { error, message, code, otp, account,type } = response.data;

      if (error === true) {
        if (message === "Not_Verified") {
          setVendorCode(code);
          setVendorOTP(otp);
          setIsLoading(false);
          navigate("/dashboard/vender-otp");
        }
        if (message === "Blocked") {
          setIsLoading(false);
          toast.error("Your has been Blocked", toastOptions);
        }
        if (message === "wrong") {
          setIsLoading(false);
          toast.error("Wrong Credentials!", toastOptions);
        }
      } else if (message === "wrong") {
        setIsLoading(false);
        toast.error("Wrong Credentials!", toastOptions);
      } else {
        // localStorage
        setAuth({
          ...auth,
          user: {
            email: account[0].email,
            name: account[0].name,
            user_id: account[0].vendor_id,
            user_phone: account[0].phone,
            loginDate: new Date().getUTCDate(),
            code: account[0].code,
            url: account[0].url,
            service_id: account[0].service,
            planType:type,
            verified: account[0].verified,
            badge: account[0].badge,
            bussinessName: account[0].business_name,
          },
          userType: 1,
        });
        localStorage.setItem(
          "auth",
          JSON.stringify({
            user: {
              email: account[0].email,
              name: account[0].name,
              user_id: account[0].vendor_id,
              user_phone: account[0].phone,
              loginDate: new Date().getUTCDate(),
              code: account[0].code,
              url: account[0].url,
              service_id: account[0].service,
              planType:type,
              verified: account[0].verified,
              badge: account[0].badge,
              bussinessName: account[0].business_name,
            },
            userType: 1,
          })
        );
        setIsLoading(false);
        navigate(`${vendorProfileRoute}/${account[0]?.url}`);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Vendor Login | CARS'R'US</title>
      </Helmet>
      <div className="login-page">
        <Link to={"/"} className="logo">
          CARS'R'US
        </Link>
        {!isLoading && (
          <div className="login-form">
            <h1 style={{ fontSize: "42px" }}>VENDOR LOGIN</h1>
            <form>
              <input
                type="email"
                placeholder="Email"
                value={email}
                id="email"
                onChange={(e) => handleFormChange(e)}
              />
              <input
                type={!passwordShow ? "password" : "text"}
                placeholder="password"
                value={password}
                id="password"
                onChange={(e) => handleFormChange(e)}
              />
              <span className="icon" onClick={()=> setPasswordShow(prev => !prev)}>
                {!passwordShow ? <AiOutlineEye  color="white"/> : <AiOutlineEyeInvisible color="white"/>}
              </span>
              <div>
                <span className="">
                  {/* <input type="checkbox" id="remember" /> */}
                  {/* <label htmlFor="remember">Remember Me</label> */}
                </span>
                <Link to={"/vender-forgot"}>Forgot Password</Link>
              </div>
              <button onClick={(e) => handleFormSubmit(e)}>Login</button>
            </form>
            <div className="social-media">
              <button>
                <img src={iphoneLogo} alt="apple" />
                Sign in with Apple
              </button>
              <button>
                <img src={googleLogo} alt="google" />
                Sign in with Google
              </button>

              <button>
                <img src={facebookLogo} alt="apple" />
                Sign in with Facebook
              </button>
            </div>
            <div style={{ color: "white" }}>
              Dont Have an Account{" "}
              <Link to={"/dashboard"} style={{ color: "white" }}>
                Sign-Up
              </Link>
            </div>
            <div style={{ color: "white" }}>
              <Link to={"/login"} style={{ color: "white" }}>
                Login As User
              </Link>
            </div>
          </div>
        )}
        {isLoading && <ProgressCircle height={"calc(100vh - 300px)"} />}
      </div>
      <ToastContainer />
    </>
  );
};

export default VendorLogin;
