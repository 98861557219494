import React from "react";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { resendVendorRoute, vendorVerifyAccount } from "../../utils/ApiRoutes";
import axios from "axios";

import OtpInput from "react-otp-input";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { toastOptions } from "../../utils/ToastOptions";
import { Helmet } from "react-helmet";
const VendorForgotOTP = () => {
  const [otp, setOtp] = useState("");

  const navigate = useNavigate();
  const handleOTPSubmit = async (e) => {
    e.preventDefault();
    const response = await axios.post(vendorVerifyAccount, {
      code: localStorage.getItem("vendorCode"),
      otp,
    });
    if (response.data.error === false) {
      navigate("/vender-newpassword");
    }
    if (response.data.error === true) {
      toast.error("invalid OTP!", toastOptions);
      setOtp("");
    }
  };

  const handleResend = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(`${resendVendorRoute}/${localStorage.getItem("vendorCode")}`);
      if (response.error === false) {
        toast.success("New OTP is Sent to your Mail", toastOptions)        
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      <Helmet>
        <title>Vendor OTP | CARS'R'US</title>
      </Helmet>
      <form className="otp-verify" >
        <div className="form">
          <h1>OTP</h1>
          <h4>Enter Otp sent to your email to verify its your email.</h4>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
            inputStyle={{
              fontSize: "18px",
              border: "1px solid #d1d1d1",
              height: "px",
              padding: "1rem",
              width: "2.5em",
              borderRadius: "4.49871px",
            }}
            inputType="tel"
          />
          <div className="btn-section">
            <button className="btn-blue" onClick={(e) => handleOTPSubmit(e)}>Submit</button>
            <button className="btn-darkgray" onClick={(e) => handleResend(e)}>Resend</button>
          </div>
        </div>
      </form>
    </>
  );
};

export default VendorForgotOTP;
