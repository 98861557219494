import React from "react";
import { useState, useEffect } from "react";
import { getVendorAds } from "../../utils/ApiRoutes";
import axios from "axios";

// import "./AddCarsMainArea.scss";
import { CarsOutline, Clicks, Cost } from "../../assets/icons/svg";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";

import {
  cardRoute,
} from "../../utils/ApiRoutes";
import DropdownMenu from "../../styledComponents/Dropdown";

import Car from "../../assets/images/car.svg";
import ProgressCircle from "../ProgressCIrcle/ProgressCircle";
import { useAuth } from "../../context/auth";
import moment from "moment";
import { Image } from 'primereact/image';
const VendorMyAdsMainArea = () => {
  const [expanded, setExpanded] = useState(false);
  const [ads, setAds] = useState([]);
  const [isloading, setIsloading] = useState(false)
  const [auth] = useAuth()
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const getData = async () => {
    setIsloading(true)
    const response = await axios.post(getVendorAds, {
      make_by: 1,
      maker_id: auth.user?.user_id,
    });
    setAds(response.data.Ads);
    setIsloading(false)
  };
  const handleChange = (panel) => (isExpanded) => {
    if (expanded === "panel1") {
      setExpanded(false);
    } else {
      setExpanded(panel);
    }
  };

  return (
    <>
    {!isloading && (<div className="add-cars-main">
      <div className="states-area">
        <div className="states-card">
          <div className="card-image">
            <CarsOutline />
          </div>
          <div className="card-content">
            <h4>Number of Cars</h4>
            <p>{ads.length}</p>
          </div>
        </div>
        {/* <div className="states-card">
          <div className="card-image">
            <Views />
          </div>
          <div className="card-content">
            <h4>Number of Views</h4>
            <p>0</p>
          </div>
        </div> */}
        <div className="states-card">
          <div className="card-image">
            <Clicks />
          </div>
          <div className="card-content">
            <h4>Number of Clicks</h4>
            <p>{ads.reduce((prev,curr) => prev + curr.views , 0 )}</p>
          </div>
        </div>
        <div className="states-card">
          <div className="card-image">
            <Cost />
          </div>
          <div className="card-content">
            <h4>Cost</h4>
            <p>
            {ads.reduce((prev,curr) => prev + curr.price , 0 )} <span>$</span>
            </p>
          </div>
        </div>
      </div>
      {/* <div className="add-cars-section">
        <h1>Add Cars</h1>
        <div onClick={() => setActive("addcar")}>
          <AddIcon />
        </div>
      </div> */}
      <div className="main-section">
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <h4>Cars {ads?.length}</h4>
          </AccordionSummary>
          <AccordionDetails>
            <div className="table-data">
              <div className="header-row">
                <div className="search-icon">
                  <SearchIcon />
                </div>
                <div className="search-input">
                  <input type="text" placeholder="Search" />
                </div>
                {/* <div className="heading">Views</div> */}
                <div className="heading">Click</div>
                <div className="heading">Review Date</div>
                <div className="heading">Status</div>
                {/* <div className="heading">Option</div> */}
              </div>
              {ads.map((ad, i) => (
                <div className="row" key={i}>
                  <div className="checkbox">
                    <input type="checkbox" />
                  </div>
                  <div className="product">
                    <div className="image">
                      {ad.card && ( <Image src={`${cardRoute}/${ad.card}`}  alt={""} preview width="250" /> )}
                      {!ad.card && <img src={Car} alt="Product"/>}
                    </div>
                    <div className="details">
                      <h4>
                        {ad.brand_name}, {ad.model_name}
                      </h4>
                      <p>
                        {ad.model_year}, {ad.milage}KM
                      </p>
                    </div>
                  </div>
                  {/* <div className="views">0</div> */}
                  <div className="clicks">{ad.views}</div>
                  <div className="review-date">{moment(ad.date).format("DD/MM/YYYY")}</div>
                  <div className="highlight">
                    <button
                    // onClick={(e) => handleHighlightClick(e)}
                    >
                      {ad.status === 0 ? "Active" : "De-Active"}
                    </button>
                  </div>
                  {/* <div className="options">
                    <DropdownMenu id={ad.code} 
                    setActive={setActive} deactivate={deactivate}
                    />
                  </div> */}
                </div>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>)}
    {isloading && (
      <ProgressCircle height={"calc(100vh - 150px)"}/>
    )}
    </>
  );
};

export default VendorMyAdsMainArea;
