import React, { useState, useEffect ,useRef, useContext} from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  GetModelRoute,
  getCitiesRoute,
  getlocationRoute,
  editProductRoute,
  cardRoute,
  updateProductRoute,
} from "../../utils/ApiRoutes";
import { addCarFormValidate } from "../../utils/ReuseFunction";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ProgressCircle from "../ProgressCIrcle/ProgressCircle";
import { dataContext } from "../../App";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const AllColors = [
  "",
  { id: 1, name: "Unlisted" },
  { id: 2, name: "White" },
  { id: 3, name: "Silver" },
  { id: 4, name: "Black" },
  { id: 5, name: "Grey" },
  { id: 6, name: "Blue" },
  { id: 7, name: "Green" },
  { id: 8, name: "Red" },
  { id: 9, name: "Gold" },
  { id: 10, name: "Maroon" },
  { id: 11, name: "Beige" },
  { id: 12, name: "Pink" },
  { id: 13, name: "Brown" },
  { id: 14, name: "Burgundy" },
  { id: 15, name: "Yellow" },
  { id: 16, name: "Bronze" },
  { id: 17, name: "Purple" },
  { id: 18, name: "Turquoise" },
  { id: 19, name: "Orange" },
  { id: 20, name: "indigo" },
  { id: 21, name: "Magenta" },
  { id: 22, name: "Navy" },
];

const EditCarForm = ({ setActive }) => {
  const imageInputRef = useRef()
  const prodCode = localStorage.getItem("prodCode");
  const { brands} = useContext(dataContext)
  const [image, setImage] = useState("");
  const [color, setColor] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const [has_image, setHas_image] = useState(0);
  const [displayImage, setDisplayImage] = useState("");
  const [cities, setCities] = useState([]);
  const [models, setModels] = useState([]);
  const [locations, setLocations] = useState([]);
  const [formData, setFormData] = useState({
    brand: "",
    model: "",
    modelYear: "",
    price: "",
    phone: "",
    country_id: "",
    city: "",
    location: "",
    mileage: "",
    color: "",
    insured: "",
    door: "",
    unlisted_color: null,
    type:"",
  });

  const handleColorChange = (event) => {
    const selectedColor = event.target.value;
    setColor(selectedColor);
  };

  const handleInputChange = async (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name === "brand") {
      const modelresponse = await axios.get(`${GetModelRoute}/${value}`);
      setModels(modelresponse.data.Model);
      document.getElementById("model").removeAttribute("disabled");
    }
    if (name === "city") {
      const locationresponse = await axios.get(`${getlocationRoute}/${value}`);
      setLocations(locationresponse.data.location);
      document.getElementById("location").removeAttribute("disabled");
    }
  };

  const getData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${editProductRoute}/${prodCode}`);
      const {
        brand_id,
        model_id,
        model_year,
        price,
        phone,
        city_id,
        location_id,
        milage,
        country_id,
        color_id,
        insured,
        door_id,
        card,
        unllisted_color,
        type,
      } = response.data.product[0];
      setImage(card);
      const cityResponse = await axios.get(`${getCitiesRoute}/${country_id}`);
      const modelresponse = await axios.get(`${GetModelRoute}/${brand_id}`);
      const locationresponse = await axios.get(
        `${getlocationRoute}/${city_id}`
      );
      setLocations(locationresponse.data.location);
      setModels(modelresponse.data.Model);
      setCities(cityResponse.data.city);
      setFormData({
        brand: brand_id,
        model: model_id,
        modelYear: model_year,
        price: price,
        phone: phone,
        city: city_id,
        location: location_id,
        mileage: milage,
        color: color_id,
        insured: insured,
        door: door_id,
        country_id: country_id,
        unllisted_color: unllisted_color,
        type:type
      });
      const id = AllColors.filter(
        (currentColor) => currentColor.id === Number(color_id)
      );
      if (id.length !== 0) {
        setColor(id[0].name);
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false)
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line 
  }, []);

  

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (addCarFormValidate(formData)) {
      const {
        brand,
        model,
        modelYear,
        price,
        phone,
        city,
        mileage,
        color,
        door,
        location,
        insured,
        type,
      } = formData;

      const fd = new FormData();
      fd.append("card", image);
      fd.append("brand_id", brand);
      fd.append("model_id", model);
      fd.append("model_year", modelYear);
      fd.append("price", price);
      fd.append("phone", phone);
      fd.append("city_id", city);
      fd.append("location_id", location);
      fd.append("milage", mileage);
      fd.append("color_id", color);
      fd.append("door_id", door);
      fd.append("insured", insured);
      fd.append("code", prodCode);
      fd.append("has_image", has_image);
      fd.append("type", type)
      try {
        const response = await axios.post(updateProductRoute, fd, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.data.error === false) {
          setActive("carmain");
        } else {
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
    setHas_image(1);
    const temp = URL.createObjectURL(e.target.files[0]);
    setDisplayImage(temp);
  };

  const date = new Date();
  const years = [];
  for (let index = date.getFullYear() + 1; index > 1950; index--) {
    years.push(index);
  }

  useEffect(() => {
    const id = AllColors.filter((currentColor) => currentColor.name === color);
    if (id.length !== 0) {
      setFormData({ ...formData, color: id[0].id });
    }
    // eslint-disable-next-line 
  }, [color]);

  return (
    <div className="add-cars-main">
      <div className="add-cars-section">
        <h1>Products</h1>
        <div onClick={() => setActive("carmain")}>
          <ArrowBackIcon />
        </div>
      </div>
      <div className="form-section">
        {!isLoading &&
          (<>
            <form onSubmit={handleSubmit}>
              <div>
                <label htmlFor="brand">Brand</label>
                <select
                  name="brand"
                  id="brand"
                  value={formData.brand}
                  onChange={handleInputChange}
                >
                  {Number(formData.brand_id) === 0 && (
                    <option value="">Select Brand</option>
                  )}
                  {brands.map((brand) => (
                    <option key={brand.brand_id} value={brand.brand_id}>
                      {brand.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="model">Model</label>
                <select
                  name="model"
                  id="model"
                  value={formData.model}
                  onChange={handleInputChange}
                  disabled
                >
                  {Number(formData.model_id) === 0 && (
                    <option value="">Select Model</option>
                  )}
                  {models.map((model) => (
                    <option key={model.model_id} value={model.model_id}>
                      {model.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="modelYear">Model Year</label>
                <select
                  name="modelYear"
                  id="modelYear"
                  onChange={handleInputChange}
                  value={formData.modelYear}
                >
                  {Number(formData.modelYear) === 0 && (
                    <option value="0">Select Model Year</option>
                  )}

                  {years.map((year) => (
                    <option value={year} key={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="price">Price/AED</label>
                <input
                  type="number"
                  id="price"
                  name="price"
                  value={formData.price}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="phone">Phone</label>
                <input
                  type="number"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  placeholder="Phone"
                />
              </div>
              <div>
                <label htmlFor="city">City</label>
                <select
                  name="city"
                  id="city"
                  value={formData.city}
                  onChange={handleInputChange}
                >
                  {Number(formData.city_id) === 0 && (
                    <option value="">Select City</option>
                  )}
                  {cities.map((city) => (
                    <option key={city.city_id} value={city.city_id}>
                      {city.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="location">Location</label>
                <select
                  name="location"
                  id="location"
                  value={formData.location}
                  onChange={handleInputChange}
                  disabled
                >
                  {Number(formData.location_id) === 0 && (
                    <option value="">Select Location</option>
                  )}
                  {locations.map((location) => (
                    <option
                      key={location.location_id}
                      value={location.location_id}
                    >
                      {location.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="mileage">Mileage</label>
                <input
                  type="number"
                  name="mileage"
                  id="mileage"
                  value={formData.mileage}
                  onChange={handleInputChange}
                  placeholder="Mileage"
                />
              </div>
              <div>
                <label htmlFor="color">Color</label>
                <Select
                  value={color}
                  onChange={handleColorChange}
                  input={<OutlinedInput />}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>Placeholder</em>;
                    }
                    return selected;
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                  className="color-menu"
                >
                  <MenuItem value="0" disabled>
                    Select Color
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="White"
                  >
                    White{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "white",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Silver"
                  >
                    Silver{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "silver",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Black"
                  >
                    Black{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "black",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Grey"
                  >
                    Grey{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "Grey",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Blue"
                  >
                    Blue{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "blue",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Green"
                  >
                    Green{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "green",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Red"
                  >
                    Red{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "red",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Gold"
                  >
                    Gold{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "gold",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Maroon"
                  >
                    Maroon{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "maroon",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Beige"
                  >
                    Beige{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "beige",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Pink"
                  >
                    Pink{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "pink",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Brown"
                  >
                    Brown{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "brown",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Burgundy"
                  >
                    Burgundy{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "#800020",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Yellow"
                  >
                    Yellow{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "yellow",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Bronze"
                  >
                    Bronze{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "#CD7F32",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Purple"
                  >
                    Purple{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "purple",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Turquoise"
                  >
                    Turquoise{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "turquoise",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Orange"
                  >
                    Orange{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "orange",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Indigo"
                  >
                    Indigo{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "indigo",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Magenta"
                  >
                    Magenta
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "magenta",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Navy"
                  >
                    Navy{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "navy",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Unlisted"
                  >
                    Unlisted
                  </MenuItem>
                </Select>
              </div>
              {formData.color === 1 && (
                <div>
                  <>
                    <label htmlFor="unlisted_color">Unlisted Color</label>
                    <input
                      type="text"
                      id="unlisted_color"
                      name="unlisted_color"
                      value={formData.unlisted_color}
                      onChange={handleInputChange}
                    />
                  </>
                </div>
              )}
              <div>
                <label htmlFor="door">Doors</label>
                <select
                  name="door"
                  id="door"
                  value={formData.door}
                  onChange={handleInputChange}
                >
                  <option value="0">Select Doors</option>
                  <option value="1">2 Doors</option>
                  <option value="2">3 Doors</option>
                  <option value="3">4 Doors</option>
                  <option value="4">5 Doors</option>
                  <option value="5">6 Doors</option>
                </select>
              </div>
              <div>
                <label htmlFor="insured">Insured</label>
                <select
                  name="insured"
                  id="insured"
                  value={formData.insured}
                  onChange={(e) => handleInputChange(e)}
                >
                  {Number(formData.insured) === 0 && (
                    <option value="0">Select Option</option>
                  )}
                  <option value="1">yes</option>
                  <option value="2">No</option>
                </select>
              </div>
              <div>
                <label htmlFor="type">New/Used</label>
                <select
                  name="type"
                  id="type"
                  value={formData.type}
                  onChange={(e) => handleInputChange(e)}
                >
                  <option value="0">Select Option</option>
                  <option value="1">New</option>
                  <option value="2">Used</option>
                </select>
              </div>
              <div>
                <label htmlFor="file">Card Image/ Video</label>
                <input
                  type="file"
                  name="file"
                  id="file"
                  hidden
                  ref={imageInputRef}
                  onChange={(e) => {
                    handleImageChange(e);
                  }}
                  accept="image/*"
                />
                <div className="input">
                  <span className="tab" onClick={()=>imageInputRef.current.click()}>Click to add image</span>
                  <span className="file-name">{image!== "" ? image?.name : ""} </span>
                </div>
              </div>

              <div>
                <button type="submit" id="create_btn">
                  Update
                </button>
              </div>
            </form>
            <div className="card-image-section ">
              <div className="card-image">
              {displayImage !== "" && <img src={displayImage} alt="Product" />}
              {displayImage === "" && (
                <img src={`${cardRoute}/${image}`} alt="" />
              )}
              {/* <div className="delete-btn" 
              onClick={handleDeleteClick}
              ><DeleteOutlineIcon/></div> */}
              </div>
            </div>
          </>)
        }
        {isLoading && (
          <ProgressCircle height={"calc(100vh - 250px)"} />
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default EditCarForm;
