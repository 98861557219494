import React from "react";
import ColorNavbarWithCategory from "../../components/ColorNavbarWithCategory/ColorNavbarWithCategory";
import Footer from "../../components/Footer/Footer";
import CarCompareMainArea from "../../components/CarCompareMainArea/CarCompareMainArea";
import { Helmet } from "react-helmet";
const CarComparisionPage = () => {
  return (
    <>
      <Helmet>
        <title>Car Compare | CARS'R'US</title>
      </Helmet>
      <ColorNavbarWithCategory color={"#ED5565"} textColor={"black"} />
      <CarCompareMainArea />
      <Footer />
    </>
  );
};

export default CarComparisionPage;
