import React, { useEffect, useState, useRef, useMemo } from "react";
import "./UserDashboardChatMain.scss";
import Avatar from "../../assets/images/download.png";
import Rating from "@mui/material/Rating";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";
import {
  profileImageRoute,
  userProfilePictureRoute,
  vendorMessageImageRoute,
  getChatList,
  getChatMessage,
  insertChatMessage,
} from "../../utils/ApiRoutes";
import Welcome from "../../assets/images/welcome.png";
import moment from "moment";
import { useAuth } from "../../context/auth";
const UserDashboardChatMain = () => {
  // Refs
  const timeoutRef = useRef(null);
  const bottomRef = useRef();
  const scrollRef = useRef();
  const [auth] = useAuth();

  const [loading, setLoading] = useState(false);
  const [chats, setChats] = useState([]);
  const [filteredChats, setFilteredChats] = useState([]);
  const [active, setActive] = useState(false);
  const [messages, setMessages] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [message, setMessage] = useState("");
  const [search, setSearch] = useState("");
  const [key1, setKey1] = useState({})
  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    timeoutRef.current = setInterval(function () {
      getMessages(
        vendor.type,
        vendor.type === 0 ? vendor.user_id : vendor.vendor_id
      );
    }, 5000);
    scrollRef?.current?.scrollIntoView({ behavior: "smooth" });
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };

    // eslint-disable-next-line
  }, [active, vendor.type, vendor.user_id, vendor.vendor_id]);

  const getData = async () => {
    setLoading(true);
    const { data } = await axios.post(getChatList, {
      key: auth.user.user_id,
      chat_for: "users",
    });
    setChats(data.Chat_List.flat(1));
    setFilteredChats(data.Chat_List.flat(1));
    setLoading(false);
  };

  const getMessages = async (type, id) => {
    if (active === false) {
      return;
    } else {
      const { data } = await axios.post(getChatMessage, {
        key_1: `${auth.user.user_id} users`,
        key_2: type === 0 ? `${id} users` : `${id} vendor`,
        type,
      });
      if (data?.Chat.length !== messages.length) {
        setMessages(data?.Chat);
       
        // if (buttonDisplay === true) {
        //   document.getElementById("bottom-button").hidden = false;
        //   console.log("in block");
        // }
      }
    }
  };

  const handleChatClick = async (id, type) => {
    const { data } = await axios.post(getChatMessage, {
      key_1: `${auth.user.user_id} users`,
      key_2: type === 0 ? `${id} users` : `${id} vendor`,
      type,
    });
    setMessages(data?.Chat);
    setKey1({ ...data?.Key1[0]})
    setVendor({ ...data?.Key2[0], type });
    setActive(true);
  };

  const handleSendClick = async (event, id, receiver, type) => {
    if (event.key === "Enter" || event.type === "click") {
      if (message === "") {
        return;
      }
      const temp = message;
      setMessage("");
      const { data } = await axios.post(insertChatMessage, {
        sender_id: auth.user.user_id,
        receiver_id: id,
        message: temp,
        sender: "users",
        receiver,
        type,
      });
      if (data.error === false) {
        const { data } = await axios.post(getChatMessage, {
          key_1: `${auth.user.user_id} users`,
          key_2: type === 0 ? `${id} users` : `${id} vendor`,
          type,
        });
        setMessages(data?.Chat);
      } else {
        console.log("something Went wrong");
      }
      scrollToBottom();
    }
  };

  // const checkBottom = (e) => {
  //   const bottom =
  //     e.target.scrollHeight - e.target.scrollTop >= e.target.clientHeight + 100;
  //   // console.log(`${e.target.scrollHeight} - ${e.target.scrollTop} === ${e.target.clientHeight + 100} ===== ${bottom}`);
  //   setButtonDisplay(bottom);
  //   if (bottom === false) {
  //     document.getElementById("bottom-button").hidden = true;
  //   }
  // };

  // const handleBottomClick = (e) => {
  //   // e.preventDefault();
  //   scrollToBottom();
  //   document.getElementById("bottom-button").hidden = true;
  // };
  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearch(value);

    let filteredChat = chats?.filter((temp) => {
      const data = temp?.shop_name.toLowerCase();
      return data.includes(value.toLowerCase());
    });
    setFilteredChats(filteredChat);
  };

  const currentUserType = useMemo(
    () => (auth.userType === 2 ? "users" : "vendor"),
    [auth.userType]
  );
  return (
    <div className="chat-area-main">
      {!loading && (
        <div className="chats-lists">
          <div
            className="search-box"
            style={{ paddingBottom: "1rem", paddingTop: ".4rem" }}
          >
            <input
              type="text"
              placeholder="Search Vendor by name..."
              value={search}
              onChange={handleSearchChange}
            />
          </div>
          {filteredChats?.map((chat, i) =>
            Object.keys(chat).length > 3 ? (
              <div
                className="chat-card"
                key={i}
                onClick={() => handleChatClick(chat?.vendor_id, 1)}
              >
                <div className="profile-image">
                  {chat?.profile ? (
                    <img
                      src={`${profileImageRoute}/${chat?.profile}`}
                      alt="profile"
                    />
                  ) : (
                    <img src={Avatar} alt="profile" />
                  )}
                </div>
                <div className="chat-details">
                  <div className="top">
                    <h4>{chat?.shop_name}</h4>
                    <Rating value={parseFloat(chat?.rating)} readOnly />
                  </div>
                  {/* <div className="bottom"><p>{chat?.message}</p></div> */}
                </div>
              </div>
            ) : (
              <div
                className="chat-card"
                key={i}
                onClick={() => handleChatClick(chat?.user_id, 0)}
              >
                <div className="profile-image">
                  {chat?.user_image ? (
                    <img
                      src={`${userProfilePictureRoute}/${chat?.user_image}`}
                      alt="profile"
                    />
                  ) : (
                    <img src={Avatar} alt="profile" />
                  )}
                </div>
                <div className="chat-details">
                  <div className="top">
                    <h4>{chat?.user_name}</h4>
                  </div>
                  <div className="bottom">{/* <p>{chat?.message}</p> */}</div>
                </div>
              </div>
            )
          )}
        </div>
      )}
      {loading && (
        <div
          className="chats-lists"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <div className="chat-loader"></div>
        </div>
      )}
      {active && (
        <div className="chat-section">
          <div className="top">
            <div className="profile">
              {vendor.type === 1 ? (
                vendor?.profile ? (
                  <img
                    src={`${profileImageRoute}/${vendor?.profile}`}
                    alt="Profile"
                  />
                ) : (
                  <img src={Avatar} alt="profile" />
                )
              ) : vendor?.user_image ? (
                <img
                  src={`${profileImageRoute}/${vendor?.user_image}`}
                  alt="Profile"
                />
              ) : (
                <img src={Avatar} alt="profile" />
              )}
            </div>
            <div className="profile-details">
              <h4>{vendor.type === 1 ? vendor.shop_name : vendor.user_name}</h4>
              {vendor.type === 1 && (
                <p>
                  <Rating value={Number(vendor?.rating)} readOnly />{" "}
                </p>
              )}
            </div>
          </div>
          <div
            className="middle"
            // onScroll={(e) => checkBottom(e)}
          >
            <div className="messages-section">
              {messages?.map((message) => (
                <React.Fragment>
                  {message.sender_id === auth.user.user_id &&
                  message.sender === currentUserType ? (
                    <div className="sent" ref={bottomRef}>
                      <div className="profile">
                        {key1?.user_image ? (
                          <img
                            src={`${userProfilePictureRoute}/${key1?.user_image}`}
                            alt="Profile"
                          />
                        ) : (
                          <img src={Avatar} alt="Profile" />
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: ".5rem",
                        }}
                      >
                        <div className="message">
                          {message?.message}
                          <span>
                          {moment(message?.date, "YYYY-MM-DD").format("YYYY-MM-DD")}{" "}
                            {moment(message?.time, "hh:mm:ss").format("h:mm a")}
                          </span>
                        </div>
                        {message.file !== null && (
                          <div className="message" ref={bottomRef}>
                            <img
                              src={`${vendorMessageImageRoute}/${message?.image}`}
                              alt="Search"
                              style={{ width: "100%" }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="reply" ref={bottomRef}>
                      <div className="profile">
                        {vendor?.profile ? (
                          <img
                            src={`${profileImageRoute}/${vendor?.profile}`}
                            alt="Profile"
                          />
                        ) : (
                          <img src={Avatar} alt="Profile" />
                        )}
                      </div>
                      <div className="message">
                        {message?.message}
                        <span>
                        {moment(message?.date, "YYYY-MM-DD").format("YYYY-MM-DD")}{" "}
                          {moment(message?.time, "hh:mm:ss").format("h:mm a")}
                        </span>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
            <div ref={scrollRef}></div>
          </div>
          <div className="bottom">
            <div className="input">
              <input
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyPress={(e) =>
                  handleSendClick(
                    e,
                    vendor.type === 0 ? vendor.user_id : vendor?.vendor_id,
                    vendor.type === 0 ? "users" : "vendor",
                    vendor.type
                  )
                }
              />
              <span
                onClick={(e) =>
                  handleSendClick(
                    e,
                    vendor.type === 0 ? vendor.user_id : vendor?.vendor_id,
                    vendor.type === 0 ? "users" : "vendor",
                    vendor.type
                  )
                }
              >
                <SendIcon />
              </span>
            </div>
          </div>
        </div>
      )}
      {!active && (
        <div className="welcome-screen">
          <img src={Welcome} alt="welcome" />
        </div>
      )}
    </div>
  );
};

export default UserDashboardChatMain;
