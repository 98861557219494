
import React from "react";
import ProfileLayout from "../../Layouts/ProfileLayout";
import ProductSection from "../../components/ProductSection/ProductSection";
import { Helmet } from "react-helmet";
const UserProducts = () => {
  return (
    <>
    <Helmet>
        <title>User Products | CARS'R'US</title>
      </Helmet>
      <ProfileLayout>
        <ProductSection />
      </ProfileLayout>
    </>
  );
};

export default UserProducts;
