import React from "react";

import ColorNavbarWithCategory from "../../components/ColorNavbarWithCategory/ColorNavbarWithCategory";
import Footer from "../../components/Footer/Footer";
import CarPoolMainArea from "../../components/CarPoolMainArea/CarPoolMainArea";
import { Helmet } from "react-helmet";

const CarPoolPage = () => {
  return (
    <div>
      <Helmet>
        <title>Car Pool Vendors Listing | CARS'R'US</title>
      </Helmet>
      <ColorNavbarWithCategory color={"#3C5298"} textColor={"white"} />
      <CarPoolMainArea />
      <Footer />
    </div>
  );
};

export default CarPoolPage;
