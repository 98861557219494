import React from "react";

import ColorNavbarWithCategory from "../../components/ColorNavbarWithCategory/ColorNavbarWithCategory";
import Footer from "../../components/Footer/Footer";
import CarRentingMainArea from "../../components/CarRentingMainArea/CarRentingMainArea";
import { Helmet } from "react-helmet";

const CarRentingPage = () => {
  return (
    <div>
      <Helmet>
        <title>Renting Vendors | CARS'R'US</title>
      </Helmet>
      <ColorNavbarWithCategory color={"#ED5565"} textColor={"white"} />
      <CarRentingMainArea />
      <Footer />
    </div>
  );
};

export default CarRentingPage;
