import "./HomeMainArea.scss";

import human1 from "../../assets/images/human1.png";
import human2 from "../../assets/images/human2.png";
import car1 from "../../assets/images/car1.png";
import car2 from "../../assets/images/car2.png";
import phoneImg from "../../assets/images/mobile-img.png";
import rating from "../../assets/images/rating.png";

import facebook from "../../assets/icons/facebook.png";
import instagram from "../../assets/icons/instagram.png";
import linkedin from "../../assets/icons/linkedIn.png";
import twitter from "../../assets/icons/twitter.png";
import youTube from "../../assets/icons/youTube.png";
import tikTok from "../../assets/icons/tikTok.png";
import { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { cardRoute, homepageRoute } from "../../utils/ApiRoutes";
import { useNavigate } from "react-router-dom";

const HomeMainArea = () => {

  const [homeStates, setHomeStates] = useState([]);

  const controller =  useMemo(() => new AbortController(), [])
  const navigate = useNavigate()


  const getData = useCallback(
    async () => {
        try {
          const {data} =  await axios.get(homepageRoute, {signal :controller.signal})
          setHomeStates(data);
        } catch (error) {
          console.error(error.message);
        } 
    },
    [controller.signal],
  )
  

  useEffect(() => {
      getData()
    return () => controller.abort()
  }, [controller, getData])
  

  return (
    <div className="home-main-area">
      <div className="first-section">
        <ul>
          <li>Services</li>
          <li>Buy/Sell Cars</li>
          <li>Parts & Accessories</li>
        </ul>
        <h2>Experience Unparalleled Automotive services</h2>
      </div>
      <div className="second-section">
        <div className="left-section">
          <h3>You put the ‘us’ in cars’r’us</h3>
          <div className="photo-gallery">
            <img src={human2} alt="human" />
            <img src={human1} alt="human" />
            <div>
              <img src={car1} alt="car" />
              <img src={car2} alt="car" />
            </div>
          </div>
        </div>
        <div className="right-section">
          <div className="icons">
            <img src={facebook} alt="social-media-icon" />
            <img src={twitter} alt="social-media-icon" />
            <img src={instagram} alt="social-media-icon" />
            <img src={linkedin} alt="social-media-icon" />
            <img src={tikTok} alt="social-media-icon" />
            <img src={youTube} alt="social-media-icon" />
          </div>
          <div className="btn">Share now</div>
          <div className="Hash-tag">#us</div>
        </div>
      </div>
      <div className="third-section">
        <div className="section-heading">
          <p>Best offers in the market</p>
          <h2>All cars everything</h2>
        </div>
        <div className="video-section">
          {homeStates?.Products?.slice(0, 3)?.map((product, i) => (
            <div className="small-card" key={i} onClick={()=>navigate(`/product/${product.url}`)}>
              <img src={`${cardRoute}/${product.card}`} alt="" />
            </div>
          ))}
        </div>
      </div>
      <div className="fourth-section">
        <div className="left-section-main">
          <h3 className="section-heading">
            Empowering you to find any car part and service
          </h3>
          <div className="left-section">
            <h4>
              Download the app to avail bonus rewards and stay ready on the go
              with
              <span style={{ color: "#E52A2D" }}> LIVE SEARCH</span>
            </h4>
          </div>
        </div>
        <div className="right-section">
          <img src={phoneImg} alt="" />
          <div>
            <img src={rating} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeMainArea;
