import "./CarBuyingLongPage.scss"

import ColorNavbar from "../../components/ColorNavbar/ColorNavbar";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Footer from "../../components/Footer/Footer"

const CarBuyingLongPage = () => {
  return (
    <div>
      <ColorNavbar color={"#ED5565"} textColor={"black"} />
      <div className="header">
        <div><span>Make</span> <KeyboardArrowDownIcon/></div>
        <div>Model <KeyboardArrowDownIcon/></div>
        <div>Price <KeyboardArrowDownIcon/></div>
        <div>Year <KeyboardArrowDownIcon/></div>
        <div>City <KeyboardArrowDownIcon/></div>
        <div>Filter <KeyboardArrowDownIcon/></div>
      </div>
      <div className="products">
      </div>
      <Footer/>
    </div>
  );
};

export default CarBuyingLongPage;
