import React from "react";
import "./Businessbadge.scss";

const BusinessBadge = ({badge}) => {
  return (
    <div className="gift-badge-main">
      {/* <div className="checkbox">
        <input type="checkbox" />
      </div> */}
      <span>{badge.name}</span>
    </div>
  );
};

export default BusinessBadge;
