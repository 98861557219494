import React, { useState } from "react";

import "../ForgotPassword/ForgotPassword.scss";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { vendorFindAccount } from "../../utils/ApiRoutes";

import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const VendorForgotPassword = () => {
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  const toastOptions = {
    position: "bottom-right",
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    const response = await axios.post(vendorFindAccount, { email });
    if (response.data.error === false) {
      localStorage.setItem("vendorCode", response.data.code);
      navigate("/vender-otp");
    } else {
      toast.error("Email not found", toastOptions);
    }
  };

  return (
    <>
      <Helmet>
        <title>Find Account | CARS'R'US</title>
      </Helmet>
      <div className="forgot-page">
        <Link to={"/"} className="logo">
          CARS'R'US
        </Link>

        <div className="form-section">
          <form>
            <h1>Find Your Account</h1>
            <p>Please enter your email address to search for your account.</p>
            <input
              type="email"
              placeholder="Email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/login");
                }}
              >
                Cancel
              </button>
              <button onClick={(e) => handleSearch(e)}>Search</button>
            </div>
          </form>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default VendorForgotPassword;
