import React from "react";
import ProfileLayout from "../../Layouts/ProfileLayout";
import AdSection from "../../components/AdSection/AdSection";
import { Helmet } from "react-helmet";
const UserAd = () => {
  return (
    <>
      <Helmet>
        <title>User Ads | CARS'R'US</title>
      </Helmet>
      <ProfileLayout>
        <AdSection />
      </ProfileLayout>
    </>
  );
};

export default UserAd;
