import React, { useState, useContext } from "react";


import "./CarsHeader.scss"

import ColorNavbar from '../ColorNavbar/ColorNavbar'
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import { AutoComplete } from "primereact/autocomplete";
import { dataContext } from "../../App";
const CarsHeader = ({background, slogan}) => {
  const navigate = useNavigate();
  const { brands } = useContext(dataContext);
  const [searchTerms, setSearchTerms] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const search = (event) => {
    const filteredItems = brands.filter((item) => {
      const itemName = item.name.toLowerCase();
      const lowerCaseSearchTerm = event.query.toLowerCase();
      return itemName.includes(lowerCaseSearchTerm);
    });
    const itemNames = filteredItems.map((item) => item.name);
    setSearchTerms(itemNames.length === 0 ? [] : itemNames);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = brands.filter((item) => {
      const itemName = item.name.toLowerCase();
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      return itemName === lowerCaseSearchTerm;
    });
    navigate(`/carshort?brand=${data[0].brand_id}`);
  };
  return (
    <div className='cars-header'>
        <ColorNavbar color={"#ED5565"} textColor={"black"}/>
        <div
        className="hero-section"
        style={{ background: `url(${background}) no-repeat right #000` }}
      >
        <div className="vertical-slogan">
          {slogan}
        </div>
        <div className="search-bar">
        <div className="main-search-container">
      <form className="main-search-bar" onSubmit={(e) => handleSubmit(e)}>
        <AutoComplete
          placeholder="Search cars on CARS'R'US"
          value={searchTerm}
          suggestions={searchTerms}
          completeMethod={search}
          onChange={(e) => setSearchTerm(e.value)}
          forceSelection
          inputId="model_year"
        />
        <button type="submit">Search</button>
      </form>
    </div>
        </div>
        <div className='services-section'>
            <ul className='services'>
                <Link to={"/dashboard"}>Sell My Car </Link>
                <Link to={"/carshort"}>Buy Car</Link>
                <Link to={"/renting"}>Rent Car</Link>
                <Link to={"/loan"}>Financing</Link>
                <Link to={"/import"}>Import Export</Link>
                <Link to={"/inspection"}>Inspection</Link>
            </ul>
        </div>
      </div>
    </div>
  )
}

export default CarsHeader