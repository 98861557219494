import React, { useState, useEffect, useRef } from "react";
import "./InspectionReport.scss";
import axios from "axios";
import { getInspectionReportDetails } from "../../utils/ApiRoutes";
import { capitalize } from "@mui/material";
import html2pdf from "html2pdf.js";

import { Chart, registerables } from "chart.js";
// import { Chart } from 'chart.js';

const InspectionReport = () => {
  const reportTemplateRef = useRef();
  const chartRef = useRef(null);
  const pieChart = useRef(null);
  const [ratingCount, setRatingCount] = useState({
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
  });
  const [inspectionReportData, setInspectionReportData] = useState({
    engine: "",
    brakes: "",
    suspension: "",
    interior: "",
    ac: "",
    electrical: "",
    exterior: "",
    tyres: "",
    wires: "",
    engine_blow: "",
    engine_noise: "",
    engine_viberation: "",
    engine_mounts: "",
    hoses: "",
    front_right_desc: "",
    front_left_desc: "",
    front_right_brake_pad: "",
    front_left_brake_pad: "",
    steering_wheel: "",
    right_ball_joint: "",
    left_ball_joint: "",
    Right_z_links: "",
    left_z_links: "",
    front_right_boots: "",
    front_left_boots: "",
    front_right_bushes: "",
    front_left_bushes: "",
    front_right_shock: "",
    front_left_shock: "",
    rear_right_bushes: "",
    rear_left_bushes: "",
    rear_right_shock: "",
    rear_left_shock: "",
    steering_wheel_buttons: "",
    horn: "",
    head_lights: "",
    indicators: "",
    wiper: "",
    right_side_mirror: "",
    left_side_mirror: "",
    rear_view_mirror_dimmer: "",
    interior_lightings: "",
    dash_controls: "",
    rear_view_camera: "",
    floor_mat: "",
    dashboard: "",
    engine_pick: "",
  });
  const [allDetails, setAllDetails] = useState({});
  const [ratingSum, setRatingSum] = useState(0)

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    let sum = 0,
      data = {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
      };
    Object.values(inspectionReportData).forEach((value) => {
      data = {
        ...data,
        [value]: data[value] + 1,
      };
      // sum += parseInt(value)
    });
    setRatingCount(data);
    setRatingSum(sum);
  }, [inspectionReportData]);

  const getData = async () => {
    try {
      const { data } = await axios.get(getInspectionReportDetails);
      setAllDetails(data.Inspection[0]);
      setInspectionReportData(JSON.parse(data.Inspection[0].report)[0]);
    } catch (error) {
      console.log(error.message);
    }
  };

  const convertToPDF = () => {
    const element = reportTemplateRef.current; // ID of the HTML element to convert

    var opt = {
      margin: [1, 0, 1, 0.2],
      filename: "myfile.pdf",
      image: { type: "png" },
      html2canvas: { scale: 1 },
      jsPDF: { unit: "pt", format: "letter", orientation: "portrait" },
    };

    html2pdf().set(opt).from(element).save();
  };

  // chart configuration

  useEffect(() => {
    Chart.register(...registerables); // Register the necessary plugins

    const chartElement = pieChart.current;
    let chartInstance = null; // Reference to the chart instance

    const data = {
      labels: [
        "0 Star",
        "1 Star",
        "2 Star",
        "3 Star",
        "4 Star",
        "5 Star",
        "6 Star",
        "7 Star",
        "8 Star",
        "9 Star",
        "10 Star",
      ],
      datasets: [
        {
          label: "Count",
          data: ratingCount,
          borderWidth: 1,
          backgroundColor: [
            "rgba(238, 238, 238, 0.8)",
            "rgba(26, 93, 26, 0.8)",
            "rgba(203, 67, 53, 0.8)",
            "rgba(31, 97, 141, 0.8)",
            "rgba(241, 196, 15, 0.8)",
            "rgba(39, 174, 96, 0.8)",
            "rgba(136, 78, 160, 0.8)",
            "rgba(211, 84, 0, 0.8)",
            "rgba(0, 0, 0, 0.8)",
            "rgba(6, 143, 255, 0.8)",
            "rgba(246, 216, 19,0.8)",
          ],
        },
      ],
    };

    const handleHover = (evt, item, legend) => {
      legend.chart.data.datasets[0].backgroundColor.forEach(
        (color, index, colors) => {
          colors[index] =
            index === item.index || color.length === 9 ? color : color + "4D";
        }
      );
      legend.chart.update();
    };

    const handleLeave = (evt, item, legend) => {
      legend.chart.data.datasets[0].backgroundColor.forEach(
        (color, index, colors) => {
          colors[index] = color.length === 9 ? color.slice(0, -2) : color;
        }
      );
      legend.chart.update();
    };

    const config = {
      type: "pie",
      data: data,
      options: {
        plugins: {
          legend: {
            onHover: handleHover,
            onLeave: handleLeave,
          },
        },
      },
    };

    // Destroy the existing chart instance before creating a new one
    if (chartInstance) {
      chartInstance.destroy();
    }

    chartInstance = new Chart(chartElement, config);

    return () => {
      if (chartInstance) {
        chartInstance.destroy(); // Clean up the chart instance when the component unmounts
      }
    };
    // eslint-disable-next-line 
  }, []);

  useEffect(() => {
    Chart.register(...registerables);
    const chartElement = chartRef.current;
    let chartInstance = null;
    const labels = Object.keys(inspectionReportData).map((key) =>
      capitalize(key.replaceAll("_", " "))
    );
    const data = {
      labels: labels,
      datasets: [
        {
          label: "My First Dataset",
          data: Object.keys(inspectionReportData).map((key) =>
            parseInt(inspectionReportData[key])
          ),
          fill: false,
          maintainAspectRatio: false,
          barThickness: 25,
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(255, 159, 64, 0.2)",
            "rgba(255, 205, 86, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(153, 102, 255, 0.2)",
            "rgba(201, 203, 207, 0.2)",
          ],
          borderColor: [
            "rgb(255, 99, 132)",
            "rgb(255, 159, 64)",
            "rgb(255, 205, 86)",
            "rgb(75, 192, 192)",
            "rgb(54, 162, 235)",
            "rgb(153, 102, 255)",
            "rgb(201, 203, 207)",
          ],
          borderWidth: 1,
        },
      ],
    };
    const config = {
      type: "bar",
      data,
      options: {
        inflateAmount: 0.5,
        indexAxis: "y",
        scales: {
          y: {
            grid: {
              drawTicks: true,
            },
          },
          x: {
            grid: {
              drawTicks: true,
              offset: false,
            },
          },
        },
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
          },
        },
      },
    };
    if (chartInstance) {
      chartInstance.destroy();
    }

    chartInstance = new Chart(chartElement, config);

    return () => {
      if (chartInstance) {
        chartInstance.destroy(); // Clean up the chart instance when the component unmounts
      }
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="inspection-report">
      <button onClick={convertToPDF}>Save Pdf</button>
      <div
        className="card"
        ref={reportTemplateRef}
        style={{
          width: "80%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          padding: "2rem 1.5rem",
          borderRadius: ".5rem",
          fontFamily: "roboto",
        }}
      >
        <div className="header">
          <h1>Inspection Report</h1>
        </div>
        {/* <div className="cards" >
          {renderedElements}
        </div> */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div>
            <h2>Average</h2>
            <h4>{(ratingSum / Object.keys(inspectionReportData).length).toFixed(1)}</h4>
          </div>
          <div
            style={{ aspectRatio: "unset", width: "600px", height: "400px" }}
          >
            <canvas ref={pieChart} height={1000}></canvas>
          </div>
        </div>
        <div
          style={{
            height: "2000px",
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
            aspectRatio: "unset",
          }}
        >
          <canvas ref={chartRef} height={1000}></canvas>
        </div>
      </div>
    </div>
  );
};

export default InspectionReport;
