import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  Fragment,
} from "react";
import "./ResultsMain.scss";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  allSearchRoute,
  bookmarkRoute,
  userBookmarkListing,
} from "../../utils/ApiRoutes";
import ProgressCircle from "../ProgressCIrcle/ProgressCircle";
import HorizontalProductCard from "../HorizontalProductCard/HorizontalProductCard";
import { useAuth } from "../../context/auth";
import { toastOptions } from "../../utils/ToastOptions";
import { toast } from "react-toastify";
import MerchantCard from "../MerchantCard/MerchantCard";
import { merchantDetailsPage } from "../../utils/Routes";

const ResultsMain = () => {
  const [auth] = useAuth();
  const [results, setResults] = useState({});
  const [bookmarkedProduct, setBookmarkedProduct] = useState([]);
  const [badges, setBadges] = useState([])
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
  const controller = useMemo(() => new AbortController(), []);

  const getBookmark = async () => {
    if (auth.user && auth.userType === 2) {
      const { data } = await axios.post(userBookmarkListing, {
        user_id: auth.user?.user_id,
      });
      setBookmarkedProduct(data?.Product);
    }
  };

  const handleBookmarkClick = async (url, product_id) => {
    if (auth.user && auth.userType === 2) {
      const index = bookmarkedProduct.findIndex(
        (data) => data.product_id === product_id
      );
      if (index === -1) {
        setBookmarkedProduct((prev) => [...prev, { product_id }]);
      } else {
        setBookmarkedProduct((prev) =>
          prev.slice(0, index).concat(prev.slice(index + 1))
        );
      }
      await axios.post(bookmarkRoute, {
        user_id: auth.user?.user_id,
        url,
      });
    } else if (auth.user && auth.userType === 1) {
      return;
    } else {
      toast.error("login to bookmark the product", toastOptions);
    }
  };

  const getData = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.post(
        allSearchRoute,
        { search: searchParams.get("search") },
        { signal: controller.signal }
      );
      setResults(data);
      setBadges(data.Badge)
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [controller.signal, searchParams]);

  useEffect(() => {
    getData();
    return () => controller.abort();
  }, [controller, getData]);

  const handleCompareProductClick = () => {
    return;
  };

  const handleCardClick = (url) => {
    navigate(`${merchantDetailsPage}/${url}`);
  };

  return (
    <Fragment>
      {!isLoading && (
        <div className="search-results-main">
          <div className="search-section-wrapper">
            <div>
              <h1>
                Search Results for : {searchParams.get("search").toUpperCase()}
              </h1>
            </div>
            {results?.Products?.length > 0 && (
              <div className="search-container">
                <h2>Products</h2>
                <div className="search-product-grid">
                  {results?.Products?.map((product, i) => (
                    <HorizontalProductCard
                      key={i}
                      product={product}
                      ad={false}
                      handleBookmarkClick={handleBookmarkClick}
                      handleCompareProductClick={handleCompareProductClick}
                      bookmarkedProduct={bookmarkedProduct}
                      setisLoading={setIsLoading}
                      isLoading={isLoading}
                    />
                  ))}
                </div>
              </div>
            )}
            {(results?.Brand_Vendors?.length > 0 ||
              results?.Maintenance_Vendors?.length > 0 ||
              results?.Parttypes_Vendors?.length > 0) && (
              <div className="search-container">
                <h2>Vendors</h2>
                <div className="search-vendor-grid">
                  {results?.Brand_Vendors?.map((vendor) =>
                    vendor?.map((data, i) => (
                      <div style={{ cursor: "pointer" }} key={i}>
                        <MerchantCard
                          vendor={data}
                          badges={badges}
                          badgeId={JSON.parse(data.badge)}
                          handleCardClick={handleCardClick}
                          background={"white"}
                        />
                      </div>
                    ))
                  )}
                  {results?.Maintenance_Vendors?.map((vendor) =>
                    vendor?.map((data, i) => (
                      <div style={{ cursor: "pointer" }} key={i}>
                        <MerchantCard
                          vendor={data}
                          badges={badges}
                          badgeId={JSON.parse(data.badge)}
                          handleCardClick={handleCardClick}
                          background={"white"}
                        />
                      </div>
                    ))
                  )}
                  {results?.Parttypes_Vendors?.map((vendor) =>
                    vendor?.map((data, i) => (
                      <div style={{ cursor: "pointer" }} key={i}>
                        <MerchantCard
                          vendor={data}
                          badges={badges}
                          badgeId={JSON.parse(data.badge)}
                          handleCardClick={handleCardClick}
                          background={"white"}
                        />
                      </div>
                    ))
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {isLoading && <ProgressCircle height={"calc(100vh - 125px)"} />}
    </Fragment>
  );
};

export default ResultsMain;
