import React from "react";

import "./HorizontalProductCardSmall.scss";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SpeedIcon from "@mui/icons-material/Speed";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { cardRoute } from "../../utils/ApiRoutes";
import { Image } from 'primereact/image';
import { useNavigate } from "react-router-dom";
const HorizontalProductCardSmall = ({ad,url}) => {
  const navigate =  useNavigate();
  const handleClick = () => {
    if (url) {
      navigate(`/product/${url}`)
    }else{
      return
    }
  }
  return (
    <div className="horizontal-product-card-small">
      <div className="product-image">
        {/* <img src={`${cardRoute}/${ad.card}`} alt="" /> */}
        <Image src={`${cardRoute}/${ad.card}`}  alt={ad.model_name} preview width="250" />
      </div>
      <div className="product-details" onClick={handleClick}>
        <div className="product-price">
          AED: <strong>{ad.price}</strong>
        </div>
        <div className="product-model">
          <h6>{ad.brand_name}</h6>
          <span />
          <h6>{ad.model_name}</h6>
        </div>
        {/* <div className="product-desc">
          2Door Wildtrac Cactus Gray @ Altayer Motors Pre owned Showroom Al
          Quose.
        </div> */}
        <div className="product-specification">
          <span>
            <CalendarTodayIcon /> {ad.model_year}
          </span>
          <span>
            <SpeedIcon /> {ad.milage} km
          </span>
        </div>
        <div className="product-location">
          <span>
            <LocationOnOutlinedIcon />
            {ad.location_name}, {ad.city_name}
          </span>
        </div>
      </div>
    </div>
  );
};

export default HorizontalProductCardSmall;
