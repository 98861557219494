import React, { useState, useEffect } from "react";
import PaymentLayout from "../../Layouts/PaymentLayout";

import "./AdPayment.scss";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import { handleCounterClick } from "../../utils/ReuseFunction";
import axios from "axios";
import { cardRoute, productRoute } from "../../utils/ApiRoutes";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import { Door } from "../../assets/icons/svg";
import { Doors, Colors } from "../../assets/data";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
const AdPayment = () => {
  const url = Object.values(useParams())[0]
  
  const [counter, setCounter] = useState(0);
  const [price, setprice] = useState(0);
  const [productDetails, setProductDetails] = useState({});
  const controller = new AbortController();

  useEffect(() => {
    getData();
    return () => controller.abort();
    // eslint-disable-next-line 
  }, []);

  const getData = async () => {
    const response = await axios.get(`${productRoute}/${url}` ,{signal:controller.signal});
    setProductDetails(response.data.Product[0]);
  };

  return (
    <div>
      <Helmet>
        <title>Ad Payment | CARS'R'US</title>
      </Helmet>
      <div className="ad-payment">
        <PaymentLayout >
          <div className="right-section">
            <div className="top-header">
              <h4>Your order summary</h4>
              <p>1 items</p>
            </div>
            <div className="card-section">
              <div className="card">
                <div className="image">
                  <img src={`${cardRoute}/${productDetails?.card}`} alt="product" />
                </div>
                <div className="details">
                  <div className="price">AED {productDetails?.price}</div>
                  <div className="model">
                    {productDetails?.brand_name} - {productDetails?.model_name}
                  </div>
                  <div className="specs">
                    <span>
                      <CalendarTodayIcon /> {productDetails?.model_year}
                    </span>
                    {/* <span>
                      <SpeedIcon /> {productDetails.milage} km
                    </span> */}
                    <span>
                      <ColorLensIcon />{" "}
                      {productDetails?.color_id === 1
                        ? productDetails?.unlisted_color
                        : Colors[Number(productDetails?.color_id)]}
                    </span>
                    <span>
                      <Door /> {Doors[Number(productDetails?.door_id)]}
                    </span>
                  </div>
                  <div className="location">
                    <span>
                      <LocationOnOutlinedIcon />
                      {productDetails?.location_name}, {productDetails?.city_name}, {productDetails?.country_name}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="days-counter">
              <h4>Days</h4>
              <div>
                <button
                  onClick={() =>
                    handleCounterClick("-", setCounter, setprice, counter)
                  }
                >
                  <RemoveIcon />
                </button>
                <h5>{counter}</h5>
                <button
                  onClick={() =>
                    handleCounterClick("+", setCounter, setprice, counter)
                  }
                >
                  <AddIcon />
                </button>
              </div>
            </div>
            <div className="price">
              <p>
                Total Payable : <span>{price} AED</span>
              </p>
            </div>
          </div>
        </PaymentLayout>
      </div>
    </div>
  );
};

export default AdPayment;
