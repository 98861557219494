import "./CarMenuCard.scss"

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { Link } from "react-router-dom";

const CarMenuCard = ({icon, text,link}) => {
  return (
    <Link className="card-menu-card" to={link}>
      <h4> {icon} {text}</h4>
      <ChevronRightIcon/>
    </Link>
  );
};

export default CarMenuCard;
