import React, { useState } from "react";
import DashboardLayout from "../../Layouts/DashboardLayout";
import AddCarsMainArea from "../../components/AddCarsMainArea/AddCarsMainArea";
import AddCarForm from "../../components/AddCarsMainArea/AddCarForm";
import CarAmenities from "../../components/AddCarsMainArea/CarAmenities";
import AddCarImages from "../../components/AddCarsMainArea/AddCarImages";
import EditCarForm from "../../components/AddCarsMainArea/EditCarForm";
import { Helmet } from "react-helmet";
const AddCarsVendor = () => {
  const [active, setActive] = useState("carmain");

  return (
    <>
      <Helmet>
        <title>
          {active === "carmain"
            ? "Car Listings"
            : active === "addcar"
            ? "Add Car"
            : active === "caramenities"
            ? "Car Amenities"
            : active === "carimages"
            ? "Car Images"
            : "Edit Car"}{" "}
          | CARS'R'US
        </title>
      </Helmet>
      <DashboardLayout>
        {active === "carmain" && <AddCarsMainArea setActive={setActive} />}
        {active === "addcar" && <AddCarForm setActive={setActive} />}
        {active === "caramenities" && <CarAmenities setActive={setActive} />}
        {active === "carimages" && <AddCarImages setActive={setActive} />}
        {active === "editcar" && <EditCarForm setActive={setActive} />}
      </DashboardLayout>
    </>
  );
};

export default AddCarsVendor;
