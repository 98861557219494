import React from 'react'
import "./TopNavigationUserProfile.scss"
import { Link, useNavigate } from 'react-router-dom'

import {MdOutlineNotificationsActive} from "react-icons/md"
import {BsBookmarkPlus,BsChatLeftText} from "react-icons/bs"
import {CgNotes} from "react-icons/cg"
import {BiSearchAlt2} from "react-icons/bi"

import MenuIcon from '@mui/icons-material/Menu';
import {useAuth} from "../../context/auth"
const TopNavigationUserProfile = ({handleMenuClick}) => { 

  const [auth] = useAuth()
    const navigate = useNavigate()
  return (
    <div className='top-navbar'>
        <div className='pill-section'>
            <Link className="large-pill" to={"/cars"}>Search Cars</Link>
            <Link className="large-pill" to={"/parts"}>Search Parts</Link>
            <Link className="large-pill" to={"/workshop"}>Search Workshops</Link>
        </div>
        <div className='notification-area'>
            {/* <div>
                <MdOutlineNotificationsActive/>
                Notification
            </div>  */}
            <div onClick={()=> navigate("/my-bookmarks")}>
                <BsBookmarkPlus/>
                Bookmark
            </div> 
            <div onClick={() => navigate("/my-chat")}>
                <BsChatLeftText/>
                Chat
            </div> 
            <div onClick={() => navigate("/my-ads")}>
                <CgNotes/>
                Advertisement
            </div> 
            {/* <div>
                <BiSearchAlt2/>
                Search
            </div>  */}
        </div>
        <div className='username'>{auth.user?.name}</div>
        <div className='ad-button' style={{cursor:"pointer"}} onClick={()=>navigate("/dashboard/vender-individual-signup")}>Place Your Ad</div>
        <div className='menu-icon'  onClick={()=> handleMenuClick()}>
            <MenuIcon/>
        </div>
    </div>
  )
}

export default TopNavigationUserProfile