import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useContext,
  useCallback,
} from "react";
import "./AddCarsMainArea.scss";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "axios";
import {
  GetModelRoute,
  addCarRoute,
  getCitiesRoute,
  getlocationRoute,
} from "../../utils/ApiRoutes";
import { addCarFormValidate } from "../../utils/ReuseFunction";
import { ToastContainer } from "react-toastify";

import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ProgressCircle from "../ProgressCIrcle/ProgressCircle";
import { useAuth } from "../../context/auth";
import { dataContext } from "../../App";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AllColors = [
  "",
  { id: 1, name: "Unlisted" },
  { id: 2, name: "White" },
  { id: 3, name: "Silver" },
  { id: 4, name: "Black" },
  { id: 5, name: "Grey" },
  { id: 6, name: "Blue" },
  { id: 7, name: "Green" },
  { id: 8, name: "Red" },
  { id: 9, name: "Gold" },
  { id: 10, name: "Maroon" },
  { id: 11, name: "Beige" },
  { id: 12, name: "Pink" },
  { id: 13, name: "Brown" },
  { id: 14, name: "Burgundy" },
  { id: 15, name: "Yellow" },
  { id: 16, name: "Bronze" },
  { id: 17, name: "Purple" },
  { id: 18, name: "Turquoise" },
  { id: 19, name: "Orange" },
  { id: 20, name: "indigo" },
  { id: 21, name: "Magenta" },
  { id: 22, name: "Navy" },
];

const AddCarForm = ({ setActive }) => {
  const imageInputRef = useRef();

  const { countries, brands } = useContext(dataContext);
  const country = useMemo(
    () =>
      countries.find(
        (country) => country.name.toLowerCase() === "united arab emirates"
      ),
    [countries]
  );
  const [auth] = useAuth();
  const initialState = useMemo(
    () => ({
      brand: "",
      model: "",
      modelYear: "",
      price: "",
      phone: auth.user ? auth.user.user_phone : "",
      country_id: country.country_id,
      city: "",
      location: "",
      mileage: "",
      color: "",
      insured: "",
      door: "",
      unlisted_color: null,
      type: "",
    }),
    [auth.user, country.country_id]
  );
  const serviceId = localStorage.getItem("serviceId");
  const [image, setImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [models, setModels] = useState([]);
  const [locations, setLocations] = useState([]);
  const [color, setColor] = useState("");
  const [displayImage, setDisplayImage] = useState("");
  const [formData, setFormData] = useState(initialState);

  const handleInputChange = async (event) => {
    const { name, value, type } = event.target;
    
    if (type === "number") {
      if (!isNaN(value) && parseFloat(value) >= 0) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
    if (name === "brand") {
      const response = await axios.get(`${GetModelRoute}/${value}`);
      setModels(response.data.Model);
      document.getElementById("model").removeAttribute("disabled");
    }
    if (name === "city") {
      const response = await axios.get(`${getlocationRoute}/${value}`);
      setLocations(response.data.location);
      document.getElementById("location").removeAttribute("disabled");
    }
  };

  const handleAddMore = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (addCarFormValidate(formData, image)) {
      const {
        brand,
        model,
        modelYear,
        price,
        phone,
        city,
        mileage,
        color,
        door,
        location,
        insured,
        country_id,
        unlisted_color,
        type,
      } = formData;

      const fd = new FormData();
      fd.append("card", image);
      fd.append("brand_id", brand);
      fd.append("model_id", model);
      fd.append("model_year", modelYear);
      fd.append("price", price);
      fd.append("phone", phone);
      fd.append("city_id", city);
      fd.append("location_id", location);
      fd.append("milage", mileage);
      fd.append("color_id", color);
      fd.append("door_id", door);
      fd.append("insured", insured);
      fd.append("vendor_code", auth.user?.code);
      fd.append("country_id", country_id);
      fd.append("unlisted_color", unlisted_color);
      fd.append("uploaded_by", 1);
      fd.append("purpose", Number(serviceId) === 6 ? 1 : 0);
      fd.append("type", type);
      try {
        const response = await axios.post(addCarRoute, fd, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.data.error === false) {
          setFormData(initialState);
          setImage(null);
          setDisplayImage("");
          setColor("");
        } else {
        }
      } catch (error) {
        console.error(error);
      }
    }
    setIsLoading(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (addCarFormValidate(formData, image)) {
      const {
        brand,
        model,
        modelYear,
        price,
        phone,
        city,
        mileage,
        color,
        door,
        location,
        insured,
        country_id,
        unlisted_color,
        type,
      } = formData;

      const fd = new FormData();
      fd.append("card", image);
      fd.append("brand_id", brand);
      fd.append("model_id", model);
      fd.append("model_year", modelYear);
      fd.append("price", price);
      fd.append("phone", phone);
      fd.append("city_id", city);
      fd.append("location_id", location);
      fd.append("milage", mileage);
      fd.append("color_id", color);
      fd.append("door_id", door);
      fd.append("insured", insured);
      fd.append("vendor_code", auth.user?.code);
      fd.append("country_id", country_id);
      fd.append("unlisted_color", unlisted_color);
      fd.append("uploaded_by", 1);
      fd.append("purpose", Number(serviceId) === 6 ? 1 : 0);
      fd.append("type", type);
      try {
        const response = await axios.post(addCarRoute, fd, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.data.error === false) {
          setActive("carmain");
        } else {
        }
      } catch (error) {
        console.error(error);
      }
    }
    setIsLoading(false);
  };

  const getData = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `${getCitiesRoute}/${country.country_id}`
      );
      setCities(data.city);
    } catch (error) {
      console.error(error);
    }
  }, [country.country_id]);

  useEffect(() => {
    getData();
  }, [getData]);

  const date = new Date();
  const years = [];
  for (let index = date.getFullYear() + 1; index > 1950; index--) {
    years.push(index);
  }

  const handleColorChange = (event) => {
    const selectedColor = event.target.value;
    setColor(selectedColor);
  };

  useEffect(() => {
    const id = AllColors.filter((currentColor) => currentColor.name === color);
    if (id?.length !== 0) {
      setFormData({ ...formData, color: id[0].id });
    }
    // eslint-disable-next-line
  }, [color]);

  const handleDeleteClick = () => {
    setDisplayImage("");
    setImage("");
  };

  return (
    <div className="add-cars-main">
      <div className="add-cars-section">
        <h1>Products</h1>
        <div onClick={() => setActive("carmain")}>
          <ArrowBackIcon />
        </div>
      </div>
      <div className="form-section">
        {!isLoading && (
          <>
            <form onSubmit={handleSubmit}>
              <div>
                <label htmlFor="brand">Brand</label>
                <select
                  name="brand"
                  id="brand"
                  value={formData.brand}
                  onChange={handleInputChange}
                >
                  <option value="">Select Brand</option>
                  {brands.map((brand) => (
                    <option key={brand.brand_id} value={brand.brand_id}>
                      {brand.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="model">Model</label>
                <select
                  name="model"
                  id="model"
                  value={formData.model}
                  onChange={handleInputChange}
                  disabled
                >
                  <option value="">Select Model</option>
                  {models.map((model) => (
                    <option key={model.model_id} value={model.model_id}>
                      {model.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="modelYear">Model Year</label>
                <select
                  name="modelYear"
                  id="modelYear"
                  onChange={handleInputChange}
                  value={formData.modelYear}
                >
                  <option value="0">Select Model Year</option>
                  {years.map((year) => (
                    <option value={year} key={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="price">Price/AED</label>
                <input
                  type="number"
                  id="price"
                  placeholder="20000 AED"
                  name="price"
                  value={formData.price}
                  min={"0"}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="phone">Phone</label>
                <input
                  type="number"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  placeholder="Phone"
                />
              </div>
              <div>
                <label htmlFor="city">City</label>
                <select
                  name="city"
                  id="city"
                  value={formData.city}
                  onChange={handleInputChange}
                >
                  <option value="">Select City</option>
                  {cities.map((city) => (
                    <option key={city.city_id} value={city.city_id}>
                      {city.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="location">Location</label>
                <select
                  name="location"
                  id="location"
                  value={formData.location}
                  onChange={handleInputChange}
                  disabled
                >
                  <option value="">Select Location</option>
                  {locations.map((location) => (
                    <option
                      key={location.location_id}
                      value={location.location_id}
                    >
                      {location.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="mileage">Mileage</label>
                <input
                  type="number"
                  name="mileage"
                  id="mileage"
                  value={formData.mileage}
                  onChange={handleInputChange}
                  placeholder="Mileage"
                  min="0"
                />
              </div>
              <div>
                <label htmlFor="color">Color</label>
                <Select
                  value={color}
                  onChange={handleColorChange}
                  input={<OutlinedInput />}
                  renderValue={(selected) => {
                    if (selected?.length === 0) {
                      return <em>Placeholder</em>;
                    }
                    return selected;
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                  className="color-menu"
                >
                  <MenuItem value="0" disabled>
                    Select Color
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="White"
                  >
                    White{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "white",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Silver"
                  >
                    Silver{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "silver",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Black"
                  >
                    Black{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "black",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Grey"
                  >
                    Grey{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "Grey",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Blue"
                  >
                    Blue{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "blue",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Green"
                  >
                    Green{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "green",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Red"
                  >
                    Red{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "red",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Gold"
                  >
                    Gold{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "gold",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Maroon"
                  >
                    Maroon{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "maroon",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Beige"
                  >
                    Beige{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "beige",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Pink"
                  >
                    Pink{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "pink",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Brown"
                  >
                    Brown{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "brown",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Burgundy"
                  >
                    Burgundy{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "#800020",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Yellow"
                  >
                    Yellow{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "yellow",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Bronze"
                  >
                    Bronze{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "#CD7F32",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Purple"
                  >
                    Purple{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "purple",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Turquoise"
                  >
                    Turquoise{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "turquoise",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Orange"
                  >
                    Orange{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "orange",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Indigo"
                  >
                    Indigo{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "indigo",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Magenta"
                  >
                    Magenta
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "magenta",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Navy"
                  >
                    Navy{" "}
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "navy",
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value="Unlisted"
                  >
                    Unlisted
                  </MenuItem>
                </Select>
              </div>

              {formData.color === 1 && (
                <div>
                  <>
                    <label htmlFor="unlisted_color">Unlisted Color</label>
                    <input
                      type="text"
                      id="unlisted_color"
                      name="unlisted_color"
                      value={formData.unlisted_color}
                      onChange={handleInputChange}
                    />
                  </>
                </div>
              )}
              <div>
                <label htmlFor="door">Doors</label>
                <select
                  name="door"
                  id="door"
                  value={formData.door}
                  onChange={handleInputChange}
                >
                  <option value="0">Select Doors</option>
                  <option value="1">2 Doors</option>
                  <option value="2">3 Doors</option>
                  <option value="3">4 Doors</option>
                  <option value="4">5 Doors</option>
                  <option value="5">6 Doors</option>
                </select>
              </div>
              <div>
                <label htmlFor="insured">Insured</label>
                <select
                  name="insured"
                  id="insured"
                  value={formData.insured}
                  onChange={(e) => handleInputChange(e)}
                >
                  <option value="0">Select Option</option>
                  <option value="1">yes</option>
                  <option value="2">No</option>
                </select>
              </div>
              <div>
                <label htmlFor="type">New/Used</label>
                <select
                  name="type"
                  id="type"
                  value={formData.type}
                  onChange={(e) => handleInputChange(e)}
                >
                  <option value="0">Select Option</option>
                  <option value="1">New</option>
                  <option value="2">Used</option>
                </select>
              </div>
              <div>
                <label htmlFor="file">Card Image/ Video</label>
                <input
                  type="file"
                  name="file"
                  id="file"
                  ref={imageInputRef}
                  onChange={(e) => {
                    setImage(e.target.files[0]);
                    setDisplayImage(URL.createObjectURL(e.target.files[0]));
                  }}
                  accept="image/*"
                  hidden
                />
                <div className="input">
                  <span
                    className="tab"
                    onClick={() => imageInputRef.current.click()}
                  >
                    Click to add image
                  </span>
                  <span className="file-name">
                    {image !== "" ? image?.name : ""}
                  </span>
                </div>
              </div>

              <div className="btn-section">
                <button type="submit" id="create_btn">
                  Finish
                </button>
                <button onClick={handleAddMore} id="create_btn">
                  Add More
                </button>
              </div>
            </form>
            {displayImage !== "" && (
              <div className="card-image-section">
                <div className="card-image">
                  <img src={displayImage} alt="displayImage" />
                  <div className="delete-btn" onClick={handleDeleteClick}>
                    <DeleteOutlineIcon />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {isLoading && <ProgressCircle height={"calc(100vh - 250px)"} />}
        <ToastContainer />
      </div>
    </div>
  );
};

export default AddCarForm;
