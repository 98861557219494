import React, { useState, useContext } from "react";

import "./WorkshopHeader.scss"

import ColorNavbar from '../ColorNavbar/ColorNavbar'
import { useNavigate } from "react-router-dom";
import { AutoComplete } from "primereact/autocomplete";
import { dataContext } from "../../App";

import Workshop from "../../assets/videos/workshop.mp4"
const WorkshopHeader = ({slogan}) => {
  const navigate = useNavigate();
  const { maintenance } = useContext(dataContext);
  const [searchTerms, setSearchTerms] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const search = (event) => {
    const filteredItems = maintenance.filter((item) => {
      const itemName = item.name.toLowerCase();
      const lowerCaseSearchTerm = event.query.toLowerCase();
      return itemName.includes(lowerCaseSearchTerm);
    });
    const itemNames = filteredItems.map((item) => item.name);
    setSearchTerms(itemNames.length === 0 ? [] : itemNames);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = maintenance.filter((item) => {
      const itemName = item.name.toLowerCase();
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      return itemName === lowerCaseSearchTerm;
    });
    navigate(`/merchant-search/workshop/${data[0].maintenance_id}`);
  };
  return (
    <div>
        <ColorNavbar color={"#0070CC"} textColor={"black"}/>
        <div
        className="hero-section"
      >
        <video src={Workshop} autoPlay loop></video>
        <div className="vertical-slogan">
          {slogan}
        </div>
        <div className="search-bar">
        <div className="main-search-container">
      <form className="main-search-bar" onSubmit={(e) => handleSubmit(e)}>
        <AutoComplete
          placeholder="Search workshops on CARS'R'US"
          value={searchTerm}
          suggestions={searchTerms}
          completeMethod={search}
          onChange={(e) => setSearchTerm(e.value)}
          forceSelection
          inputId="model_year"
        />
        <button type="submit">Search</button>
      </form>
    </div>
        </div>
      </div>
    </div>
  )
}

export default WorkshopHeader