import { useContext, useEffect, useState } from "react";
import "./MainHeader.scss";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";
import Person2Icon from "@mui/icons-material/Person2";

import { Link, useNavigate } from "react-router-dom";
import { vendorProfileRoute } from "../../../utils/Routes";
import { cities } from "../../../assets/data";
import { useAuth } from "../../../context/auth";
import { handleLogout } from "../../../utils/ReuseFunction";
import CarMenu from "../../CarMenu/CarMenu";
import WorkshopMenu from "../../WorkshopMenu/WorkshopMenu";
import PartsMenu from "../../PartsMenu/PartsMenu";
// import BlinkerMenu from "../../BlinkerMenu/BlinkerMenu";
import { GiHamburgerMenu } from "react-icons/gi";
import ResponsiveMenu from "../../ResponsiveMenu/ResponsiveMenu";
import { AutoComplete } from "primereact/autocomplete";
import { dataContext } from "../../../App";
import { toast } from "react-toastify";
import { toastOptions } from "../../../utils/ToastOptions";
import NotificationMenu from "../../NotificationMenu/NotificationMenu";

const MainHeader = () => {
  const [auth, setAuth] = useAuth();
  const { parts, brands, maintenance } = useContext(dataContext);
  // States
  const [active, setActive] = useState("");
  const [currentCity, setCurrentCity] = useState("Dubai");

  const [profileMenu, setProfileMenu] = useState(false);
  const [searchMenu, setSearchMenu] = useState(false);
  const [searchTerm, setSearchTerm] = useState("All");
  const [searchTerms, setSearchTerms] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const navigate = useNavigate();

  const menuClick = (value) => {
    setSearchMenu(false);
    setSearchTerm(value);
    setSearchValue("");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchValue === "") {
      toast.error("Please type Something to Search", toastOptions);
      return;
    }
    if (searchTerm === "All") {
      alert(searchValue);
      navigate(`/search/result?search=${searchValue}`);
    }
    if (searchTerm === "Cars") {
      const data = brands.filter((item) => {
        const itemName = item.name.toLowerCase();
        const lowerCaseSearchTerm = searchValue.toLowerCase();
        return itemName === lowerCaseSearchTerm;
      });
      data.length > 0
        ? navigate(`/carshort?brand=${data[0].brand_id}`)
        : toast.error("Please select from the suggestions", toastOptions);
    } else if (searchTerm === "Workshops") {
      const data = maintenance.filter((item) => {
        const itemName = item.name.toLowerCase();
        const lowerCaseSearchTerm = searchValue.toLowerCase();
        return itemName === lowerCaseSearchTerm;
      });
      data.length > 0
        ? navigate(`/merchant-search/workshop/${data[0].maintenance_id}`)
        : toast.error("Please select from the suggestions", toastOptions);
    } else if (searchTerm === "Parts") {
      const data = parts.filter((item) => {
        const itemName = item.name.toLowerCase();
        const lowerCaseSearchTerm = searchValue.toLowerCase();
        return itemName === lowerCaseSearchTerm;
      });
      data.length > 0
        ? navigate(`/merchant-search/parts/${data[0].parts_type_id}`)
        : toast.error("Please select from the suggestions", toastOptions);
    }
  };

  useEffect(() => {
    setSearchMenu(false);
  }, [searchTerm]);

  const search = (event) => {
    if (searchTerm === "Cars") {
      const filteredItems = brands.filter((item) => {
        const itemName = item.name.toLowerCase();
        const lowerCaseSearchTerm = event.query.toLowerCase();
        return itemName.includes(lowerCaseSearchTerm);
      });
      const itemNames = filteredItems.map((item) => item.name);
      setSearchTerms(itemNames.length === 0 ? [] : itemNames);
    } else if (searchTerm === "Workshops") {
      const filteredItems = maintenance.filter((item) => {
        const itemName = item.name.toLowerCase();
        const lowerCaseSearchTerm = event.query.toLowerCase();
        return itemName.includes(lowerCaseSearchTerm);
      });
      const itemNames = filteredItems.map((item) => item.name);
      setSearchTerms(itemNames.length === 0 ? [] : itemNames);
    } else if (searchTerm === "Parts") {
      const filteredItems = parts.filter((item) => {
        const itemName = item.name.toLowerCase();
        const lowerCaseSearchTerm = event.query.toLowerCase();
        return itemName.includes(lowerCaseSearchTerm);
      });
      const itemNames = filteredItems.map((item) => item.name);
      setSearchTerms(itemNames.length === 0 ? [] : itemNames);
    } else if (searchTerm === "All") {
      const filteredItems = [...parts, ...maintenance, ...brands].filter(
        (item) => {
          const itemName = item.name.toLowerCase();
          const lowerCaseSearchTerm = event.query.toLowerCase();
          return itemName.includes(lowerCaseSearchTerm);
        }
      );
      const itemNames = filteredItems.map((item) => item.name);
      setSearchTerms(itemNames.length === 0 ? [] : itemNames);
    }
  };

  return (
    <>
      {searchMenu && (
        <div
          className="navbar-search-fixed-menu"
          onClick={() => setSearchMenu(false)}
        ></div>
      )}
      <div className="main-header">
        <nav className="navbar">
          <div className="brand">
            <Link to="/">
              <h1>CARS'R'US </h1>
            </Link>
            <div
              style={{ position: "relative" }}
              onClick={() =>
                setActive((prev) =>
                  prev === "countryMenu" ? "" : "countryMenu"
                )
              }
            >
              {!(active === "countryMenu") && <span>{currentCity}</span>}
              {active === "countryMenu" ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowUpIcon />
              )}
              {active === "countryMenu" && (
                <ul className="country-menu">
                  {cities
                    .filter((city) => city !== currentCity)
                    .map((filtercity, i) => (
                      <li key={i} onClick={() => setCurrentCity(filtercity)}>
                        {filtercity}
                      </li>
                    ))}
                </ul>
              )}
            </div>
          </div>
          <div className="nav-links">
            <div
              onClick={() =>
                setActive((prev) => (prev === "carMenu" ? "" : "carMenu"))
              }
              className="navlink"
            >
              <Link to="/cars">
                <span> Cars</span>
              </Link>
              {active === "carMenu" ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
              {active === "carMenu" && (
                <CarMenu setActive={setActive} top={"100px"} />
              )}
            </div>
            <div
              onClick={() =>
                setActive((prev) =>
                  prev === "workshopMenu" ? "" : "workshopMenu"
                )
              }
              className="navlink"
            >
              <Link to={"/workshop"}>
                <span>Workshops</span>
              </Link>
              {active === "workshopMenu" ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
              {active === "workshopMenu" && (
                <WorkshopMenu setActive={setActive} top={"100px"} />
              )}
            </div>
            <div
              onClick={() =>
                setActive((prev) => (prev === "partsMenu" ? "" : "partsMenu"))
              }
              className="navlink"
            >
              <Link to={"/parts"}>
                <span>Parts</span>
              </Link>
              {active === "partsMenu" ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
              {active === "partsMenu" && (
                <PartsMenu setActive={setActive} top={"100px"} />
              )}
            </div>
          </div>

          {auth?.userType !== 1 && (
            <div className="live-search-link">
              <div className="white-circle" style={{ background: "white" }} />
              <Link to="/search">Live Search</Link>
            </div>
          )}

          <div className="right-navigation">
            {!auth.user && (
              <>
                <Link
                  to={"/login"}
                  className="btn btn-primary"
                >
                  <span>Log in</span>
                </Link>
                {/* <Link
                  to={"/usersignup"}
                  style={{ color: "black", textDecoration: "none" }}
                >
                  <span>Sign Up</span>
                </Link> */}
                <Link to={"/dashboard"} className="btn-dark btn">
                  Register
                </Link>
                {/* <Link to={"/dashboard"} className="btn-primary btn">
                  Sell My Car
                </Link> */}
              </>
            )}
            {auth.user && (
              <>
                {auth.userType === 1 && <NotificationMenu />}
                <div className="prodile-section">
                  <div className="profile-image">
                    <Person2Icon />
                  </div>
                  <div className="profile-name">Welcome {auth.user.name}</div>
                  <div
                    className="down-icon"
                    onClick={() => setProfileMenu((prev) => !prev)}
                  >
                    <ArrowDropDownIcon />
                  </div>
                  {profileMenu && auth.userType === 2 && (
                    <ul className="profile-menu">
                      <li onClick={() => navigate("/profile")}>Profile</li>
                      <li onClick={() => navigate("/my-products")}>
                        My Products
                      </li>
                      <li onClick={() => handleLogout(setAuth)}>Sign Out</li>
                    </ul>
                  )}
                  {profileMenu && auth.userType === 1 && (
                    <ul className="profile-menu">
                      <li
                        onClick={() =>
                          navigate(`${vendorProfileRoute}/${auth.user?.url}`)
                        }
                      >
                        Profile
                      </li>
                      {/* <li onClick={() => navigate("/ad")}>My Ads</li> */}
                      <li onClick={() => handleLogout(setAuth)}>Sign Out</li>
                    </ul>
                  )}
                </div>
              </>
            )}
          </div>
        </nav>
        <div
          className="home-menu-icon"
          onClick={() =>
            setActive((prev) => (prev === "sidemenu" ? "" : "sidemenu"))
          }
        >
          <div className="brand">
            <Link to="/">
              <h1>CARS'R'US </h1>
            </Link>
          </div>
          <GiHamburgerMenu color="white" />
        </div>
        <ResponsiveMenu setActive={setActive} active={active} />
        {/* Search Everything on CARS'R'US */}
        <div>
          <div className="navbar-search-wrapper">
            <div className="navbar-search">
              <form onSubmit={handleSubmit}>
                {searchTerm === "All" && (
                  <input
                    type="text"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder={`Search ${
                      searchTerm === "All"
                        ? "everything"
                        : searchTerm === "Workshops"
                        ? "workshops"
                        : searchTerm === "Parts"
                        ? "parts"
                        : "cars"
                    } on CARS'R'US `}
                  />
                )}
                {!(searchTerm === "All") && (
                  <AutoComplete
                    placeholder={`Search ${
                      searchTerm === "All"
                        ? "everything"
                        : searchTerm === "Workshops"
                        ? "workshops"
                        : searchTerm === "Parts"
                        ? "parts"
                        : "cars"
                    } on CARS'R'US `}
                    value={searchValue}
                    suggestions={searchTerms}
                    completeMethod={search}
                    onChange={(e) => setSearchValue(e.value)}
                    forceSelection
                    inputId="model_year"
                  />
                )}
                <span onClick={handleSubmit}>
                  <SearchIcon />
                </span>
              </form>
              <div onClick={() => setSearchMenu(true)}>
                <span>{searchTerm}</span>
                <KeyboardArrowDownIcon />
                {searchMenu === true && (
                  <div className="search-menu">
                    <ul>
                      {searchTerm !== "Workshops" && (
                        <li onClick={() => menuClick("Workshops")}>
                          Workshops
                        </li>
                      )}
                      {searchTerm !== "Parts" && (
                        <li onClick={() => menuClick("Parts")}>Parts</li>
                      )}
                      {searchTerm !== "Cars" && (
                        <li onClick={() => menuClick("Cars")}>Cars</li>
                      )}
                      {searchTerm !== "All" && (
                        <li onClick={() => menuClick("All")}>All</li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainHeader;
