import React, { useState, useEffect } from "react";
import "./UserBookmarksMainArea.scss";
import axios from "axios";
import {
  cardRoute,
  insertCompareRoute,
  userBookmarkListing,
} from "../../utils/ApiRoutes";
import AddIcon from "@mui/icons-material/Add";
import { Door, PinkCar } from "../../assets/icons/svg";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SpeedIcon from "@mui/icons-material/Speed";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

import { Colors, Doors } from "../../assets/data";
import { useNavigate } from "react-router-dom";
import { productDetail } from "../../utils/Routes";
import { ToastContainer, toast } from "react-toastify";
import { toastOptions } from "../../utils/ToastOptions";
import ProgressCircle from "../ProgressCIrcle/ProgressCircle";
import { useAuth } from "../../context/auth";
import { Image } from "primereact/image";

const temp = [0, 1, 2];
const UserBookmarksMainArea = () => {
  const [auth] = useAuth();
  const navigate = useNavigate();
  const [bookmarks, setBookmarks] = useState([]);
  const [compareList, setCompareList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const getData = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.post(userBookmarkListing, {
        user_id: auth.user.user_id,
      });
      setBookmarks(data.Product);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = (url) => {
    navigate(`${productDetail}/${url}`);
  };

  const handleCompareProductClick = (e, product) => {
    if (compareList.length < 2) {
      for (let index = 0; index < 2; index++) {
        if (compareList[index] === product) {
          toast.error("cannot add same Product again", toastOptions);
          return;
        }
      }
      setCompareList((prev) => [...prev, product]);
    } else {
      toast.error("Upto 2 variants can be selected at a time.", toastOptions);
    }
  };

  const handleDeleteClick = (url) => {
    const temp = compareList.filter((item) => item?.url !== url);
    setCompareList(temp);
  };

  const handleCompareClick = async () => {
    compareList.map((item, i) =>
      localStorage.setItem(
        `product-${i + 1}`,
        JSON.stringify({
          img: item?.card,
          url: item?.url,
          product_id: item?.product_id,
        })
      )
    );
    const data = [];
    for (let index = 0; index < 2; index++) {
      const temp = JSON.parse(localStorage.getItem(`product-${index + 1}`));
      data.push(temp);
    }
    if (data[0].product_id > data[1].product_id) {
      await axios.post(insertCompareRoute, {
        user_id: auth.user.user_id,
        product_id: String(data[0].product_id) + String(data[1].product_id),
        combine_id : `${String(data[0].product_id)}-${String(data[1].product_id)}`
      });
    } else if (data[1].product_id > data[0].product_id) {
      await axios.post(insertCompareRoute, {
        user_id: auth.user.user_id,
        product_id: String(data[1].product_id) + String(data[0].product_id),
        combine_id: `${String(data[1].product_id)}-${String(data[0].product_id)}`
      });
    }
    navigate("/Compare");
  };

  return (
    <>
      {!isLoading && (
        <div className="bookmarks-main">
          <div className="compare-section">
            <h3>Compare</h3>
            <div className="compares">
              <div
                className="inner"
                style={{ display: "flex", gap: "1rem", alignItems: "center" }}
              >
                {temp.map((value) =>
                  compareList[value] !== undefined ? (
                    <div key={value} style={{ position: "relative" }}>
                      <div
                        className="delete-icon"
                        onClick={() =>
                          handleDeleteClick(compareList[value]?.url)
                        }
                      >
                        <DeleteOutlinedIcon />
                      </div>
                      <div
                        className="vertical-bookmark-card"
                        onClick={() => handleClick(compareList[value]?.url)}
                      >
                        <div className="product-image" onClick={(e) => e.stopPropagation()}>
                          <Image
                            src={`${cardRoute}/${compareList[value]?.card}`}
                            alt="Product"
                            preview
                          />
                          <div className="rotate-view">
                            <SyncOutlinedIcon />
                          </div>
                          <div className="bookmark-icon">
                            <BookmarkIcon />
                          </div>
                        </div>
                        <div className="product-desc">
                          <div className="product-price">
                            <span>
                              AED: <strong>{compareList[value]?.price}</strong>
                            </span>
                          </div>
                          <div className="product-type">
                            <h6>{compareList[value]?.brand_name}</h6>
                            <span />
                            <p>{compareList[value]?.model_name}</p>
                          </div>
                          <div className="product-specification">
                            <span>
                              <CalendarTodayIcon />{" "}
                              {compareList[value]?.model_year}
                            </span>
                            <span>
                              <SpeedIcon /> {compareList[value]?.milage} km
                            </span>
                            <span>
                              <ColorLensIcon />{" "}
                              {compareList[value]?.color_id === 1
                                ? compareList[value].unlisted_color
                                : Colors[Number(compareList[value]?.color_id)]}
                            </span>
                            <span>
                              <Door />{" "}
                              {Doors[Number(compareList[value]?.door_id)]}
                            </span>
                          </div>
                          <div className="product-location">
                            <span>
                              <LocationOnOutlinedIcon />
                              {compareList[value]?.location_name},{" "}
                              {compareList[value]?.city_name}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="compare-icon">
                      <PinkCar />
                      <span className="add-icon">
                        <AddIcon />
                      </span>
                    </div>
                  )
                )}
              </div>
              {compareList.length > 1 && (
                <div>
                  <button onClick={handleCompareClick}>Compare Them</button>
                </div>
              )}
            </div>
          </div>
          <div className="bookmarks-section">
            <h3>Bookmarks</h3>
            <div className="bookmarks">
              {bookmarks?.map((bookmark, i) => (
                <div key={i} style={{ position: "relative" }}>
                  <div
                    className="gallery"
                    onClick={(e) => handleCompareProductClick(e, bookmark)}
                  >
                    <CompareArrowsIcon />
                  </div>
                  <div
                    className="vertical-bookmark-card"
                    onClick={() => handleClick(bookmark?.url)}
                  >
                    <div
                      className="product-image"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Image
                        src={`${cardRoute}/${bookmark?.card}`}
                        alt="Product"
                        preview
                      />
                      <div className="rotate-view">
                        <SyncOutlinedIcon />
                      </div>
                      <div className="bookmark-icon">
                        <BookmarkIcon />
                      </div>
                    </div>
                    <div className="product-desc">
                      <div className="product-price">
                        <span>
                          AED: <strong>{bookmark?.price}</strong>
                        </span>
                      </div>
                      <div className="product-type">
                        <h6>{bookmark?.brand_name}</h6>
                        <span />
                        <p>{bookmark?.model_name}</p>
                      </div>
                      <div className="product-specification">
                        <span>
                          <CalendarTodayIcon /> {bookmark?.model_year}
                        </span>
                        <span>
                          <SpeedIcon /> {bookmark?.milage} km
                        </span>
                        <span>
                          <ColorLensIcon />{" "}
                          {bookmark?.color_id === 1
                            ? bookmark.unlisted_color
                            : Colors[Number(bookmark?.color_id)]}
                        </span>
                        <span>
                          <Door /> {Doors[Number(bookmark?.door_id)]}
                        </span>
                      </div>
                      <div className="product-location">
                        <span>
                          <LocationOnOutlinedIcon />
                          {bookmark?.location_name}, {bookmark?.city_name}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <ToastContainer />
        </div>
      )}
      {isLoading && <ProgressCircle height={"calc(100vh - 125px)"} />}
    </>
  );
};

export default UserBookmarksMainArea;
