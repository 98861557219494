import React from "react";
import ColorNavbar from "../../components/ColorNavbar/ColorNavbar";
import Footer from "../../components/Footer/Footer";
import LiveSearchMainArea from "../../components/LiveSearchMainArea/LiveSearchMainArea";
import { Helmet } from "react-helmet";
// import axios from 'axios'
const LiveSearchHomePage = () => {
  // useEffect(() => {
  //   return () => {
  //     const backFunction = async () => {
  //       await axios.get(
  //         `http://192.168.100.55:1000/api/Update/search/${sessionStorage.getItem("searchCode")}`
  //       );
  //       console.log(
  //         `http://192.168.100.55:1000/api/Update/search/${sessionStorage.getItem(
  //           "searchCode"
  //         )}`
  //       );
  //     };
  //     backFunction();
  //   };
  // }, []);

  return (
    <>
      <Helmet>
        <title>Live Search | CARS'R'US</title>
      </Helmet>
      <ColorNavbar textColor={"white"} color={"#232626"} />
      <LiveSearchMainArea />
      <Footer />
    </>
  );
};

export default LiveSearchHomePage;
