import React, { useEffect, useState } from "react";
import "./VerticalProductCard.scss";
import "swiper/css";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SpeedIcon from "@mui/icons-material/Speed";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";

import CallIcon from "@mui/icons-material/Call";
import ChatBubbleOutlineRoundedIcon from "@mui/icons-material/ChatBubbleOutlineRounded";
import ColorLensIcon from "@mui/icons-material/ColorLens";

import { useNavigate } from "react-router-dom";
import {
  cardRoute,
  insertChatMessage,
  productRoute,
} from "../../utils/ApiRoutes";
import noImage from "../../assets/images/no-image.png";
import { Doors, Colors } from "../../assets/data";
import { Door } from "../../assets/icons/svg";
import { productDetail } from "../../utils/Routes";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { useAuth } from "../../context/auth";
import { Skeleton } from "primereact/skeleton";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/ToastOptions";
import axios from "axios";
import moment from "moment";
const VerticalProductCard = ({
  product,
  ad,
  handleCompareProductClick,
  bookmarkedProduct,
  handleBookmarkClick,
  compare,
  bookmark,
  border,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [auth] = useAuth();
  const handleClick = () => {
    navigate(`${productDetail}/${product?.url}`);
  };
  const extension = product?.card !== null ? product?.card.split(".")[1] : "";

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  const handleCallClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (auth.user) {
      navigator.clipboard.writeText(product.phone);
      toast.success("Phone Number is copied in clipboard", toastOptions);
    } else {
      toast.error("Please Login to get Phone Number", toastOptions);
    }
  };

  const handleChatClick = async (e, id, receiver, type, error) => {
    e.stopPropagation();
    e.preventDefault();
    if (!error) {
      if (auth.user) {
        await axios.post(insertChatMessage, {
          sender_id: auth.user.user_id,
          receiver_id: id,
          message: "Hello",
          sender: auth.userType === 2 ? "users" : "vendor",
          receiver,
          type,
        });
        await axios.get(`${productRoute}/${product.url}/Chat/Enter`);
        auth.userType === 2
          ? navigate("/my-chat")
          : navigate("/dashboard/business-chats");
      } else {
        toast.error("Please login to chat", toastOptions);
      }
    } else {
      toast.error("You Cannot Chat with yourself", toastOptions);
    }
  };

  return (
    <>
      {!loading && (
        <div style={{ position: "relative" }}>
          {compare && (
            <div
              className="gallery"
              onClick={(e) =>
                handleCompareProductClick(
                  e,
                  product?.card,
                  product?.url,
                  product?.product_id
                )
              }
            >
              <CompareArrowsIcon />
            </div>
          )}
          {bookmark && (
            <div
              className={
                auth.user &&
                bookmarkedProduct &&
                bookmarkedProduct.find(
                  (data) => data.product_id === product.product_id
                )
                  ? "favourite fav-active"
                  : "favourite"
              }
              onClick={() =>
                handleBookmarkClick(product.url, product.product_id)
              }
            >
              <FavoriteBorderOutlinedIcon />
            </div>
          )}

          <div
            className="vertical-product-card"
            onClick={handleClick}
            style={{ border: border ? "1px solid black" : "" }}
          >
            <div className="product-image">
              {extension === "jpg" ||
              extension === "png" ||
              extension === "jpeg" ? (
                <img src={`${cardRoute}/${product?.card}`} alt="Product" />
              ) : extension === "mp4" ? (
                <video src={`${cardRoute}/${product?.card}`} controls />
              ) : (
                <img src={noImage} alt="no" />
              )}
              {ad && (
                <div className="ad-pill">
                  <span>Ad</span>
                </div>
              )}
              {product.plan_type === 2 && (
                <div className="featured-badge-product">Featured</div>
              )}
              {product.plan_type === 3 && (
                <div className="featured-badge-product">Premium</div>
              )}
              {product.product_type === "Ads" && (
                <div className="ad-pill">
                  <span>Ad</span>
                </div>
              )}
            </div>
            <div className="product-desc">
              <div className="product-price">
                <span>
                  AED: <strong>{product?.price}</strong>
                </span>
              </div>
              <div className="product-type">
                <h6>{product?.brand_name}</h6>
                <span />
                <p>{product?.model_name}</p>
              </div>
              <div className="product-specification">
                <span>
                  <CalendarTodayIcon /> {product?.model_year}
                </span>
                <span>
                  <SpeedIcon /> {product?.milage} km
                </span>
                <span>
                  <ColorLensIcon />{" "}
                  {product?.color_id === 1
                    ? product.unlisted_color
                    : Colors[Number(product?.color_id)]}
                </span>
                <span>
                  <Door /> {Doors[Number(product?.door_id)]}
                </span>
              </div>
              <div className="product-location">
                <span>
                  <LocationOnOutlinedIcon />
                  {product?.location_name}, {product?.city_name}
                </span>
              </div>
              <div className="Uplaoded-date">
              uploaded: {moment(`${product.date} ${product.time}`, 'YYYY-MM-DD HH:mm:ss').fromNow()}
              </div>
              <div className="btn-section">
                <button onClick={(e) => handleCallClick(e)}>
                  <CallIcon /> Call
                </button>
                <button
                  onClick={(e) =>
                    handleChatClick(
                      e,
                      product?.uploader_id,
                      product.uploaded_by === 2 ? "users" : "vendor",
                      auth.userType === 2 && product.uploaded_by === 2
                        ? 0
                        : auth.userType === 1 && product.uploaded_by === 1
                        ? 2
                        : 1,
                      auth.userType === 2 &&
                        product.uploaded_by === 2 &&
                        auth.user.user_id === product?.uploader_id
                        ? true
                        : auth.userType === 1 &&
                          product.uploaded_by === 1 &&
                          auth.user.user_id === product?.uploader_id
                        ? true
                        : false
                    )
                  }
                >
                  <ChatBubbleOutlineRoundedIcon /> Chat
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {loading && (
        <div>
          <Skeleton
            width="17.5rem"
            height="10rem"
            borderRadius="16px"
            className="mb-2"
          ></Skeleton>
          <Skeleton width="17.5rem" height="1.5rem" className="mb-2"></Skeleton>
          <Skeleton width="15rem" height="1.5rem" className="mb-2"></Skeleton>
          <Skeleton width="14rem" height="1.5rem" className="mb-2"></Skeleton>
          <Skeleton width="17.5rem" height="1.5rem" className="mb-2"></Skeleton>
          <div style={{ display: "flex", gap: "1rem" }}>
            <Skeleton
              width="8.5rem"
              height="2.5rem"
              borderRadius="16px"
              className="mb-2"
            ></Skeleton>
            <Skeleton
              width="8.5rem"
              height="2.5rem"
              borderRadius="16px"
              className="mb-2"
            ></Skeleton>
          </div>
        </div>
      )}
    </>
  );
};

export default VerticalProductCard;
