import React from "react";
import { carMenuDataLeft, carMenuDataRight } from "../../assets/data/index";
import CarMenuCard from "../../components/CarMenuCard/CarMenuCard";
import "./CarMenu.scss";
import { useAuth } from "../../context/auth";
const CarMenu = ({ setActive, top }) => {
  const [auth] = useAuth();

  return (
    <div
      className="car-menu"
      style={{ top }}
      onMouseLeave={() => setActive("")}
    >
      <div className="car-menu-left border-right">
        {carMenuDataLeft.map((data, i) => (
          <>
            <CarMenuCard icon={data.icon} text={data.text} link={data.link} />
          </>
        ))}
      </div>
      <div className="car-menu-right">
        {carMenuDataRight.map((data, i) => (
          <>
            <CarMenuCard
              icon={data.icon}
              text={data.text}
              link={
                auth.user && data.text === "Sell My Car"
                  ? auth.userType === 2
                    ? "/dashboard/vender-individual-signup"
                    : "/dashboard/vender-add-cars"
                  : data.link
              }
            />
          </>
        ))}
        <div className="car-menu-bottom-content">
          <span className="red-border" />
          <div className="car-menu-content">
            <h4 style={{ color: "black" }}>Live Search</h4>
            <h4 style={{ color: "black" }}>
              Login to Car’r’us enter your car specification and let our system
              notify you.
            </h4>
            <h4 style={{ color: "black" }}>
              <span style={{ color: "#FF0000" }}>Sign up</span> - for
              notification for Price changes and New Placments first
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarMenu;
