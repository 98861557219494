import React from "react";
import PaymentGateway from "../components/PaymentGateway/PaymentGateway";

const PaymentLayout = ({ children }) => {
  return (
    <div className="background-light width-100 hieght-100">
      <div className="payment flex item-center background-light item-center hieght-100 gap-5">
        <PaymentGateway />
        <div className="flex ">{children}</div>
      </div>
    </div>
  );
};

export default PaymentLayout;
