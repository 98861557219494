import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  MaintenanceImagesRoute,
  UpdateMaintenance,
  getMaintenanceRoute,
} from "../../utils/ApiRoutes";
import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import "./VendorMaintenance.scss";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { toastOptions } from "../../utils/ToastOptions";
import { vendorProfileRoute } from "../../utils/Routes";
import { useAuth } from "../../context/auth";
import { Helmet } from "react-helmet";
const VendorMaintenance = () => {
  const [auth] = useAuth();
  const navigate = useNavigate();
  const [maintenance, setMaintenance] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState({});
  const [maintenanceIds, setMaintenanceIds] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  useEffect(() => {
    getMaintenance();
  }, []);

  const getMaintenance = async () => {
    setIsLoading(true);
    const response = await axios.get(getMaintenanceRoute);
    try {
      const { maintenance } = response.data;
      setMaintenance(maintenance);

      const initialState = {};
      maintenance.forEach((id) => {
        initialState[id.maintenance_id] = false;
      });
      setIsChecked(initialState);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setIsChecked({ ...isChecked, [id]: checked });
    checked
      ? setMaintenanceIds(maintenanceIds.concat(`,${id}`))
      : setMaintenanceIds(maintenanceIds.replace(`,${id}`, ""));
  };

  const HandleBackClick = (e) => {
    e.preventDefault();
    navigate("/dashboard/vender-brands");
  };
  const handleNextClick = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    e.target.setAttribute("disabled", true);
    if (maintenanceIds !== "") {
      const response = await axios.post(UpdateMaintenance, {
        code: auth.user?.code,
        maintenance_ids: maintenanceIds.replace(",", ""),
      });
      if (response.data.error === false) {
        setIsLoading(false);
        navigate(`${vendorProfileRoute}/${auth.user?.url}`);
      } else {
        setIsLoading(false);
      }
    } else {
      toast.error("Please select a Maintenance Service", toastOptions);
    }
    e.target.removeAttribute("disabled");
    setIsLoading(false);
  };

  const handleSelectAll = (e) => {
    setSelectAll((prev) => !prev);
    const initialState = {};
    const ids =  maintenance.map((maintain) => {
      initialState[maintain.maintenance_id] = selectAll ? false :  true;
      return maintain.maintenance_id;
    });
    setIsChecked(initialState);
    setMaintenanceIds( selectAll ? "" :  `,${ids.join(",")}`)
  };


  return (
    <>
      <Helmet>
        <title>Vendor Maintenances | CARS'R'US</title>
      </Helmet>
      <div className="vendor-maintenance">
        {!isLoading && (
          <div className="maintenance-section">
            <div className="stepper">
              <Stepper
                activeStep={3}
                alternativeLabel
                sx={{ paddingTop: "2rem" }}
              >
                <Step>
                  <StepLabel></StepLabel>
                </Step>
                <Step>
                  <StepLabel></StepLabel>
                </Step>
                <Step>
                  <StepLabel></StepLabel>
                </Step>
                <Step>
                  <StepLabel></StepLabel>
                </Step>
              </Stepper>
            </div>
            <h1>Choose/Highlights Service and show what makes you unique</h1>
            <div className="Select-all">
              <div>
              <label htmlFor="select_all_brands">
                <input
                  type="checkbox"
                  name="select_all"
                  checked={selectAll}
                  id="select_all_brands"
                  onChange={handleSelectAll}
                />{" "}
                Select All
              </label>
              </div>
            </div>
            <div className="maintenance-card-section">
              {maintenance.map((maintain) => (
                <div key={maintain.maintenance_id} className="maintenances">
                  <div className="maintenance-image">
                    <img
                      src={`${MaintenanceImagesRoute}/${maintain.image}`}
                      alt=""
                    />
                    <div className="checkbox">
                      <input
                        type="checkbox"
                        id={maintain.maintenance_id}
                        onChange={(e) => handleCheckboxChange(e)}
                        checked={isChecked[maintain.maintenance_id]}
                      />
                    </div>
                  </div>
                  <h4>{maintain.name.length > 30  ? `${maintain.name.slice(0,30)}...` :maintain.name  }</h4>
                </div>
              ))}
            </div>
            <div className="btn-section">
              <button onClick={(e) => HandleBackClick(e)}>Back</button>
              <button onClick={(e) => handleNextClick(e)}>Next</button>
            </div>
          </div>
        )}
        {isLoading && <div className="vendor-loader"></div>}
      </div>
      <ToastContainer />
    </>
  );
};

export default VendorMaintenance;
