import "./Login.scss";
import { useState, useContext, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";

import iphoneLogo from "../../assets/icons/iphone-logo.png";
import googleLogo from "../../assets/icons/google-logo.png";
// import facebookLogo from "../../assets/icons/facebook-logo.png";

import axios from "axios";
import { toast } from "react-toastify";

import { loginRoute } from "../../utils/ApiRoutes";
import { dataContext } from "../../App";
import ProgressCircle from "../../components/ProgressCIrcle/ProgressCircle";
import { useAuth } from "../../context/auth";
import { Helmet } from "react-helmet";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { toastOptions } from "../../utils/ToastOptions";
// import FacebookLogin from "react-facebook-login";

const Login = () => {
  const [auth, setAuth] = useAuth();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [passwordShow, setPasswordShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { setCode, setOtp } = useContext(dataContext);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { value, id } = e.target;
    setFormData((prevState) => ({ ...prevState, [id]: value }));
  };

  const { email, password } = formData;
  const handleFormSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        if (formValidate()) {
          setIsLoading(true);
          const response = await axios.post(loginRoute, { email, password });
          const { error, message, code, otp, account } = response.data;

          if (error === true) {
            if (message === "Not_Verified") {
              setCode(code);
              setOtp(otp);
              localStorage.setItem("code", code);
              setIsLoading(false);
              navigate("/otp");
            }
            if (message === "Blocked") {
              setIsLoading(false);
              toast.error("Your has been Blocked", toastOptions);
            }
            if (message === "wrong") {
              setIsLoading(false);
              setFormData({ email: "", password: "" });
              toast.error("Invalid Credentials!", toastOptions);
            }
          } else if (error === "wrong") {
            setIsLoading(false);
            setFormData({ email: "", password: "" });
            toast.error("Invalid Credentials!", toastOptions);
          } else if (error === false) {
            setAuth({
              ...auth,
              user: {
                email: account[0].email,
                name: account[0].fullname,
                user_id: account[0].user_id,
                user_phone: account[0].phone,
                loginDate: new Date().getUTCDate(),
                code: account[0]?.code,
              },
              userType: 2,
            });
            localStorage.setItem(
              "auth",
              JSON.stringify({
                user: {
                  email: account[0].email,
                  name: account[0].fullname,
                  user_id: account[0].user_id,
                  user_phone: account[0].phone,
                  loginDate: new Date().getUTCDate(),
                  code: account[0]?.code,
                },
                userType: 2,
              })
            );
            setIsLoading(false);
            navigate("/profile");
          } else {
            toast.error("Something Went Wrong", toastOptions);
            setIsLoading(false);
          }
        }
      } catch (error) {
        toast.error(error.message, toastOptions);
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line
    [email, password]
  );

  const formValidate = () => {
    if (email === "") {
      toast.error("email is required", toastOptions);
      return false;
    }
    if (password === "") {
      toast.error("Password is required", toastOptions);
      return false;
    }
    return true;
  };

  // const responseFacebook = (response) => {
  //   console.log(response);
  // }

  return (
    <>
      <Helmet>
        <title>Login | CARS'R'US</title>
      </Helmet>
      <div className="login-page">
        <Link to={"/"} className="logo">
          CARS'R'US
        </Link>
        {!isLoading && (
          <div className="login-form">
            <h1 style={{ fontSize: "42px" }}>LOGIN</h1>
            <form>
              <input
                type="email"
                placeholder="Email"
                value={email}
                id="email"
                onChange={(e) => handleChange(e)}
              />
              <input
                type={!passwordShow ? "password" : "text"}
                placeholder="Password"
                value={password}
                id="password"
                onChange={(e) => handleChange(e)}
              />
              <span
                className="icon"
                onClick={() => setPasswordShow((prev) => !prev)}
              >
                {!passwordShow ? (
                  <AiOutlineEye color="white" />
                ) : (
                  <AiOutlineEyeInvisible color="white" />
                )}
              </span>
              <div>
                <span className="">
                  {/* <input type="checkbox" id="remember" /> */}
                  {/* <label htmlFor="remember">Remember Me</label> */}
                </span>
                <Link to="/forgot">Forgot Password</Link>
              </div>
              <button onClick={(e) => handleFormSubmit(e)}>Login</button>
            </form>
            <div className="social-media">
              <button>
                <img src={iphoneLogo} alt="apple" />
                Sign in with Apple
              </button>
              <button>
                <img src={googleLogo} alt="google" />
                Sign in with Google
              </button>
              {/* <FacebookLogin
                appId="856121895967605"
                autoLoad={true}
                fields="name,email,picture"
                // onClick={componentClicked}
                callback={responseFacebook}
              /> */}
            </div>
            <div style={{ color: "white" }}>
              Dont Have an Account{" "}
              <Link to={"/usersignup"} style={{ color: "white" }}>
                Sign-Up
              </Link>
            </div>
            <div style={{ color: "white" }}>
              <Link to={"/dashboard/vender-login"} style={{ color: "white" }}>
                Login As Vendor
              </Link>
            </div>
          </div>
        )}
        {isLoading && <ProgressCircle height={"calc(100vh - 300px)"} />}
      </div>
    </>
  );
};

export default Login;
