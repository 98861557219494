import React, {  useEffect, useState } from "react";
import "./CarPartsVendor.scss";
import axios from "axios";
import { GetPartsRoute  } from "../../utils/ApiRoutes";

import { useNavigate } from "react-router-dom";

import { UpdatePartRoute } from "../../utils/ApiRoutes";

import { ToastContainer, toast } from "react-toastify";
import { toastOptions } from "../../utils/ToastOptions";

import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import { useAuth } from "../../context/auth";
import { Helmet } from "react-helmet";
const CarPartsVendor = () => {
  const [auth] = useAuth()
  const [parts, setParts] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [partTypeIds, setPartTypeIds] = useState("");
  const navigate = useNavigate();
  const controller = new AbortController();
  useEffect(() => {
    getParts();
    return () => controller.abort();
    // eslint-disable-next-line 
  }, []);

  const getParts = async () => {
    setIsloading(true);

    try {
      const response = await axios.get(`${GetPartsRoute}`,{signal : controller.signal});
      const { partstypes } = response.data;
      setParts(partstypes);
      setIsloading(false);
    } catch (error) {
      setIsloading(false);
      toast.error("Something went wrong please try again", toastOptions);
    }
  };

  const handleNextClick = async (e) => {
    e.preventDefault();
    setIsloading(true);
    e.target.setAttribute("disabled", true);
    if(partTypeIds!==""){
      const response = await axios.post(UpdatePartRoute, {
        code: auth.user.code,
        part_types_ids: partTypeIds.replace(",", ""),
      } ,{signal : controller.signal});
      const { error } = response.data;
      if (error === false) {
        setIsloading(false);
        navigate("/dashboard/business-profile");
      } else {
        setIsloading(false);
        toast.error("something went wrong")
      }
    }else{
      setIsloading(false);
      toast.error("Please Select a Part Type!",toastOptions)
    }
    e.target.removeAttribute("disabled");
  };

  const HandleBackClick = (e) => {
    e.preventDefault();
    navigate("/dashboard/vender-brands");
  };

  const handlePartClick = (e) => {
    if (e.target.classList.contains("active")) {
      e.target.classList.remove("active");
      setPartTypeIds((prev) => prev.replace(`,${e.target.id}`, ""));
    } else {
      e.target.classList.add("active");
      setPartTypeIds((prev) => prev.concat(`,${e.target.id}`));
    }
  };
  return (
   <>
   <Helmet>
        <title>Vendor Parts | CARS'R'US</title>
      </Helmet>
    <div className="parts-section-main">
      {!isloading && (
        <div className="parts-section">
          <div className="stepper">
            <Stepper
              activeStep={2}
              alternativeLabel
              sx={{ paddingTop: "2rem" }}
            >
              <Step>
                <StepLabel></StepLabel>
              </Step>
              <Step>
                <StepLabel></StepLabel>
              </Step>
              <Step>
                <StepLabel></StepLabel>
              </Step>
              <Step>
                <StepLabel></StepLabel>
              </Step>
            </Stepper>
          </div>
          <h1>Choose/Highlights Service and show what makes you unique</h1>
          <div className="parts-card-section">
            {parts.map((partType) => (
              <div
                className="card "
                key={partType.parts_type_id}
                id={partType.parts_type_id}
                onClick={(e) => handlePartClick(e)}
              >
                {partType.name}
              </div>
            ))}
          </div>
          <div className="btn-section">
            <button onClick={(e) => HandleBackClick(e)}>Back</button>
            <button onClick={(e) => handleNextClick(e)}>Next</button>
          </div>
        </div>
      )}
      {isloading && <div className="vendor-loader"></div>}
      <ToastContainer/>
    </div>
   </>
  );
};

export default CarPartsVendor;
