import React from "react";
import ProfileLayout from "../../Layouts/ProfileLayout";
import UserBookmarksMainArea from "../../components/UserBookmarksMainArea/UserBookmarksMainArea";
import { Helmet } from "react-helmet";
const UserBookmarks = () => {
  return (
    <>
      <Helmet>
        <title>User Bookmarks | CARS'R'US</title>
      </Helmet>
      <ProfileLayout>
        <UserBookmarksMainArea />
      </ProfileLayout>
    </>
  );
};

export default UserBookmarks;
