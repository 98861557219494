import React, { useState, useEffect } from "react";
import "./DashboardBussinessSearch.scss";
import DashboardLayout from "../../Layouts/DashboardLayout";
import { Helmet } from "react-helmet";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getVendorSearches } from "../../utils/ApiRoutes";
import { useAuth } from "../../context/auth";
import axios from "axios";
import ProgressCircle from "../../components/ProgressCIrcle/ProgressCircle";
import { useNavigate } from "react-router-dom";
import moment from "moment";
const DashboardBussinessSearch = () => {
  const [expanded, setExpanded] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [Searches, setSearches] = useState([]);
  const [auth] = useAuth();
  const navigate = useNavigate();
  const controller = new AbortController();
  const handleChange = (panel) => (isExpanded) => {
    if (expanded === "panel1") {
      setExpanded(false);
    } else {
      setExpanded(panel);
    }
  };

  useEffect(() => {
    if (auth.user?.planType === 2 || auth.user?.planType === 3) {
      getData();
    } else {
      navigate(`/dashboard/business-profile/${auth.user?.url}`);
    }
    return () => controller.abort();
    // eslint-disable-next-line
  }, []);

  const getData = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.post(getVendorSearches, {
        vendor_id: auth.user.user_id,
      },{signal: controller.signal});
      // console.log(data);
      setSearches(data.Search);
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Live Search History | CARS'R'US</title>
      </Helmet>
      <DashboardLayout>
        {!isLoading && (
          <div className="search-history-main">
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              defaultExpanded={true}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <h4>Searches {Searches.length} </h4>
              </AccordionSummary>
              <AccordionDetails>
                <div className="table-data">
                  <div className="header-row">
                    {/* <div className="search-input">
                      <input type="text" placeholder="Search" />
                    </div> */}
                    <div className="heading">#</div>
                    <div className="heading">Search Queries</div>
                    <div className="heading">Date</div>
                    <div className="heading">Time</div>
                    <div className="heading">Option</div>
                  </div>
                  {Searches.map((search, i) => (
                    <div className="row" key={i}>
                      <div className="count">{i + 1}</div>
                      <div className="product">
                        <p>{search.query}</p>
                      </div>
                      <div className="review-date">{moment(search.date).format("DD/MM/YYYY")}</div>
                      <div className="review-date">{ moment(search.time, "HH:mm:ss").format("h:mm A")}</div>
                      <div className="options"></div>
                    </div>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        )}
        {isLoading && <ProgressCircle height={"calc(100vh - 150px)"} />}
      </DashboardLayout>
    </>
  );
};

export default DashboardBussinessSearch;
