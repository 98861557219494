import React, { useState, useRef } from "react";
import GoogleMapReact from "google-map-react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { merchantDetailsPage } from "../../utils/Routes";
import "./Map.scss";
import noImage from "../../assets/images/no-image.png";
import { profileImageRoute } from "../../utils/ApiRoutes";
import { useNavigate } from "react-router-dom";

const Map = ({ vendors, setBounds, bounds }) => {
  const mapRef = useRef();
  const navigate = useNavigate();
  const [hoveredMarker, setHoveredMarker] = useState("");
  const [zoom, setZoom] = useState(15);

  // Calculate center of the map based on valid vendors
  // let totalLat = 0;
  // let totalLng = 0;
  // let validVendorCount = 0;

  // vendors.forEach((data) => {
  //   data.forEach((vendor) => {
  //     if (vendor.latitude !== null && vendor.longitude !== null) {
  //       totalLat += parseFloat(vendor.latitude);
  //       totalLng += parseFloat(vendor.longitude);
  //       validVendorCount++;
  //     }
  //   });
  // });
  // eslint-disable-next-line
  // const centerLat = totalLat / validVendorCount;
  // eslint-disable-next-line
  // const centerLng = totalLng / validVendorCount;

  // useEffect(() => {
  // if (mapRef.current && bounds) {
  // const { nw, se } = bounds;
  // mapRef.current.fitBounds([[nw.lat, nw.lng], [se.lat, se.lng]], {
  //   padding: [16, 16, 16, 16],
  // });
  // }
  // }, [bounds]);

  const handleChildClick = (key, childProps) => {
    // Perform actions when the child element is clicked
    // alert("Child clicked: " + key);
  };

  const handleChildHover = (key, childProps) => {
    // Perform actions when the child element is clicked
    console.log("Child clicked: " + key);
    setHoveredMarker(key);
  };

  const validMarkers = vendors
    .flat()
    .filter((vendor) => (vendor.latitude !== null && vendor.longitude !== null) && (vendor.latitude !== "null" && vendor.longitude !== "null") &&  (vendor.latitude !== "0" && vendor.longitude !== "0") );
  const markers = validMarkers.map((vendor) => (
    <div
      key={vendor.url}
      lat={parseFloat(vendor.latitude)}
      lng={parseFloat(vendor.longitude)}
      className="pin"
    >
      <span
        style={{
          color: "red",
          opacity: vendor.url === hoveredMarker ? "0" : "1",
          transition: "0.2s ease all",
        }}
      >
        <LocationOnIcon />
      </span>
      <div
        onClick={() => navigate(`${merchantDetailsPage}/${vendor.url}`)}
        onMouseLeave={() => setHoveredMarker("")}
        className={
          vendor.url === hoveredMarker ? "pin-card" : "hidden pin-card"
        }
      >
        <img
          src={
            vendor?.profile === null
              ? noImage
              : `${profileImageRoute}/${vendor?.profile}`
          }
          alt="profile"
        />
        <p className="pin-text">{vendor.name}</p>
      </div>
    </div>
  ));

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_MAP_API,
        }}
        onChange={({ zoom, bounds }) => {
          setZoom(15);
          setBounds(bounds);
        }}
        defaultCenter={{ lat: 25.3622088, lng: 68.3520766 }}
        defaultZoom={zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map;
        }}
        onChildClick={handleChildClick}
        onChildMouseEnter={handleChildHover}
      >
        {markers}
      </GoogleMapReact>
    </div>
  );
};

export default Map;
