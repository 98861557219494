import React from "react";
import ColorNavbar from "../../components/ColorNavbar/ColorNavbar";
import Footer from "../../components/Footer/Footer";
import AutoLoanMainArea from "../../components/AutoLoanMainArea/AutoLoanMainArea";
import { Helmet } from "react-helmet";

const AutoloanPage = () => {
  return (
    <>
      <Helmet>
        <title>Loan Calculator | CARS'R'US</title>
      </Helmet>
      <ColorNavbar textColor={"white"} color={"#232626"} />
      <AutoLoanMainArea />
      <Footer />
    </>
  );
};

export default AutoloanPage;
