import React, { useState, useEffect } from "react";

import "./UserInformationSection.scss";

import Profile from "../../assets/images/download.png";

import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import axios from "axios";
import { GetUserProfileDetails, userProfilePictureRoute } from "../../utils/ApiRoutes";

import ProgressCircle from "../ProgressCIrcle/ProgressCircle";

const gendor = ["", "Male", "Female", "Others"] 

const UserInformationSection = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [profileDetails, setProfileDetails] = useState({});
  
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);
  const auth = localStorage.getItem("auth")
  const getData = async () => {
    const paresedData = JSON.parse(auth)
    setIsLoading(true);
    const response = await axios.get(`${GetUserProfileDetails}/${paresedData.user.code}`);
    setProfileDetails(response.data.User[0]);
    setIsLoading(false);
  };
  return (
    <>
      {!isLoading && (
        <div className="user-information-main">
          <div className="first-section">
            <div className="left-side">
              <h3 className="red-heading">Personal Details</h3>
              <div className="user-profile">
                <img src={profileDetails?.image ? `${userProfilePictureRoute}/${profileDetails.image}` : Profile} alt="profile" />
                {!profileDetails?.image && (<div>Verification Photo</div>)}
              </div>
              <div className="red-heading">Address</div>
              <div className="details">
                <h6>Address Line</h6>
                <h4>{profileDetails?.address ? profileDetails?.address  : "-" }</h4>
              </div>
              <div className="details">
                <h6>location</h6>
                <h4>{profileDetails?.location_name ? profileDetails?.location_name  : "-" }</h4>
              </div>
              <div className="details">
                <h6>City</h6>
                <h4>{profileDetails?.city_name ? profileDetails?.city_name : "-" }</h4>
              </div>
              <div className="details">
                <h6>Country</h6>
                <h4>{profileDetails?.country_name ? profileDetails?.country_name : "-" }</h4>
              </div>
            </div>
            <div className="right-side">
              {/* <button onClick={() => navigate("/edit-profile")}>
                Edit Details
              </button> */}
              <div className="details">
                <h6>Name</h6>
                <h4>{profileDetails?.fullname}</h4>
              </div>
              <div className="details">
                <h6>Gender</h6>
                <h4>{profileDetails?.gender!== 0 ? gendor[Number(profileDetails?.gender)]: "-" }</h4>
              </div>
              <div className="details">
                <h6>Date of Birth</h6>
                <h4>{profileDetails?.dob || "-"}</h4>
              </div>
              {/* <div className="details">
            <h6>Nationality</h6>
            <h4>Nigerian</h4>
          </div> */}
              <div className="red-heading">Contact Details</div>
              <div className="details">
                <h6>Phone Number</h6>
                <h4>{profileDetails?.phone !== 0? profileDetails?.phone : "-"  }</h4>
              </div>
              <div className="details">
                <h6>Email</h6>
                <h4>{profileDetails?.email}</h4>
              </div>
              <div className="red-heading">Account Tier</div>
              <div>
                <Stepper
                  activeStep={0}
                  orientation="vertical"
                  variant="outlined"
                >
                  <Step>
                    <StepLabel>Tier-1</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Tier-2</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Tier-3</StepLabel>
                  </Step>
                </Stepper>
              </div>
            </div>
          </div>
          <div className="last-section">
            <h3 className="red-heading">Submitted Documents</h3>
          </div>
        </div>
      )}
      {isLoading && (
        <ProgressCircle height={"calc(100vh - 125px)"}/>
      )}
    </>
  );
};

export default UserInformationSection;
