import React, { useState, useEffect, useContext } from "react";
import { MaintenanceImagesRoute } from "../../utils/ApiRoutes";
import { dataContext } from "../../App";
import { useNavigate } from "react-router-dom";

import "./WorkshopMenu.scss"

const WorkshopMenu = ({ setActive ,  top }) => {
  const navigate = useNavigate();
  const { maintenance, subMaintenance } = useContext(dataContext);
  const [workshopSubMenuFilterData, setworkshopSubMenuFilterData] = useState(
    []
  );
  const [maintenanceId, setMaintenanceId] = useState("");
  useEffect(() => {
    menuFilter();
    // eslint-disable-next-line
  }, [maintenanceId]);

  const menuFilter = () => {
    const temp = subMaintenance.filter(
      (data) => data.maintenance_id === maintenanceId
    );
    setworkshopSubMenuFilterData(temp);
  };
  const handleWorkshopMenuClick = (id) => {
    navigate(`/merchant-search/workshop/${id}`);
  };
  return (
    <>
      <div
        className="workshop-menu"
        style={{top }}
        onMouseLeave={() => {
          setActive("");
        }}
      >
        <div className="left">
          <div className="menu-item" onMouseEnter={() => setMaintenanceId(-1)}>
            <p style={{ color: "black" }}>NOT Sure Help-Me Choose!</p>
          </div>
          {maintenance.map((data,i) => (
            <div
              className="menu-item"
              onMouseEnter={() => setMaintenanceId(data.maintenance_id)}
              key={i}
            >
              <img src={`${MaintenanceImagesRoute}/${data.image}`} alt="" />

              <p
                style={{ color: "black" }}
                onClick={() =>
                  navigate(`/merchant-search/workshop/${data.maintenance_id}`)
                }
              >
                {data.name}
              </p>
            </div>
          ))}
        </div>
        <div className="right">
          <div className="red-bar"></div>
          {workshopSubMenuFilterData.length > 0  &&
            workshopSubMenuFilterData.map((data) => (
              <p
                className="menu-item"
                key={data.sub_maintenance_id}
                onClick={() => handleWorkshopMenuClick(maintenanceId)}
              >
                {data.name}
              </p>
            ))}
          {maintenanceId === "" && <p className="menu-item">No Data to Show</p>}
          {maintenanceId === -1 && (
            <>
              <p className="menu-item">General Check up</p>
              <p className="menu-item">Body Repair</p>
              <p className="menu-item">Leak</p>
              <p className="menu-item">Minor Repairs</p>
              <p className="menu-item">Banging or Knocking Noise</p>
              <p className="menu-item">My Car Won't Start</p>
              <p className="menu-item">Loss of Power</p>
              <p className="menu-item">
                Other Problem - Smoking, Strange Smells etc
              </p>
              <p className="menu-item">Noise</p>
              <p className="menu-item">General Repairs</p>
              <p className="menu-item">Vibration</p>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default WorkshopMenu;
