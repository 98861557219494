import React from "react";
import UserInformationSection from "../../components/UserInformationSection/UserInformationSection";
import ProfileLayout from "../../Layouts/ProfileLayout";
import { Helmet } from "react-helmet";
const UserProfile = () => {
  return (
    <>
      <Helmet>
        <title>User Profile | CARS'R'US</title>
      </Helmet>
      <ProfileLayout>
        <UserInformationSection />
      </ProfileLayout>
    </>
  );
};

export default UserProfile;
