import React,{useEffect}  from "react";
import ColorNavbar from "../../components/ColorNavbar/ColorNavbar";
import Footer from "../../components/Footer/Footer";
import PricingCard from "../../components/PricingCard/PricingCard";
import "./Pricing.scss";
import { FaCrown } from "react-icons/fa";
import { BiPlus } from "react-icons/bi";
import { AiFillStar } from "react-icons/ai";
import {Helmet} from "react-helmet";
import { handleScrollToTop } from "../../utils/ReuseFunction";
const Pricing = () => {

  const basicFeatures = [
    "Vendor landing page",
    "Can post Ads",
    "Shop appear in relevant categories",
    "Appear in live search for customers"
  ];

  const plusFeatures = [
    "Vendor landing page",
    "Can post Ads",
    "Shop appear in relevant categories",
    "Appear in live search for customers",
    "live search queries for 2 weeks",
    "Get featured badge for Shop",
    "Get featured badge for Ads",
    "Call Button Displayed",
    "Display Ads on Website",
    "Additional service stamps",
  ] 

  const premiumFeatures = [
    "Vendor landing page",
    "Can post Ads",
    "Shop appear in relevant categories",
    "Appear in live search for customers",
    "live search queries for month",
    "Get featured badge for Shop",
    "Get featured badge for Ads",
    "Chat & Call Button Displayed",
    "Display Ads on Website",
    "Additional service stamps",
    "CARS R US REcomended Stamp",
  ];
  useEffect(() => {
    handleScrollToTop();
  }, [])
  
  return (
    <>
    <Helmet>
        <title>Pricings | CARS'R'US</title>
    </Helmet>
      <ColorNavbar color={"black"} textColor={"white"} />
      <div className="pricing-header-wrapper">
        <div className="pricing-header-main">
          <h2>CARS'R'US Pricing Packages For Vendors</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa
            numquam ab voluptates dolor a saepe doloremque, hic sint nobis
            temporibus amet magnam qui. Magnam corporis et quia molestias.
            Tenetur, libero?
          </p>
        </div>
      </div>
      <div className="pricing-main-area">
        <PricingCard
          icon={<AiFillStar />}
          id={1}
          title={"Basic"}
          description={
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem possimus fugit eveniet itaque iste?"
          }
          price={99}
          features={basicFeatures}
        />
        <PricingCard
          icon={<BiPlus />}
          id={2}
          title={"Plus"}
          description={
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem possimus fugit eveniet itaque iste?"
          }
          price={199}
          features={plusFeatures}
        />
        <PricingCard
          icon={<FaCrown />}
          id={3}
          title={"Premium"}
          description={
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem possimus fugit eveniet itaque iste?"
          }
          price={399}
          features={premiumFeatures}
        />
      </div>
      <Footer />
    </>
  );
};

export default Pricing;
