import React from "react";
import WorkshopHeader from "../../components/WorkshopHeader/WorkshopHeader";
import WorkshopHomeMainArea from "../../components/WorkshopHomeMainArea/WorkshopHomeMainArea";
import Footer from "../../components/Footer/Footer";
import {Helmet} from "react-helmet"
const WorkshopHome = () => {
  return (
    <>
      <Helmet>
        <title>Workshops | CARS'R'US</title>
      </Helmet>
      <WorkshopHeader slogan={"WORK SHOPS"} />
      <WorkshopHomeMainArea />
      <Footer />
    </>
  );
};

export default WorkshopHome;
