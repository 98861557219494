import React from "react";
import DashboardLayout from "../../Layouts/DashboardLayout";
import "./VerifyBusiness.scss";
import { Helmet } from "react-helmet";
import axios from "axios";
import { verifyBusinessRoute } from "../../utils/ApiRoutes";
import { useAuth } from "../../context/auth";

import {toast } from "react-toastify"
import  {toastOptions} from "../../utils/ToastOptions"
const VerifyBusiness = () => {

  const [auth , setAuth] =  useAuth();

  const handleVerifyClick = async (e) => {
    e.preventDefault();
    const {data} = await axios.post(verifyBusinessRoute , {url : auth.user.url})
    if (data.error === false) {
      toast.success (data.message , toastOptions)
    }
    setAuth({...auth, user: {...auth.user , verified : 1}});
    localStorage.setItem( "auth" , JSON.stringify({...auth, user: {...auth.user , verified : 1}}));
  };

  return (
    <>
      <Helmet>
        <title>Verify Vendor Profile | CARS'R'US</title>
      </Helmet>
      <DashboardLayout>
        <div className="business-verify">
          <div>
            <h2>Build Trust</h2>
            <p>
              Verified users get upto 5x more engagement from buyers & sellets
            </p>
          </div>
          <div>
            <h2>Get more visibility on your ads</h2>
            <p>Ads by verified users stand out in front of potential buyers</p>
          </div>
          <div>
            <h2>Priority on issue resolution</h2>
            <p>
              Verified users will get priority from the customer support team on
              issues resolution{" "}
            </p>
          </div>
          {console.log(auth.user.verified)}
          <button onClick={handleVerifyClick}>{auth.user?.verified ?  auth.user?.verified === 0 ? "Get Verified" : auth.user?.verified === 1 ? "Verification in Process" : auth.user?.verified === 2 ? "Rejected" : "Verified" : "Get Verified" }</button>
        </div>
      </DashboardLayout>
    </>
  );
};

export default VerifyBusiness;
