export const carBuying = "/carshort"
export const renting = "/renting"
export const auction = "/"
export const financing = "/loan"
export const importAndExport = "/import"
export const carpool = "/carpool"
export const sellMyCar = "/dashboard"
export const insurance = "/inspection"
export const merchantDetailsPage = "/merchant"



// products routes

// export const products = "/products"  //not used
export const productDetail = "/product"


// Dashboard route 
export const vendorProfileRoute = "/dashboard/business-profile"
export const vendorProfileEditRoute = "/dashboard/business-profile-edit"

// user Dashboard route
export const userAdslistingRoute = "/my-ads"

// Ad Payment 
export const userAdPaymentRoute = "/payment"