import React, { useEffect, useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { getCountry, updateCountry } from "../../utils/ApiRoutes";
import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";

import "./country-flag.css";
import "./VendorImportExportCountry.scss";
import { useNavigate } from "react-router-dom";
import { vendorProfileRoute } from "../../utils/Routes";
import { useAuth } from "../../context/auth";
import { Helmet } from "react-helmet";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const VendorImportExportCountry = () => {
  const navigate = useNavigate();
  const [selectedImportCountries, setSelectedImportCountries] = useState([]);
  const [selectedIds, setSelectedIds] = useState("");
  const [selectedExportCountries, setSelectedExportCountries] = useState([]);
  const [selectedExportIds, setSelectedExportIds] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState([]);

  const [auth] = useAuth();

  const handleExportChange = (event) => {
    const {
      target: { value },
    } = event;
    const current = countries.filter((country) => {
      return country.name === value[value.length - 1];
    });
    setSelectedExportCountries(
      typeof value === "string" ? value.split(",") : value
    );
    setSelectedExportIds((prev) => prev.concat(`,${current[0].country_id}`));
  };

  const handleImportChange = (event) => {
    const { value } = event.target;
    const current = countries.filter((country) => {
      return country.name === value[value.length - 1];
    });
    setSelectedImportCountries(
      typeof value === "string" ? value.split(",") : value
    );
    setSelectedIds((prev) => prev.concat(`,${current[0].country_id}`));
  };

  // const handleCheckboxChange = (e) => {
  //   const { id, checked } = e.target;
  //   setIsChecked({ ...isChecked, [id]: checked });
  //   checked
  //     ? setBrandIds(brandIds.concat(`,${id}`))
  //     : setBrandIds(brandIds.replace(`,${id}`, ""));
  // };

  useEffect(() => {
    getCountries();
  }, []);

  const getCountries = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(getCountry);
      setCountries(response.data.country);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleNextClick = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(updateCountry, {
        import_ids: selectedIds.replace(",", ""),
        export_ids: selectedExportIds.replace(",", ""),
        code: auth.user?.code,
      });
      if (response.data.error === false) {
        navigate(`${vendorProfileRoute}/${auth.user?.url}`);
      } else {
        console.log(response.data.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleBackClick = (e) => {
    e.preventDefault();
    navigate("/dashboard/vender-brands");
  };

  return (
    <>
      <Helmet>
        <title>import/Export Countries | CARS'R'US</title>
      </Helmet>
      <div className="country-main">
        {!isLoading ? (
          <>
            <div className="stepper">
              <Stepper
                activeStep={3}
                alternativeLabel
                sx={{ paddingTop: "2rem" }}
              >
                <Step>
                  <StepLabel></StepLabel>
                </Step>
                <Step>
                  <StepLabel></StepLabel>
                </Step>
                <Step>
                  <StepLabel></StepLabel>
                </Step>
                <Step>
                  <StepLabel></StepLabel>
                </Step>
              </Stepper>
            </div>
            <h1>Choose/Highlight Services and Show What Makes You Unique</h1>
            <div className="country-section">
              <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="importedFrom">Imported From</InputLabel>
                <Select
                  labelId="importedFrom"
                  id="importedFromCheckbox"
                  multiple
                  value={selectedImportCountries}
                  onChange={handleImportChange}
                  input={<OutlinedInput label="Imported From" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {countries.map((country) => (
                    <MenuItem
                      key={country.country_id}
                      value={country.name}
                      id={country.name}
                    >
                      <Checkbox
                        checked={
                          selectedImportCountries.indexOf(country.name) > -1
                        }
                      />
                      <div
                        className={`flag flag-${country.country_code.toLowerCase()}`}
                      ></div>
                      <ListItemText primary={country.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="exportTo">Exported To</InputLabel>
                <Select
                  labelId="exportTo"
                  id="exportToCheckbox"
                  multiple
                  value={selectedExportCountries}
                  onChange={handleExportChange}
                  input={<OutlinedInput label="Exported To" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {countries.map((country) => (
                    <MenuItem
                      key={country.country_id}
                      value={country.name}
                      name={country.country_id}
                    >
                      <Checkbox
                        checked={
                          selectedExportCountries.indexOf(country.name) > -1
                        }
                      />
                      <div
                        className={`flag flag-${country.country_code.toLowerCase()}`}
                      ></div>
                      <ListItemText primary={country.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="btn-section">
              <button onClick={handleBackClick}>Back</button>
              <button onClick={handleNextClick}>Next</button>
            </div>
          </>
        ) : (
          <div className="vendor-loader"></div>
        )}
      </div>
    </>
  );
};

export default VendorImportExportCountry;
