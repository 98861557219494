import React from 'react'
import ProfileLayout from '../../Layouts/ProfileLayout'
import { Helmet } from 'react-helmet'
import ChangePasswordForm from '../../components/ChangePasswordForm/ChangePasswordForm'
const UserChangePassword = () => {
  return (
    <>
        <Helmet>
            <title>User Change Password | CARS'R'Us</title>
        </Helmet>
        <ProfileLayout>
            <ChangePasswordForm/>
        </ProfileLayout>
    </>
  )
}

export default UserChangePassword