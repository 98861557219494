import React from "react";
import "./DashboardLeftbar.scss";
import { useAuth } from "../../context/auth";
import { vendorProfileRoute } from "../../utils/Routes";

import { Link,useNavigate } from "react-router-dom";

import HandymanIcon from "@mui/icons-material/Handyman";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import { AiOutlineHome ,AiOutlineHistory} from "react-icons/ai";
import {RiLockPasswordLine } from "react-icons/ri"
import { MdOutlineVerified } from "react-icons/md";
import { CiBullhorn } from "react-icons/ci";
import {TbEdit} from "react-icons/tb"
import { Car } from "../../assets/icons/svg";
import { BiLogOut } from "react-icons/bi";

const DashboardLeftbar = ({ leftbar }) => {
  const navigate = useNavigate();
  const [auth ,setAuth] = useAuth()
  const handleLinkClick = (current, link) => {
    navigate(link);
  };

  const handleLogout = () => {
    setAuth({
      user:null,
      userType:0,
    })
    localStorage.removeItem("auth");
    navigate("/");
  };

  return (
    <>
      {leftbar && (
        <div className="dashboard-leftbar">
          <Link to={"/"}>CARS'R'US</Link>
          <div className="nav-items">
            <div className="nav-list">
              <div
                onClick={() =>
                  handleLinkClick("home", `${vendorProfileRoute}/${auth.user?.url}`)
                }
                className="nav-link"
              >
                {" "}
                <AiOutlineHome /> <span>Home</span>
              </div>
              <div
                onClick={() =>
                  handleLinkClick("home", "/dashboard/my-ads")
                }
                className="nav-link"
              >
                {" "}
                <CiBullhorn /> <span>My Ads</span>
              </div>
              <div
                onClick={() =>
                  handleLinkClick("home", "/dashboard/business-reviews")
                }
                className="nav-link"
              >
                {" "}
                <RateReviewOutlinedIcon /> <span>Reviews</span>
              </div>
              <div
                onClick={() =>
                  handleLinkClick("home", "/dashboard/business-verify")
                }
                className="nav-link"
              >
                {" "}
                <MdOutlineVerified /> <span>Get Verified</span>
              </div>
              {(auth.user?.planType === 2 || auth.user?.planType === 3 )  &&(<div
                onClick={() =>
                  handleLinkClick("Search", "/dashboard/search-history")
                }
                className="nav-link"
              >
                {" "}
                <AiOutlineHistory /> <span>Search History</span>
              </div>)}
              {/* {serviceId === 1 || serviceId === 4 || serviceId === 6 &&( */}
              <div
                onClick={() =>
                  handleLinkClick("home", "/dashboard/business-gifts")
                }
                className="nav-link"
              >
                {" "}
                <ChatBubbleOutlineIcon /> <span>Gifts Certificates</span>
              </div>
              <div
                onClick={() =>
                  handleLinkClick("home", "/dashboard/vender-add-cars")
                }
                className="nav-link"
              >
                {" "}
                <Car /> <span>Cars</span>
              </div>
              
              <div
                onClick={() =>
                  handleLinkClick("home", "/dashboard/business-chats")
                }
                className="nav-link"
              >
                {" "}
                <ChatBubbleOutlineIcon /> <span>Chats</span>
              </div>
              
              
                <div
                  onClick={() =>
                    handleLinkClick("home", "/dashboard/vender-add-services")
                  }
                  className="nav-link"
                >
                  {" "}
                  <HandymanIcon /> <span>Services</span>
                </div>
              <div onClick={() =>
                  handleLinkClick("edit", `/dashboard/business-profile-edit/${auth.user.url}`)
                }
                className="nav-link">
                  <TbEdit /> <span>Edit Details</span>
              </div>
              <div onClick={() =>
                  handleLinkClick("edit", `/dashboard/change-password`)
                }
                className="nav-link">
                  <RiLockPasswordLine /> <span>Change Password</span>
              </div>
              <div
                onClick={() =>
                  handleLogout("home", "/dashboard/vender-add-cars")
                }
                className="nav-link"
              >
                <BiLogOut /> <span>Log Out</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardLeftbar;
