import React from "react";
import DashbaoardLayout from "../../Layouts/DashboardLayout";
import ChangePasswordFormVendor from "../../components/ChangePasswordFormVendor/ChangePasswordFormVendor";
import { Helmet } from "react-helmet";
const DashboardBussinessChangePassword = () => {
  return (
    <>
      <Helmet>
        <title>Change Password | CARS-R-US</title>
      </Helmet>
      <DashbaoardLayout>
        <ChangePasswordFormVendor />
      </DashbaoardLayout>
    </>
  );
};

export default DashboardBussinessChangePassword;
