import React , {Fragment}from 'react'
import Footer from '../../components/Footer/Footer'
import ColorNavbar from '../../components/ColorNavbar/ColorNavbar'
import LiveSearchDetailedMain from '../../components/LiveSearchDetailedMain/LiveSearchDetailedMain'
import { Helmet } from "react-helmet"
const LiveSearchDetailed = () => {
  return (
    <Fragment>
        <Helmet>
        <title>Live Search Details| CARS'R'US</title>
        </Helmet>
        <ColorNavbar  textColor={"white"} color={"#232626"} />
        <LiveSearchDetailedMain/>
        <Footer/>
    </Fragment>
  )
}

export default LiveSearchDetailed