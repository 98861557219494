
import AutoLoanCalculator from "../AutoLoanCalculator/AutoLoanCalculator";
import "./AutoLoanMainArea.scss";


const AutoLoanMainArea = () => {
  return (
    <div className="auto-loan-main">
      <AutoLoanCalculator />
    </div>
  );
};

export default AutoLoanMainArea;
