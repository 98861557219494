import React, { useState, useContext } from "react";

import "./VendorOTP.scss";
import "react-toastify/dist/ReactToastify.css";

import { resendVendorRoute, vendorVerifiedRoute } from "../../utils/ApiRoutes";
import { dataContext } from "../../App";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/ToastOptions";
import { useAuth } from "../../context/auth";
import { Helmet } from "react-helmet";
import { CircularProgress } from "@mui/material";

const VendorOTP = () => {
  const [confirm, setConfirm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();

  const { vendorOTP, vendorCode, setVendorOTP } = useContext(dataContext);

  const handleOTPSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    if (Number(vendorOTP) === Number(confirm)) {
      const { data } = await axios.get(`${vendorVerifiedRoute}/${vendorCode}`);
      setAuth({
        ...auth,
        user: {
          email: data.vendor[0].email,
          name: data.vendor[0].name,
          user_id: data.vendor[0].vendor_id,
          user_phone: data.vendor[0].phone,
          loginDate: new Date().getUTCDate(),
          code: vendorCode,
          url: data.vendor[0].url,
          verified: 0,
        },
        userType: 1,
      });
      localStorage.setItem(
        "auth",
        JSON.stringify({
          user: {
            email: data.vendor[0].email,
            name: data.vendor[0].name,
            user_id: data.vendor[0].vendor_id,
            user_phone: data.vendor[0].phone,
            loginDate: new Date().getUTCDate(),
            code: vendorCode,
            url: data.vendor[0].url,
            verified: 0,
          },
          userType: 1,
        })
      );

      navigate("/dashboard/vender-service");
    } else {
      toast.error("invalid OTP", toastOptions);
      setConfirm("");
    }
    setIsLoading(false);
  };

  const handleResend = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(`${resendVendorRoute}/${vendorCode}`);
      setVendorOTP(response.data.otp);
      toast.success("New OTP is Sent to your Mail", toastOptions)
    } catch (error) {
      console.error();
    }
  };

  return (
    <>
      <Helmet>
        <title>Vendor OTP | CARS'R'US</title>
      </Helmet>
      <form className="otp-verify">
        <div className="form">
          <h1>OTP</h1>
          <h4>Enter Otp sent to your Email to verify your email.</h4>
          <OtpInput
            value={confirm}
            onChange={setConfirm}
            numInputs={6}
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
            inputStyle={{
              fontSize: "18px",
              border: "1px solid #d1d1d1",
              height: "px",
              padding: "1rem",
              width: "2.5em",
              borderRadius: "4.49871px",
            }}
            inputType="tel"
          />
          <div className="btn-section">
            <button className="btn-blue" onClick={(e) => handleOTPSubmit(e)}>
            {isLoading ?  <CircularProgress size={22} color="inherit" /> : "Submit"}
            </button>
            <button className="btn-darkgray" onClick={(e) => handleResend(e)}>
              Resend
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default VendorOTP;
