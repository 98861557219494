
import React, { useContext, useRef, useState, useEffect } from "react";

import "./LiveSearchMainArea.scss";

import {
  GetModelRoute,
  InsertChatRequest,
  getChatMessage,
  insertChatMessage,
  profileImageRoute,
  searchRoute,
  vendorMessageImageRoute,
} from "../../utils/ApiRoutes";
import { dataContext } from "../../App";
import { useAuth } from "../../context/auth";
import { toastOptions } from "../../utils/ToastOptions";

import Avatar from "../../assets/images/avatar.jpg";

import SendIcon from "@mui/icons-material/Send";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress, Rating } from "@mui/material";

import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { AutoComplete } from "primereact/autocomplete";
import { motion } from "framer-motion";
import FormData from "form-data";
import axios from "axios";
const LiveSearchMainArea = () => {
  const [auth] = useAuth();
  const currentlocation = useLocation();
  const navigate = useNavigate();
  const timeoutRef = useRef(null);
  const scrollRef = useRef();
  const inputRef = useRef();
  const { maintenance, subMaintenance, brands, parts } = useContext(dataContext);
  const [models, setModels] = useState([]);
  const [active, setActive] = useState(false);
  const [message, setMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState({
    brand_id: "",
    model_id: "",
    model_year: "",
    service: "",
  });
  const [filterInput, setFilterInput] = useState("");
  const [file, setFile] = useState({
    file: null,
    has_image: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerms, setSearchTerms] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [filteredVendors, setFilteredVendors] = useState([]);
  const [vendorLastMessages, setVendorLastMessages] = useState([]);
  const [messages, setMessages] = useState([]);
  const [vendor, setVendor] = useState(null);
  const [code, setCode] = useState("");
  const [searchExists, setSearchExists] = useState(false);
  const [vendorStatus, setVendorStatus] = useState([]);
  const [location, setLocation] = useState({
    latitude: "",
    longitude: "",
    radius: 15,
  });

  useEffect(() => {
    return () => {
      // backFunction();
    };
    // eslint-disable-next-line
  }, [currentlocation]);

  // const backFunction = async () => {
  //   const searchCode = sessionStorage.getItem("searchCode");
  //   console.log(searchCode);
  //   if (searchCode) {
  //     await axios.get(`${process.env.REACT_APP_UPDATE_STATUS}/${searchCode}`);
  //     sessionStorage.removeItem("searchCode");
  //   }
  // };

  useEffect(() => {
    const geolocationAPI = navigator.geolocation;
    if (!geolocationAPI) {
      toast.error(
        "Geolocation API is not available in your browser!",
        toastOptions
      );
    } else {
      geolocationAPI.getCurrentPosition(
        (position) => {
          const { coords } = position;
          setLocation((prev) => ({
            ...prev,
            latitude: coords.latitude,
            longitude: coords.longitude,
          }));
        },
        (error) => {
          toast.error(
            "Something went wrong getting your position!",
            toastOptions
          );
        }
      );
    }
  }, []);

  useEffect(() => {
    timeoutRef.current = setInterval(function () {
      getMessages( vendor?.vendor_id);
      getVendors(code);
    }, 7000);
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
    // eslint-disable-next-line
  }, [active, code]);

  const handleChatClick = async (id) => {
    const temp = vendorStatus.filter((item) => item?.vendor_id === id);
    if (temp[0].status === 1) {
      const { data } = await axios.post(getChatMessage, {
        key_1: `${auth.user.user_id} users`,
        key_2: `${id} vendor`,
        type : 1 ,
      });
      setMessages(data?.Chat);
      setVendor({ ...data?.Key2[0], type: 1 });
      setActive(true);
    }
  };
  // Small Filter Chnage
  const handleChange = (e) => {
    setFilterInput(e.target.value);

    setFilteredVendors(
      vendors.map((item) =>
        item.filter((item) =>
          item.name.toLowerCase().includes(e.target.value.toLowerCase())
        )
      )
    );
  };

  const handleMessageSubmit = async (e, vendor_id) => {
    e.preventDefault();
    const currentDate = new Date();
    const temp = message;
    const messageData = {
      date: currentDate.toDateString(),
      message: temp,
      time: currentDate.getTime(),
      sender_id: auth.user?.user_id,
      receiver_id: vendor_id,
      receiver: "vendor",
      sender : "users",
      file: null,
      type: 1,
    };
    setMessages((prev) => [...prev, messageData]);
    setMessage("");
    const { data } = await axios.post(insertChatMessage, {
      sender_id: auth.user.user_id,
      receiver_id: vendor_id,
      message: temp,
      sender: "users",
      receiver : "vendor",
      type : 1,
    });
    console.log(data);
    scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
  };

  const getMessages = async (id ) => {
    if (active === false) {
      return;
    } else {
      const { data } = await axios.post(getChatMessage, {
        key_1: `${auth.user.user_id} users`,
        key_2: `${id} vendor`,
        type : 1 ,
      });
      if (data?.Chat?.length !== messages?.length) {
        setMessages(data?.Chat);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (searchTerm.service === "") {
      toast.error("Please Select Service", toastOptions);
      return;
    }
    if (auth.userType === 2) {
      setIsLoading(true);
      const temp = [
        ...maintenance,
        ...subMaintenance,
        // ...subParts,
        ...parts,
      ].filter((item) => item.name === searchTerm.service);
      if (temp?.length > 0) {
        if (vendors.length > 0) {
          setVendors([]);
        }
        if (active) {
          setActive(false);
          setVendor(null);
        }
        let tempData = {
          content_id: 0,
          type: 0,
        };
        for (const key in temp[0]) {
          if (key === "sub_maintenance_id") {
            tempData = { type: 4, content_id: temp[0][key] };
            break;
          } else if (key === "maintenance_id") {
            tempData = { type: 3, content_id: temp[0][key] };
            break;
          } else if (key === "parts_type_id") {
            tempData = { type: 2, content_id: temp[0][key] };
            break;
          }
        }
        const formData = new FormData();
        formData.append("user_id", auth.user?.user_id);
        formData.append(
          "search",
          `Customer ${auth.user.name} requested ${temp[0].name} for ${searchTerm.brand_id} ${searchTerm.model_id} ${searchTerm.model_year}`
        );
        formData.append("content_id", tempData.content_id);
        formData.append("type", tempData.type);
        formData.append("image", file.file);
        formData.append("has_image", file.has_image);
        formData.append("latitude", location.latitude);
        formData.append("longitude", location.longitude);
        formData.append("radius", location.radius);
        const { data } = await axios.post(searchRoute, formData);
        if (data.error === true) {
          setVendors([]);
          setSearchExists(true);
        } else if (data.error === false) {
          sessionStorage.setItem("searchCode", data.code);
          setSearchExists(true);
          setCode(data.code);
          const response = await axios.get(`${searchRoute}/${data.code}`);
          setVendors(response.data.Vendor);
          setFilteredVendors(response.data.Vendor);
          setVendorStatus(response.data.status);
        }
      } else {
        toast.error("Please Select From Options", toastOptions);
      }
      setIsLoading(false);
    } else {
      toast.error("Sign out, switch to a user account, and access Live Search.", toastOptions);
    }
  };
  const getVendors = async (searchCode) => {
    if (searchCode) {
      const response = await axios.get(`${searchRoute}/${searchCode}`);
      setVendors(response.data.Vendor);
      setFilteredVendors(response.data.Vendor);
      setVendorLastMessages(response.data.Message);
      setVendorStatus(response.data.status);
    } else {
      return;
    }
  };

  const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);

  const scrollBottom = () => {
    if (scrollRef.current) {
      const { scrollHeight, clientHeight } = scrollRef.current;
      scrollRef.current.scrollTop = scrollHeight - clientHeight;
    }
  };
  useEffect(() => {
    if (isScrolledToBottom) {
      scrollBottom();
    }
    // eslint-disable-next-line
  }, [messages]);

  const handleScroll = () => {
    if (
      scrollRef.current.scrollTop + scrollRef.current.clientHeight ===
      scrollRef.current.scrollHeight
    ) {
      setIsScrolledToBottom(true);
    } else {
      setIsScrolledToBottom(false);
    }
  };

  const search = (event) => {
    if (event.originalEvent.target.id === "brand_id") {
      const filteredItems = [...brands].filter((item) => {
        const itemName = item.name.toLowerCase();
        const lowerCaseSearchTerm = event.query.toLowerCase();
        return itemName.includes(lowerCaseSearchTerm);
      });
      const itemNames = filteredItems.map((item) => item.name);
      setSearchTerms(itemNames.length === 0 ? [] : itemNames);
    } else if (event.originalEvent.target.id === "model_id") {
      const filteredItems = [...models].filter((item) => {
        const itemName = item.name.toLowerCase();
        const lowerCaseSearchTerm = event.query.toLowerCase();
        return itemName.includes(lowerCaseSearchTerm);
      });
      const itemNames = filteredItems.map((item) => item.name);
      setSearchTerms(itemNames.length === 0 ? [] : itemNames);
    } else if (event.originalEvent.target.id === "model_year") {
      const date = new Date();
      const currentYear = date.getFullYear();
      const years = [];

      for (let year = currentYear + 1; year >= 1950; year--) {
        years.push(year);
      }

      const filteredItems = years.filter((year) => {
        const yearString = year.toString();
        const lowerCaseSearchTerm = event.query.toLowerCase();
        return yearString.includes(lowerCaseSearchTerm);
      });

      const itemNames = filteredItems.map((year) => year.toString());
      setSearchTerms(itemNames.length === 0 ? [] : itemNames);
    } else if (event.originalEvent.target.id === "service") {
      const filteredItems = [
        ...maintenance,
        ...subMaintenance,
        ...parts,
        // ...subParts,
      ].filter((item) => {
        const itemName = item.name.toLowerCase();
        const lowerCaseSearchTerm = event.query.toLowerCase();
        return itemName.includes(lowerCaseSearchTerm);
      });
      
      // const itemNames = filteredItems.map((item) =>item.name.length > 70 ?  `${item.name.slice(0,70)}...` : item.name);
      const itemNames = filteredItems.map((item) =>item.name);

      setSearchTerms(itemNames.length === 0 ? [] : itemNames);
    }
  };

  const handleBrandChange = async (e) => {
    setSearchTerm((prev) => ({ ...prev, brand_id: e.value }));
    const currentId = brands.filter((item) => item.name === e.value)[0]
      ?.brand_id;
    if (currentId) {
      const { data } = await axios.get(`${GetModelRoute}/${currentId}`);
      setModels(data.Model);
    }
  };
  const handleRequestClick = async (e, vendor_id) => {
    if (e.target.innerText !== "Requested") {
      e.target.innerText = "Requested";
      const temp = [
        ...maintenance,
        ...subMaintenance,
        // ...subParts,
        ...parts,
      ].filter((item) => item.name === searchTerm.service);
      const formData = new FormData();
      formData.append("search_code", sessionStorage.getItem("searchCode"));
      formData.append("vendor_id", vendor_id);
      formData.append("user_id", auth.user?.user_id);
      formData.append(
        "search",
        `Customer ${auth.user.name} requested ${temp[0].name} for ${searchTerm.brand_id} ${searchTerm.model_id} ${searchTerm.model_year}`
      );
      formData.append("image", file.file);
      formData.append("has_image", file.has_image);

      await axios.post(InsertChatRequest, formData);
    } else {
      return;
    }
  };
  return (
    <div className="live-search-home">
      <div className="heading">Live Search</div>
      <div className="search-area">
        <form>
          <div className="inputs-autocomplete">
            <div className="input-section-inner">
              <label htmlFor="brand_id">Brand</label>
              <AutoComplete
                value={searchTerm.brand_id}
                placeholder="Audi"
                suggestions={searchTerms}
                completeMethod={search}
                onChange={(e) => handleBrandChange(e)}
                forceSelection
                autoFocus
                inputId="brand_id"
              />
            </div>
            <div className="input-section-inner">
              <label htmlFor="model_id">Model</label>
              <AutoComplete
                value={searchTerm.model_id}
                placeholder="R8"
                suggestions={searchTerms}
                completeMethod={search}
                onChange={(e) =>
                  setSearchTerm((prev) => ({ ...prev, model_id: e.value }))
                }
                forceSelection
                inputId="model_id"
              />
            </div>

            <div className="input-section-inner">
              <label htmlFor="model_year">Model Year</label>
              <AutoComplete
                placeholder="2014"
                value={searchTerm.model_year}
                suggestions={searchTerms}
                completeMethod={search}
                onChange={(e) =>
                  setSearchTerm((prev) => ({ ...prev, model_year: e.value }))
                }
                forceSelection
                inputId="model_year"
              />
            </div>

            <div className="input-section-inner">
              <label htmlFor="service">Service</label>
              <AutoComplete
                placeholder="Engine"
                value={searchTerm.service}
                suggestions={searchTerms}
                completeMethod={search}
                onChange={(e) =>
                  setSearchTerm((prev) => ({ ...prev, service: e.value }))
                }
                forceSelection
                inputId="service"
              />
            </div>

            <div className="icon" onClick={() => inputRef.current.click()}>
              <AddAPhotoOutlinedIcon />
            </div>
          </div>
          <input
            type="file"
            onChange={(e) => setFile({ file: e.target.files[0], has_image: 1 })}
            ref={inputRef}
            style={{ display: "none" }}
          />
          <button
            onClick={
              auth.user?.user_id ? handleSubmit : () => navigate("/login")
            }
            style={{ cursor: isLoading ? "not-allowed" : "pointer" }}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress size={22} color="inherit" />
            ) : auth.user?.user_id ? (
              "Search"
            ) : (
              "Login"
            )}
          </button>
        </form>
      </div>
      <div className="live-search-results">
        {searchExists && (
          <motion.div
            initial={{ y: 30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ duration: 0.8, ease: "easeInOut" }}
            className="results"
          >
            <h4>Your Live Search Results</h4>
            <p>
              You can Edit your Search by searching again or edit existing
              searches. Your results below will remain until you remove them
              from your list
            </p>
            <input
              placeholder="Search By Vendor Name ..."
              type="text"
              name="vendorfilter"
              id="vendorfilter"
              value={filterInput}
              onChange={handleChange}
            />
            {[].slice( 0 , [].length > 5 ? 5 : [].length  )}
            {vendors?.length > 0 && (
              <div className="chat-list">
                {filteredVendors?.slice( 0 , filteredVendors.length > 5 ? 5 : filteredVendors.length  )?.map((item) =>
                  item?.map((vendor) => (
                    <div className="chat-card" key={vendor.vendor_id}>
                      <a
                        href={`https://staging-carus.digitalelliptical.com/merchant/${vendor.url}`}
                        target="_blank"
                        rel="noreferrer"
                        className="profile-image"
                      >
                        <img
                          src={
                            vendor?.profile
                              ? `${profileImageRoute}/${vendor?.profile}`
                              : Avatar
                          }
                          alt="profile"
                        />
                      </a>
                      <div
                        className="chat-details"
                        onClick={() => handleChatClick(vendor.vendor_id)}
                      >
                        <div className="top">
                          <h4>{vendor.name}</h4>
                          <Rating value={Number(vendor?.rating)} readOnly />
                          {vendorLastMessages
                            .filter(
                              (item) => item[0]?.vendor_id === vendor?.vendor_id
                            )
                            .map((item) => <p>{item[0]?.message}</p>) || null}
                        </div>
                        <div className="bottom" style={{ display: "flex" }}>
                          {vendorStatus
                            ?.filter(
                              (item) => item?.vendor_id === vendor?.vendor_id
                            )
                            .map((item) => (
                              <>
                                {item.status !== 0 && (
                                  <button
                                    className={
                                      Number(item.status) === 1
                                        ? "accept"
                                        : "pending"
                                    }
                                  >
                                    {Number(item.status) === 1
                                      ? "Accepted"
                                      : "Rejected"}
                                  </button>
                                )}
                                {item.status === 0 && (
                                  <button
                                    onClick={(e) =>
                                      handleRequestClick(e, vendor.vendor_id)
                                    }
                                  >
                                    Request
                                  </button>
                                )}
                              </>
                            ))}
                        </div>
                      </div>
                    </div>
                  ))
                )}
                {/* <span className="see-more"><Link to={"/search/details"}>See More</Link></span> */}
                <span className="see-more" onClick={()=>navigate("/search/details", {state: {vendors,vendorStatus,code,file,searchTerm}})}>See More</span>
              </div>
            )}
            {vendors?.length === 0 && (
              <div className="no-vendor">
                {isLoading ? "" : "No Vendors Found..."}
              </div>
            )}
          </motion.div>
        )}

        {active === true && (
          <motion.div
            initial={{ y: 30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8, ease: "easeInOut" }}
            className="live-chat"
          >
            {vendor !== null && (
              <>
                <div className="top-section">
                  <div className="chat-left">
                    <div className="profile">
                      <img
                        src={
                          vendor?.profile
                            ? `${profileImageRoute}/${vendor?.profile}`
                            : Avatar
                        }
                        alt="Profile"
                      />
                    </div>
                    <div className="profile-details">
                      <h3>{vendor?.shop_name}</h3>
                      <Rating value={parseFloat(vendor?.rating)} readOnly />
                    </div>
                  </div>
                  <div className="chat-right" onClick={() => setActive(false)}>
                    <CloseIcon />
                  </div>
                </div>
                <div
                  className="messages"
                  ref={scrollRef}
                  onScroll={handleScroll}
                >
                  {messages.map((message, i) => (
                    <>
                      {message?.sender !=="vendor" ? (
                        <>
                          <div className="sent">{message?.message}</div>
                          {message?.file !== null && (
                            <div className="sent">
                              <img
                                src={`${vendorMessageImageRoute}/${message?.file}`}
                                alt="Search"
                                style={{ width: "100%" }}
                              />
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="reply">{message?.message}</div>
                      )}
                    </>
                  ))}
                  <div style={{ height: "30px" }} ref={scrollRef}></div>
                </div>
                <div className="bottom-section">
                  <div className="input">
                    <form
                      onSubmit={(e) =>
                        handleMessageSubmit(e, vendor?.vendor_id)
                      }
                    >
                      <input
                        type="text"
                        name="message"
                        id="message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                      <button type="submit">
                        <SendIcon />
                      </button>
                    </form>
                  </div>
                </div>
              </>
            )}
          </motion.div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default LiveSearchMainArea;
