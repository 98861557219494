import "./AutoLoanCalculator.scss";

import { useState, useEffect } from "react";

import Slider from "@mui/material/Slider";

const AutoLoanCalculator = () => {
  const [sliderValue, setSliderValue] = useState(1.5);
  const [formData, setFormData] = useState({
    monthlySalary: "",
    carAmount: "",
    loanPeriod: "",
  });
  const [CalculatedStates, setCalculatedStates] = useState({
    monthlyInstallment: 0,
    principlePayment: 0,
    totalInterest: 0,
  });
  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  

  useEffect(() => {
    const data = calculateLoan(
      Number(formData.monthlySalary),
      Number(formData.carAmount),
      Number(formData.loanPeriod),
      Number(sliderValue)
    );
    if (!isNaN(data.monthlyInstallment) && isFinite(data.monthlyInstallment)) {
      setCalculatedStates(data);
    }
  }, [formData, sliderValue]);

  function calculateLoan(monthlySalary, carAmount, loanPeriod, interestRate) {
    // Convert interest rate from percentage to decimal
    interestRate = interestRate / 100;
  
    // Calculate monthly interest rate
    var monthlyInterestRate = interestRate / 12;
  
    // Calculate total number of payments
    var totalPayments = loanPeriod * 12;
  
    // Calculate loan amount after down payment
    var loanAmount = carAmount;
  
    // Calculate monthly installment
    var monthlyInstallment =
      (loanAmount * monthlyInterestRate) /
      (1 - Math.pow(1 + monthlyInterestRate, -totalPayments));
  
    // Calculate total interest
    var totalInterest = monthlyInstallment * totalPayments - loanAmount;
  
    // Calculate principle payment
    var principlePayment = totalInterest / totalPayments;
  
    return {
      monthlyInstallment: monthlyInstallment.toFixed(2),
      principlePayment: principlePayment.toFixed(2),
      totalInterest: totalInterest.toFixed(2),
    };
  }
  

  const handleChange = (e) => {
    const { value, id } = e.target;
    const numericValue = parseFloat(value);
  
    if (id !== "loanPeriod") {
      const sanitizedValue = numericValue > 0 ? numericValue : 0;
      setFormData((prev) => ({ ...prev, [id]: sanitizedValue }));
    } else {
      setFormData((prev) => ({ ...prev, [id]: value }));
    }
  };

  return (
    <div className="auto-loan-calculator">
      <div className="left-side">
        <div className="Section-heading">
          <h4>Auto Loan Calculator</h4>
          <p>
            An informed decision is the best one This tool will estimate the
            value of your package
          </p>
        </div>
        <div className="input-sections">
          <h5>Loan Calculator</h5>
          <form>
            <div>
              <label htmlFor="monthlySalary">Monthly Salary</label>
              <input
                type="number"
                id="monthlySalary"
                name="monthlySalary"
                placeholder="5000AED"
                value={formData.monthlySalary}
                onChange={handleChange}
                min={0}
              />
            </div>
            <div className="half-input">
              <div className="">
                <label htmlFor="carAmount">Car Amount </label>
                <input
                  type="number"
                  id="carAmount"
                  name="carAmount"
                  placeholder="AED"
                  value={formData.carAmount}
                  onChange={handleChange}
                  min={0}
                />
              </div>
              <div className="">
                <label htmlFor="loanPeriod">Loan Period</label>
                <select
                  id="loanPeriod"
                  name="loanPeriod"
                  placeholder="Years"
                  value={formData.loanPeriod}
                  onChange={handleChange}
                >
                  <option value="0">Select Period</option>
                  <option value="1">01</option>
                  <option value="2">02</option>
                  <option value="3">03</option>
                  <option value="4">04</option>
                  <option value="5">05</option>
                  <option value="6">06</option>
                  <option value="7">07</option>
                  <option value="8">08</option>
                  <option value="9">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <div className="slider-area">
          <label htmlFor="slider">Intrest Rate</label>
          <div className="slider-inner">
            <Slider
              getAriaLabel={() => "Minimum distance"}
              value={sliderValue}
              onChange={handleSliderChange}
              valueLabelDisplay="auto"
              step={0.1}
              max={20}
              min={0}
              sx={{ color: "#E52A2D" }}
            />
            <div className="slider-value">{sliderValue}%</div>
          </div>
        </div>
      </div>
      <div className="right-side">
        <div className="top-area">
          <h3>Totals</h3>
          <div className="content">
            <p className="section-heading">Pre and post tax estimates</p>
            <div className="amount-card">
              <p>Total Intrest </p>
              <h6>{CalculatedStates?.totalInterest}</h6>
            </div>
            <div className="amount-card">
              <p>Principle Payment</p>
              <h6>{CalculatedStates?.principlePayment}</h6>
            </div>
            <div className="amount-card">
              <p>Monthly Instalment</p>
              <h6>{CalculatedStates?.monthlyInstallment}</h6>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default AutoLoanCalculator;
