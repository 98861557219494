import React from 'react'
import ProfileLayout from '../../Layouts/ProfileLayout'
import PromoteAdSection from '../../components/PromoteAdSection/PromoteAdSection'

const PromoteAd = () => {
  return (
    <ProfileLayout>
        <PromoteAdSection/>
    </ProfileLayout>
  )
}

export default PromoteAd