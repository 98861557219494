import React from "react";
import ColorNavbarWithCategory from "../../components/ColorNavbarWithCategory/ColorNavbarWithCategory";
import Footer from "../../components/Footer/Footer";
import SingleProductMainArea from "../../components/SingleProductMainArea/SingleProductMainArea";
import { Helmet } from "react-helmet";
const SingleProductPage = () => {
  return (
    <>
      <Helmet>
        <title>Product Details | CARS'R'US</title>
      </Helmet>
      <div>
        <ColorNavbarWithCategory color={"#ED5565"} textColor={"black"} />
        <SingleProductMainArea />
        <Footer />
      </div>
    </>
  );
};

export default SingleProductPage;
