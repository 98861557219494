import React, { useState, useEffect , useContext, useCallback} from "react";
import { UploadedBy, filterInitialState } from "../../assets/data/index";
import "./FilterWithSlider.scss";
import Slider from "@mui/material/Slider";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import {
  GetBrandRoute,
  GetModelRoute,
  getCitiesRoute,
  getlocationRoute,
} from "../../utils/ApiRoutes";
import {
  BodyType,
  ColorsForDisplay,
  Cylinders2,
  Doors,
  Horspower,
  SteeringSide,
  TransmissionType,
  Trim,
  Warrenty,
  fuelTypes,
  insured,
  newUsed,
} from "../../assets/data";
import { handlePillClick } from "../../utils/ReuseFunction";
import { dataContext } from "../../App";
import { formatNumber } from "../../utils/ReuseFunction";

const FilterWithSlider = ({ color, filters, setFilters , filterRange }) => {
  const [cities, setCities] = useState([]);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [locations, setLocations] = useState([]);
  const { countries } =  useContext(dataContext)
  const handleCloseClick = () => {
    document.getElementById("menu").style.display = "none";
  };
  const getData = useCallback(
    async () => {
      const brandsResponse = await axios.get(GetBrandRoute);
      setBrands(brandsResponse.data.brand);
      const uae = countries.find((data) => data.country_code.toLowerCase() === "AE".toLowerCase())
      const cityResponse = await axios.get(`${getCitiesRoute}/${uae.country_id}`);
      setCities(cityResponse.data.city);
    },
    [countries],
  )
  useEffect(() => {
    getData();
  }, [getData]);

  const handleChange = async (e) => {
    const { id, value } = e.target;
    setFilters((prev) => ({ ...prev, [id]: Number(value), count : prev[id] === 0 ?  ++prev.count : prev.count  }));
    if (id === "city_id") {
      document.getElementById("location_id").removeAttribute("disabled");
      const locationResponse = await axios.get(`${getlocationRoute}/${value}`);
      setLocations(locationResponse.data.location);
    }
    if (id === "brand_id") {
      document.getElementById("model_id").removeAttribute("disabled");
      const locationResponse = await axios.get(`${GetModelRoute}/${value}`);
      setModels(locationResponse.data.Model);
    }
  };

  const handleChange1 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (activeThumb === 0) {
      setPrice([Math.min(newValue[0], price[1] - minDistance), price[1]]);
    } else {
      setPrice([price[0], Math.max(newValue[1], price[0] + minDistance)]);
    }
  };
  const handleChange2 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (activeThumb === 0) {
      setYear([Math.min(newValue[0], Year[1] - 0), Year[1]]);
    } else {
      setYear([Year[0], Math.max(newValue[1], Year[0] + 10)]);
    }
  };

  const minDistance = 0;
  const currentYear = new Date().getFullYear();
  const [price, setPrice] = useState([filterRange.min, filterRange.max]);
  const [Year, setYear] = useState([1950, Number(currentYear) + 1]);
  const [mechanical_condition, setMechanical_condition] = useState(0);
  const [body_condition, setBody_condition] = useState(0);

  const handleMouseUp = (current) => {
    if (current === "price") {
      setFilters((prev) => ({
        ...prev,
        min_price: price[0],
        max_price: price[1],
        count:
          prev.min_price === 0 || prev.max_price === 0
            ? ++prev.count
            : prev.count,
      }));
    }
    if (current === "year") {
      setFilters((prev) => ({
        ...prev,
        min_year: Year[0],
        max_year: Year[1],
        count:
          prev.min_year === 1950 || prev.max_year === Number(currentYear) + 1
            ? ++prev.count
            : prev.count,
      }));
    }
    if (current === "mechanical_condition") {
      setFilters((prev) => ({
        ...prev,
        mechanical_condition: mechanical_condition,
        count: prev.mechanical_condition === 0  ? ++prev.count : prev.count,
      }));
    }
    if (current === "body_condition") {
      setFilters((prev) => ({
        ...prev,
        body_condition: body_condition,
        count: prev.body_condition === 0 ? ++prev.count : prev.count,
      }));
    }
  };

  const handleChange3 = (event, newValue) => {
    setMechanical_condition(newValue);
  };

  const handleChange4 = (event, newValue) => {
    setBody_condition(newValue);
  };

  const clearFilter = (inital) => {
    setFilters({...inital, count:0});
    setPrice([filterRange.min, filterRange.max])
    setYear([1950, Number(currentYear) + 1])
    setMechanical_condition(0)
    setBody_condition(0)
  }

  return (
    <div className="left-area" id="menu">
      <div className="side-header">
        <div>
          <h4 style={{ color: color }}>Filter</h4>
          <h5 style={{ color: color }}>
            {filters.count > 0
              ? `${filters.count} filters applied`
              : "No Filters applied"}
          </h5>
          {filters.count > 0 && (
            <button
              style={{ backgroundColor: color, color: "white" }}
              onClick={() => clearFilter(filterInitialState)}
              className="filter-button"
            >
              Clear Filters
            </button>
          )}
        </div>
        <div onClick={handleCloseClick}>
          <CloseIcon />
        </div>
      </div>
      {/* <div className="btn-section">
        <button style={{ backgroundColor: color }}>Full Price</button>
        <button style={{ color: color, border: `1px solid ${color}` }}>
          Monthly Price
        </button>
      </div>
      <div className="finance">
        <span style={{ color: color }}>Get finance per-approval</span>
        <AccountBalanceOutlinedIcon sx={{ color: color }} />
      </div> */}
      <div className="slider-area">
        <div className="slider-desc">
          <h6 style={{ color: color }}>Price</h6>
          <p style={{ color: color }}>AED {formatNumber(filterRange.min)} - {formatNumber(filterRange.max)}</p>
        </div>
        <div style={{ paddingLeft: ".7rem" }}>
          <Slider
            getAriaLabel={() => "Minimum distance"}
            value={price}
            onChange={handleChange1}
            valueLabelDisplay="auto"
            disableSwap
            min={filterRange.min}
            max={filterRange.max}
            step={1000}
            sx={{ color: color, width: "95%" }}
            onChangeCommitted={() => handleMouseUp("price")}
          />
        </div>
        <div className="slider-desc">
          <h6 style={{ color: color }}>Year</h6>
          <p style={{ color: color }}>1950 - {currentYear + 1}</p>
        </div>
        <div style={{ paddingLeft: ".7rem" }}>
          <Slider
            getAriaLabel={() => "Minimum distance"}
            value={Year}
            onChange={handleChange2}
            valueLabelDisplay="auto"
            disableSwap
            min={1950}
            max={currentYear + 1}
            step={1}
            sx={{ color: color, width: "95%" }}
            onChangeCommitted={() => handleMouseUp("year")}
          />
        </div>
        <div className="slider-desc">
          <h6 style={{ color: color }}>Mechanical Condition</h6>
          <p style={{ color: color }}>1 - 10</p>
        </div>
        <div>
          <Slider
            getAriaLabel={() => "Minimum distance"}
            value={mechanical_condition}
            onChange={handleChange3}
            valueLabelDisplay="auto"
            step={1}
            max={10}
            min={0}
            sx={{ color: color }}
            onChangeCommitted={() => handleMouseUp("mechanical_condition")}
          />
        </div>
        <div className="slider-desc">
          <h6 style={{ color: color }}>Body Condition</h6>
          <p style={{ color: color }}>1 - 10</p>
        </div>
        <div>
          <Slider
            getAriaLabel={() => "Minimum distance"}
            value={body_condition}
            onChange={handleChange4}
            valueLabelDisplay="auto"
            step={1}
            max={10}
            min={0}
            sx={{ color: color }}
            onChangeCommitted={() => handleMouseUp("body_condition")}
          />
        </div>
      </div>

      <div className="select-section">
      {/* Country Select  */}

      {/* <label htmlFor="country_id" style={{ color: color }}>
          Country
        </label>
        <select
          name="country_id"
          id="country_id"
          value={filters.country_id}
          onChange={handleChange}
          style={{ color: color,borderColor: color}}
        >
          {filters.country_id === 0 && (
            <option value="0">Select Country</option>
          )}
          {countries.map((country) => (
            <option value={country.country_id} key={country.country_id}>
              {country.name}
            </option>
          ))}
        </select> */}

      {/* City Select  */}

      <label htmlFor="city_id" style={{ color: color }}>
          City
        </label>
        <select
          name="city_id"
          id="city_id"
          value={filters.city_id}
          onChange={handleChange}
          style={{ color: color,borderColor: color}}
        >
          {filters.city_id === 0 && <option value="0">Select City</option>}
          {cities.map((city) => (
            <option value={city.city_id} key={city.city_id}>
              {city.name}
            </option>
          ))}
        </select>

      {/* Location Select */}

      <label htmlFor="location_id" style={{ color: color }}>
          Location
        </label>
        <select
          name="location_id"
          id="location_id"
          disabled
          value={filters.location_id}
          onChange={handleChange}
          style={{ color: color,borderColor: color}}

        >
          {filters.location_id === 0 && (
            <option value="0">Select Location</option>
          )}
          {locations.map((location) => (
            <option value={location.location_id} key={location.location_id}>
              {location.name}
            </option>
          ))}
        </select>
      </div>
      <div className="select-section">
      {/* Brand Select  */}

      <label htmlFor="brand_id" style={{ color: color }}>
          Brand
        </label>
        <select
          name="brand_id"
          id="brand_id"
          value={filters.brand_id}
          style={{ color: color,borderColor: color}}
          onChange={handleChange}
        >
          {filters.model_id === 0 && <option value="0">Select Brand</option>}
          {brands.map((brand) => (
            <option value={brand.brand_id} key={brand.brand_id}>
              {brand.name}
            </option>
          ))}
        </select>

      {/* Model Select */}

      <label htmlFor="model_id" style={{ color: color }}>
          Model
        </label>
        <select name="model_id" id="model_id" disabled value={filters.model_id} onChange={handleChange} style={{ color: color,borderColor: color}}>
          {filters.model_id === 0 && <option value="0">Select Model</option>}
          {models.map((model) => (
            <option value={model.model_id} key={model.model_id}>
              {model.name}
            </option>
          ))}
        </select> 
       </div>

      <div className="pill-section">
        {/* New / Used */}
        <h4 style={{ color: color }}>New / Used</h4>
        <div className="pills">
          {newUsed.map((type, i) =>
            type === "" ? null : (
              <div
                className={filters.type === i ? "pill pill-active" : "pill"}
                style={{ color: color }}
                onClick={() => handlePillClick(i, "type", filters , setFilters)}
                key={i}
              >
                {type}
              </div>
            )
          )}
        </div>
        {/* Fuel Type */}
        <h4 style={{ color: color }}>Fuel Types</h4>
        <div className="pills">
          {fuelTypes.map((fuelType, i) =>
            fuelType === "" ? null : (
              <div
                className={
                  filters.fuel_type === i ? "pill pill-active" : "pill"
                }
                style={{ color: color }}
                onClick={() => handlePillClick(i, "fuel_type" , filters , setFilters)}
                key={i}
              >
                {fuelType}
              </div>
            )
          )}
        </div>
        {/* Colors */}
        {/* <h4 style={{ color: color }}>Colors</h4>
        <div className="colors">
          {ColorsForDisplay.map((color, i) =>
            color === "" || color === "Unlisted" ? null : (
              <div
                key={i}
                style={{ backgroundColor: color }}
                className={
                  filters.color_id === i
                    ? "color-box color-box-active"
                    : "color-box"
                }
                onClick={() => handlePillClick(i, "color_id", filters , setFilters)}
              />
            )
          )}
        </div> */}
        {/* Steering Side */}
        <h4 style={{ color: color }}>Steering Side</h4>
        <div className="pills">
          {SteeringSide.map((side, i) =>
            side === "" ? null : (
              <div
                className={
                  filters.steering_side === i ? "pill pill-active" : "pill"
                }
                style={{ color: color }}
                onClick={() => handlePillClick(i, "steering_side", filters , setFilters)}
                key={i}
              >
                {side}
              </div>
            )
          )}
        </div>
        {/* Transmission Type */}
        <h4 style={{ color: color }}>Transmission Type</h4>
        <div className="pills">
          {TransmissionType.map((type, i) =>
            type === "" ? null : (
              <div
                className={
                  filters.transmission_type === i ? "pill pill-active" : "pill"
                }
                style={{ color: color }}
                onClick={() => handlePillClick(i, "transmission_type", filters , setFilters)}
                key={i}
              >
                {type}
              </div>
            )
          )}
        </div>
        {/* Warrenty */}
        <h4 style={{ color: color }}>Warranty</h4>
        <div className="pills">
          {Warrenty.map((side, i) =>
            side === "" ? null : (
              <div
                className={filters.warranty === i ? "pill pill-active" : "pill"}
                style={{ color: color }}
                onClick={() => handlePillClick(i, "warranty", filters , setFilters)}
                key={i}
              >
                {side}
              </div>
            )
          )}
        </div>
        {/* insured */}
        <h4 style={{ color: color }}>Insured</h4>
        <div className="pills">
          {insured.map((side, i) =>
            side === "" ? null : (
              <div
                className={filters.insured === i ? "pill pill-active" : "pill"}
                style={{ color: color }}
                onClick={() => handlePillClick(i, "insured", filters , setFilters)}
                key={i}
              >
                {side}
              </div>
            )
          )}
        </div>
        {/* BodyType  Pills */}
        <h4 style={{ color: color }}>Body Types</h4>
        <div className="pills">
          {BodyType.map((type, i) =>
            type === "" ? null : (
              <div
                className={
                  filters.body_type === i ? "pill pill-active" : "pill"
                }
                style={{ color: color }}
                onClick={() => handlePillClick(i, "body_type", filters , setFilters)}
                key={i}
              >
                {type}
              </div>
            )
          )}
        </div>

        {/* Trim Pills */}

        <h4 style={{ color: color }}>Trim</h4>
        <div className="pills">
          {Trim.map((trim, i) =>
            trim === "" ? null : (
              <div
                className={filters.trim === i ? "pill pill-active" : "pill"}
                style={{ color: color }}
                onClick={() => handlePillClick(i, "trim", filters , setFilters)}
                key={i}
              >
                {trim}
              </div>
            )
          )}
        </div>

        {/* Doors */}
        <h4 style={{ color: color }}>Doors</h4>
        <div className="pills">
          {Doors.map((door, i) =>
            door === "" ? null : (
              <div
                className={filters.door_id === i ? "pill pill-active" : "pill"}
                style={{ color: color }}
                onClick={() => handlePillClick(i, "door", filters , setFilters)}
                key={i}
              >
                {door}
              </div>
            )
          )}
        </div>

        {/* Cylinders */}

        <h4 style={{ color: color }}>Cylinders</h4>
        <div className="pills">
          {Cylinders2.map((cylinder, i) =>
            cylinder === "" ? null : (
              <div
                className={
                  filters.cylinders === i ? "pill pill-active" : "pill"
                }
                style={{ color: color }}
                onClick={() => handlePillClick(i, "cylinder", filters , setFilters)}
                key={i}
              >
                {cylinder}
              </div>
            )
          )}
        </div>
        {/* HorsePower */}

        <h4 style={{ color: color }}>Horspower</h4>
        <div className="pills">
          {Horspower.map((horsepower, i) =>
            horsepower === "" ? null : (
              <div
                className={
                  filters.horsepower === i ? "pill pill-active" : "pill"
                }
                style={{ color: color }}
                onClick={() => handlePillClick(i, "horsepower", filters , setFilters)}
                key={i}
              >
                {horsepower}
              </div>
            )
          )}
        </div>
        {/* Uploaded bY */}

        <h4 style={{ color: color }}>Uploaded By</h4>
        <div className="pills">
          {UploadedBy.map((uploadedBy, i) =>
            uploadedBy === "" ? null : (
              <div
                className={
                  filters.uploaded_by === i ? "pill pill-active" : "pill"
                }
                style={{ color: color }}
                onClick={() => handlePillClick(i, "uploadedBy", filters , setFilters)}
                key={i}
              >
                {uploadedBy}
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default FilterWithSlider;
