import React from "react";
import ColorHeader from "../../components/Headers/ColorHeader/ColorHeader";
import tireBackgorund from "../../assets/images/tire-background.png";
import PartsHomeMainArea from "../../components/PartsHomeMainArea/PartsHomeMainArea";
import Footer from "../../components/Footer/Footer";
import { Helmet } from "react-helmet";
const PartsHomePage = () => {
  return (
    <>
      <Helmet>
        <title>Parts | CARS'R'US</title>
      </Helmet>
      <ColorHeader background={tireBackgorund} slogan={"BUY PARTS"} />
      <PartsHomeMainArea />
      <Footer />
    </>
  );
};

export default PartsHomePage;
