import "./PartsHomeMainArea.scss";
import facebook from "../../assets/icons/facebook.png";
import instagram from "../../assets/icons/instagram.png";
import linkedin from "../../assets/icons/linkedIn.png";
import twitter from "../../assets/icons/twitter.png";
import youTube from "../../assets/icons/youTube.png";
import tikTok from "../../assets/icons/tikTok.png";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { dataContext } from "../../App";
import axios from "axios";
import {
  blogImageRoute,
  brandLogoRoute,
  getBlogsRoute,
  serviceHomePage,
} from "../../utils/ApiRoutes";
import { Link, useNavigate } from "react-router-dom";
// import { merchantDetailsPage } from "../../utils/Routes";
// import MerchantCard from "../MerchantCard/MerchantCard";
import BiggestShopCard from "../BiggestShopCard/BiggestShopCard";

const PartsHomeMainArea = () => {
  const [states, setStates] = useState({});
  const [blogs, setBlogs] = useState([]);
  const navigate = useNavigate();

  const { brands } = useContext(dataContext);
  const controller = useMemo(() => new AbortController(), []);
  const getData = useCallback(async () => {
    const { data } = await axios.get(`${serviceHomePage}/3/Stats`, {
      signal: controller.signal,
    });
    const response = await axios.get(getBlogsRoute, {
      signal: controller.signal,
    });
    setBlogs(response.data.populor);
    setStates(data);
  }, [controller.signal]);

  useEffect(() => {
    getData();

    return () => controller.abort();
  }, [controller, getData]);

  // const handleCardClick = (url) => {
  //   navigate(`${merchantDetailsPage}/${url}`);
  // };

  return (
    <>
      <div className="parts-home-area">
        <div className="first-section">
          <h4>Parts for which Make?</h4>
          <div className="brands">
            {brands?.map((brand, i) => (
              <img
                src={`${brandLogoRoute}/${brand.logo}`}
                alt={brand.name}
                key={i}
              />
            ))}
          </div>
        </div>
        {/* <div className="second-section">
          <h4>Some of the biggest Part Shops in Town </h4>
          <div className="comparision-cards">
            {states?.vendor &&
              states?.vendor?.slice(0, 2)?.map((vendor, i) => (
                <div key={i} style={{ cursor: "pointer" }}>
                  <MerchantCard
                    vendor={vendor}
                    badges={states?.Badge}
                    badgeId={JSON.parse(vendor.badge)}
                    handleCardClick={handleCardClick}
                    background={"white"}
                  />
                </div>
              ))}
          </div>
        </div> */}
        <div className="second-section">
          <h4>Some of the biggest Part Shops in Town </h4>
          <div className="comparision-cards">
            {states?.vendor &&
              states?.vendor?.slice(0, 2)?.map((vendor, i) => (
                <div key={i} style={{ cursor: "pointer" }}>
                  <BiggestShopCard vendor={vendor}/>
                </div>
              ))}
          </div>
        </div>
        {/* {states?.vendor && states?.vendor.length > 2 && (
          <div className="third-section">
            {states?.vendor &&
              states?.vendor
                ?.slice(2, 7)
                ?.map((vendor, i) => (
                  <MerchantCard
                    vendor={vendor}
                    badges={states?.Badge}
                    badgeId={JSON.parse(vendor.badge)}
                    handleCardClick={handleCardClick}
                    background={"white"}
                    key={i}
                  />
                ))}
          </div>
        )} */}
        <div className="fourth-section">
          <div className="section-header">
            <h2>Car-Buying Guides</h2>
            <Link to={"/blog"}>View all</Link>
          </div>
          <div className="guide-cards">
            {blogs?.map((blog, i) => (
              <div
                className="guide-card"
                onClick={() => navigate(`/blog/${blog.url}`)}
                key={i}
              >
                <img
                  src={`${blogImageRoute}/${blog?.image}`}
                  alt={blog?.name}
                />
                <h4>{blog?.name}</h4>
              </div>
            ))}
          </div>
        </div>
        <div className="fifth-section">
          <div className="section-header">
            <h4>Follow us on social media</h4>
            <p>All the latest car news for you</p>
          </div>
          <div className="social-media-icons">
            <img src={facebook} alt="social-media-icon" />
            <img src={twitter} alt="social-media-icon" />
            <img src={instagram} alt="social-media-icon" />
            <img src={linkedin} alt="social-media-icon" />
            <img src={tikTok} alt="social-media-icon" />
            <img src={youTube} alt="social-media-icon" />
          </div>
          <div className="hashtag">#US</div>
        </div>
      </div>
    </>
  );
};

export default PartsHomeMainArea;
