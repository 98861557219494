import React, { useState, useContext } from "react";
import "./ResponsiveMenu.scss";

import { AiOutlineClose } from "react-icons/ai";
import { FiChevronDown } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";

import { carMenuDataLeft, carMenuDataRight } from "../../assets/data/index";
import { dataContext } from "../../App";
import { useAuth } from "../../context/auth";
import {handleLogout} from "../../utils/ReuseFunction"
const ResponsiveMenu = ({ active, setActive }) => {
  const [subMenuActive, setSubMenuActive] = useState("");
  const { pathname } = useLocation();
  const { maintenance, parts } = useContext(dataContext);
  const [auth , setAuth ] = useAuth();
  return (
    <>
      {active === "sidemenu" && (
        <div className="click-away-listner" onClick={() => setActive("")}></div>
      )}
      <div
        className="main-left-menu"
        style={{
          width: active === "sidemenu" ? "250px" : "0px",
          left: active === "sidemenu" ? "0px" : "-250px",
        }}
      >
        <div className="wrapper">
          <div className="header-main">
            <span onClick={() => setActive("")}>
              <AiOutlineClose size={18} />
            </span>
          </div>
          <div className="navigation-links">
            <Link to={"/"}>
              <div
                className={
                  pathname === "/"
                    ? "navigation-link active"
                    : "navigation-link"
                }
              >
                Home
              </div>
            </Link>
            <div
              className="navigation-link sub-link"
              onClick={() =>
                setSubMenuActive((prev) =>
                  prev === "carsMenu" ? "" : "carsMenu"
                )
              }
            >
              <span>Cars</span> <FiChevronDown />
            </div>
            {subMenuActive === "carsMenu" && (
              <div className="navigation-sub-menu">
                <Link to={"/cars"} className="sub-menu-links">
                  Cars Home
                </Link>
                {carMenuDataLeft.map((data, i) => (
                  <Link key={i} className="sub-menu-links" to={data.link}>
                    {data.text}
                  </Link>
                ))}
                {carMenuDataRight.map((data, i) => (
                  <Link key={i} className="sub-menu-links" to={data.link}>
                    {data.text}
                  </Link>
                ))}
              </div>
            )}
            <div
              className="navigation-link sub-link"
              onClick={() =>
                setSubMenuActive((prev) =>
                  prev === "workshopMenu" ? "" : "workshopMenu"
                )
              }
            >
              <span>Workshops</span> <FiChevronDown />
            </div>
            {subMenuActive === "workshopMenu" && (
              <div className="navigation-sub-menu">
                <Link to={"/workshop"} className="sub-menu-links">
                  Workshop Home
                </Link>
                {maintenance.map((data, i) => (
                  <Link
                    key={i}
                    className="sub-menu-links"
                    to={`/merchant-search/workshop/${data.maintenance_id}`}
                  >
                    {data.name}
                  </Link>
                ))}
              </div>
            )}
            <div
              className="navigation-link sub-link"
              onClick={() =>
                setSubMenuActive((prev) =>
                  prev === "partsMenu" ? "" : "partsMenu"
                )
              }
            >
              <span>Parts</span> <FiChevronDown />
            </div>
            {subMenuActive === "partsMenu" && (
              <div className="navigation-sub-menu">
                <Link to={"/parts"} className="sub-menu-links">
                  Parts Home
                </Link>
                {parts.map((data, i) => (
                  <Link
                    key={i}
                    className="sub-menu-links"
                    to={`/merchant-search/parts/${data.parts_type_id}`}
                  >
                    {data.name}
                  </Link>
                ))}
              </div>
            )}
           {auth.userType !== 1 && ( <Link to={"/search"}>
              <div className="navigation-link">Live Search</div>
            </Link>)}
            {!auth.user && (
              <>
                <Link to={"/login"}>
                  <div className="navigation-link">Login</div>
                </Link>
                {/* <Link to={"/usersignup"}>
                  <div className="navigation-link">Sign up</div>
                </Link> */}
                <Link to={"/dashboard"}>
                  <div className="navigation-link">Register</div>
                </Link>
              </>
            )}
            {auth.user && (
              <>
              <div
                className="navigation-link sub-link"
                onClick={() =>
                  setSubMenuActive((prev) =>
                    prev === "profileMenu" ? "" : "profileMenu"
                  )
                }
              >
                <span>{auth.user.name}</span> <FiChevronDown />
              </div>
              {subMenuActive === "profileMenu" && (
              <div className="navigation-sub-menu">
                  <Link
                    className="sub-menu-links"
                    to={auth.userType === 2 ? "/profile" : `/dashboard/business-profile/${auth.user.url}`}
                  >
                    Profile
                  </Link>
                  <div className="sub-menu-links" onClick={()=>handleLogout(setAuth)}>
                    Logout
                  </div>
              </div>
            )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ResponsiveMenu;
