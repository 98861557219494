import React, { useState, useRef } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import axios from "axios";
import {
  getProductImagesRoute,
  imagesRoute,
  insertProductImages,
  productImageDeleteRoute,
} from "../../utils/ApiRoutes";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useEffect } from "react";
import ProgressCircle from "../ProgressCIrcle/ProgressCircle";
const AddCarImages = ({ setActive }) => {
  const inputRef = useRef();
  const prodCode = localStorage.getItem("prodCode");
  const [files, setFiles] = useState(null);
  const [displayImages, setDisplayImages] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const handleImagesClick = () => {
    inputRef.current.click();
  };

  const handleChange = (e) => {
    const arr = Array.from(e.target.files);
    const processedImages = arr.map((file) => URL.createObjectURL(file));
    setFiles(e.target.files);
    setDisplayImages((prev) => [...prev, ...processedImages]);
  };

  const handleSubmit = async () => {
    setIsloading(true);
    for (let index = 0; index < files.length; index++) {
      const formData = new FormData();
      formData.append("code", prodCode);
      formData.append("files", files[index]);
      try {
        const response = await axios.post(insertProductImages, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.data.error === false) {
          setActive("carmain");
        }
      } catch (e) {
        console.log(e);
      }
    }
    setIsloading(false);
  };

  const handleDeleteClick1 = (index) => {
    setDisplayImages(displayImages.filter((_, i) => i !== index));
    setFiles(files.filter((_, i) => i !== index));
  };

  const handleDeleteClick2 = async (index, name) => {
    setDisplayImages(displayImages.filter((_, i) => i !== index));
    const response = await axios.post(productImageDeleteRoute, {
      code: prodCode,
      Image: name,
    });
    console.log(response.data);
  };

  useEffect(() => {
    getimages();
  }, []);

  const getimages = async () => {
    setIsloading(true);
    const response = await axios.get(`${getProductImagesRoute}/${prodCode}`);

    if (response.data.product[0].images !== null) {
      setDisplayImages(JSON.parse(response.data.product[0].images));
    }
    setIsloading(false);
  };

  return (
    <div className="add-cars-main">
      <div className="add-cars-section">
        <h1>Images</h1>
        <div onClick={() => setActive("carmain")}>
          <ArrowBackIcon />
        </div>
      </div>
      {!isloading && (
        <>
          <div className="image-upload">
            <div className="input-area" onClick={handleImagesClick}>
              Click Here to upload images
              <UploadFileIcon />
            </div>

            {displayImages !== null && (
              <div className="images-display">
                {displayImages.map((image, index) => (
                  <div className="image-display" key={index}>
                    {image.toLowerCase().includes("blob") ? (
                      <>
                        <img src={image} alt="" />
                        <div
                          className="delete-btn"
                          onClick={() => handleDeleteClick1(index)}
                        >
                          <DeleteOutlineIcon />
                        </div>
                      </>
                    ) : (
                      <>
                        <img src={`${imagesRoute}/${image}`} alt="" />
                        <div
                          className="delete-btn"
                          onClick={() => handleDeleteClick2(index, image)}
                        >
                          <DeleteOutlineIcon />
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            )}

            <div className="btn-section">
              <button onClick={handleSubmit}>Submit</button>
            </div>
          </div>
        </>
      )}
      {isloading && (
        <div className="image-upload">
          <ProgressCircle height={"calc(100vh - 250px)"} />
        </div>
      )}
      <input
        type="file"
        style={{ display: "none" }}
        ref={inputRef}
        onChange={(e) => handleChange(e)}
        multiple
        accept="images/*"
      />
    </div>
  );
};

export default AddCarImages;
