import React, { useCallback, useEffect, useState } from "react";

import "./CarBrandVendor.scss";

import {
  GetBrandRoute,
  UpdateBrandRoute,
  brandLogoRoute,
} from "../../utils/ApiRoutes";

import axios from "axios";
import { useNavigate } from "react-router-dom";

import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import { toast, ToastContainer } from "react-toastify";
import { toastOptions } from "../../utils/ToastOptions";
import { useAuth } from "../../context/auth";
import { Helmet } from "react-helmet";
const CarBrandsVendor = () => {
  const [brands, setBrands] = useState([]);
  const [isChecked, setIsChecked] = useState({});
  const selectedService = localStorage.getItem("serviceId");
  const [brandIds, setBrandIds] = useState("");
  const [auth] = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const navigate = useNavigate();
  const controller = new AbortController();

  useEffect(() => {
    getBrands();
    return () => controller.abort();

    // eslint-disable-next-line
  }, []);

  const getBrands = useCallback(async () => {
    setIsLoading(true);
    const response = await axios.get(GetBrandRoute, {
      signal: controller.signal,
    });
    try {
      const { brand } = response.data;
      setBrands(brand);

      const initialState = {};
      brand.forEach((id) => {
        initialState[id.brand_id] = false;
      });
      setIsChecked(initialState);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  }, [controller.signal]);

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setIsChecked({ ...isChecked, [id]: checked });
    checked
      ? setBrandIds(brandIds.concat(`,${id}`))
      : setBrandIds(brandIds.replace(`,${id}`, ""));
  };

  const handleNextClick = async (e) => {
    e.preventDefault();
    e.target.setAttribute("disabled", true);
    setIsLoading(true);
    if (brandIds !== "") {
      const response = await axios.post(UpdateBrandRoute, {
        code: auth.user?.code,
        brand_ids: brandIds.replace(",", ""),
      });
      const { error } = response.data;

      if (Number(selectedService) === 2) {
        if (error === false) {
          setIsLoading(false);
          navigate("/dashboard/vender-maintenance");
        } else {
          setIsLoading(false);
        }
      } else if (Number(selectedService) === 3) {
        if (error === false) {
          setIsLoading(false);
          navigate("/dashboard/vender-parts");
        } else {
          setIsLoading(false);
        }
      } else if (Number(selectedService) === 5) {
        if (error === false) {
          setIsLoading(false);
          navigate("/dashboard/vender-country");
        } else {
          setIsLoading(false);
        }
      }
    } else {
      toast.error("please select a Brand", toastOptions);
    }

    e.target.removeAttribute("disabled");
  };

  const handleBackClick = (e, path) => {
    e.preventDefault();
    navigate(path);
  };

  const handleSelectAll = (e) => {
    setSelectAll((prev) => !prev);
    const initialState = {};
    const ids =  brands.map((brand) => {
      initialState[brand.brand_id] = selectAll ? false :  true;
      return brand.brand_id;
    });
    setIsChecked(initialState);
    setBrandIds( selectAll ? "" :  `,${ids.join(",")}`)
  };

  return (
    <>
      <Helmet>
        <title>Car Brands | CARS'R'US</title>
      </Helmet>
      <div className="brands-main">
        {!isLoading && (
          <>
            <div className="stepper">
              <Stepper
                activeStep={2}
                alternativeLabel
                sx={{ paddingTop: "2rem" }}
              >
                <Step>
                  <StepLabel></StepLabel>
                </Step>
                <Step>
                  <StepLabel></StepLabel>
                </Step>
                <Step>
                  <StepLabel></StepLabel>
                </Step>
                <Step>
                  <StepLabel></StepLabel>
                </Step>
              </Stepper>
            </div>
            <h1>Car makes you provide services for</h1>
            <div className="Select-all">
              <div>
              <label htmlFor="select_all_brands">
                <input
                  type="checkbox"
                  name="select_all"
                  checked={selectAll}
                  id="select_all_brands"
                  onChange={handleSelectAll}
                />{" "}
                Select All
              </label>
              </div>
            </div>
            <div className="brands-section">
              {brands.map((brand, i) => (
                <div key={i} className="brand">
                  <div className="brand-logo">
                    <img
                      src={`${brandLogoRoute}/${brand.logo}`}
                      alt={brand.name}
                    />
                  </div>
                  <input
                    className="brand-checkbox"
                    type="checkbox"
                    id={brand.brand_id}
                    checked={isChecked[brand.brand_id]}
                    onChange={(e) => handleCheckboxChange(e)}
                  />
                </div>
              ))}
            </div>
            <div className="btn-section">
              <button
                onClick={(e) => handleBackClick(e, "/dashboard/vender-service")}
              >
                Back
              </button>
              <button onClick={(e) => handleNextClick(e)}>Next</button>
            </div>
          </>
        )}
        {isLoading && <div className="vendor-loader"></div>}
        <ToastContainer />
      </div>
    </>
  );
};

export default CarBrandsVendor;
