import React, { useEffect, useState } from "react";
import MerchantNavbar from "../../components/MerchantNavbar/MerchantNavbar";
import Footer from "../../components/Footer/Footer";
import MerchantHomeMainArea from "../../components/MerchantHomeMainArea/MerchantHomeMainArea";
import axios from "axios";
import {
  getVendorProfileDetails,
  getVendorServices,
} from "../../utils/ApiRoutes";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
const MerchantHomePage = () => {
  const url = Object.values(useParams())[0];

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageVendors, setCurrentPageReviews] = useState([]);

  const [vendorDetails, setVendorDetails] = useState([]);
  const [services, setServices] = useState([]);
  const [timings, setTimings] = useState([]);
  const [images, setImages] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [reviewChange, setReviewChange] = useState(0);
  const [loading, setLoading] = useState(false);
  const [ratingDetails, setRatingDetails] = useState({
    Total_Reviews: 0,
    five_Percentage: 0,
    four_Percentage: 0,
    three_Percentage: 0,
    two_Percentage: 0,
    one_Percentage: 0,
  });
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [reviewChange]);

  const getData = async () => {
    setLoading(true);
    const response = await axios.get(`${getVendorProfileDetails}/${url}`);
    const {
      Total_Reviews,
      five_Percentage,
      four_Percentage,
      three_Percentage,
      two_Percentage,
      one_Percentage,
    } = response?.data;
    setRatingDetails({
      Total_Reviews,
      five_Percentage,
      four_Percentage,
      three_Percentage,
      two_Percentage,
      one_Percentage,
    });
    setVendorDetails(response.data.vendor[0]);
    setReviews(response.data.review);
    const itemsPerPage = 3; // Number of items to display per page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentPageItems = [
      ...response.data.review?.slice(startIndex, endIndex),
    ];
    setCurrentPageReviews(currentPageItems);
    setImages(JSON.parse(response.data.vendor[0].images));
    setTimings(JSON.parse(response.data.vendor[0].timings));
    const { data } = await axios.get(`${getVendorServices}/${url}`);
    setServices(data);
    setLoading(false);
  };

  useEffect(() => {
    const itemsPerPage = 3; // Number of items to display per page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentPageItems = [...reviews?.slice(startIndex, endIndex)];
    setCurrentPageReviews(currentPageItems);
    // eslint-disable-next-line
  }, [currentPage]);

  return (
    <>
      <Helmet>
        <title>Merchant Landing | CARS'R'US</title>
      </Helmet>
      <div>
        <MerchantNavbar color={"#3c5298"} textColor={"white"}/>
        <MerchantHomeMainArea
          loading={loading}
          vendorDetails={vendorDetails}
          setReviewChange={setReviewChange}
          reviews={reviews}
          timings={timings}
          images={images}
          services={services}
          ratingDetails={ratingDetails}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          currentPageVendors={currentPageVendors}
        />
        <Footer />
      </div>
    </>
  );
};

export default MerchantHomePage;
