import React from "react";
import DashboardLayout from "../../Layouts/DashboardLayout";
import BusinessProfileMainArea from "../../components/BusinessProfileMainArea/BusinessProfileMainArea";
import { Helmet } from "react-helmet";
const BusinessProfileMain = () => {
  return (
    <>
      <Helmet>
        <title>Vendor Profile | CARS'R'US</title>
      </Helmet>
      <DashboardLayout>
        <BusinessProfileMainArea />
      </DashboardLayout>
    </>
  );
};

export default BusinessProfileMain;
