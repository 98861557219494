import React from "react";

import "./BusinessPromotion.scss";

import location from "../../assets/icons/location.svg";
import message from "../../assets/icons/message.svg";
import promotion from "../../assets/icons/promotion.svg";
import recommended from "../../assets/icons/recommended.svg";
import store from "../../assets/icons/store.svg";
import click from "../../assets/icons/click.svg";
import viber from "../../assets/icons/viber.svg";
import { useNavigate } from "react-router-dom";
import {ToastContainer} from "react-toastify"

const BusinessPromotion = () => {
    const navigate = useNavigate();
    const handlePromotionClick = (element) => {
        navigate(element)
    }

  return (
    <div className="business-promotion">
      <div className="top-header">
        Promotion from our side to get them to pay more for advertisments
      </div>
      <h2>Goals</h2>
      <div className="card-section">
      <div className="promotion-card" onClick={()=>handlePromotionClick("recommended")}>
        <img src={recommended} alt="" />
        <div className="promotion-details">
          <h4>Recommended </h4>
          <p>
            What are our recommendation.... we should mention what we will
            achieve without you
          </p>
        </div>
      </div>
      <div className="promotion-card" onClick={()=>handlePromotionClick("recommended")}>
        <img src={location} alt="" />
        <div className="promotion-details">
          <h4>Promote my store location</h4>
          <p>
            Should be simple enough to explain that more calls mean more sales
          </p>
        </div>
      </div>
      <div className="promotion-card" onClick={()=>handlePromotionClick("recommended")}>
        <img src={promotion} alt="" />
        <div className="promotion-details">
          <h4>Promote Product or Job</h4>
          <p>
            Should be simple enough to explain that more calls mean more sales
          </p>
        </div>
      </div>
      <div className="promotion-card" onClick={()=>handlePromotionClick("recommended")}>
        <img src={store} alt="" />
        <div className="promotion-details">
          <h4>In Store Promotion</h4>
          <p>
            Should be simple enough to explain that more calls mean more sales
          </p>
        </div>
      </div>
      <div className="promotion-card" onClick={()=>handlePromotionClick("recommended")}>
        <img src={click} alt="" />
        <div className="promotion-details">
          <h4>First Picks</h4>
          <p>
            Should be simple enough to explain that more calls mean more sales
          </p>
        </div>
      </div>
      <div className="promotion-card" onClick={()=>handlePromotionClick("recommended")}>
        <img src={viber} alt="" />
        <div className="promotion-details">
          <h4>Get More Calls</h4>
          <p>
            Should be simple enough to explain that more calls mean more sales
          </p>
        </div>
      </div>
      <div className="promotion-card" onClick={()=>handlePromotionClick("recommended")}>
        <img src={message} alt="" />
        <div className="promotion-details">
          <h4>Get More Messages</h4>
          <p>
            Should be simple enough to explain that more calls mean more sales
          </p>
        </div>
      </div>
      </div>
      <ToastContainer/>
    </div>
  );
};

export default BusinessPromotion;
