import { Box, CircularProgress } from "@mui/material";
import React from "react";

const ProgressCircle = ({height}) => {
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      height={height}
    >
      <CircularProgress size={"3.5rem"} color="error" />
    </Box>
  );
};

export default ProgressCircle;
