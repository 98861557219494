import "./BlogPost.scss";

// import Blog from "../../assets/images/Blog.png";
import { blogImageRoute } from "../../utils/ApiRoutes";
import { useNavigate } from "react-router-dom";

const BlogPost = ({ featured  , blog}) => {
  const navigate = useNavigate()

  const handleReadClick = (url)=> {
   navigate(`/blog/${url}`)
  }


  return (
    <>
      {featured === "true" && (
        <div className="featured-blog" >
          <div className="left-section">
          <img src={`${blogImageRoute}/${blog?.image}`} alt="blog" />
          </div>
          <div className="right-section">
            {"".slice(0,30)}
          <div className="blog-header">{blog?.name.length > 30 ? `${blog?.name.slice(0,30)}...`:blog?.name }</div>
            <div className="blog-desc">
              {blog?.description ? atob(blog?.description).length> 350 ? `${atob(blog?.description).slice(0,350)}...`: atob(blog?.description)  :"" }
            </div>
            <div className="blog-btn" onClick={()=>handleReadClick(blog?.url)}>Read More</div>
          </div>
        </div>
      )}
      {!(featured === "true") && (
        <div className="blog" >
          <div className="top-section">
            <img src={`${blogImageRoute}/${blog?.image}`} alt="blog" />
          </div>
          <div className="bottom-section">
            <div className="blog-header">{blog?.name}</div>
            <div className="blog-desc">
              {blog?.description ?atob(blog?.description) :"" }
            </div>
            <div className="blog-btn" onClick={()=>handleReadClick(blog?.url)}>Read More</div>
          </div>
        </div>
      )}
    </>
  );
};

export default BlogPost;
