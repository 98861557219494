import React from "react";
import ColorNavbar from "../../components/ColorNavbar/ColorNavbar";
import Footer from "../../components/Footer/Footer";
import InspectionFormMainArea from "../../components/InspectionFormMainArea/InspectionFormMainArea";
import { Helmet } from "react-helmet";

const InspectionFormPage = () => {
  return (
    <>
      <Helmet>
        <title>Car Inspection | CARS'R'US</title>
      </Helmet>
      <ColorNavbar color={"#232626"} textColor={"white"} />
      <InspectionFormMainArea />
      <Footer />
    </>
  );
};

export default InspectionFormPage;
