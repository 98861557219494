import React from "react";
import ColorNavbarWithCategory from "../../components/ColorNavbarWithCategory/ColorNavbarWithCategory";
import Footer from "../../components/Footer/Footer";
import BlogDetailSection from "../../components/BlogDetailSection/BlogDetailSection";
import { Helmet } from "react-helmet";
const BlogDetailPage = () => {
  return (
    <>
      <Helmet>
        <title>Blog Detail | CARS'R'US</title>
      </Helmet>
      <div>
        <ColorNavbarWithCategory color={"#ED5565"} textColor={"white"} />
        <BlogDetailSection />
        <Footer />
      </div>
    </>
  );
};

export default BlogDetailPage;
