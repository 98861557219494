import React from 'react'
import ProfileLayout from '../../Layouts/ProfileLayout'
import MySearchSection from '../../components/MySearchSection/MySearchSection'

const MySearch = () => {
  return (
    <ProfileLayout>
        <MySearchSection/>
    </ProfileLayout>
  )
}

export default MySearch