import React from 'react'

import "./SpecificationComponent.scss"

const SpecificationComponent = ({text}) => {
  return (
    <div className='specification-card'>{text}</div>
  )
}

export default SpecificationComponent