import React from 'react'
import PromotionSelectionComponent from '../../components/PromotionSelectionComponent/PromotionSelectionComponent'
import DashboardLayout from "../../Layouts/DashboardLayout"

const PromotionTopOfSearches = () => {
  return (
    <DashboardLayout>
        <PromotionSelectionComponent/>
    </DashboardLayout>
  )
}

export default PromotionTopOfSearches