import React from "react";

import ColorNavbarWithCategory from "../../components/ColorNavbarWithCategory/ColorNavbarWithCategory";
import Footer from "../../components/Footer/Footer";
import BlogMainSection from "../../components/BlogMainSection/BlogMainSection";
import { Helmet } from "react-helmet";

const BlogPage = () => {
  return (
    <div>
      <Helmet>
        <title>Blogs | CARS'R'US</title>
      </Helmet>
      <ColorNavbarWithCategory color={"#ED5565"} textColor={"white"} />
      <BlogMainSection />
      <Footer />
    </div>
  );
};

export default BlogPage;
