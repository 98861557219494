import React, { useState, useEffect } from "react";
import Payment from "payment";

import "./PaymentGateway.scss"

import PaymentOptions from "../../assets/images/payment-option.png"
import { updateAdsStatus } from "../../utils/ApiRoutes";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { userAdslistingRoute } from "../../utils/Routes";
import { useAuth } from "../../context/auth";

const PaymentGateway = () => {
  const  [URLSearchParams] = useSearchParams()
  const code =  URLSearchParams.get("code")
  const navigate= useNavigate()
  const [cardNumber, setCardNumber] = useState("");
  const [cardType, setCardType] = useState("");
  const [cardName, setCardName] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [isValid, setIsValid] = useState(false);
  
  const [auth] = useAuth()
  useEffect(() => {
    const isValidCardNumber = Payment.fns.validateCardNumber(cardNumber);
    const isValidCardName = cardName.length > 0;
    const isValidExpiryDate = Payment.fns.validateCardExpiry(expiryDate);
    const isValidCvv = Payment.fns.validateCardCVC(cvv, cardType);

    setIsValid(
      isValidCardNumber && isValidCardName && isValidExpiryDate && isValidCvv
    );
  }, [cardNumber, cardName, expiryDate, cvv, cardType]);

  const handleCardNumberChange = (e) => {
    const inputCardNumber = e.target.value;
    const cardType = Payment.fns.cardType(inputCardNumber);
    setCardNumber(inputCardNumber);
    setCardType(cardType);
  };

  const handleSubmit =async (e) => {
    e.preventDefault();
    // Submit payment method data
    e.preventDefault();
    const response = await axios.post(updateAdsStatus,{
      code:code,
      maker_id:auth.user?.user_id ,
      make_by: 0,
      days: 4,
    }) 
    if(response.data.error===false){
      navigate(userAdslistingRoute)
    }
  };

  const handleNumericInput = (e) => {
    const { value, name } = e.target;
    const numericValue = value.replace(/[^0-9]/g, "");
    switch (name) {
      case "cardNumber":
        setCardNumber(numericValue);
        break;
      case "cvv":
        setCvv(numericValue.slice(0, 3));
        break;
      case "expiryDate":
        setExpiryDate(numericValue.slice(0, 4));
        break;
      default:
        break;
    }
  };

  return (
    <div className="payment-gateway">
      <div>
      <form onSubmit={handleSubmit}>
        <div className="heading">
            <h4>Confirm Payment</h4>
            <img src={PaymentOptions} alt="Payment Options" />
        </div>
        <input
          type="text"
          name="cardNumber"
          value={cardNumber}
          onChange={handleCardNumberChange}
          onBlur={handleNumericInput}
          placeholder="Enter your card number"
        />

        <input
          type="text"
          name="cardName"
          value={cardName}
          onChange={(e) => setCardName(e.target.value)}
          placeholder="Enter name on card"
        />

        <div className="section">
        <input
          type="text"
          name="expiryDate"
          value={expiryDate}
          onChange={(e) => setExpiryDate(e.target.value)}
          onBlur={handleNumericInput}
          placeholder="MM/YY"
          maxLength="4"
        />

        <input
          type="text"
          name="cvv"
          value={cvv}
          onChange={(e) => setCvv(e.target.value)}
          onBlur={handleNumericInput}
          placeholder="Enter CVV"
          maxLength="3"
        />
        </div>

        <button type="submit" 
        // disabled={!isValid}
        >
          Pay Now
        </button>
      </form>
      <div className="red-background"></div>
      </div>
    </div>
  );
};

export default PaymentGateway;
