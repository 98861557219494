import React from "react";
import ProfileLayout from "../../Layouts/ProfileLayout";
import UserDashboardChatMain from "../../components/UserDashboardChatMain/UserDashboardChatMain";
import { Helmet } from "react-helmet";
const UserDashboardChat = () => {
  return (
    <>
      <Helmet>
        <title>User Chat | CARS'R'US</title>
      </Helmet>
      <ProfileLayout>
        <UserDashboardChatMain />
      </ProfileLayout>
    </>
  );
};

export default UserDashboardChat;
