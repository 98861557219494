import "./AddServiceMainArea.scss";
import React, { useState, useEffect, useMemo, useContext } from "react";
import { UpdateBrandRoute, brandLogoRoute } from "../../utils/ApiRoutes";
import ProgressCircle from "../ProgressCIrcle/ProgressCircle";
import { dataContext } from "../../App";
import axios from "axios";
import { useAuth } from "../../context/auth";

const UpdateBrand = ({ currentData, setActive }) => {
  const controller = useMemo(() => new AbortController(), []);
  const [isLoading, setIsLoading] = useState(true);
  const [isChecked, setIsChecked] = useState({});
  const [brandIds, setBrandIds] = useState("");
  const { brands } = useContext(dataContext);
  const [auth] = useAuth();
  useEffect(() => {
    const getData = async () => {
      try {
        let initial = {};

        brands.forEach((brand) => {
          initial[brand.brand_id] = false;
        });

        let ids = currentData.flat(1).map((brand) => {
          const temp = brands.filter(
            (data) => data.name.toLowerCase() === brand.name.toLowerCase()
          );
          initial[temp[0].brand_id] = true;
          return temp[0].brand_id;
        });
        setBrandIds(`,${ids.join(",")}`);
        setIsChecked(initial);
      } catch (error) {
        console.error(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    getData();
    return () => controller.abort();
  }, [brands, controller, currentData]);

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setIsChecked({ ...isChecked, [id]: checked });
    checked
      ? setBrandIds(brandIds.concat(`,${id}`))
      : setBrandIds(brandIds.replace(`,${id}`, ""));
  };

  const handleUpdateClick = async (e) => {
    e.preventDefault();
    e.target.setAttribute("disabled", true);
    setIsLoading(true);
    try {
      if (brandIds !== "") {
        const response = await axios.post(UpdateBrandRoute, {
          code: auth.user?.code,
          brand_ids: brandIds.replace(",", ""),
        });
        const { error } = response.data;
        if (error === false) {
          setActive("main");
        }
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      e.target.removeAttribute("disabled");
    }
  };

  return (
    <>
      <div className="add-service-main-aera">
        {!isLoading && (
          <div className="brand-wrapper">
            <div className="edit-brands-section">
              {currentData.length > 0 &&
                brands.map((brand, i) => (
                  <div key={i} className="brand">
                    <div className="brand-logo">
                      <img
                        src={`${brandLogoRoute}/${brand.logo}`}
                        alt={brand.name}
                      />
                    </div>
                    <input
                      className="brand-checkbox"
                      type="checkbox"
                      id={brand.brand_id}
                      checked={isChecked[brand.brand_id]}
                      onChange={(e) => handleCheckboxChange(e)}
                    />
                  </div>
                ))}
            </div>
            <button className="edit-btn" onClick={handleUpdateClick}>
              Update Brands
            </button>
          </div>
        )}
        {isLoading && <ProgressCircle height={"calc(100vh - 150px)"} />}
      </div>
    </>
  );
};

export default UpdateBrand;
