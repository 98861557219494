import React, {useState,useEffect} from "react";

import "./CarBuyingLongWithFilterMainArea.scss";

import FilterWithSlider from "../FilterWithSlider/FilterWithSlider";
import HorizontalProductCard from "../../components/HorizontalProductCard/HorizontalProductCard"

import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import StairsOutlinedIcon from "@mui/icons-material/StairsOutlined";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import WindowIcon from "@mui/icons-material/Window";
import ViewListIcon from "@mui/icons-material/ViewList";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import axios from "axios";
import { bookmarkRoute, filterRoute } from "../../utils/ApiRoutes";
import { useAuth } from "../../context/auth";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/ToastOptions";

const CarBuyingLongWithFilterMainArea = () => {
  const [auth] = useAuth();
  const [products, setProducts] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [bookmarkedProduct, setBookmarkedProduct] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [currentPageProducts, setCurrentPageProducts] = useState([]);
  const [filters, setFilters] = useState({
    brand_id: 0,
    model_id: 0,
    min_year: 0,
    max_year: 0,
    country_id: 0,
    city_id: 0,
    location_id: 0,
    fuel_type: 0,
    body_condition: 0,
    mechanical_condition: 0,
    warranty: 0,
    cylinders: 0,
    transmission_type: 0,
    body_type: 0,
    horsepower: 0,
    steering_side: 0,
    trim: 0,
    min_price: 0,
    max_price: 0,
    color_id: 0,
    insured: 0,
    door_id: 0,
    purpose: 0,
    type: 0,
    count:0,
  });

  useEffect(() => {
    filterFunction();
    // eslint-disable-next-line
  }, [filters]);


  const filterFunction = async () => {
    setisLoading(true);
    const response = await axios.post(filterRoute, filters);
    setProducts(response.data.Products);
    const itemsPerPage = 9; // Number of items to display per page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentPageItems = [
      ...response.data.Products?.slice(startIndex, endIndex),
    ];
    setCurrentPageProducts(currentPageItems);
    setisLoading(false);
  };


  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  

  const handleCompareProductClick = () => {

  }

  const handleBookmarkClick = async (url, product_id) => {
    if (auth.user && auth.userType === 2) {
      const index = bookmarkedProduct.findIndex(
        (data) => data.product_id === product_id
      );
      if (index === -1) {
        setBookmarkedProduct((prev) => [...prev, { product_id }]);
      } else {
        setBookmarkedProduct((prev) =>
          prev.slice(0, index).concat(prev.slice(index + 1))
        );
      }
      await axios.post(bookmarkRoute, {
        user_id: auth.user?.user_id,
        url,
      });
    } else if (auth.user && auth.userType === 1) {
      return;
    } else {
      toast.error("login to bookmark the product", toastOptions);
    }
  };


  return (
    <div className="car-buying-long-filter">
      <FilterWithSlider color={"#686868"} filters={filters}
          setFilters={setFilters} />
      <div className="right-area">
        <div className="Header">
          <h1>Buying and Selling</h1>
          <h2>Tire Repair in Dubai, Deria</h2>
          <p>1248 results • Jul 14 - 21</p>
          <div className="filter-area">
            <div className="left">
              <button>
                <SellOutlinedIcon />
                Price
              </button>
              <button>
                <ApartmentOutlinedIcon />
                Price
              </button>
              <button>
                <StairsOutlinedIcon />
                Price
              </button>
              <button>
                <TuneOutlinedIcon />
                Price
              </button>
            </div>
            <div className="right">
              <WindowIcon />
              <ViewListIcon />
              <p>
                Sort by: <span>Price</span>
              </p>
            </div>
          </div>
          <div className="second-filters">
            <div>
              <span>Make</span> <KeyboardArrowDownIcon />
            </div>
            <div>
              Model <KeyboardArrowDownIcon />
            </div>
            <div>
              Price <KeyboardArrowDownIcon />
            </div>
            <div>
              Year <KeyboardArrowDownIcon />
            </div>
            <div>
              City <KeyboardArrowDownIcon />
            </div>
            <button>Search</button>
          </div>
        </div>
        <div className="products-of-car-buying">
        
        </div>
      </div>
    </div>
  );
};

export default CarBuyingLongWithFilterMainArea;
