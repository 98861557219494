import React from "react";
import DashboardLayout from "../../Layouts/DashboardLayout";
import VendorMyAdsMainArea from "../../components/VendorMyAdsMainArea/VendorMyAdsMainArea";
import { Helmet } from "react-helmet";
const VendorMyAds = () => {
  return (
    <>
      <Helmet>
        <title>Vendor Ads | CARS'R'US</title>
      </Helmet>
      <DashboardLayout>
        <VendorMyAdsMainArea />
      </DashboardLayout>
    </>
  );
};

export default VendorMyAds;
