import React, { useState, useEffect } from "react";

import "./AdSection.scss";
import HorizontalProductCardSmall from "../HorizontalProductCardSmall/HorizontalProductCardSmall";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import axios from "axios";
import { getVendorAds } from "../../utils/ApiRoutes";
import { useNavigate } from "react-router-dom";

import ProgressCircle from "../ProgressCIrcle/ProgressCircle"
import { useAuth } from "../../context/auth";
import moment from "moment";
const AdSection = () => {
  const navigate = useNavigate()
  const [ads, setAds] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [auth ] =useAuth();
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const getData = async () => {
    setIsLoading(true)
    const response = await axios.post(getVendorAds , {
       maker_id:auth.user.user_id,
       make_by:0
    });
    setAds(response?.data?.Ads);
    setIsLoading(false)
  };
  return (
    <>
    {!isLoading && (<div className="ad-section">
      <h2>Your Ads</h2>
      <div className="ads-area">
        <table>
          <tr>
            <th>
              <input type="hidden" name="" id="" />
            </th>
            <th> Product Detail</th>
            <th> Date Posted</th>
            <th> Expiry Date</th>
            <th> Messages</th>
            <th className="btn-heading"> Status</th>
          </tr>
          {ads.map((ad) => (
            <>
              <tr>
                <td>{/* <input type="checkbox" name="" id="" /> */}</td>
                <td>
                  <HorizontalProductCardSmall ad={ad}/>
                </td>
                <td>{moment(ad?.date).format("DD/MM/YYYY")}</td>
                <td>{moment(ad?.expiry).format("DD/MM/YYYY")}</td>
                <td>{ad.message}</td>
                <td className="btn-section">
                  {/* <button className="red-button">Promote</button> */}
                  <button className={ad.status === 0  ? "white-button": "white-button red"}>{ad.status === 0 ? "Active" : "Deactive"}</button>
                  {/* <button className="more-button">
                    <MoreHorizIcon />
                  </button> */}
                </td>
              </tr>
              <div className="btn-section-responsive">
                <button className="red-button">Promote</button>
                <button className="white-button">Active</button>
                <button className="more-button">
                  <MoreHorizIcon />
                </button>
              </div>
            </>
          ))}
        </table>
      </div>
      <div className="add-ad" style={{cursor:"pointer"}} onClick={()=>navigate("/dashboard/vender-individual-signup")}>+ Add Another Car for as low as 5000 AED </div>
    </div>)}
    {isLoading && (
      <ProgressCircle height={"calc(100vh - 125px)"}/>
    )}
    </>
  );
};

export default AdSection;
