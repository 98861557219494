import React, { useState, useEffect, useCallback, useMemo } from "react";
import "./BlogDetailSection.scss";
import axios from "axios";
import {  useNavigate ,useParams} from "react-router-dom";
import { blogImageRoute, getBlogsRoute } from "../../utils/ApiRoutes";
import { handleScrollToTop } from "../../utils/ReuseFunction";
import moment from "moment";

const BlogDetailSection = () => {
  // hooks
  const {url} = useParams()
  const navigate = useNavigate();
  // states
  const [blog, setBlog] = useState({})
  const [popular, setPopular] = useState([])
  const [featured, setFeatured] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  
  const controller = useMemo(() => new AbortController(), [])

  const getData =  useCallback(
    async () => {
      setIsLoading(true);
      try {
        const {data} =  await axios.get(`${getBlogsRoute}/${url}` , {signal : controller.signal})
        setBlog(data.blog[0])
        const response =  await axios.get(getBlogsRoute , {signal: controller.signal})
        setPopular(response.data.populor)
        setFeatured(response.data.featured)
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    },
    [url , controller.signal],
  )


  useEffect(() => {
    getData();
    handleScrollToTop();
    return () => controller.abort()
  }, [url ,getData , controller]);

  const decodedDescription = decodeURIComponent(
    blog?.blog ? atob(blog?.blog) : ""
  );

  const handleReadClick = (url) => {
    navigate(`/blog/${url}`);
  };

  return (
    <>
      {!isLoading && (
        <div className="blog-details">
          <div className="left-section">
            <div className="card">
              <div className="blog-timing">
                <h6>Date : {moment(blog?.date).format("DD/MM/YYYY")} </h6>
                <h6>Time : {moment(blog?.time, "HH:mm:ss").format("h:mm A")} </h6>
              </div>
              <div className="blog-image">
                <img src={`${blogImageRoute}/${blog?.image}`} alt="blog" />
              </div>
              <div className="blog-title">{blog?.name}</div>
              <div
                className="blog-description"
                dangerouslySetInnerHTML={{ __html: decodedDescription }}
              ></div>
            </div>
          </div>
          <div className="right-section">
            {/* <BlogPost featured={true}/> */}
            <div className="featured">
              {featured.length > 0 && <h2>Featured</h2>}
              {featured?.map((feature, i) => (
                <div className="card" key={i}>
                  <div className="blog-image">
                    <img
                      src={`${blogImageRoute}/${feature?.image}`}
                      alt="blog"
                    />
                  </div>
                  <div className="blog-title">{featured?.name}</div>
                  <div className="blog-description">
                    {feature?.description ? atob(feature?.description) : ""}
                  </div>
                  <div className="btn-section">
                    <button onClick={() => handleReadClick(feature?.url)}>
                      Read More
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <div className="popular">
              {popular.length > 0 && (
                <h2 style={{ paddingTop: ".8rem" }}>Popular</h2>
              )}
              {popular?.map((data, i) => (
                <div className="card" key={i}>
                  <div className="blog-image">
                    <img src={`${blogImageRoute}/${data?.image}`} alt="blog" />
                  </div>
                  <div className="blog-title">{data?.name}</div>
                  <div className="blog-description">
                    {data?.description ? atob(data?.description) : ""}
                  </div>
                  <div className="btn-section">
                    <button onClick={() => handleReadClick(data?.url)}>
                      Read More
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {isLoading && (
        <div className="blog-center">
          <div className="main-loader"></div>
        </div>
      )}
    </>
  );
};

export default BlogDetailSection;
