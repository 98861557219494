import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { resendRoute, verifiedRoute } from "../../utils/ApiRoutes";
import axios from "axios";
import { dataContext } from "../../App";
import "../OTP/OTP.scss"
import OtpInput from "react-otp-input";

import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../context/auth";
import { CircularProgress } from "@mui/material";

const toastOptions = {
  position: "bottom-right",
  autoClose: 1000,
  pauseOnHover: true,
  draggable: true,
  theme: "light",
};


const IndividualOTP = () => {

  const [auth, setAuth] = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [confirm, setConfirm] = useState("");

  const navigate = useNavigate();

  const {code, otp, setOtp } = useContext(dataContext);
 

  const handleOTPSubmit = async (e) => {
    setIsLoading(true)
    e.preventDefault();
    if (Number(otp) === Number(confirm)) {
      const { data } = await axios.get(`${verifiedRoute}/${code}`);
      setAuth({
        ...auth,
        user: {
          email: data.user[0].email,
          name: data.user[0].fullname,
          user_id: data.user[0].user_id,
          user_phone: data.user[0].phone,
          loginDate:new Date().getUTCDate(),
          code
        },
        userType: 2,
      });
      localStorage.setItem(
        "auth",
        JSON.stringify({
          user: {
            email: data.user[0].email,
            name: data.user[0].fullname,
            user_id: data.user[0].user_id,
            user_phone: data.user[0].phone,
            loginDate:new Date().getUTCDate(),
            code
          },
          userType: 2,
        })
      );
      navigate("/profile");
    } else {
      toast.error("invalid OTP",toastOptions);
      setConfirm("")
    }
    setIsLoading(false)
  }

  const handleResend = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(`${resendRoute}/${code}`);
      setOtp(response.data.otp);
      toast.success("New OTP is Sent to your Mail", toastOptions)
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <form className="otp-verify">
      <div className="form">
        <h1>OTP</h1>
        <h4>Enter Otp sent to your Email to verify your email.</h4>
        <OtpInput
          value={confirm}
          onChange={setConfirm}
          numInputs={6}
          renderSeparator={<span>-</span>}
          renderInput={(props) => <input {...props} />}
          inputStyle={{fontSize:"18px",border:"1px solid #d1d1d1", height:"px" ,padding:"1rem" ,width:"2.5em",borderRadius:"4.49871px"}}
          inputType="tel"
        />
        <div className="btn-section">
          <button className="btn-blue" onClick={(e) => handleOTPSubmit(e)}> {isLoading ? <CircularProgress size={22} color="inherit" />: "Submit"}</button>
          <button className="btn-darkgray" onClick={(e) => handleResend(e)}>Resend</button>
        </div>
      </div>
    </form>
  );
};

export default IndividualOTP;
