import React, { useState, useEffect } from "react";
import "./CarCompareMainArea.scss";
import axios from "axios";
import { cardRoute, productRoute} from "../../utils/ApiRoutes";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ProgressCircle from "../ProgressCIrcle/ProgressCircle"
import {
  fuelTypes,
  Colors,
  Warrenty,
  Doors,
  Cylinders,
  TransmissionType,
  Horspower,
  SteeringSide,
  Trim,
  RegionalSpec,
} from "../../assets/data";

const CarCompareMainArea = () => {
  const [data, setData] = useState([]);
  const [product1, setProduct1] = useState({});
  const [product2, setProduct2] = useState({});
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    getData();
    // eslint-disable-next-line 
  }, []);
  const Products = [];
  const getData = async () => {
    setIsLoading(true)
    for (let index = 0; index < 2; index++) {
      const temp = localStorage.getItem(`product-${index + 1}`);
      if (temp) {
        const parsedData = JSON.parse(temp);
        const { data } = await axios.get(`${productRoute}/${parsedData.url}`)
        Products.push(data.Product[0]);
        setData((prev) => [...prev, parsedData]);
      }
    }
    const uniqueArray = [...new Set(Products)]
    console.log(data)
    setProduct1(uniqueArray[0]);
    setProduct2(uniqueArray[1]);
    setIsLoading(false)
  };

  return (
    <>
    {!isLoading && (<div className="car-compare-main">
      <div >
        <h1>Car Comaprison</h1>
      </div>
      <div className="car-images">
        <div className="image">
          <img src={`${cardRoute}/${product1?.card}`} alt="Product" />
        </div>
        <div className="image">
          <img src={`${cardRoute}/${product2?.card}`} alt="Product" />
        </div>
        <div className="image1" style={{ border: "4px dashed black" }}>
          <AddIcon />
        </div>
      </div>
      {/* <div className="car-price">
        <div className="price">{product1?.price} AED</div>
        <div className="price">{product2?.price} AED</div>
        <div className="price"></div>
      </div> */}
      <div className="car-details">
        <table>
          <tr>
            <th>Specification</th>
            <th>Description of the Specification</th>
            <th>Car1</th>
            <th>Car2</th>
          </tr>
          <tr>
            <td>Price</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.price === null ||
              product1?.price === undefined ||
              product1?.price === ""
                ? "Fuel Type Not Specified"
                : `${product1?.price} AED`}
            </td>
            <td>
              {product2?.price === null ||
              product2?.price === undefined ||
              product2?.price === ""
                ? "Fuel Type Not Specified"
                : `${product2?.price} AED`}
            </td>
          </tr>
          <tr>
            <td>Fuel Type</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.fuel_type === null ||
              product1?.fuel_type === undefined ||
              product1?.fuel_type === ""
                ? "Fuel Type Not Specified"
                : fuelTypes[Number(product1?.fuel_type)]}
            </td>
            <td>
              {product2?.fuel_type === null ||
              product2?.fuel_type === undefined ||
              product2?.fuel_type === ""
                ? "Fuel Type Not Specified"
                : fuelTypes[Number(product2?.fuel_type)]}
            </td>
          </tr>
          <tr>
            <td>Body Condition</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.body_condition === null ||
              product1?.body_condition === undefined ||
              product1?.body_condition === ""||
              product1?.body_condition === 0
                ? "Body Condition Not Specified"
                : `${product1?.body_condition} / 10 `}
            </td>
            <td>
              {product2?.body_condition === null ||
              product2?.body_condition === undefined ||
              product2?.body_condition === ""||
              product2?.body_condition === 0
                ? "Body Condition Not Specified"
                : `${product2?.body_condition} / 10`}
            </td>
          </tr>
          <tr>
            <td>Mechanical Condition</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.mechanical_condition === null ||
              product1?.mechanical_condition === undefined ||
              product1?.mechanical_condition === ""||
              product1?.mechanical_condition === 0
                ? "Mechanical Condition Not Specified"
                : `${product1?.mechanical_condition} / 10 `}
            </td>
            <td>
              {product2?.mechanical_condition === null ||
              product2?.mechanical_condition === undefined ||
              product2?.mechanical_condition === ""||
              product2?.mechanical_condition === 0
                ? "Mechanical Condition Not Specified"
                : `${product2?.mechanical_condition} / 10 `}
            </td>
          </tr>
          <tr>
            <td>Color</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.color_id === null ||
              product1?.color_id === undefined ||
              product1?.color_id === ""
                ? "Color Not Specified"
                : Colors[Number(product1?.color_id)]}
            </td>
            <td>
              {product2?.color_id === null ||
              product2?.color_id === undefined ||
              product2?.color_id === ""
                ? "Color Not Specified"
                : Colors[Number(product2?.color_id)]}
            </td>
          </tr>
          <tr>
            <td>Warranty</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.warranty === null ||
              product1?.warranty === undefined ||
              product1?.warranty === "" ||
              product1?.warranty === 0
                ? "Warranty Not Specified"
                : Warrenty[Number(product1?.warranty)]}
            </td>
            <td>
              {product2?.warranty === null ||
              product2?.warranty === undefined ||
              product2?.warranty === "" ||
              product2?.warranty === 0
                ? "Warranty Not Specified"
                : Warrenty[Number(product2?.warranty)]}
            </td>
          </tr>
          <tr>
            <td>Doors</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.door_id === null ||
              product1?.door_id === undefined ||
              product1?.door_id === ""
                ? "Doors Not Specified"
                : Doors[Number(product1?.door_id)]}
            </td>
            <td>
              {product2?.door_id === null ||
              product2?.door_id === undefined ||
              product2?.door_id === ""
                ? "Doors Not Specified"
                : Doors[Number(product2?.door_id)]}
            </td>
          </tr>
          <tr>
            <td>Cylinders</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.cylinders === null ||
              product1?.cylinders === undefined ||
              product1?.cylinders === "" ||
              product1?.cylinders === 0  
                ? "Cylinders Not Specified"
                : `${Cylinders[Number(product1?.cylinders)]} Cylinders`}
            </td>
            <td>
              {product2?.cylinders === null ||
              product2?.cylinders === undefined ||
              product2?.cylinders === "" ||
              product2?.cylinders === 0  
                ? "Cylinders Not Specified"
                : `${Cylinders[Number(product2?.cylinders)]} Cylinders`}
            </td>
          </tr>
          <tr>
            <td>Transmission Type</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.transmission_type === null ||
              product1?.transmission_type === undefined ||
              product1?.transmission_type === ""||
              product1?.transmission_type === 0
                ? "Transmission Type Not Specified"
                : TransmissionType[Number(product1?.transmission_type)]}
            </td>
            <td>
              {product2?.transmission_type === null ||
              product2?.transmission_type === undefined ||
              product2?.transmission_type === ""||
              product2?.transmission_type === 0
                ? "Transmission Type Not Specified"
                : TransmissionType[Number(product2?.transmission_type)]}
            </td>
          </tr>
          <tr>
            <td>Horsepower</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.horsepower === null ||
              product1?.horsepower === undefined ||
              product1?.horsepower === ""||
              product1?.horsepower === 0
                ? "Horsepower Not Specified"
                : Horspower[Number(product1?.horsepower)]}
            </td>
            <td>
              {product2?.horsepower === null ||
              product2?.horsepower === undefined ||
              product2?.horsepower === ""||
              product2?.horsepower === 0
                ? "Horsepower Not Specified"
                : Horspower[Number(product2?.horsepower)]}
            </td>
          </tr>
          <tr>
            <td>Steering Side</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.steering_side === null ||
              product1?.steering_side === undefined ||
              product1?.steering_side === ""||
              product1?.steering_side === 0
                ? "Steering Side Not Specified"
                : SteeringSide[Number(product1?.steering_side)]}
            </td>
            <td>
              {product2?.steering_side === null ||
              product2?.steering_side === undefined ||
              product2?.steering_side === ""||
              product2?.steering_side === 0
                ? "Steering Side Not Specified"
                : SteeringSide[Number(product2?.steering_side)]}
            </td>
          </tr>
          <tr>
            <td>Trim</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.trim === null ||
              product1?.trim === undefined ||
              product1?.trim === ""||
              product1?.trim === 0
                ? "Trim Not Specified"
                : Trim[Number(product1?.trim)]}
            </td>
            <td>
              {product2?.trim === null ||
              product2?.trim === undefined ||
              product2?.trim === ""||
              product2?.trim === 0
                ? "Trim Not Specified"
                : Trim[Number(product2?.trim)]}
            </td>
          </tr>
          <tr>
            <td>Regional Spec</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.regional_spec === null ||
              product1?.regional_spec === undefined ||
              product1?.regional_spec === ""||
              product1?.regional_spec === 0
                ? "Regional Spec Not Specified"
                : RegionalSpec[Number(product1?.regional_spec)]}
            </td>
            <td>
              {product2?.regional_spec === null ||
              product2?.regional_spec === undefined ||
              product2?.regional_spec === ""||
              product2?.regional_spec === 0
                ? "Regional Spec Not Specified"
                : RegionalSpec[Number(product2?.regional_spec)]}
            </td>
          </tr>
          <tr>
            <td>Model Year</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.model_year === null ||
              product1?.model_year === undefined ||
              product1?.model_year === ""
                ? "Model Year Not Specified"
                : product1?.model_year}
            </td>
            <td>
              {product2?.model_year === null ||
              product2?.model_year === undefined ||
              product2?.model_year === ""
                ? "Model Year Not Specified"
                : product2?.model_year}
            </td>
          </tr>
          <tr>
            <td>Kilometers</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.milage === null ||
              product1?.milage === undefined ||
              product1?.milage === ""
                ? "Kilometers Not Specified"
                : product1?.milage}
            </td>
            <td>
              {product2?.milage === null ||
              product2?.milage === undefined ||
              product2?.milage === ""
                ? "Kilometers Not Specified"
                : product2?.milage}
            </td>
          </tr>
          <tr>
            <td>Insured</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.insured === null ||
              product1?.insured === undefined ||
              product1?.insured === ""
                ? "Insured Not Specified"
                : product1?.insured === 1
                ? "Yes"
                : "No"}
            </td>
            <td>
              {product2?.insured === null ||
              product2?.insured === undefined ||
              product2?.insured === ""
                ? "Insured Not Specified"
                : product2?.insured === 1
                ? "Yes"
                : "No"}
            </td>
          </tr>
        </table>
        <table>
          <tr>
            <th>Name of the Feature</th>
            <th>Description of the Feature</th>
            <th>Car1</th>
            <th>Car2</th>
          </tr>
          <tr>
            <td>Abs</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.abs === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
            <td>
              {product2?.abs === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>Cassette Player</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.cassette_player === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
            <td>
              {product2?.cassette_player === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>Front Camera</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.front_camera === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
            <td>
              {product2?.front_camera === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>Power Mirrors</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.power_mirrors === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
            <td>
              {product2?.power_mirrors === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>Rear Camera</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.rear_camera === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
            <td>
              {product2?.rear_camera === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>Air Bags</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.air_bags === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
            <td>
              {product2?.air_bags === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>Cool box</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.cool_box === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
            <td>
              {product2?.cool_box === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>Heated Seats</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.heated_seats === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
            <td>
              {product2?.heated_seats === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>Power Steering</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.power_steering === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
            <td>
              {product2?.power_steering === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>Sun Roof</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.sun_roof === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
            <td>
              {product2?.sun_roof === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>OCD Player</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.ocd_player === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
            <td>
              {product2?.ocd_player === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>Front Speakers</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.front_speakers === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
            <td>
              {product2?.front_speakers === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>Power Locks</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.power_locks === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
            <td>
              {product2?.power_locks === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>Rear Speakers</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.rear_speakers === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
            <td>
              {product2?.rear_speakers === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>Alloy Rims</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.alloy_rims === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
            <td>
              {product2?.alloy_rims === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>Climate Control</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.climate_control === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
            <td>
              {product2?.climate_control === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>Keyless Entry</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.keyless_entry === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
            <td>
              {product2?.keyless_entry === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>Rear Seat Entertainment</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.rear_seat_entertainment === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
            <td>
              {product2?.rear_seat_entertainment === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>USB and Auxillary Cable</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.usb_and_auxillary_cable === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
            <td>
              {product2?.usb_and_auxillary_cable === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>AM FM Radio</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.AM_FM_Radio === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
            <td>
              {product2?.AM_FM_Radio === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>DVD Player</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.dvd_player === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
            <td>
              {product2?.dvd_player === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>Navigation System</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.navigation_system === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
            <td>
              {product2?.navigation_system === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>Rear AC Vents</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.rear_ac_vents === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
            <td>
              {product2?.rear_ac_vents === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>Air Conditioning</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.air_conditioning === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
            <td>
              {product2?.air_conditioning === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>Cruise Control</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.cruise_control === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
            <td>
              {product2?.cruise_control === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>Immobilizer Key</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.immobilizer_key === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
            <td>
              {product2?.immobilizer_key === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>Power Windows</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.power_windows === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
            <td>
              {product2?.power_windows === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>Steering Switches</td>
            <td>Description of the Feature</td>
            <td>
              {product1?.steering_switches === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
            <td>
              {product2?.steering_switches === 0 ? (
                <div className="close-icon">
                  <span>
                    <CloseIcon />
                  </span>
                  <span>Don't have This Feature</span>
                </div>
              ) : (
                <div className="check-icon">
                  <span>
                    <CheckIcon />
                  </span>
                  <span>Have This Feature</span>
                </div>
              )}
            </td>
          </tr>
        </table>
      </div>
    </div>)}
    {isLoading && (
      <div className="car-compare-main">
      <ProgressCircle height={"80vh"} />
      </div>
    )}
    </>
  );
};

export default CarCompareMainArea;
