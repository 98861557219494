import { useState, useContext, useCallback } from "react";

import "../UserSignup/UserSignup.scss";
import "react-toastify/dist/ReactToastify.css";
import "../VendorImportExportCountry/country-flag.css";

import { dataContext } from "../../App";
import { registerRoute } from "../../utils/ApiRoutes";

import leftBg from "../../assets/images/Left.png";
import GoogleIcon from "../../assets/icons/google-icon.png";

import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

import { toastOptions } from "../../utils/ToastOptions";
import "../VendorImportExportCountry/country-flag.css";

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Dropdown } from "primereact/dropdown";

const IndividualSignupFromPage = () => {
  const [formData, setFormData] = useState({
    fullname: "",
    country_id: "",
    email: "",
    age: "",
    password: "",
    cPassword: "",
    termsCheck: false,
  });

  const navigate = useNavigate();
  const { setOtp, setCode, countries } = useContext(dataContext);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [cPasswordShow, setCPasswordShow] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { fullname, email, age, password, cPassword } = formData;

  const handleChange = useCallback(async (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [id]: value }));
  }, []);

  const formValidate = useCallback(() => {
    if (formData.fullname === "" || formData.fullname.length < 3) {
      toast.error(
        "fullname is required & must be greater than 3 character",
        toastOptions
      );
      return false;
    }
    if (formData.email === "") {
      toast.error("email is required ", toastOptions);
      return false;
    }
    if (formData.password !== formData.cPassword) {
      toast.error("password and confirm password must be same", toastOptions);
      return false;
    }
    if (parseInt(formData.age) < 18 || parseInt(formData.age) > 120) {
      toast.error("invalid age", toastOptions);
      return false;
    }
    if (
      !/[a-z]/.test(formData.password) ||
      !/[A-Z]/.test(formData.password) ||
      !/\d/.test(formData.password) ||
      !/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(formData.password) ||
      formData.password.length < 8
    ) {
      toast.error(
        "Password must contain 8 character with uppercase ,lowercase ,number and special Character.",
        toastOptions
      );
      return false;
    }
    if (!formData.termsCheck) {
      toast.error("Please Agree our terms and conditions", toastOptions);
      return false;
    }
    return true;
  }, [
    formData.fullname,
    formData.email,
    formData.age,
    formData.password,
    formData.cPassword,
    formData.termsCheck,
  ]);

  const handleFormSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        if (formValidate()) {
          setIsLoading(true);
          const data = await axios.post(registerRoute, formData);
          if (data.data.error === false) {
            setOtp(data.data.otp);
            setCode(data.data.code);
            navigate("/individual-otp");
            setIsLoading(false);
            localStorage.setItem("code", data.data.code);
          }
          if (data.data.error === true) {
            setIsLoading(false);
            toast.error("Email is already Taken.", toastOptions);
          }
        }
      } catch (error) {
        toast.error(error.message, toastOptions);
      } finally {
        setIsLoading(false);
      }
    },
    [formValidate, formData, setOtp, setCode, navigate]
  );

  const handleSelectChange = async (e) => {
    setSelectedCountry(e.value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      country_id: e.value.country_id,
    }));
  };

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center justify-space-between">
          <div>{option.name}</div>
          <img
            alt={option.name}
            src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
            className={`mr-2 flag flag-${option.country_code.toLowerCase()}`}
            style={{ width: "32px" }}
          />
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center justify-space-between">
        <div>{option.name}</div>
        <img
          alt={option.name}
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`mr-2 flag flag-${option.country_code.toLowerCase()}`}
          style={{ width: "32px" }}
        />
      </div>
    );
  };

  return (
    <>
      <div className="user-signup-page">
        <div className="left-side">
          <img src={leftBg} alt="bg" />
        </div>
        {!isLoading && (
          <div className="right-side">
            <div className="signup-form">
              <h4>Create account</h4>
              <p>Sign up with cars’r’us to send your inquiry </p>
              <form autoComplete={false}>
                <div className="section">
                  <div className="full-width sub-section">
                    <label htmlFor="fullname">
                      Full Name <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="fullname"
                      name="name"
                      value={fullname}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="country">
                    <label htmlFor="nationality">
                      Nationality <span style={{ color: "red" }}>*</span>
                    </label>
                    <Dropdown
                      value={selectedCountry}
                      onChange={(e) => handleSelectChange(e)}
                      options={countries}
                      optionLabel="name"
                      placeholder="Select a Country"
                      filter
                      valueTemplate={selectedCountryTemplate}
                      itemTemplate={countryOptionTemplate}
                      className="w-full md:w-14rem"
                    />
                  </div>
                </div>
                <div className="section">
                  <div className="sub-section">
                    <label htmlFor="email">
                      Email <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="email"
                      id="email"
                      value={email}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="sub-section">
                    <label htmlFor="age">
                      Age <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="number"
                      id="age"
                      value={age}
                      onChange={(e) => handleChange(e)}
                      min={0}
                      max={2}
                    />
                  </div>
                </div>
                <div className="section">
                  <div className="sub-section">
                    <label htmlFor="pass">
                      Password <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type={!passwordShow ? "password" : "text"}
                      id="password"
                      value={password}
                      onChange={(e) => handleChange(e)}
                    />
                    <span onClick={() => setPasswordShow((prev) => !prev)}>
                      {!passwordShow ? (
                        <AiOutlineEye />
                      ) : (
                        <AiOutlineEyeInvisible />
                      )}
                    </span>
                  </div>
                  <div className="sub-section">
                    <label htmlFor="cpass">
                      Confirm password <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type={!cPasswordShow ? "password" : "text"}
                      id="cPassword"
                      value={cPassword}
                      onChange={(e) => handleChange(e)}
                    />
                    <span onClick={() => setCPasswordShow((prev) => !prev)}>
                      {!cPasswordShow ? (
                        <AiOutlineEye />
                      ) : (
                        <AiOutlineEyeInvisible />
                      )}
                    </span>
                  </div>
                </div>

                <div className="checkbox-area">
                  <div className="left-side">
                    <div className="checkbox">
                      <input
                        type="checkbox"
                        id="terms"
                        checked={formData.termsCheck}
                        onChange={() =>
                          setFormData((prev) => ({
                            ...prev,
                            termsCheck: !prev.termsCheck,
                          }))
                        }
                      />
                      <label htmlFor="terms">
                        I agree to all the <Link to="/terms">Terms</Link> and{" "}
                        <Link to="/policy">Privacy policy</Link>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="button-area">
                  <button onClick={(e) => handleFormSubmit(e)}>
                    Create account
                  </button>
                  <button>
                    <img src={GoogleIcon} alt="" /> Sign-in with google
                  </button>
                </div>
              </form>
              <div className="bottom-text">
                Have an account? <Link to="/login">Log In</Link>
              </div>
            </div>
          </div>
        )}
        {isLoading && (
          <span className="center">
            <div className="custom-loader"></div>
          </span>
        )}
      </div>
    </>
  );
};

export default IndividualSignupFromPage;
