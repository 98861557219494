import { toast } from "react-toastify";
import { toastOptions } from "./ToastOptions";
import { UploadedBy } from "../assets/data";

export function tConvert(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time.pop();
    time[4] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}

export const handleScrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};
export const formValidate = (name, email, password, cPassword) => {
  if (name === "" || name.length < 3) {
    toast.error(
      "Name is required & must be greater than 3 character",
      toastOptions
    );
    return false;
  }
  if (email === "") {
    toast.error("email is required ", toastOptions);
    return false;
  }
  if (password !== cPassword) {
    toast.error("password and confirm password must be same", toastOptions);
    return false;
  }
  if (
    !/[a-z]/.test(password) ||
    !/[A-Z]/.test(password) ||
    !/\d/.test(password) ||
    !/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password) ||
    password.length < 8
  ) {
    toast.error(
      "Password must contain 8 character with uppercase ,lowercase ,number and special Character.",
      toastOptions
    );
    return false;
  }
  return true;
};

export const loginFormValidate = (email, password) => {
  if (email === "") {
    toast.error("email is required", toastOptions);
    return false;
  }
  if (password === "") {
    toast.error("Password is required", toastOptions);
    return false;
  }
  return true;
};

export const handleCounterClick = (
  operation,
  setCounter,
  setPrice,
  counter
) => {
  if (operation === "+") {
    setCounter((prev) => prev + 1);
    setPrice((prev) => prev + 200);
  } else if (counter > 0) {
    setCounter((prev) => prev - 1);
    setPrice((prev) => prev - 200);
  } else {
    return;
  }
};

const geolocationAPI = navigator.geolocation;

export const handleLocationClick = (e, setBusineessDetails, setLocation) => {
  e.preventDefault();
  if (!geolocationAPI) {
    toast.error(
      "Geolocation API is not available in your browser!",
      toastOptions
    );
  } else {
    geolocationAPI.getCurrentPosition(
      (position) => {
        const { coords } = position;
        setBusineessDetails((prev) => ({
          ...prev,
          latitude: coords.latitude,
          longitude: coords.longitude,
        }));
        setLocation(`${coords.latitude} - ${coords.longitude}`);
      },
      (error) => {
        toast.error(
          "Something went wrong getting your position!",
          toastOptions
        );
      }
    );
  }
};

export const addCarFormValidate = (formData, image) => {
  for (const key in formData) {
    if (formData.hasOwnProperty(key) && formData[key] === "") {
      toast.error("Please Complete the Form Properly", toastOptions);
      return false;
    }
    if (image === "" || image === null) {
      toast.error("Please insert the product Image", toastOptions);
      return false;
    }
  }
  return true;
};

export function convertTo12HourFormat(time) {
  const [hours, minutes] = time.split(":");
  // Converting hours from string to number
  let hoursNum = parseInt(hours, 10);
  // Determining AM or PM
  const meridiem = hoursNum >= 12 ? "PM" : "AM";
  // Converting to 12-hour format
  hoursNum = hoursNum % 12 || 12;
  // Formatting the time with leading zero for single-digit hours
  const formattedTime = `${hoursNum
    .toString()
    .padStart(2, "0")}:${minutes} ${meridiem}`;
  return formattedTime;
}

export const handleLogout = (setAuth) => {
  setAuth({
    user: null,
    userType: 0,
  });
  localStorage.removeItem("auth");
};

export const handlePillClick = (id, current, filters, setFilters) => {
  if (current === "body_type") {
    filters.body_type === id
      ? setFilters((prev) => ({ ...prev, body_type: 0, count: --prev.count }))
      : setFilters((prev) => ({
          ...prev,
          body_type: id,
          count: prev.body_type === 0 ? ++prev.count : prev.count,
        }));
  } else if (current === "trim") {
    filters.trim === id
      ? setFilters((prev) => ({ ...prev, trim: 0, count: --prev.count }))
      : setFilters((prev) => ({
          ...prev,
          trim: id,
          count: prev.trim === 0 ? ++prev.count : prev.count,
        }));
  } else if (current === "door") {
    filters.door_id === id
      ? setFilters((prev) => ({ ...prev, door_id: 0, count: --prev.count }))
      : setFilters((prev) => ({
          ...prev,
          door_id: id,
          count: prev.door_id === 0 ? ++prev.count : prev.count,
        }));
  } else if (current === "cylinder") {
    filters.cylinders === id
      ? setFilters((prev) => ({ ...prev, cylinders: 0, count: --prev.count }))
      : setFilters((prev) => ({
          ...prev,
          cylinders: id,
          count: prev.cylinders === 0 ? ++prev.count : prev.count,
        }));
  } else if (current === "fuel_type") {
    filters.fuel_type === id
      ? setFilters((prev) => ({ ...prev, fuel_type: 0, count: --prev.count }))
      : setFilters((prev) => ({
          ...prev,
          fuel_type: id,
          count: prev.fuel_type === 0 ? ++prev.count : prev.count,
        }));
  } else if (current === "transmission_type") {
    filters.transmission_type === id
      ? setFilters((prev) => ({
          ...prev,
          transmission_type: 0,
          count: --prev.count,
        }))
      : setFilters((prev) => ({
          ...prev,
          transmission_type: id,
          count: prev.transmission_type === 0 ? ++prev.count : prev.count,
        }));
  } else if (current === "color_id") {
    filters.color_id === id
      ? setFilters((prev) => ({ ...prev, color_id: 0, count: --prev.count }))
      : setFilters((prev) => ({
          ...prev,
          color_id: id,
          count: prev.color_id === 0 ? ++prev.count : prev.count,
        }));
  } else if (current === "type") {
    filters.type === id
      ? setFilters((prev) => ({ ...prev, type: 0, count: --prev.count }))
      : setFilters((prev) => ({
          ...prev,
          type: id,
          count: prev.type === 0 ? ++prev.count : prev.count,
        }));
  } else if (current === "steering_side") {
    filters.steering_side === id
      ? setFilters((prev) => ({
          ...prev,
          steering_side: 0,
          count: --prev.count,
        }))
      : setFilters((prev) => ({
          ...prev,
          steering_side: id,
          count: prev.steering_side === 0 ? ++prev.count : prev.count,
        }));
  } else if (current === "warranty") {
    filters.warranty === id
      ? setFilters((prev) => ({ ...prev, warranty: 0, count: --prev.count }))
      : setFilters((prev) => ({
          ...prev,
          warranty: id,
          count: prev.warranty === 0 ? ++prev.count : prev.count,
        }));
  } else if (current === "insured") {
    filters.insured === id
      ? setFilters((prev) => ({ ...prev, insured: 0, count: --prev.count }))
      : setFilters((prev) => ({
          ...prev,
          insured: id,
          count: prev.insured === 0 ? ++prev.count : prev.count,
        }));
  } else if (current === "horsepower") {
    filters.horsepower === id
      ? setFilters((prev) => ({
          ...prev,
          horsepower: 0,
          count: --prev.count,
        }))
      : setFilters((prev) => ({
          ...prev,
          horsepower: id,
          count: prev.horsepower === 0 ? ++prev.count : prev.count,
        }));
  } else if (current === "uploadedBy") {
    filters.horsepower === id
      ? setFilters((prev) => ({
          ...prev,
          uploaded_by: 0,
          count: --prev.count,
        }))
      : setFilters((prev) => ({
          ...prev,
          uploaded_by: id,
          count: prev.uploaded_by === 0 ? ++prev.count : prev.count,
        }));
  }
  
  console.log(filters.count);
};

export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });
export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}
export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

export async function getCroppedImg(
  imageSrc,
  pixelCrop,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  );

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  const croppedCanvas = document.createElement("canvas");

  const croppedCtx = croppedCanvas.getContext("2d");

  if (!croppedCtx) {
    return null;
  }

  // Set the size of the cropped canvas
  croppedCanvas.width = pixelCrop.width;
  croppedCanvas.height = pixelCrop.height;

  // Draw the cropped image onto the new canvas
  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  // As Base64 string
  // return croppedCanvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    croppedCanvas.toBlob((file) => {
      resolve(URL.createObjectURL(file));
    }, "image/jpeg");
  });
}


export function formatNumber(number) {
  // Define an array of suffixes for different magnitudes
  const suffixes = ["", "k", "M", "B", "T"];

  // Determine the magnitude of the number
  const magnitude = Math.floor(Math.log10(number) / 3);

  // Calculate the scaled number
  const scaledNumber = number / Math.pow(1000, magnitude);

  // Use toFixed(1) to keep one decimal place, adjust as needed
  const formattedNumber = scaledNumber.toFixed(1);

  // Add the appropriate suffix
  const suffix = suffixes[magnitude];

  // Combine the formatted number and suffix
  return `${formattedNumber}${suffix}`;
}