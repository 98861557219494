import React from 'react'

import ColorNavbarWithCategory from "../../components/ColorNavbarWithCategory/ColorNavbarWithCategory"
import Footer from "../../components/Footer/Footer"
import CarBuyingLongWithFilterMainArea from '../../components/CarBuyingLongWithFIlterMainArea/CarBuyingLongWithFilterMainArea'


const CarBuyingLongWithFilter = () => {
  return (
    <div>
        <ColorNavbarWithCategory color={"#ED5565"} textColor={"white"}/>
        <CarBuyingLongWithFilterMainArea/>
        <Footer/>
    </div>
  )
}

export default CarBuyingLongWithFilter