import React, { useState, useEffect, useCallback, useMemo } from "react";

import "./BlogMainSection.scss";

import LongSearchBar from "../LongSearchBar/LongSearchBar";
import BlogPost from "../BlogPost/BlogPost";

import { Stack, Pagination } from "@mui/material";
import { handleScrollToTop } from "../../utils/ReuseFunction";
import axios from "axios";
import { getBlogsRoute } from "../../utils/ApiRoutes";
const BlogMainSection = () => {
  // states
  const [blogs, setBlogs] = useState([])
  const [populor, setPopulor] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageBlogs, setCurrentPageBlogs] = useState([])

  const controller = useMemo(() => new AbortController(), [])

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const getData = useCallback(
    async () => {
      const {data} =  await axios.get(getBlogsRoute , {signal: controller.signal})
      setBlogs(data.blog)
      setPopulor(data.populor)
      const itemsPerPage = 9; // Number of items to display per page
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const currentPageItems = [
        ...data.blog?.slice(startIndex, endIndex),
      ];
      setCurrentPageBlogs(currentPageItems);
    } ,
    [currentPage , controller.signal],
  )
  
  useEffect(() => {
    getData()
    handleScrollToTop();
    return () => controller.abort();
  }, [getData,controller]);

  useEffect(() => {
    const itemsPerPage = 9; // Number of items to display per page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentPageItems = [
      ...blogs?.slice(startIndex, endIndex),
    ];
    setCurrentPageBlogs(currentPageItems);
    console.log(...blogs?.slice(startIndex, endIndex));
    // eslint-disable-next-line 
  }, [currentPage]);

  return (
    <div className="blog-main-area">
      <div className="section-heading">
        <h1>Blog</h1>
      </div>

      <div className="search-bar">
        <LongSearchBar />
      </div>

      {populor.length > 0 && <h1>Popular</h1>}
      <div className="blogs">
        {populor?.map((blog, i) => (
          <BlogPost key={i} blog={blog} featured="true" />
        ))}
      </div>

      {/* {featured.length > 0 && (
        <>
          <h1>Featured</h1>
          <div className="blogs">
            {featured?.map((blog, i) => (
              <BlogPost key={i} blog={blog} />
            ))}
          </div>
        </>
      )} */}

      <div className="blogs">
        {currentPageBlogs?.map((blog, i) => (
          <BlogPost key={i} blog={blog} />
        ))}
      </div>
      <Stack spacing={2}>
        {Math.ceil((blogs?.length ) / 9) > 1 && (<Pagination
          variant="outlined"
          page={currentPage}
          onChange={handlePageChange}
          count={ (blogs?.length)!==0 ? Math.ceil((blogs?.length ) / 9) : 1}
        />)}
      </Stack>
    </div>
  );
};

export default BlogMainSection;
