import React, { useState} from "react";

import "./LongSearchBar.scss";
import axios from "axios";
import { blogSearchRoute } from "../../utils/ApiRoutes";

const LongSearchBar = () => {  
  const [searchTerm, setSearchTerm] = useState("");

  const handleSubmit = async (e) => {
   e.preventDefault();
   const {data} = await axios.post(blogSearchRoute , {search: searchTerm})
   console.log(data);
  };

  return (
    <div className="main-search-container">
      <form className="main-search-bar" onSubmit={(e) => handleSubmit(e)}>
        <input type="text" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search blogs by there title"/>
        <button type="submit">Search</button>
      </form>
    </div>
  );
};

export default LongSearchBar;
