// import React, { useState } from "react";

import "./WorkshopHomeMainArea.scss";

import WorkshopCard from "../WorkshopCard/WorkshopCard";
import PartsImage from "../../assets/images/parts.png";

import facebook from "../../assets/icons/facebook.png";
import instagram from "../../assets/icons/instagram.png";
import linkedin from "../../assets/icons/linkedIn.png";
import twitter from "../../assets/icons/twitter.png";
import youTube from "../../assets/icons/youTube.png";
import tikTok from "../../assets/icons/tikTok.png";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { dataContext } from "../../App";
import { MaintenanceImagesRoute, serviceHomePage } from "../../utils/ApiRoutes";
import axios from "axios";
import { Link } from "react-router-dom";
import BiggestShopCard from "../BiggestShopCard/BiggestShopCard";

const WorkshopHomeMainArea = () => {
  const { maintenance } = useContext(dataContext);
  const [state, setState] = useState({});
  const controller = useMemo(() => new AbortController(), []);

  const getData = useCallback(async () => {
    try {
      const { data } = await axios.get(`${serviceHomePage}/2/Stats`);
    setState(data);
    } catch (error) {
      console.error(error.message);
    }
  }, []);

  useEffect(() => {
    getData()
    return () => controller.abort();
  }, [controller, getData]);

  return (
    <div className="workshop-home">
      <div className="first-section">
        <div className="section-heading">What can we do for you? </div>
        <div className="services">
          {maintenance?.map((maintenance, i) => (
            <WorkshopCard
              count={String(i + 1)}
              service={maintenance.name}
              img={`${MaintenanceImagesRoute}/${maintenance.image}`}
              id={maintenance.maintenance_id}
              key={i}
            />
          ))}
        </div>
      </div>
      <div className="second-section">
        <div className="section-heading">Superior Service, Honest Prices </div>
        <div className="card-area">
          {state?.vendor &&
            state?.vendor.map((vendor, i) => (
              <BiggestShopCard vendor={vendor}/>
            ))}
        </div>
      </div>
      <div className="third-section">
        <div className="left-side">
          <img src={PartsImage} alt="" />
        </div>
        <div className="right-side">
          <h4>Looking for parts?</h4>
          <div className="section-description">
            Instantly find any part under the sun with Cars R Us{" "}
            <span style={{ color: "#FF0000" }}> Live Search </span>or Browse
            through parts sellers
          </div>
          <div className="buttons">
            <Link to={"/search"} className="live-search-btn">Live Search</Link>
            <Link to={"/parts"} className="browse-btn">Browse</Link>
          </div>
        </div>
      </div>
      <div className="fourth-section">
        <div className="section-header">
          <h4>Follow us on social media</h4>
          <p>All the latest car news for you</p>
        </div>
        <div className="social-media-icons">
          <img src={facebook} alt="social-media-icon" />
          <img src={twitter} alt="social-media-icon" />
          <img src={instagram} alt="social-media-icon" />
          <img src={linkedin} alt="social-media-icon" />
          <img src={tikTok} alt="social-media-icon" />
          <img src={youTube} alt="social-media-icon" />
        </div>
        <div className="hashtag">#US</div>
      </div>
    </div>
  );
};

export default WorkshopHomeMainArea;
