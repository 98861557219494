import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import ProfileLayout from "../../Layouts/ProfileLayout";
import axios from "axios";
import {
  GetUserProfileDetails,
  getCitiesRoute,
  getlocationRoute,
  updateUserProfileDetails,
  userProfilePictureRoute,
} from "../../utils/ApiRoutes";
import "./EditUserProfileDetails.scss";
import "../VendorImportExportCountry/country-flag.css";
import { useNavigate } from "react-router-dom";
import ProgressCircle from "../../components/ProgressCIrcle/ProgressCircle";
import { useAuth } from "../../context/auth";
import { dataContext } from "../../App";
import { Helmet } from "react-helmet";
import noImage from "../../assets/images/download.png";
import { toast } from "react-toastify"
import { toastOptions } from "../../utils/ToastOptions";

const EditUserProfileDetails = () => {
  const { countries } = useContext(dataContext);
  const profileRef = useRef(null);
  const navigate = useNavigate();
  const [displayImage, setDisplayImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [auth] = useAuth();
  const [profileDetails, setProfileDetails] = useState({
    fullname: "",
    email: "",
    phone: "",
    age: "",
    dob: "",
    gender: "",
    country_id: "",
    city_id: "",
    location_id: "",
    has_image: "",
    image: "",
    address: "",
  });
  const controller = new AbortController();
  const [locations, setLocations] = useState([]);
  const [cities, setCities] = useState([]);
  useEffect(() => {
    getData();
    return () => controller.abort();
    // eslint-disable-next-line
  }, []);

  const getData = async () => {
    setIsLoading(true);
    const response = await axios.get(
      `${GetUserProfileDetails}/${auth.user.code}`,
      { signal: controller.signal }
    );
    const {
      fullname,
      email,
      phone,
      age,
      dob,
      gender,
      country_id,
      city_id,
      location_id,
      has_image,
      image,
      address,
    } = response.data.User[0];
    setProfileDetails((prev) => ({
      ...prev,
      fullname,
      email,
      phone,
      age,
      dob,
      gender,
      country_id,
      city_id,
      location_id,
      has_image,
      image,
      address,
    }));
    const cityResponse = await axios.get(
      `${getCitiesRoute}/${response.data.User[0].country_id}`,
      { signal: controller.signal }
    );
    setCities(cityResponse.data.city);
    const locationResponse = await axios.get(
      `${getlocationRoute}/${response.data.User[0].city_id}`,
      { signal: controller.signal }
    );
    setLocations(locationResponse.data.location);
    setIsLoading(false);
  };

  const handleChange = async (e) => {
    const { value, id ,type} = e.target;
    if (type === "number") {
      if (!isNaN(value) && parseFloat(value) >= 0) {
        setProfileDetails((prev) => ({ ...prev, [id]: value }));
      }
    } else {
      setProfileDetails((prev) => ({ ...prev, [id]: value }));
    }
    
    if (id === "country_id") {
      const cityResponse = await axios.get(`${getCitiesRoute}/${value}`);
      setCities(cityResponse.data.city);
    }
    if (id === "city_id") {
      const locationResponse = await axios.get(`${getlocationRoute}/${value}`);
      setLocations(locationResponse.data.location);
    }
  };

  const handleFileChange = (e) => {
    setProfileDetails((prev) => ({
      ...prev,
      image: e.target.files[0],
      has_image: 1,
    }));
    setDisplayImage(URL.createObjectURL(e.target.files[0]));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (profileDetails.age < 18) {
      toast.error("Age cannot be less than 18", toastOptions)
      return;
    }
    setIsLoading(true);
    const fd = new FormData();
    const {
      fullname,
      email,
      phone,
      age,
      dob,
      gender,
      country_id,
      city_id,
      location_id,
      has_image,
      image,
      address,
    } = profileDetails;
    fd.append("fullname", fullname);
    fd.append("email", email);
    fd.append("phone", phone);
    fd.append("age", age);
    fd.append("dob", dob);
    fd.append("gender", gender);
    fd.append("country_id", country_id);
    fd.append("city_id", city_id);
    fd.append("location_id", location_id);
    fd.append("image", image);
    fd.append("address", address);
    fd.append("has_image", has_image);
    fd.append("code", auth.user.code);
    const response = await axios.post(updateUserProfileDetails, fd, {
      signal: controller.signal,
    });
    if (response.data.error === false) {
      navigate("/profile");
    }
    setIsLoading(false);
  };
  return (
    <>
      <Helmet>
        <title>Edit Profile | CARS'R'US</title>
      </Helmet>
      <ProfileLayout>
        {!isLoading && (
          <div className="edit-form-user">
            {/* <h4>Edit Your Details</h4> */}
            <div className="white-card">
              <div className="edit-profile-header">
                <h4>Personal Info</h4>
                <p>Update your personal info here</p>
              </div>
              <form>
                <div className="profile-section">
                  <div className="profile">
                    {displayImage !== "" && (
                      <img src={displayImage} alt="profile" />
                    )}
                    {displayImage === "" && profileDetails.image !== null && (
                      <img
                        src={`${userProfilePictureRoute}/${profileDetails.image}`}
                        alt="profile"
                      />
                    )}
                    {displayImage === "" && profileDetails.image === null && (
                      <img src={noImage} alt="placeholder" />
                    )}
                  </div>
                  <div>
                    <input
                      type="file"
                      name="image"
                      id="image"
                      onChange={handleFileChange}
                      accept="image/*"
                      hidden
                      ref={profileRef}
                    />
                    <div
                      className="change-btn"
                      onClick={() => profileRef.current.click()}
                    >
                      {profileDetails.image === null
                        ? "Add Profile"
                        : "Change Profile"}
                    </div>
                  </div>
                </div>
                <div className="section">
                  <div>
                    <label htmlFor="fullname">Fullname</label>
                    <input
                      type="text"
                      name="fullname"
                      id="fullname"
                      value={profileDetails.fullname}
                      onChange={handleChange}
                      placeholder="Enter Your Name"
                    />
                  </div>
                  <div>
                    <label htmlFor="phone">Phone</label>
                    <input
                      type="number"
                      name="phone"
                      id="phone"
                      value={profileDetails.phone}
                      onChange={handleChange}
                      placeholder="Enter Your Phone#"
                    />
                  </div>
                </div>
                <div className="select-section">
                  <div>
                    <label htmlFor="country_id">Country</label>
                    <select
                      name="country_id"
                      id="country_id"
                      value={profileDetails.country_id}
                      onChange={handleChange}
                    >
                      {profileDetails.country_id === 0 && (
                        <option value="0">Select City</option>
                      )}
                      {countries?.map((country) => (
                        <option
                          value={country.country_id}
                          key={country.country_id}
                        >
                          {country.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label htmlFor="city_id">City</label>
                    <select
                      name="city_id"
                      id="city_id"
                      value={profileDetails.city_id}
                      onChange={handleChange}
                    >
                      {profileDetails.city_id === 0 && (
                        <option value="0">Select City</option>
                      )}
                      {cities?.map((city) => (
                        <option value={city.city_id} key={city.city_id}>
                          {city.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="select-section">
                  <div>
                    <label htmlFor="location_id">Location</label>
                    <select
                      name="location_id"
                      id="location_id"
                      value={profileDetails.location_id}
                      onChange={handleChange}
                    >
                      {profileDetails.location_id === 0 && (
                        <option value="0">Select City</option>
                      )}
                      {locations?.map((location) => (
                        <option
                          value={location.location_id}
                          key={location.location_id}
                        >
                          {location.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label htmlFor="gender">Gender</label>
                    <select
                      name="gender"
                      id="gender"
                      value={profileDetails.gender}
                      onChange={handleChange}
                    >
                      {profileDetails.gender === 0 && (
                        <option value="0">Select gender</option>
                      )}
                      <option value="1">Male</option>
                      <option value="2">Female</option>
                      <option value="3">Other</option>
                    </select>
                  </div>
                </div>
                <div className="select-section">
                  <div>
                    <label htmlFor="age">Age</label>
                    <input
                      type="number"
                      name="age"
                      id="age"
                      value={profileDetails.age}
                      onChange={handleChange}
                      placeholder="Enter your age"
                    />
                  </div>
                  <div>
                    <label htmlFor="dob">Date Of Birth</label>
                    <input
                      type="date"
                      name="dob"
                      id="dob"
                      value={profileDetails.dob}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="section">
                  <div className="full-width">
                    <label htmlFor="address">Address</label>
                    <input
                      type="text"
                      name="address"
                      id="address"
                      value={profileDetails.address}
                      onChange={handleChange}
                      placeholder="Enter Your Address"
                    />
                  </div>
                </div>
                <div className="button-section">
                  <div>
                    <button onClick={handleSubmit}>Update Details</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
        {isLoading && <ProgressCircle height={"calc(100vh - 125px)"} />}
      </ProfileLayout>
    </>
  );
};

export default EditUserProfileDetails;
