import React from 'react'


import DashboardLayout from "../../Layouts/DashboardLayout"
import BusinessPromotion from '../../components/BusinessPromotioin/BusinessPromotion'
const DashboardBusinessPromotion = () => {
  return (
      <DashboardLayout>
        <BusinessPromotion/>
    </DashboardLayout>
  )
}

export default DashboardBusinessPromotion