import React, { useState, useEffect } from "react";
import { NoProfile } from "../../assets/icons/svg";
import "./BusinessProfileMainArea.scss";

import {
  getCountry,
  getCitiesRoute,
  getlocationRoute,
  getVendorDetails,
  vendorImagesRoute,
  bannerImageRoute,
  profileImageRoute,
} from "../../utils/ApiRoutes";
import axios from "axios";
import {  useParams } from "react-router-dom";
import ProgressCircle from "../ProgressCIrcle/ProgressCircle";
import NoBackground from "../../assets/images/bg-placeholder.jpg"
const BusinessProfileMainArea = () => {
  const url = Object.values(useParams())[0];
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [Images, setImages] = useState([]);
  const [locations, setLocations] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [businessDetails, setBusinessDetails] = useState({
    address: "",
    city_id: "",
    description: "",
    location_id: "",
    name: "",
    phone: "",
    since: "",
    trade_no: "",
    vision: "",
    website: "",
    country_id: "",
    has_banner: 0,
    banner: "",
    sunday: "",
    monday: "",
    tuesday: "",
    wednesday: "",
    thursday: "",
    friday: "",
    saturday: "",
    longitude: "",
    latitude: "",
    profile: "",
  });

  useEffect(() => {
    getData();
    // eslint-disable-next-line 
  }, [url]);

  const getData = async () => {
    setIsloading(true);
    const response = await axios.get(`${getVendorDetails}/${url}`);
    const countryResponse = await axios.get(getCountry);
    const {
      address,
      city_id,
      description,
      location_id,
      name,
      phone,
      since,
      trade_no,
      vision,
      website,
      country_id,
      banner,
      timings,
      longitude,
      latitude,
      images,
      profile,
      vendor_name,
      vendor_phone,
      vendor_email,
      vendor_address,
    } = response.data.business[0];
    setBusinessDetails((prev) => ({
      ...prev,
      address,
      city_id,
      description,
      location_id,
      name,
      phone,
      since,
      trade_no,
      vision,
      website,
      country_id,
      banner,
      longitude,
      latitude,
      profile,
      vendor_name,
      vendor_phone,
      vendor_email,
      vendor_address,
    }));
    if (timings) {
      const temp = JSON.parse(timings);
      for (let index = 0; index < temp?.length; index++) {
        const element = Object.keys(temp[index])[0];
        const data = Object.values(temp[index])[0];
        if (data !== "Close") {
          const time = data.split("-");
          const timeStart =time[0]
          const timeEnd = time[1]
          setBusinessDetails((prev) => ({
            ...prev,
            [element]: timeStart + " - " + timeEnd,
          }));
        } else if (data === "Close") {
          setBusinessDetails((prev) => ({ ...prev, [element]: data }));
        }
      }
    }
    if (images) {
      const temp = JSON.parse(images);
      setImages(temp);
    }
    setCountries(countryResponse.data.country);
    const locationResponse = await axios.get(`${getlocationRoute}/${city_id}`);
    setLocations(locationResponse.data.location);
    const cityResponse = await axios.get(`${getCitiesRoute}/${country_id}`);
    setCities(cityResponse.data.city);
    setIsloading(false);
  };

  const locationFilter = (locations) => {
    const filterData = locations.filter(
      (current) => current?.location_id === businessDetails?.location_id
    );
    return filterData[0]?.name;
  };
  const CityFilter = (cities) => {
    const filterData = cities.filter(
      (current) => current?.city_id === businessDetails?.city_id
    );
    return filterData[0]?.name;
  };
  const countryFilter = (countries) => {
    const filterData = countries.filter(
      (current) => current?.country_id === businessDetails?.country_id
    );
    return filterData[0]?.name;
  };

  return (
    <>
      {!isloading && (
        <div className="business-profile-main-area">
          <div className="profile-section">
         
            {/* <h4>Profile Pictures</h4> */}
            <div className="photos-section">
              <div className="profile-section">
                {(businessDetails?.profile === "" ||
                  businessDetails.profile === "null" || businessDetails.profile === null) && (
                  <div>
                    <NoProfile />
                  </div>
                )}
                {businessDetails.profile !== "" &&
                  businessDetails.profile !== null && (
                    <div className="profile">
                      <img
                        src={`${profileImageRoute}/${businessDetails?.profile}`}
                        alt="profile"
                      />
                    </div>
                  )}
              </div>
              <div className="cover-section">
                <div className="cover-wrapper">
                  <div className="cover-image">
                    {businessDetails.banner ? (
                      <img
                        src={`${bannerImageRoute}/${businessDetails.banner}`}
                        alt=""
                      />
                    ) : (
                      <img src={NoBackground} alt=""  />
                    )}
                  </div>
                  {/* <div className="bottom-left">
                    <h4>{businessDetails.name}</h4>
                    <p>
                      {" " +
                        countryFilter(countries) +
                        ", " +
                        CityFilter(cities) +
                        ", " +
                        locationFilter(locations)}{" "}
                    </p>
                  </div> */}
                  {/* <div className="bottom-right">
                    <Link to={`${vendorProfileEditRoute}/${url}`}>
                      {"Edit Business information ->"}
                    </Link>
                  </div> */}
                  <div className="top-right">
                    {/* <span className="icon">
                      <ImageOutlinedIcon />
                    </span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="business-infomation">
            <h4>Personal Infomation</h4>
            <div className="wrapper">
              <p>Name : {businessDetails?.vendor_name} </p>
              <p>Business Phone : {businessDetails?.vendor_phone}</p>
              <p>Business Website : {businessDetails?.vendor_email}</p> 
              <p>Business Website : {businessDetails?.vendor_address}</p> 
            </div>
          </div>
          <div className="Images">
            <h4>Images</h4>
            <div className="wrapper">
              {Images?.length === 0
                ? "No images have Been Uploaded"
                : Images.map((image, i) => (
                    <div className="image" key={i}>
                      <img src={`${vendorImagesRoute}/${image}`} alt="shop" />
                    </div>
                  ))}
            </div>
          </div>
          <div className="business-infomation">
            <h4>Business Infomation</h4>
            <div className="wrapper">
              {/* <p>Company Name : {businessDetails?.name}</p> */}
              <p>
                Working since :{" "}
                {businessDetails?.since && businessDetails?.since !== "null"
                  ? businessDetails?.since
                  : "  ----"}
              </p>
              <p>Business Address : {businessDetails?.address} </p>
              <p>Business Phone : {businessDetails?.phone}</p>
              <p>Business Website : {businessDetails?.website}</p>
            </div>
          </div>
          <div className="business-description">
            <h4>Business Description</h4>
            <div className="wrapper">
              <p>
                {businessDetails?.description &&
                businessDetails?.description !== "null"
                  ? businessDetails?.description
                  : "Not Specified"}
              </p>
            </div>
          </div>
          <div className="business-location">
            <h4>Business Location</h4>
            <div className="wrapper">
              <p>
                Map-cordinates :{" "}
                {(businessDetails?.longitude
                  ? businessDetails?.longitude
                  : "----") +
                  "-" +
                  (businessDetails?.latitude
                    ? businessDetails?.latitude
                    : "----")}
              </p>
              <p>
                Business Location:{" "}
                {" " +
                  countryFilter(countries) +
                  ", " +
                  CityFilter(cities) +
                  ", " +
                  locationFilter(locations)}{" "}
              </p>
            </div>
          </div>
          <div className="business-timings">
            <h4>Business Timings</h4>
            <div className="wrapper">
              <p>
                Monday :{" "}
                {businessDetails?.monday && businessDetails?.monday !== "null"
                  ? businessDetails?.monday
                  : "----"}{" "}
              </p>
              <p>
                Tuesday :{" "}
                {businessDetails?.tuesday && businessDetails?.tuesday !== "null"
                  ? businessDetails?.tuesday
                  : "----"}{" "}
              </p>
              <p>
                Wednesday :{" "}
                {businessDetails?.wednesday &&
                businessDetails?.wednesday !== "null"
                  ? businessDetails?.wednesday
                  : "----"}{" "}
              </p>
              <p>
                Thursday :{" "}
                {businessDetails?.thursday &&
                businessDetails?.thursday !== "null"
                  ? businessDetails?.thursday
                  : "----"}{" "}
              </p>
              <p>
                Friday :{" "}
                {businessDetails?.friday && businessDetails?.friday !== "null"
                  ? businessDetails?.friday
                  : "----"}{" "}
              </p>
              <p>
                Saturday :{" "}
                {businessDetails?.saturday &&
                businessDetails?.saturday !== "null"
                  ? businessDetails?.saturday
                  : "----"}{" "}
              </p>
              <p>
                Sunday :{" "}
                {businessDetails?.sunday && businessDetails?.sunday !== "null"
                  ? businessDetails?.sunday
                  : "----"}{" "}
              </p>
            </div>
          </div>
        </div>
      )}
      {isloading && <ProgressCircle height={"calc(100vh - 150px)"} />}
    </>
  );
};

export default BusinessProfileMainArea;
