import React from "react";
import DashboardLayout from "../../Layouts/DashboardLayout";
import VendorDashboardChatMain from "../../components/VendorDashboardChatMain/VendorDashboardChatMain";
import { Helmet } from "react-helmet";
const VendorDashboardChat = () => {
  return (
    <>
      <Helmet>
        <title>Vendor Chat | CARS'R'US</title>
      </Helmet>
      <DashboardLayout>
        <VendorDashboardChatMain />
      </DashboardLayout>
    </>
  );
};

export default VendorDashboardChat;
