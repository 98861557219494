import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import StatesCard from "../StatesCard/StatesCard";
import "./CarsHomeMainArea.scss";
import facebook from "../../assets/icons/facebook.png";
import instagram from "../../assets/icons/instagram.png";
import linkedin from "../../assets/icons/linkedIn.png";
import twitter from "../../assets/icons/twitter.png";
import youTube from "../../assets/icons/youTube.png";
import tikTok from "../../assets/icons/tikTok.png";


import  mechanic1 from "../../assets/images/mechanic1.jpg";
import  mechanic2 from "../../assets/images/mechanic2.jpg";
import  mechanic3 from "../../assets/images/mechanic3.jpg";


import axios from "axios";
import {
  blogImageRoute,
  brandLogoRoute,
  carHomepageRoute,
  cardRoute,
  getBlogsRoute,
} from "../../utils/ApiRoutes";
import { dataContext } from "../../App";
import VerticalProductCard from "../VerticalProductCard/VerticalProductCard";
import { Link, useNavigate } from "react-router-dom";

const CarsHomeMainArea = () => {
  const [states, setstates] = useState({});
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { brands } = useContext(dataContext);
  const navigate = useNavigate()

  const controller = useMemo(() => new AbortController(), []);

  const getData = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(carHomepageRoute, {
        signal: controller.signal,
      });
      const response = await axios.get(getBlogsRoute, {
        signal: controller.signal,
      });
      setstates(data);
      setBlogs(response.data.populor);
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [controller.signal]);

  useEffect(() => {
    getData();
    return () => controller.abort();
  }, [controller, getData]);

  const handleCompareProductClick = () => {};

  const handleBookmarkClick = () => {};

  return (
    <div className="cars-home">
      <div className="first-section">
        <div className="states">
          <StatesCard count={states?.Used} name={"Used Cars for Sale"} />
          <StatesCard count={states?.New} name={"New Cars for Sale"} />
          <StatesCard count={states?.Year} name={"Current Model Year Cars"} />
          <StatesCard count={states?.Brand} name={"Available Brands"} />
        </div>
        <div className="brands">
          {brands?.map((brand, i) => (
            <img
              src={`${brandLogoRoute}/${brand.logo}`}
              alt={brand.name}
              key={i}
            />
          ))}
        </div>
      </div>
      <div className="second-section">
        <div className="top-heading">Pre/Post Purchase Services</div>
        <div className="second-section-main">
          <div className="heading">
            Get your desired car inspected before purchase
          </div>
          <div className="cards">
            <div className="grey-card">
              <img src={mechanic1} alt="" />
            </div>
            <div className="grey-card-big">
              <img src={mechanic2} alt=""  />
            </div>
            <div className="grey-card">
              <img src={mechanic3} alt="" />
            </div>
          </div>
          <div className="heading">
            Get your new Ride tuned before hitting the road
          </div>
        </div>
      </div>
      <div className="third-section">
        <div className="section-heading">Popular Comparisons</div>
        <div className="comparision-cards">
          <div className="comparision-card">
            <h4>Toyota Camry vs Nissan Altima</h4>
            <div>
              {states?.Product_One?.flat(1).map((product, i) => (
                <img src={`${cardRoute}/${product.card}`} alt="" key={i} />
              ))}
            </div>
          </div>
          <div className="comparision-card">
            <h4>Nissan Patrol vs Land Cruiser</h4>
            <div>
              {states?.Product_Two?.flat(1).map((product, i) => (
                <img src={`${cardRoute}/${product.card}`} alt="" key={i} />
              ))}
            </div>
          </div>
        </div>
        <div className="long-btn">More popular comparisons</div>
      </div>
      <div className="fourth-section">
        <div className="section-heading">Trending Searches</div>
        <div className="trending-card">
          {states?.Populor_Searches &&
            states.Populor_Searches?.map((product, i) => (
              <VerticalProductCard
                key={i}
                product={product}
                ad={false}
                handleBookmarkClick={handleBookmarkClick}
                handleCompareProductClick={handleCompareProductClick}
                setisLoading={setIsLoading}
                isLoading={isLoading}
                compare={false}
                bookmark={false}
                border={true}
              />
            ))}
        </div>
      </div>
      <div className="fifth-section">
        <div className="section-header">
          <h2>Car-Buying Guides</h2>
          <Link to={"/blog"}>View all</Link>
        </div>
        <div className="guide-cards">
          {blogs?.map((blog, i) => (
            <div className="guide-card" onClick={() => navigate(`/blog/${blog.url}`)} key={i}>
              <img src={`${blogImageRoute}/${blog?.image}`} alt={blog?.name} />
              <h4>{blog?.name}</h4>
            </div>
          ))}
        </div>
      </div>
      <div className="sixth-section">
        <div className="section-header">
          <h4>Follow us on social media</h4>
          <p>All the latest car news for you</p>
        </div>
        <div className="social-media-icons">
          <img src={facebook} alt="social-media-icon" />
          <img src={twitter} alt="social-media-icon" />
          <img src={instagram} alt="social-media-icon" />
          <img src={linkedin} alt="social-media-icon" />
          <img src={tikTok} alt="social-media-icon" />
          <img src={youTube} alt="social-media-icon" />
        </div>
        <div className="hashtag">#US</div>
      </div>
    </div>
  );
};

export default CarsHomeMainArea;
