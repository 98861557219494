import "./StatesCard.scss"

const StatesCard = ({count, name ,}) => {
  return (
    <div className='state-card'>
        <h4>{name}</h4>
        <h2>{count}</h2>
    </div>
  )
}

export default StatesCard