import React from "react";
import MerchantNavbar from "../../components/MerchantNavbar/MerchantNavbar";
import Footer from "../../components/Footer/Footer";
import MerchantAreaMainArea from "../../components/MerchantSearchMainArae/MerchantAreaMainArea";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

const MerchantSearchPage = () => {
  const params = useParams();
  params.type.charAt(0).toUpperCase();
  return (
    <div>
      <Helmet>
        <title>
          {`${params.type.charAt(0).toUpperCase()}${params.type.slice(1)}`}{" "}
          Vendors Listing | CARS'R'US
        </title>
      </Helmet>
      <MerchantNavbar color={"#3C5298"} textColor={"white"} />
      <MerchantAreaMainArea />
      <Footer />
    </div>
  );
};

export default MerchantSearchPage;
