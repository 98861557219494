import React from 'react'
import DashboardLayout from '../../Layouts/DashboardLayout'
import AddServiceMainArea from '../../components/AddServiceMainArea/AddServiceMainArea'

const AddServiceVendor = () => {
  return (
    <DashboardLayout>
        <AddServiceMainArea/>
    </DashboardLayout>
  )
}

export default AddServiceVendor