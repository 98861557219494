import React from "react";
import "./BiggestShopCard.scss";
import { profileImageRoute } from "../../utils/ApiRoutes";
import noImage from "../../assets/images/no-image.png";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { Link } from "react-router-dom";

const BiggestShopCard = ({ vendor }) => {
  return (
    <div className="biggest-shop-card_main">
      <div className="vendor-image">
        {vendor.type === 2 && <div className="merchant-badge">Featured</div>}
        {vendor.type === 3 && <div className="merchant-badge">Premium</div>}
        <img
          src={
            vendor?.profile === null
              ? noImage
              : `${profileImageRoute}/${vendor?.profile}`
          }
          alt="Profile"
        />
      </div>
      <div className="hover-div">
        <div className="hover-vendor-details">
          <h4>{vendor?.name}</h4>
          <div className="hover-location">
            <LocationOnOutlinedIcon />
            <span>
              {`${vendor?.location_name} - ${vendor?.city_name} - ${vendor?.country_name}`}
            </span>
          </div>
          <div className="hover-merchant-desc">
            “
            {vendor?.description && vendor?.description !== "null"
              ? vendor?.description
              : "----"}
            “
          </div>
          <div className="hover-btn-section">
            <Link to={`/merchant/${vendor?.url}`}><button>Visit Profile</button></Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BiggestShopCard;
