import React, { useState } from "react";

import "./PromoteAdSection.scss";

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { handleCounterClick } from "../../utils/ReuseFunction";
const PromoteAdSection = () => {
  const [counter, setCounter] = useState(0);
  const [price, setPrice] = useState(0);
  return (
    <div className="promote-ad-section">
      <h2>Add Details for promotion</h2>
      <h6>
        Your vehicle will be boosted to the top of the main page and within its
        body type, make and model. this does not include year*
      </h6>
      <div className="calculation-area">
        <h3>No. of Days</h3>
        <button onClick={() => handleCounterClick("-",setCounter,setPrice,counter)}><RemoveIcon/></button>
        <p>{counter}</p>
        <button onClick={() => handleCounterClick("+",setCounter,setPrice,counter)} style={{borderColor:"#10C92D", color:"#10C92D"}}><AddIcon/></button>
      </div>
      <div className="calculation-area">
        <h3>Total Cost</h3>
        <p>{price} AED</p>
      </div>
        <button className="promote-btn">Promote</button>
    </div>
  );
};

export default PromoteAdSection;
