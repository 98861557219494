import React, { useEffect, useState } from "react";
import DashboardLayout from "../../Layouts/DashboardLayout";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import {
  getVendorReviews,
  userProfilePictureRoute,
} from "../../utils/ApiRoutes";
import "./DashboardBusinessReviews.scss";
import { Rating } from "@mui/material";

import Avatar from "../../assets/images/avatar.jpg";
import { ToastContainer, toast } from "react-toastify";
import { toastOptions } from "../../utils/ToastOptions";
import ProgressCircle from "../../components/ProgressCIrcle/ProgressCircle";
import { useAuth } from "../../context/auth";
import { Helmet } from "react-helmet";
const DashboardBusinessReviews = () => {
  const [expanded, setExpanded] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [auth] = useAuth();

  const controller = new AbortController();

  const handleChange = (panel) => (isExpanded) => {
    if (expanded === "panel1") {
      setExpanded(false);
    } else {
      setExpanded(panel);
    }
    // setExpanded(isExpanded !=="panel1" ? panel : false);
  };

  useEffect(() => {
    getReviews();
    return () => controller.abort();
    // eslint-disable-next-line
  }, []);

  const getReviews = async () => {
    setIsLoading(true);
    try {
      const product = await axios.get(`${getVendorReviews}/${auth.user?.code}`,{signal: controller.signal});
      setReviews(product.data.review);
    } catch (error) {
      toast.error("something went wrong", toastOptions);
    }
    setIsLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>Vendor Reviews | CARS'R'US</title>
      </Helmet>
      <DashboardLayout>
        {!isLoading && (
          <div className="reviews-main">
            <div className="main-section">
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <h4>Business Reviews {reviews?.length}</h4>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="table-data">
                    <div className="header-row">
                      <div className="search-icon">
                        <SearchIcon />
                      </div>
                      <div className="search-input">
                        <input type="text" placeholder="Search" />
                      </div>
                      <div className="heading">Review</div>
                      <div className="heading">Click</div>
                      <div className="heading">Date</div>
                      <div className="heading">Status</div>
                      <div className="heading">Option</div>
                    </div>
                    {reviews.map((review, i) => (
                      <div className="row" key={i}>
                        <div className="checkbox">
                          <input type="checkbox" />
                        </div>
                        <div className="product">
                          <div className="image">
                            {review?.profile && (
                              <img
                                src={`${userProfilePictureRoute}/${review?.profile}`}
                                alt=""
                              />
                            )}
                            {!review?.profile && <img src={Avatar} alt="" />}
                          </div>
                          <div className="details">
                            <h4>{review?.fullname}</h4>
                          </div>
                        </div>
                        <div className="views">
                          <Rating
                            name="read-only"
                            readOnly
                            value={Number(review.rating)}
                          />
                        </div>
                        <div className="clicks">0</div>
                        <div className="review-date">{review?.date}</div>
                        <div className="highlight">
                          {/* <button onClick={(e) => handleHighlightClick(e)}>
                        {product.status === 0 ? "Active" : "De-Active"}
                      </button> */}
                        </div>
                        <div className="options">
                          {/* <DropdownMenu id={product.code} setActive={setActive} deactivate={deactivate}/> */}
                        </div>
                      </div>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
            <ToastContainer />
          </div>
        )}
        {isLoading && <ProgressCircle height={"calc(100vh - 150px)"} />}
      </DashboardLayout>
    </>
  );
};

export default DashboardBusinessReviews;
