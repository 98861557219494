import React, { useState, useEffect, useRef, useContext } from "react";
import "./BusinessProfile.scss";

import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import {
  getCitiesRoute,
  getlocationRoute,
  getVendorDetails,
  updateVendorDetails,
  bannerImageRoute,
  editVendorImages,
  vendorImagesRoute,
  profileImageRoute,
  vendorImageDeleteRoute,
} from "../../utils/ApiRoutes";
import { NoProfile } from "../../assets/icons/svg";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { handleLocationClick } from "../../utils/ReuseFunction";
import { useNavigate, useParams } from "react-router-dom";
import { vendorProfileRoute } from "../../utils/Routes";
import ProgressCircle from "../ProgressCIrcle/ProgressCircle";
import { useAuth } from "../../context/auth";
import { dataContext } from "../../App";
import moment from "moment";


const Days = {
  monday : "",
  tuesday : "",
  wednesday : "",
  thursday : "",
  friday : "",
  saturday : "",
  sunday : "",
}

const BusinessProfile = () => {
  // Session Variables
  const url = Object.values(useParams())[0];
  const [auth] = useAuth();
  const { countries } = useContext(dataContext);
  // hooks
  const inputRef = useRef();
  const imagesUploadRef = useRef();
  const profileImageRef = useRef();
  const navigate = useNavigate();
  // states
  const [Images, setImages] = useState([]);
  const [location, setLocation] = useState("");
  const [banner, setBanner] = useState(null);
  const [profile, setProfile] = useState(null);
  const [selectAll, setSelectAll] = useState(false)
  const [locations, setLocations] = useState([]);
  const [cities, setCities] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [displayImage, setDisplayImage] = useState(null);
  const [files, setFiles] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState("");
  const [displayMultipleImages, setDisplayMultipleImages] = useState([]);
  const [businessDetails, setBusinessDetails] = useState({
    address: "",
    city_id: "",
    description: "",
    location_id: "",
    name: "",
    phone: "",
    since: "",
    trade_no: "",
    vision: "",
    website: "",
    country_id: "",
    has_banner: 0,
    banner: "",
    has_profile: 0,
    vendor_name: "",
    vendor_phone : "",
    vendor_address : "",
    profile: "",
    sunday: {
      checked: false,
      start: "",
      end: "",
    },
    monday: {
      checked: false,
      start: "",
      end: "",
    },
    tuesday: {
      checked: false,
      start: "",
      end: "",
    },
    wednesday: {
      checked: false,
      start: "",
      end: "",
    },
    thursday: {
      checked: false,
      start: "",
      end: "",
    },
    friday: {
      checked: false,
      start: "",
      end: "",
    },
    saturday: {
      checked: false,
      start: "",
      end: "",
    },
    longitude: "",
    latitude: "",
  });
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [url]);

  const getData = async () => {
    setIsloading(true);
    const response = await axios.get(`${getVendorDetails}/${url}`);
    const {
      address,
      city_id,
      description,
      location_id,
      name,
      phone,
      since,
      trade_no,
      vision,
      website,
      country_id,
      banner,
      timings,
      longitude,
      latitude,
      images,
      profile,
      vendor_name,
      vendor_phone,
      vendor_address,
    } = response.data.business[0];
    setBusinessDetails((prev) => ({
      ...prev,
      address,
      city_id,
      description,
      location_id,
      name,
      phone,
      since,
      trade_no,
      vision,
      website,
      country_id,
      banner,
      profile,
      longitude,
      latitude,
      vendor_name,
      vendor_phone,
      vendor_address,
    }));
    if (timings) {
      const temp = JSON.parse(timings);
      for (let index = 0; index < temp.length; index++) {
        const element = Object.keys(temp[index])[0];
        const data = Object.values(temp[index])[0];
        if (data !== "Close") {
          const time = data.split("-");
          setBusinessDetails((prev) => ({
            ...prev,
            [element]: { checked: true, start: moment(time[0] , "h:mm A").format("HH:mm"), end: moment(time[1] , "h:mm A").format("HH:mm") },
          }));
        } else if (data === "Close") {
          setBusinessDetails((prev) => ({
            ...prev,
            [element]: { checked: false, start: "", end: "" },
          }));
        }
      }
    }
    if (images) {
      const temp = JSON.parse(images);
      setImages(temp);
    }
    setLocation(`${latitude} - ${longitude}`);
    const locationResponse = await axios.get(`${getlocationRoute}/${city_id}`);
    setLocations(locationResponse.data.location);
    const cityResponse = await axios.get(`${getCitiesRoute}/${country_id}`);
    setCities(cityResponse.data.city);
    setIsloading(false);
  };

  const handleChange = async (e) => {
    const { value, id } = e.target;
    setBusinessDetails((prev) => ({ ...prev, [id]: value }));
    if (id === "city_id") {
      const response = await axios.get(`${getlocationRoute}/${value}`);
      setLocations(response.data.location);
      document.getElementById("location_id").removeAttribute("disabled");
    }
    if (id === "country_id") {
      const response = await axios.get(`${getCitiesRoute}/${value}`);
      setCities(response.data.city);
      document.getElementById("city_id").removeAttribute("disabled");
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();
    setIsloading(true);
    try {
      const {
        address,
        city_id,
        description,
        location_id,
        name,
        phone,
        since,
        trade_no,
        vision,
        website,
        country_id,
        has_banner,
        sunday,
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        longitude,
        latitude,
        has_profile,
      } = businessDetails;

      const fd = new FormData();
      fd.append("b_name", name);
      fd.append("b_phone", phone);
      fd.append("b_address", address);
      fd.append("website", website);
      fd.append("trade_no", trade_no);
      fd.append("city_id", city_id);
      fd.append("location_id", location_id);
      fd.append("country_id", country_id);
      fd.append("since", since);
      fd.append("vision", vision);
      fd.append("description", description);
      fd.append("banner", banner);
      fd.append("url", url);
      fd.append("has_Banner", has_banner);
      fd.append(
        "sunday",
        sunday.checked
          ? moment(sunday.start, "HH:mm").format("h:mm A") +
              "-" +
              moment(sunday.end, "HH:mm").format("h:mm A")
          : "Close"
      );
      fd.append(
        "monday",
        monday.checked
          ? moment(monday.start, "HH:mm").format("h:mm A") +
              "-" +
              moment(monday.end, "HH:mm").format("h:mm A")
          : "Close"
      );
      fd.append(
        "tuesday",
        tuesday.checked
          ? moment(tuesday.start, "HH:mm").format("h:mm A") +
              "-" +
              moment(tuesday.end, "HH:mm").format("h:mm A")
          : "Close"
      );
      fd.append(
        "wednesday",
        wednesday.checked
          ? moment(wednesday.start, "HH:mm").format("h:mm A") +
              "-" +
              moment(wednesday.end, "HH:mm").format("h:mm A")
          : "Close"
      );
      fd.append(
        "thursday",
        thursday.checked
          ? moment(thursday.start, "HH:mm").format("h:mm A") +
              "-" +
              moment(thursday.end, "HH:mm").format("h:mm A")
          : "Close"
      );
      fd.append(
        "friday",
        friday.checked
          ? moment(friday.start, "HH:mm").format("h:mm A") +
              "-" +
              moment(friday.end, "HH:mm").format("h:mm A")
          : "Close"
      );
      fd.append(
        "saturday",
        saturday.checked
          ? moment(saturday.start, "HH:mm").format("h:mm A") +
              "-" +
              moment(saturday.end, "HH:mm").format("h:mm A")
          : "Close"
      );
      fd.append("longitude", longitude);
      fd.append("latitude", latitude);
      fd.append("has_Profile", has_profile);
      fd.append("profile", profile);
      await axios.post(updateVendorDetails, fd);
      if (files !== null) {
        for (let index = 0; index < files.length; index++) {
          try {
            const imageData = new FormData();
            imageData.append("image", files[index]);
            imageData.append("code", auth.user?.code);
            await axios.post(editVendorImages, imageData);
          } catch (error) {
            console.log(error);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
    navigate(`${vendorProfileRoute}/${url}`);
    setIsloading(false);
  };

  const handlefilesChanges = (e) => {
    setFiles(e.target.files);
    const temp = Array.from(e.target.files);
    setDisplayMultipleImages(temp.map((file) => URL.createObjectURL(file)));
  };

  const handleDeleteClick = async (image) => {
    const { data } = await axios.post(vendorImageDeleteRoute, {
      code: auth.user?.code,
      Image: image,
    });
    if (data.error === false) {
      setImages(Images.filter((current) => current !== image));
    } else {
      console.log("error");
    }
  };
  const handleUploadDelete = (image) => {
    setDisplayMultipleImages(
      displayMultipleImages.filter((current) => current !== image)
    );
  };
  const handleCheckChange = (e) => {
    const { id, checked } = e.target;
    setBusinessDetails((prev) => ({ ...prev, [id]: { ...prev[id], checked } }));
  };

  const handleTime = (e) => {
    const { name, id, value } = e.target;
    if (id === "start") {
      setBusinessDetails((prev) => ({
        ...prev,
        [name]: { ...prev[name], [id]: value },
      }));
    } else if (id === "end") {
      setBusinessDetails((prev) => ({
        ...prev,
        [name]: { ...prev[name], [id]: value },
      }));
    }
  };

  const handleSelectAll = () => {
    setSelectAll(prev=>!prev)
    const initial = businessDetails 
    Object.keys(Days).forEach(key => initial[key].checked = selectAll ? false :  true )
    setBusinessDetails(initial)
  }

  return (
    <div className="business-profile-main">
      {!isloading && (
        <>
          <div className="wrapper">
            <div className="profile">
              <input
                type="file"
                ref={profileImageRef}
                hidden
                onChange={(e) => {
                  setProfilePhoto(URL.createObjectURL(e.target.files[0]));
                  setProfile(e.target.files[0]);
                  setBusinessDetails((prev) => ({ ...prev, has_profile: 1 }));
                }}
                accept="image/*"
              />
              <div>
                {(businessDetails?.profile === "" || businessDetails.profile) && <NoProfile />}
                {profilePhoto !== "" ? (
                  <img src={profilePhoto} alt="Profile" />
                ) : (
                  <img
                    src={`${profileImageRoute}/${businessDetails?.profile}`}
                    alt="Profile"
                  />
                )}

                <span
                  className="icon"
                  onClick={() => profileImageRef.current.click()}
                >
                  <ImageOutlinedIcon />
                </span>
              </div>
            </div>
            <div className="background">
              <div className="background-inner">
                {(displayImage !== null || businessDetails.banner !== null) && (
                  <img
                    src={
                      displayImage !== null
                        ? displayImage
                        : businessDetails.banner !== null
                        ? `${bannerImageRoute}/${businessDetails.banner}`
                        : ""
                    }
                    alt="banner"
                  />
                )}
                <div onClick={() => inputRef.current.click()}>
                  Change Background{" "}
                  <span>
                    <ImageOutlinedIcon />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <form>
          {/* <h2>Personal Information</h2>
            <div className="business-information">
              <input
                type="file"
                name="banner"
                id="banner"
                onChange={(e) => {
                  setBanner(e.target.files[0]);
                  setBusinessDetails((prev) => ({ ...prev, has_banner: 1 }));
                  setDisplayImage(URL.createObjectURL(e.target.files[0]));
                }}
                ref={inputRef}
                hidden
                accept="image/*"
              />
              <div>
                <label htmlFor="name"> Name </label>
                <input
                  type="text"
                  id="vendor_name"
                  name="vendor_name"
                  value={businessDetails.vendor_name}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="address"> Address </label>
                <input
                  type="text"
                  id="vendor_address"
                  name="vendor_address"
                  value={businessDetails.vendor_address}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="phone"> Phone </label>
                <input
                  type="text"
                  id="vendor_phone"
                  name="vendor_phone"
                  value={businessDetails.vendor_phone}
                  onChange={handleChange}
                />
              </div>
            </div> */}
            <h2>Business Images</h2>
            <div className="images-input">
              <input
                type="file"
                name="images"
                id="images"
                hidden
                onChange={(e) => handlefilesChanges(e)}
                multiple
                ref={imagesUploadRef}
                accept="image/*"
              />
              <div
                className="images-upload-area"
                onClick={() => imagesUploadRef.current.click()}
              >
                <p>Click Here to upload images</p>
                <UploadFileIcon />
              </div>
              <div className="display-images-area">
                {displayMultipleImages?.map((image, i) => (
                  <div className="image" key={i}>
                    <img src={image} alt="store" />
                    <span onClick={() => handleUploadDelete(image)}>
                      <DeleteOutlineIcon />
                    </span>
                  </div>
                ))}
                {Images?.map((image, i) => (
                  <div className="image" key={i}>
                    <img src={`${vendorImagesRoute}/${image}`} alt="store" />
                    <span onClick={() => handleDeleteClick(image)}>
                      <DeleteOutlineIcon />
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <h2>Business Information</h2>
            <div className="business-information">
              <input
                type="file"
                name="banner"
                id="banner"
                onChange={(e) => {
                  setBanner(e.target.files[0]);
                  setBusinessDetails((prev) => ({ ...prev, has_banner: 1 }));
                  setDisplayImage(URL.createObjectURL(e.target.files[0]));
                }}
                ref={inputRef}
                hidden
                accept="image/*"
              />
              <div>
                <label htmlFor="name"> Business Name </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={businessDetails.name}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="address"> Business Address </label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  value={businessDetails.address}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="phone"> Business Phone </label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  value={businessDetails.phone}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="website"> Business Website </label>
                <input
                  type="text"
                  id="website"
                  name="website"
                  value={businessDetails.website}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="since"> Working Since </label>
                <input
                  type="text"
                  id="since"
                  name="since"
                  value={businessDetails.since}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="vision"> Vision Statement </label>
                <input
                  type="text"
                  id="vision"
                  name="vision"
                  value={businessDetails.vision}
                  onChange={handleChange}
                />
              </div>
            </div>
            <h2>Business Descriptions</h2>
            <div className="business-description">
              <textarea
                name="description"
                id="description"
                cols="30"
                rows="10"
                value={businessDetails.description}
                onChange={handleChange}
              ></textarea>
            </div>
            <h2>Select Location</h2>
            <div className="business-location">
              <input
                type="text"
                id="location"
                name="location"
                placeholder="Search"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
              <button
                onClick={(e) =>
                  handleLocationClick(e, setBusinessDetails, setLocation)
                }
              >
                <RoomOutlinedIcon /> Current Location
              </button>
            </div>
            <div className="business-location">
              <select
                name="country_id"
                id="country_id"
                onChange={handleChange}
                value={businessDetails.country_id}
              >
                <option value="0">Select Country</option>
                {countries.map((country) => (
                  <option value={country.country_id} key={country.country_id}>
                    {country.name}
                  </option>
                ))}
              </select>
              <select
                name="city_id"
                id="city_id"
                onChange={handleChange}
                value={businessDetails.city_id}
              >
                <option value="0">Select City</option>
                {cities.map((city) => (
                  <option value={city.city_id} key={city.city_id}>
                    {city.name}
                  </option>
                ))}
              </select>
              <select
                name="location_id"
                id="location_id"
                onChange={handleChange}
                value={businessDetails.location_id}
              >
                <option value="0">Select Location</option>
                {locations.map((location) => (
                  <option
                    value={location.location_id}
                    key={location.location_id}
                  >
                    {location.name}
                  </option>
                ))}
              </select>
            </div>

            <h2>Job Timings</h2>
            <p>
              <span style={{ color: "red", fontWeight: "bold" }}>Note:</span>{" "}
              Timing unchecked will be considerd as close
            </p>

            <div className="select_all">
              <label htmlFor="select_all_amenities">
                <input type="checkbox" name="select_all" id="select_all_amenities"  checked={selectAll} onChange={handleSelectAll} /> Select All
              </label>
            </div>
            <div className="job-timings">
              <div>
                <input
                  type="checkbox"
                  name="monday"
                  id="monday"
                  checked={businessDetails.monday.checked}
                  onChange={handleCheckChange}
                />
                <label htmlFor="monday">Monday</label>
                <input
                  type="time"
                  name="monday"
                  id="start"
                  value={businessDetails.monday.start}
                  onChange={handleTime}
                />
                <span>To</span>
                <input
                  type="time"
                  name="monday"
                  id="end"
                  value={businessDetails.monday.end}
                  onChange={handleTime}
                />
              </div>
              <div>
                <input
                  type="checkbox"
                  name="tuesday"
                  id="tuesday"
                  checked={businessDetails.tuesday.checked}
                  onChange={handleCheckChange}
                />
                <label htmlFor="tuesday">Tuesday</label>

                <input
                  type="time"
                  name="tuesday"
                  id="start"
                  value={businessDetails.tuesday.start}
                  onChange={handleTime}
                />
                <span>To</span>
                <input
                  type="time"
                  name="tuesday"
                  id="end"
                  value={businessDetails.tuesday.end}
                  onChange={handleTime}
                />
              </div>
              <div>
                <input
                  type="checkbox"
                  name="wednesday"
                  id="wednesday"
                  checked={businessDetails.wednesday.checked}
                  onChange={handleCheckChange}
                />
                <label htmlFor="wednesday">Wednesday</label>

                <input
                  type="time"
                  name="wednesday"
                  id="start"
                  value={businessDetails.wednesday.start}
                  onChange={handleTime}
                />
                <span>To</span>
                <input
                  type="time"
                  name="wednesday"
                  id="end"
                  value={businessDetails.wednesday.end}
                  onChange={handleTime}
                />
              </div>
              <div>
                <input
                  type="checkbox"
                  name="thursday"
                  id="thursday"
                  checked={businessDetails.thursday.checked}
                  onChange={handleCheckChange}
                />
                <label htmlFor="thursday">Thursday</label>

                <input
                  type="time"
                  name="thursday"
                  id="start"
                  value={businessDetails.thursday.start}
                  onChange={handleTime}
                />
                <span>To</span>
                <input
                  type="time"
                  name="thursday"
                  id="end"
                  value={businessDetails.thursday.end}
                  onChange={handleTime}
                />
              </div>
              <div>
                <input
                  type="checkbox"
                  name="friday"
                  id="friday"
                  checked={businessDetails.friday.checked}
                  onChange={handleCheckChange}
                />
                <label htmlFor="friday">Friday</label>
                <input
                  type="time"
                  name="friday"
                  id="start"
                  value={businessDetails.friday.start}
                  onChange={handleTime}
                />
                <span>To</span>
                <input
                  type="time"
                  name="friday"
                  id="end"
                  value={businessDetails.friday.end}
                  onChange={handleTime}
                />
              </div>
              <div>
                <input
                  type="checkbox"
                  name="saturday"
                  id="saturday"
                  checked={businessDetails.saturday.checked}
                  onChange={handleCheckChange}
                />
                <label htmlFor="saturday">Saturday</label>
                <input
                  type="time"
                  name="saturday"
                  id="start"
                  value={businessDetails.saturday.start}
                  onChange={handleTime}
                />
                <span>To</span>
                <input
                  type="time"
                  name="saturday"
                  id="end"
                  value={businessDetails.saturday.end}
                  onChange={handleTime}
                />
              </div>
              <div>
                <input
                  type="checkbox"
                  name="sunday"
                  id="sunday"
                  checked={businessDetails.sunday.checked}
                  onChange={handleCheckChange}
                />
                <label htmlFor="sunday">Sunday</label>
                <input
                  type="time"
                  name="sunday"
                  id="start"
                  value={businessDetails.sunday.start}
                  onChange={handleTime}
                />
                <span>To</span>
                <input
                  type="time"
                  name="sunday"
                  id="end"
                  value={businessDetails.sunday.end}
                  onChange={handleTime}
                />
              </div>
            </div>

            <div className="btn-section">
              <button onClick={handleClick}>Update Details</button>
            </div>
          </form>
        </>
      )}
      {isloading && <ProgressCircle height={"calc(100vh - 150px)"} />}
      <ToastContainer />
    </div>
  );
};

export default BusinessProfile;
