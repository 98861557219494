import React from 'react'
import DashboardLayout from '../../Layouts/DashboardLayout'
import BusinessCars from '../../components/BusinessCars/BusinessCars'

const DashboardBusinessCars = () => {
  return (
    <DashboardLayout>
        <BusinessCars/>
    </DashboardLayout>
  )
}

export default DashboardBusinessCars