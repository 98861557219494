import { useState, useEffect } from "react";
import "./InspectionFormMainArea.scss";
import axios from "axios";
import {
  GetBrandRoute,
  GetModelRoute,
  getCitiesRoute,
  getCountry,
  getlocationRoute,
  inspectionFormRoute,
} from "../../utils/ApiRoutes";
import CarFinance from "../../assets/images/Car finance.svg";
import { ToastContainer ,toast} from "react-toastify";
import { toastOptions } from "../../utils/ToastOptions";
import { useAuth } from "../../context/auth";
const initailState = {
  country_id: "",
  city_id: "",
  location_id: "",
  address: "",
  model_year: "",
  brand_id: "",
  model_id: "",
  name: "",
  phone: "",
  available_date: "",
  available_time: "",
}
const InspectionFormMainArea = () => {
  const [auth] = useAuth()
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [locations, setLocations] = useState([]);
  const [models, setModels] = useState([]);
  const [brands, setBrands] = useState([]);
  const [formData, setFormData] = useState({
    country_id: "",
    city_id: "",
    location_id: "",
    address: "",
    model_year: "",
    brand_id: "",
    model_id: "",
    name: "",
    phone: "",
    available_date: "",
    available_time: "",
  });
  useEffect(() => {
    const getData = async () => {
      try {
        const brandResponse = await axios.get(GetBrandRoute);
        const countryResponse = await axios.get(getCountry);
        setBrands(brandResponse.data.brand);
        setCountries(countryResponse.data.country);
      } catch (error) {
        console.error(error);
      }
    };
    getData();
  }, []);
  const handleInputChange = async (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name === "brand_id") {
      const response = await axios.get(`${GetModelRoute}/${value}`);
      setModels(response.data.Model);
      document.getElementById("model_id").removeAttribute("disabled");
    }
    if (name === "city_id") {
      const response = await axios.get(`${getlocationRoute}/${value}`);
      setLocations(response.data.location);
      document.getElementById("location_id").removeAttribute("disabled");
    }
    if (name === "country_id") {
      const response = await axios.get(`${getCitiesRoute}/${value}`);
      setCities(response.data.city);
      document.getElementById("city_id").removeAttribute("disabled");
    }
  };

  const date = new Date();
  const years = [];
  for (let index = date.getFullYear() + 1; index > 1950; index--) {
    years.push(index);
  }

const formValidate = ()=>{
  for (const key in formData) {
    if (formData.hasOwnProperty(key) && formData[key] === "") {
      toast.error("Please Complete the Form Properly",toastOptions);
      return false;
    }
  }
  return true;
}

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formValidate()) {
      if(auth.user){
        console.log(formData);
        const {data} = await axios.post(inspectionFormRoute,{
          ...formData,
          user_id:auth.user.user_id,
        })
        if (data.error===false) {
          toast.success("form Submitted successfully" , toastOptions)
          setFormData(initailState)
        }
      }else{
        toast.error("Please login To submit form", toastOptions)
      }
    }
  }

  return (
    <div className="inspection-section">
      <div className="left-side">
        <img src={CarFinance} alt="" />
      </div>
      <div className="right-side">
        <div className="section-heading">
          Apply For Car’R’Us inspection and vehicle condition verification below
        </div>
        <div className="form-section">
          <h4>Enter your car details below</h4>
          <form>
            <select
              name="country_id"
              id="country_id"
              value={formData.country_id}
              onChange={handleInputChange}
            >
              <option value="">Select Country</option>
              {countries?.map((data) => (
                <option key={data.country_id} value={data.country_id}>
                  {data.name}
                </option>
              ))}
            </select>
            <select
              name="city_id"
              id="city_id"
              value={formData.city_id}
              onChange={handleInputChange}
              disabled
            >
              <option value="">Select City</option>
              {cities.map((city) => (
                <option key={city.city_id} value={city.city_id}>
                  {city.name}
                </option>
              ))}
            </select>
            <select
              name="location_id"
              id="location_id"
              value={formData.location_id}
              onChange={handleInputChange}
              disabled
            >
              <option value="">Select Location</option>
              {locations.map((location) => (
                <option key={location.location_id} value={location.location_id}>
                  {location.name}
                </option>
              ))}
            </select>
            <input type="text" name="address" id="address" placeholder="Address" value={formData.address} onChange={handleInputChange}/>
            <select
              name="brand_id"
              id="brand_id"
              value={formData.brand_id}
              onChange={handleInputChange}
            >
              <option value="">Select Brand</option>
              {brands.map((brand) => (
                <option key={brand.brand_id} value={brand.brand_id}>
                  {brand.name}
                </option>
              ))}
            </select>
            <select
              name="model_id"
              id="model_id"
              value={formData.model_id}
              onChange={handleInputChange}
              disabled
            >
              <option value="">Select Model</option>
              {models.map((model) => (
                <option key={model.model_id} value={model.model_id}>
                  {model.name}
                </option>
              ))}
            </select>
            <select
              name="model_year"
              id="model_year"
              onChange={handleInputChange}
              value={formData.model_year}
            >
              <option value="0">Select Model Year</option>
              {years.map((year) => (
                <option value={year} key={year}>
                  {year}
                </option>
              ))}
            </select>
            <input type="date" name="available_date" id="available_date" value={formData.available_date} onChange={handleInputChange}/>
            <input type="time" name="available_time" id="available_timeme" value={formData.available_time} onChange={handleInputChange}/>
            <input type="text" name="name" id="name" value={formData.name} onChange={handleInputChange} placeholder="Full Name"/>
            <input type="number" name="phone" id="phone" value={formData.phone} onChange={handleInputChange} placeholder="Phone Number"/>
            <button onClick={handleSubmit}>Submit</button>
          </form>
        </div>
        <ToastContainer/>
      </div>
    </div>
  );
};

export default InspectionFormMainArea;
