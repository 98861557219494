import React from "react";

import "./DashboardTopbar.scss";
import MenuIcon from "@mui/icons-material/Menu";

import {
  Avatar,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { IconButton } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Logout from "@mui/icons-material/Logout";
import { useAuth } from "../../context/auth";
import NotificationMenu from "../NotificationMenu/NotificationMenu";
const DashboardTopbar = ({ handleMenuClick, notification }) => {
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleAccountMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    setAuth({
      user: null,
      userType: 0,
    });
    localStorage.removeItem("auth");
    navigate("/");
  };

  return (
    <>
      
      <div className="dashboard-topbar">
        <div className="search-bar">
          {/* <span onClick={() => setSearch("")}>
          <SearchIcon />
        </span>
        <input
          type="text"
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        /> */}
        </div>
        <div className="user-profile">
          <NotificationMenu/>
          <div>
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleAccountMenuClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Avatar sx={{ width: 32, height: 32 }} alt="Profile">
                  M
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 24,
                    height: 24,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 16,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={handleClose}>
                <Avatar /> Profile
              </MenuItem>
              {/* <MenuItem onClick={handleClose}>
          <Avatar /> My account
        </MenuItem> */}
              <Divider />
              {/* <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem> */}
              <MenuItem onClick={() => logout()}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </div>

          <div className="menu-icon" onClick={() => handleMenuClick()}>
            <MenuIcon />
          </div>
        </div>
      </div>
      {(!auth.user?.planType || auth.user?.planType === "No Plan Active") && (
        <div className="alert-area">
          <div className="alert">
            You have not activated any of the packages to access Cars'r'us. To
            begin enjoying our services, kindly purchase a package from{" "}
            <Link to={"/pricing"}> here</Link>
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardTopbar;
