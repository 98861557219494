import React, { useCallback, useEffect, useMemo, useState } from "react";
import HorizontalProductCardSmall from "../HorizontalProductCardSmall/HorizontalProductCardSmall";

import "./MySearchSection.scss";
import { useAuth } from "../../context/auth";
import { deleteHistory, insertChatMessage, productRoute, userHistoryListing } from "../../utils/ApiRoutes";
import axios from "axios";
import ProgressCircle from "../ProgressCIrcle/ProgressCircle";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/ToastOptions";
const MySearchSection = () => {
  const [ads, setAds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [auth] = useAuth();

  const navigate = useNavigate();
  const controller =  useMemo(() => new AbortController(), [])

  const getData = useCallback(
    async () => {
      setIsLoading(true);
      const response = await axios.post(userHistoryListing, {
        user_id : auth.user.user_id
      }, {
        signal: controller.signal
      });
      setAds(response?.data?.History);
      setIsLoading(false);
    },
    [auth.user.user_id , controller.signal],
  )
  
  useEffect(() => {
    getData();
    return () => controller.abort()
  }, [getData ,controller]);


  const handleRemove = useCallback(
    async (e,code) => {
        e.preventDefault();
        try {
          const {data} = await axios.post(deleteHistory,{
            code  ,
          } , {signal: controller.signal})
          if (data.error ===false) {
            getData()
          }
        } catch (error) {
          console.log(error);
        }
    },
    [controller,getData],
  )
   

  const handleMessage = useCallback(
    async (e ,id, receiver, type) => {
       e.preventDefault();
       try {
        e.preventDefault();
      if (auth.user) {
      await axios.post(insertChatMessage, {
        sender_id: auth.user.user_id,
        receiver_id: id,
        message: "Hello",
        sender: auth.userType === 2 ? "users" : "vendor",
        receiver,
        type,
      });
      
      navigate("/my-chat");
    } else {
      toast.error("Please login to chat with vendor", toastOptions);
    }
       } catch (error) {
        console.log(error);
       }
    },
    [auth.user,auth.userType,navigate],
  )
  

  return (
    <>
      {!isLoading && (<div className="my-search-main">
        <h4>Search History</h4>
        <div className="history-section">
          {ads.map((ad, i) => (
            <div className="history" key={i}>
              <HorizontalProductCardSmall ad={ad} url={ad.product_url} />
              <h5>{moment(ad.date).format("DD/MM/YYYY")}</h5>
              <button onClick={(e)=>handleMessage(
                      e,
                      ad?.uploader_id,
                      ad.uploaded_by === 2 ? "users" : "vendor",
                      ad.uploaded_by === 2 ? 0 : 1
                    )} className="history-button">Message Agent</button>
              <button onClick={(e)=>handleRemove(e,ad?.code)} className="history-button">Remove Agent</button>
              {false && (<button onClick={()=>navigate("/")} className="history-button">Agent Profile</button>)}
            </div>
          ))}
        </div>
      </div>)}
      {isLoading && <ProgressCircle height={"calc(100vh - 125px)"} />}
    </>
  );
};

export default MySearchSection;
