import React, { useState, useEffect, useContext } from "react";

import "./CarPoolMainArea.scss";
import axios from "axios";
import { getVendors, vendorFilterRoute } from "../../utils/ApiRoutes";
import MerchantCard from "../MerchantCard/MerchantCard";
import { useNavigate } from "react-router-dom";
import { merchantDetailsPage } from "../../utils/Routes";
import NoData from "../../assets/images/no-result-blue.svg";
import { Pagination } from "@mui/material";
import FilterWithCheckBox from "../FilterWithCheckBox/FilterWithCheckBox";
import Map from "../map/Map";
import MenuIcon from "@mui/icons-material/Menu";
import ProgressCircle from "../ProgressCIrcle/ProgressCircle";
import { dataContext } from "../../App";

const CarPoolMainArea = () => {
  const navigate = useNavigate();
  const {countries} =  useContext(dataContext)
  const [isLoading, setisLoading] = useState(true);
  const [badges, setbadges] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageVendors, setCurrentPageVendors] = useState([]);
  const [bounds, setBounds] = useState(null);
  const [vendors, setVendors] = useState([]);
  const [vendorFilter, setVendorFilter] = useState({
    badge_id: 0,
    country_id: 0,
    search: "",
    city_id: 0,
    service_id: 4,
  });

  useEffect(() => {
    FilterChange();
    // eslint-disable-next-line
  }, [vendorFilter.badge_id, vendorFilter.search, vendorFilter.city_id ,vendorFilter.country_id,]);

  const FilterChange = async () => {
    if (
      vendorFilter.badge_id !== 0 ||
      vendorFilter.search !== "" ||
      vendorFilter.city_id !== 0 ||
      vendorFilter.country_id !== 0
    ) {
      try {
        setisLoading(true)
        const { data } = await axios.post(vendorFilterRoute, vendorFilter);
        if (data.error === false) {
          setVendors(data.vendor);
          const itemsPerPage = 9; // Number of items to display per page
          const startIndex = (currentPage - 1) * itemsPerPage;
          const endIndex = startIndex + itemsPerPage;
          const currentPageItems = [
            ...data.vendor?.slice(startIndex, endIndex),
          ];
          setCurrentPageVendors(currentPageItems);
        }
      } catch (error) {
        console.log(error);
      }finally{
        setisLoading(false)
      }
    } else {
      getData();
    }
  };

  useEffect(() => {
    const uae = countries.find(
      (data) => data.country_code.toLowerCase() === "AE".toLowerCase()
    );
    setVendorFilter((prev) => ({ ...prev, country_id: uae.country_id }));
    getData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const itemsPerPage = 5; // Number of items to display per page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentPageItems = [...vendors?.slice(startIndex, endIndex)];
    setCurrentPageVendors(currentPageItems);
    // eslint-disable-next-line
  }, [currentPage]);

  const getData = async () => {
    setisLoading(true);
    try {
      const response = await axios.get(`${getVendors}/4`);
      setVendors(response.data.vendor);
      setbadges(response.data.Badge);
      const itemsPerPage = 5; // Number of items to display per page
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const currentPageItems = [
        ...response.data.vendor?.slice(startIndex, endIndex),
      ];
      setCurrentPageVendors(currentPageItems);
    } catch (error) {
      console.log(error);
    }finally{
      setisLoading(false);
    }
  };
  const handleCardClick = (url) => {
    navigate(`${merchantDetailsPage}/${url}`);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleMenuClick = () => {
    document.getElementById("filters_menu").style.display = "block";
  };

  return (
    <div className="car-pool-main">
      <FilterWithCheckBox
        vendorFilter={vendorFilter}
        setVendorFilter={setVendorFilter}
      />
      <div className="pool-right-side">
        {!isLoading && (<>
        <div className="menu-bar">
          <MenuIcon onClick={handleMenuClick} />
        </div>
        <div className="car-pool-header">
          <h1>Car Pool</h1>
          <h4>Merchants that offer Car Pool</h4>
        </div>
        <div className="vendor-cards">
          {currentPageVendors.flat(1)?.map((vendor, i) => (
            <div
              key={i}
              style={{ cursor: "pointer" }}
              // onClick={() => handleCardClick(vendor?.url)}
            >
              <MerchantCard
                handleCardClick={handleCardClick}
                vendor={vendor}
                badges={badges}
                badgeId={JSON.parse(vendor.badge)}
              />
            </div>
          ))}
        </div>
        {vendors.length === 0 && <img src={NoData} alt="no data" />}
        <div className="pagination-center">
          {Math.ceil(vendors?.length / 5) > 1 && (
            <Pagination
              variant="outlined"
              page={currentPage}
              onChange={handlePageChange}
              count={vendors?.length !== 0 ? Math.ceil(vendors?.length / 5) : 1}
            />
          )}
        </div>
        </>)}
        {isLoading && (<ProgressCircle height={"80vh"} />)}
      </div>
      <div
        className="map"
        style={{
          position: "absolute",
          right: ".5rem",
          top: "14rem",
          width: "18%",
        }}
      >
        <Map vendors={vendors} bounds={bounds} setBounds={setBounds} />
      </div>
    </div>
  );
};

export default CarPoolMainArea;
