import {
  auction,
  carBuying,
  financing,
  importAndExport,
  renting,
  carpool,
  sellMyCar,
  insurance,
} from "../../utils/Routes";

import {
  Buy,
  Rent,
  PeopleInCar,
  CorpDebt,
  ImportAndExport,
  PriceTag,
} from "../../assets/icons/svg";

export const steps = [
  {
    label: "Sign-Up",
  },
  {
    label: "Service",
  },
  {
    label: "Brand",
  },
  {
    label: "Parts",
  },
];

export const fuelTypes = [
  "",
  "Petrol",
  "Diesel",
  "Electric",
  "Hybrid",
  "LPG",
  "CNG",
  "Hydrogen",
];
export const BodyCondition = ["", "Excellent", "Good", "Fair", "Poor"];
export const MechanicalCondition = ["", "Excellent", "Good", "Fair", "Poor"];
export const Warrenty = ["", "Yes", "No"];
export const insured = ["", "Yes", "No"];
export const Doors = [
  "",
  "2 Doors",
  "3 Doors",
  "4 Doors",
  "5 Doors",
  "6 Doors",
];
export const Cylinders = [
  "",
  "4",
  "6",
  "8",
  "More than 8",
];
export const Cylinders2 = [
  "",
  "4 Cylinders",
  "6 Cylinders",
  "8 Cylinders",
  "More than 8",
];
export const TransmissionType = ["", "Automatic", "Manual"];
export const BodyType = [
  "",
  "Sedan",
  "Coupe",
  "Hatchback",
  "Station Wagon",
  "SUV",
  "Crossover",
  "Convertible",
  "Roadster",
  "Pick-up Truck",
  "Van",
  "MPV",
  "Limousine",
  "Supercar",
  "Electric Vehicle",
  "Hybrid Car",
];
export const Horspower = [
  "",
  "Less than 100 HP",
  "100 - 200 HP",
  "200 - 300 HP",
  "300 - 400 HP",
  "400 - 500 HP",
  "500 - 600 HP",
  "600+ HP",
];

export const UploadedBy = ["","Merchant" , "User"]
export const SteeringSide = ["", "Left", "Right"];
export const Trim = ["", "Base", "Premium", "Sport", "Luxury", "Limited"];

export const RegionalSpec = [
  "","GCC Specs","American Specs","Canadian Specs","European Specs","Japanese Specs","Other"
]

export const carMenuDataLeft = [
  {
    icon: <Buy />,
    text: "Buy Used Cars/ New Cars",
    link: carBuying,
  },
  {
    icon: <Rent />,
    text: "Rent a Car",
    link: renting,
  },
  {
    icon: <PeopleInCar />,
    text: "Auction",
    link: auction,
  },
  {
    icon: <CorpDebt />,
    text: "financing",
    link: financing,
  },
  {
    icon: <ImportAndExport />,
    text: "Car Importing Exporting",
    link: importAndExport,
  },
];
export const carMenuDataRight = [
  {
    icon: <PriceTag />,
    text: "Sell My Car",
    link: sellMyCar,
  },
  {
    icon: <PriceTag />,
    text: "Car Insurance",
    link: insurance,
  },
  {
    icon: <PeopleInCar />,
    text: "Car Pool",
    link: carpool,
  },
];


export const Colors = [
  "",
  "Unlisted",
  "White",
  "Silver",
  "Black",
  "Grey",
  "Blue",
  "Green",
  "Red",
  "Gold",
  "Maroon",
  "Beige",
  "Pink",
  "Brown",
  "Burgundy",
  "Yellow",
  "Bronze",
  "Purple",
  "Turquoise",
  "Orange",
  "indigo",
  "Magenta",
  "Navy",
];

export const ColorsForDisplay = [
  "",
  "Unlisted",
  "White",
  "Silver",
  "Black",
  "Grey",
  "Blue",
  "Green",
  "Red",
  "Gold",
  "Maroon",
  "Beige",
  "Pink",
  "Brown",
  "#800020",
  "Yellow",
  "#CD7F32",
  "Purple",
  "Turquoise",
  "Orange",
  "indigo",
  "Magenta",
  "Navy",
];

export const parts = [
  "Bumper",
  "Header Panel / Front Grill",
  "Front Quarter Panel",
  "Fender",
  "Rim",
  "Headlights",
  "Fender Extension Panel",
  "Hood",
  "Cowl Panel",
  "Front Windshield",
  "Rear Windshield",
  "Roof",
  "Rear View Mirror",
  "Window",
  "Door",
  "Scuff Plate",
  "Dog Leg",
  "Trunk",
  "Break light",
  "Indicator",
  "Fender Flare",
  "Back Quarter Panel",
]

export const newUsed = [
  "","New","Used"
]

export const cities = [
  "Abu Dhabi",
  "Sharjah",
  "Ajman",
  "Umm Al Quwain",
  "Ras Al Khamaish",
  "Fujarah",
  "Dubai",
];

export const filterInitialState ={
  brand_id:  0,
  model_id: 0,
  min_year: 1950,
  max_year: new Date().getFullYear() + 1,
  country_id: 0,
  city_id: 0,
  location_id: 0,
  fuel_type: 0,
  body_condition: 0,
  mechanical_condition: 0,
  warranty: 0,
  cylinders: 0,
  transmission_type: 0,
  body_type: 0,
  horsepower: 0,
  steering_side: 0,
  trim: 0,
  min_price: 0,
  max_price: 0,
  color_id: 0,
  insured: 0,
  door_id: 0,
  purpose: 0,
  type: 0,
  count: 0,
  orderby: "desc",
  order_column: "product.product_id",
  startdate: 0,
  enddate: 0,
} 