import React, { useState } from "react";
import ColorNavbarWithCategory from "../../components/ColorNavbarWithCategory/ColorNavbarWithCategory";
import Footer from "../../components/Footer/Footer";
import CarBuyingShortMainArea from "../../components/CarBuyingShortMainArea/CarBuyingShortMainArea";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import "./CarBuyingShortPage.scss";
import { cardRoute, insertCompareRoute } from "../../utils/ApiRoutes";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/ToastOptions";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useAuth } from "../../context/auth";
const CarBuyingShortPage = () => {
  const [compareMenu, setCompareMenu] = useState(false);
  const [images, setImages] = useState([]);
  const [auth] = useAuth();
  const navigate = useNavigate();

  const controller = new AbortController();
  const handleCompareMenuClick = () => {
    setCompareMenu((prev) => !prev);
  };
  const handleCompareProductClick = (e, img, url, product_id) => {
    if (images.length < 2) {
      setCompareMenu(true);
      for (let index = 0; index < 2; index++) {
        if (images[index] === img) {
          toast.error("cannot add same Product again", toastOptions);
          return;
        }
      }
      setImages((prev) => [...prev, img]);
      localStorage.setItem(
        `product-${images.length + 1}`,
        JSON.stringify({ img, url, product_id })
      );
    } else {
      toast.error("Upto 2 variants can be selected at a time.", toastOptions);
    }
  };

  const handleDeleteClick = (img) => {
    setImages(images.filter((current) => current !== img));
    for (let index = 0; index < 2; index++) {
      const temp = JSON.parse(localStorage.getItem(`product-${index + 1}`));
      if (temp?.img === img) {
        localStorage.removeItem(`product-${index + 1}`);
      }
    }
  };

  const handleCompareClick = async () => {
    if (auth.user) {
      const data = [];
      for (let index = 0; index < 2; index++) {
        const temp = JSON.parse(localStorage.getItem(`product-${index + 1}`));
        data.push(temp);
      }
      if (data[0].product_id > data[1].product_id) {
        await axios.post(
          insertCompareRoute,
          {
            user_id: auth.user?.user_id,
            product_id: String(data[0].product_id) + String(data[1].product_id),
            combine_id: `${String(data[0].product_id)}-${String(data[1].product_id)}`
          },
          { signal: controller.signal }
        );
      } else if (data[1].product_id > data[0].product_id) {
        await axios.post(
          insertCompareRoute,
          {
            user_id: auth.user?.user_id,
            product_id: String(data[1].product_id) + String(data[0].product_id),
            combine_id: `${String(data[0].product_id)}-${String(data[1].product_id)}`
          },
          { signal: controller.signal }
        );
      }
    }
    navigate("/compare");
  };

  return (
    <div>
      <Helmet>
        <title>Cars Listing | CARS'R'US</title>
      </Helmet>
      <ColorNavbarWithCategory color={"#ED5565"} textColor={"black"} />
      <CarBuyingShortMainArea
        handleCompareProductClick={handleCompareProductClick}
      />
      <Footer />
      {images.length > 0 && (
        <div
          className="compare-dialog"
          style={{ height: !compareMenu ? "10px" : "200px" }}
        >
          <div className="inner">
            <div className="header" onClick={handleCompareMenuClick}>
              <h4>Compare</h4>
            </div>
          </div>
          {compareMenu && (
            <div className="compare-products">
              <div className="images-section">
                {images?.map((image, i) => (
                  <div className="product">
                    <img
                      src={`${cardRoute}/${image}`}
                      alt="Product"
                      loading="lazy"
                    />
                    <span onClick={() => handleDeleteClick(image)}>
                      <DeleteOutlineIcon />
                    </span>
                  </div>
                ))}
                <div
                  className="product1"
                  style={{ border: "4px dashed black" }}
                >
                  <AddIcon />
                </div>
              </div>
              {images.length > 1 && (
                <button onClick={() => handleCompareClick()}>
                  Compare them
                </button>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CarBuyingShortPage;
