import "./CarBuyingShortMainArea.scss";

import VerticalProductCard from "../VerticalProductCard/VerticalProductCard";

import MenuIcon from "@mui/icons-material/Menu";
import { BiMenu } from "react-icons/bi";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import {
  AiOutlineSortDescending,
  AiOutlineSortAscending,
  AiOutlineCalendar,
} from "react-icons/ai";
import FilterWithSlider from "../FilterWithSlider/FilterWithSlider";
import axios from "axios";
import {
  bookmarkRoute,
  filterRoute,
  userBookmarkListing,
} from "../../utils/ApiRoutes";
import { useState, useEffect, useCallback } from "react";
import { Pagination } from "@mui/material";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/ToastOptions";
import { useAuth } from "../../context/auth";
import HorizontalProductCard from "../HorizontalProductCard/HorizontalProductCard";
import { useSearchParams } from "react-router-dom";
import { handleScrollToTop } from "../../utils/ReuseFunction";
import moment from "moment";
const CarBuyingShortMainArea = ({ handleCompareProductClick }) => {
  const [auth] = useAuth();
  const [searchParams] = useSearchParams();
  const [products, setProducts] = useState([]);
  const [bookmarkedProduct, setBookmarkedProduct] = useState([]);
  const [gridView, setGridView] = useState(true);
  const [isLoading, setisLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [currentPageProducts, setCurrentPageProducts] = useState([]);
  const [filterRange, setFilterRange] = useState({
    max: 100000,
    min: 10000,
  });
  const [filters, setFilters] = useState({
    brand_id: searchParams.get("brand") ? searchParams.get("brand") : 0,
    model_id: 0,
    min_year: 1950,
    max_year: new Date().getFullYear() + 1,
    country_id: 0,
    city_id: 0,
    location_id: 0,
    fuel_type: 0,
    body_condition: 0,
    mechanical_condition: 0,
    warranty: 0,
    cylinders: 0,
    transmission_type: 0,
    body_type: 0,
    horsepower: 0,
    steering_side: 0,
    trim: 0,
    min_price: 0,
    max_price: 0,
    color_id: 0,
    insured: 0,
    door_id: 0,
    purpose: 0,
    type: 0,
    count: 0,
    orderby: "desc",
    order_column: "product.product_id",
    startdate: 0,
    enddate: 0,
    uploaded_by:0,
  });

  const getData = useCallback(async () => {
    if (auth.user && auth.userType === 2) {
      const { data } = await axios.post(userBookmarkListing, {
        user_id: auth.user?.user_id,
      });
      setBookmarkedProduct(data?.Product);
    }
  }, [auth.user, auth.userType]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    setisLoading(true);
    const itemsPerPage = 12; // Number of items to display per page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentPageItems = [...products.slice(startIndex, endIndex)];
    setCurrentPageProducts(currentPageItems);
    setisLoading(false);
    handleScrollToTop();
  }, [currentPage, products]);

  const handleMenuClick = () => {
    document.getElementById("menu").style.display = "block";
  };

  useEffect(() => {
    filterFunction();
    // eslint-disable-next-line
  }, [filters]);

  const filterFunction = useCallback(async () => {
    try {
      setisLoading(true);
      const response = await axios.post(filterRoute, filters);
      setProducts(response.data.Products);
      const itemsPerPage = 12; // Number of items to display per page
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const currentPageItems = [
        ...response.data.Products?.slice(startIndex , endIndex),
      ];
      setCurrentPageProducts(currentPageItems);
      setFilterRange({
        max: response.data.Max[0].price,
        min: response.data.Min[0].price,
      });
    } catch (error) {
      console.error(error.message);
    } finally {
      setisLoading(false);
    }
  }, [currentPage, filters]);

  const handleBookmarkClick = useCallback(
    async (url, product_id) => {
      if (auth.user && auth.userType === 2) {
        const index = bookmarkedProduct.findIndex(
          (data) => data.product_id === product_id
        );
        if (index === -1) {
          setBookmarkedProduct((prev) => [...prev, { product_id }]);
        } else {
          setBookmarkedProduct((prev) =>
            prev.slice(0, index).concat(prev.slice(index + 1))
          );
        }
        await axios.post(bookmarkRoute, {
          user_id: auth.user?.user_id,
          url,
        });
      } else if (auth.user && auth.userType === 1) {
        return;
      } else {
        toast.error("login to bookmark the product", toastOptions);
      }
    },
    [auth.user, auth.userType, bookmarkedProduct]
  );

  return (
    <div className="car-buying-short-main">
      <div>
        <FilterWithSlider
          color={"#ed5565"}
          filters={filters}
          setFilters={setFilters}
          filterRange={filterRange}
        />
      </div>
      <div className="right-area">
        <div className="menu-bar" onClick={handleMenuClick}>
          <MenuIcon />
        </div>
        <div className="section-header">
          <h1>Buy Car’s</h1>
          {/* <h2>Ford Bronco - Al Quoz, Dubai </h2> */}
        </div>
        <div className="results">
          <p>
            {products?.length} results • {moment().format("DD/MM/YYYY")}
          </p>
          <div className="filter filter-justify-between">
            <div className="filter">
              <button
                className="filter-pill"
                onClick={() => setGridView((prev) => !prev)}
              >
                {!gridView ? (
                  <>
                    <BsFillGrid3X3GapFill /> Grid view
                  </>
                ) : (
                  <>
                    <BiMenu /> Long view
                  </>
                )}
              </button>
              <button
                className="filter-pill"
                onClick={() =>
                  setFilters((prev) => ({
                    ...prev,
                    orderby: prev.orderby === "desc" ? "asc" : "desc",
                  }))
                }
              >
                {filters.orderby === "asc" ? (
                  <>
                    <AiOutlineSortAscending /> Oldest
                  </>
                ) : (
                  <>
                    <AiOutlineSortDescending /> Newest
                  </>
                )}
              </button>
              <button
                className={
                  filters.order_column === "model_year" ? "active" : ""
                }
                onClick={() =>
                  setFilters((prev) => ({
                    ...prev,
                    order_column:
                      prev.order_column === "model_year"
                        ? "product.product_id"
                        : "model_year",
                  }))
                }
              >
                {" "}
                <AiOutlineCalendar /> Model Year
              </button>
            </div>
            <div className="filter-div">
              <span className="filter-span">
                <label htmlFor="startdate">Start Date : </label>
                <input
                  type="date"
                  name="startdate"
                  id="startdate"
                  value={filters.startdate}
                  onChange={(e) =>
                    setFilters((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                      count:
                        prev[e.target.name] === 0 ? ++prev.count : prev.count,
                    }))
                  }
                />
              </span>
              <span className="filter-span">
                <label htmlFor="enddate">End Date : </label>
                <input
                  type="date"
                  name="enddate"
                  id="enddate"
                  value={filters.enddate}
                  onChange={(e) =>
                    setFilters((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                      count:
                        prev[e.target.name] === 0 ? ++prev.count : prev.count,
                    }))
                  }
                />
              </span>
            </div>
          </div>
        </div>
        {!isLoading && (
          <div
            className="products-section"
            style={{
              gridTemplateColumns: gridView ? "repeat(3, auto)" : "auto",
            }}
          >
            {!gridView &&
              currentPageProducts?.map((product, i) => (
                <HorizontalProductCard
                  key={i}
                  product={product}
                  ad={false}
                  handleBookmarkClick={handleBookmarkClick}
                  handleCompareProductClick={handleCompareProductClick}
                  bookmarkedProduct={bookmarkedProduct}
                  setisLoading={setisLoading}
                  isLoading={isLoading}
                />
              ))}
            {gridView &&
              currentPageProducts?.map((product, i) => (
                <VerticalProductCard
                  key={i}
                  product={product}
                  ad={false}
                  handleBookmarkClick={handleBookmarkClick}
                  handleCompareProductClick={handleCompareProductClick}
                  bookmarkedProduct={bookmarkedProduct}
                  compare={true}
                  bookmark={true}
                />
              ))}
          </div>
        )}
        {isLoading && (
          <div className="center">
            <div className="product-loader"></div>
          </div>
        )}
        <div className="pagination-center">
          {Math.ceil(products?.length / 12) > 1 && (
            <Pagination
              variant="outlined"
              page={currentPage}
              onChange={handlePageChange}
              count={
                products?.length !== 0 ? Math.ceil(products?.length / 12) : 0
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CarBuyingShortMainArea;
