import React, {  useState, useContext } from "react";

import "./VendorSIgnup.scss";
import "react-toastify/dist/ReactToastify.css";

import { dataContext } from "../../App";
import {
  addVendorRoute,
  getCitiesRoute,
  getlocationRoute,
} from "../../utils/ApiRoutes";
import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import { useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import axios from "axios";
import {  toast } from "react-toastify";
import { toastOptions } from "../../utils/ToastOptions";
import { Helmet } from "react-helmet";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Dropdown } from "primereact/dropdown";

const VendorSIgnup = () => {
  // Navigation Hook
  const navigate = useNavigate();
  // destructuring Context variable
  const { setVendorOTP, setVendorCode ,countries } = useContext(dataContext);
  // States
  const [selectedCountry, setSelectedCountry] = useState({});
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [suggestion, setSuggestion] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [city_id, setCity_id] = useState("");
  const [location_id, setLocation_id] = useState("");
  const [country_id, setCountry_id] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false)
  const [cPasswordShow, setCPasswordShow] = useState(false)
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    b_name: "",
    website: "",
    b_email: "",
    b_phone: "",
    trade_no: "",
    b_address: "",
    password: "",
    cPassword: "",
  });
  // destructuring state
  const {
    name,
    email,
    phone,
    address,
    b_name,
    website,
    b_email,
    b_phone,
    trade_no,
    b_address,
    password,
    cPassword,
  } = formData;

  const formValidate = () => {
    if (password !== cPassword) {
      toast.error("password and confirm password must be same", toastOptions);
      return false;
    }
    if (
      !/[a-z]/.test(password) ||
      !/[A-Z]/.test(password) ||
      !/\d/.test(password) ||
      !/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>?]/.test(password) ||
      password.length < 8
    ) {
      toast.error(
        "Password must contain 8 character with uppercase ,lowercase ,number and special Character.",
        toastOptions
      );
      return false;
    }
    if (name === "" || name.length < 3) {
      toast.error(
        "Name is required & must be greater than 3 character",
        toastOptions
      );
      return false;
    }
    if (email === "") {
      toast.error("Email is required ", toastOptions);
      return false;
    }
    if (phone === "") {
      toast.error("Phone number is required ", toastOptions);
      return false;
    }
    if (address === "") {
      toast.error("Address is required ", toastOptions);
      return false;
    }
    if (b_name === "") {
      toast.error("Business name is required ", toastOptions);
      return false;
    }
    if (b_email === "") {
      toast.error("Business email is required ", toastOptions);
      return false;
    }
    if (b_phone === "") {
      toast.error("Business Phone is required ", toastOptions);
      return false;
    }
    if (trade_no === "") {
      toast.error("Trade license no is required ", toastOptions);
      return false;
    }
    if (b_address === "") {
      toast.error("Business Address is required ", toastOptions);
      return false;
    }
    if (city_id === "") {
      toast.error("City is required ", toastOptions);
      return false;
    }
    if (location_id === "") {
      toast.error("Location is required ", toastOptions);
      return false;
    }

    return true;
  };
  // handle input change Functions
  const handleFormChange = (e) => {
    const { id, value ,type} = e.target;
    if (type === "number") {
      if (!isNaN(value) && parseFloat(value) >= 0) {
        setFormData({ ...formData, [id]: value });
      }
    } else {
      setFormData({ ...formData, [id]: value });
    }
    
  };
  const handleSelectChange = async (e) => {
    setSelectedCountry(e.value)
    setCountry_id(e.value.country_id);
    const response = await axios.get(`${getCitiesRoute}/${e.value.country_id}`);
    setCities(response.data.city);
    document.getElementById("city_id").removeAttribute("disabled");
  };
  // handle Next Change
  const handleNextClick = async (e) => {
    e.preventDefault();
    e.target.setAttribute("disabled", true);
    if (formValidate()) {
      setIsLoading(true);
      const response = await axios.post(addVendorRoute, {
        name,
        phone,
        email,
        address,
        b_name,
        website,
        b_email,
        b_phone,
        trade_no,
        b_address,
        location_id,
        city_id,
        country_id,
        password,
      });

      const { error, code, otp } = response.data;
      if (error === false) {
        setVendorCode(code);
        setVendorOTP(otp);
        localStorage.removeItem("vendorCode");
        localStorage.setItem("vendorCode", code);
        setIsLoading(false);
        navigate("/dashboard/vender-otp");
      } else if (error === true) {
        setIsLoading(false);
        toast.error("Email is already taken", toastOptions);
      } else {
        setIsLoading(false);
        toast.error("something went wrong", toastOptions);
      }
    }
    e.target.removeAttribute("disabled");
  };

 

  // handling Change for Cities
  const handleChange = async (event) => {
    setSelectedCity(event.target.value);
    const selected_City = cities.filter((city) => {
      return city.name === event.target.value;
    });
    const response = await axios.get(
      `${getlocationRoute}/${selected_City[0].city_id}`
    );
    setSuggestion(response.data.location);
    setCity_id(selected_City[0].city_id);
  };

  // handling Change for location
  const handleSuggetionChange = (e) => {
    setSelectedLocation(e.target.value);
    const selected_Location = suggestion.filter((location) => {
      return location.name === e.target.value;
    });
    setLocation_id(selected_Location[0].location_id);
  };

  const handleBackClick = (e, path) => {
    e.preventDefault();
    navigate(path);
  };

  const handleCheckboxChange = (e) => {
    if (e.target.checked && e.target.id === "bEmail") {
      setFormData({ ...formData, b_email: formData.email });
    } else if (!e.target.checked && e.target.id === "bEmail") {
      setFormData({ ...formData, b_email: "" });
    }
    if (e.target.checked && e.target.id === "bPhone") {
      setFormData({ ...formData, b_phone: formData.phone });
    } else if (!e.target.checked && e.target.id === "bPhone") {
      setFormData({ ...formData, b_phone: "" });
    }
  };

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center justify-space-between">
          <div>{option.name}</div>
          <img
            alt={option.name}
            src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
            className={`mr-2 flag flag-${option.country_code.toLowerCase()}`}
            style={{ width: "32px" }}
          />
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center justify-space-between">
        <div>{option.name}</div>
        <img
          alt={option.name}
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`mr-2 flag flag-${option.country_code.toLowerCase()}`}
          style={{ width: "32px" }}
        />
      </div>
    );
  };



  return (
    <>
      <Helmet>
        <title>Vendor Signup | CARS'R'US</title>
      </Helmet>
      <div className="vender-signup">
        {!isLoading && (
          <div className="signup-form">
            <div className="stepper">
              <Stepper
                activeStep={0}
                alternativeLabel
                sx={{ paddingTop: "2rem" }}
              >
                <Step>
                  <StepLabel></StepLabel>
                </Step>
                <Step>
                  <StepLabel></StepLabel>
                </Step>
                <Step>
                  <StepLabel></StepLabel>
                </Step>
                <Step>
                  <StepLabel></StepLabel>
                </Step>
              </Stepper>
            </div>
            <h1>Sign Up</h1>
            <form>
              <h1>Personal Details</h1>
              <div className="section">
                <input
                  type="text"
                  placeholder="Merchant name "
                  value={name}
                  onChange={(e) => handleFormChange(e)}
                  id="name"
                />

                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  id="email"
                  onChange={(e) => handleFormChange(e)}
                />
              </div>
              <div className="section">
                <input
                  type="number"
                  placeholder="Contact Number"
                  value={phone}
                  name="phone"
                  onChange={(e) => handleFormChange(e)}
                  id="phone"
                  min={"0"}
                />
                <textarea
                  id="address"
                  cols="30"
                  rows="1"
                  placeholder="Address"
                  value={address}
                  name="address"
                  onChange={(e) => handleFormChange(e)}
                ></textarea>
              </div>
              <div className="section">
                <div style={{width:"100%" ,position:"relative"}}>
                <input
                  type={!cPasswordShow ? "password" : "text"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => handleFormChange(e)}
                  id="password"
                />
                <span className="icon" onClick={()=> setCPasswordShow(prev => !prev)}>
                {!cPasswordShow ? <AiOutlineEye  /> : <AiOutlineEyeInvisible />}
              </span>
                </div>
                <div style={{width:"100%", position:"relative"}}>
                <input
                  type={!passwordShow ? "password"  : "text"}
                  placeholder="Confrim Password"
                  value={cPassword}
                  id="cPassword"
                  onChange={(e) => handleFormChange(e)}
                />
                <span className="icon" onClick={()=> setPasswordShow(prev => !prev)}>
                {!passwordShow ? <AiOutlineEye  /> : <AiOutlineEyeInvisible />}
              </span>
                </div>
              </div>
              <h1>Business Details</h1>
              <div className="section">
                <input
                  type="text"
                  placeholder="Business Name"
                  value={b_name}
                  id="b_name"
                  onChange={(e) => handleFormChange(e)}
                />
                <input
                  type="text"
                  placeholder="Website (if any)"
                  value={website}
                  id="website"
                  onChange={(e) => handleFormChange(e)}
                />
              </div>
              <div className="section">
                <div className="sub-section">
                  <input
                    type="email"
                    placeholder="Business Email"
                    value={b_email}
                    id="b_email"
                    onChange={(e) => handleFormChange(e)}
                  />
                  <label htmlFor="bEmail">
                    <input
                      type="checkbox"
                      id="bEmail"
                      onChange={(e) => handleCheckboxChange(e)}
                    />{" "}
                    Same as Personal Email
                  </label>
                </div>
                <div className="sub-section">
                  <input
                    type="tel"
                    placeholder="Business Number"
                    value={b_phone}
                    id="b_phone"
                    onChange={(e) => handleFormChange(e)}
                  />
                  <label htmlFor="bPhone">
                    <input
                      type="checkbox"
                      id="bPhone"
                      onChange={(e) => handleCheckboxChange(e)}
                    />{" "}
                    Same as Personal Number
                  </label>
                </div>
              </div>
              <div className="section">
                <textarea
                  id="b_address"
                  cols="30"
                  rows="1"
                  placeholder="Business Address"
                  onChange={(e) => handleFormChange(e)}
                ></textarea>
              </div>
              <div className="section">
                <input
                  type="text"
                  placeholder="Trade License no."
                  value={trade_no}
                  id="trade_no"
                  onChange={(e) => handleFormChange(e)}
                />
                <div className="drop-down country_vendor">
                  <Dropdown
                      value={selectedCountry}
                      onChange={(e) => handleSelectChange(e)}
                      options={countries}
                      optionLabel="name"
                      placeholder="Select a Country"
                      filter
                      valueTemplate={selectedCountryTemplate}
                      itemTemplate={countryOptionTemplate}
                      className="w-full md:w-14rem"
                    />
                </div>
              </div>
              <div className="section">
                <div className="drop-down">
                  <InputLabel id="cities" sx={{ fontSize: "14px" }}>
                    City
                  </InputLabel>
                  <Select
                    labelId="cities"
                    id="city"
                    value={selectedCity}
                    label="City"
                    onChange={handleChange}
                    sx={{
                      width: "100%",
                      height: "40px",
                      background: "#D9D9D9",
                      borderRadius: "11.7039px",
                    }}
                  >
                    {cities.map((city) => (
                      <MenuItem key={city.name} value={city.name}>
                        {city.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="drop-down">
                  <InputLabel id="location" sx={{ fontSize: "14px" }}>
                    Location
                  </InputLabel>
                  <Select
                    labelId="location"
                    id="location"
                    value={selectedLocation}
                    label="Location"
                    onChange={handleSuggetionChange}
                    sx={{
                      width: "100%",
                      height: "40px",
                      background: "#D9D9D9",
                      borderRadius: "11.7039px",
                    }}
                  >
                    {suggestion.map((location) => (
                      <MenuItem key={location.name} value={location.name}>
                        {location.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="btn-section">
                <button onClick={(e) => handleBackClick(e, "/dashboard")}>
                  Back
                </button>
                <button onClick={(e) => handleNextClick(e)}>Next</button>
              </div>
            </form>
          </div>
        )}
        {isLoading && <div class="vendor-loader"></div>}
      </div>
    </>
  );
};

export default VendorSIgnup;
