import React from 'react'

import "./WorkshopCard.scss"
import { Link } from 'react-router-dom'


const WorkshopCard = ({count, service , img , id }) => {
  return (
    <div className='workshop-card'>
      <div className='count'>{count}</div>
      <div className='image-section'>
        <img src={img} alt="" />
      </div>
      <div className='card-name'>{service?.length > 20 ? `${service.slice(0,30)}...`  :  service}</div>
      <Link to={`/merchant-search/workshop/${id}`} className='find-now-btn'>Find Now</Link>
    </div>
  )
}

export default WorkshopCard