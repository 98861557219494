import React,{useState} from 'react'

import RightNavigation from '../components/RightNavigation/RightNavigation'
import TopNavigationUserProfile from '../components/TopNavigationUserProfile/TopNavigationUserProfile'

import "./LayoutStyle.scss"
const ProfileLayout = ({children}) => {


  const [display, setDisplay] = useState(window.innerWidth<800 ? false : true)
  
  const  handleMenuClick = () => {
    setDisplay((prev) => !prev)
  }

  return (
    <div className="flex background-light">
        <div>
            <RightNavigation display={display} setDisplay={setDisplay}/>
        </div>
        <div className='flex flex-column ml-3 width-md hieght-100'>
            <div>
                <TopNavigationUserProfile handleMenuClick={handleMenuClick}/>
            </div>
            {children}
        </div>
    </div>
  )
}

export default ProfileLayout