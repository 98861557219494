import React, { useState, useContext } from "react";

import { useNavigate } from "react-router-dom";
import { AutoComplete } from "primereact/autocomplete";
import { dataContext } from "../../../App";
import "./ColorHeader.scss";

import ColorNavbar from "../../ColorNavbar/ColorNavbar";

const ColorHeader = ({ background, slogan }) => {
  const navigate = useNavigate();
  const { parts } = useContext(dataContext);
  const [searchTerms, setSearchTerms] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const search = (event) => {
    const filteredItems = parts.filter((item) => {
      const itemName = item.name.toLowerCase();
      const lowerCaseSearchTerm = event.query.toLowerCase();
      return itemName.includes(lowerCaseSearchTerm);
    });
    const itemNames = filteredItems.map((item) => item.name);
    setSearchTerms(itemNames.length === 0 ? [] : itemNames);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = parts.filter((item) => {
      const itemName = item.name.toLowerCase();
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      return itemName === lowerCaseSearchTerm;
    });
    // console.log(data);
    navigate(`/merchant-search/parts/${data[0].parts_type_id}`);
  };

  return (
    <div className="color-header">
      <ColorNavbar color={"#6577C3"} textColor={"black"} />
      <div
        className="hero-section"
        style={{ background: `url(${background}) no-repeat center #000` }}
      >
        <div className="vertical-slogan">{slogan}</div>
        <div className="search-bar">
          <div className="main-search-container">
            <form className="main-search-bar" onSubmit={(e) => handleSubmit(e)}>
              <AutoComplete
                placeholder="Search parts vendor on CARS'R'US"
                value={searchTerm}
                suggestions={searchTerms}
                completeMethod={search}
                onChange={(e) => setSearchTerm(e.value)}
                forceSelection
                inputId="model_year"
              />
              <button type="submit">Search</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColorHeader;
