import React, { useState } from "react";

import "./VendorServicePage.scss";

import { toastOptions } from "../../utils/ToastOptions";
import { UpdateVendorService } from "../../utils/ApiRoutes";

import CarRentalIcon from "@mui/icons-material/CarRental";

import Car from "../../assets/icons/car-svgrepo.svg";
import CarTireChange from "../../assets/icons/car-tire-change.svg";
import PeopleInCar from "../../assets/icons/people-in-car-svg.svg";
import ScrewdriverAndWrench from "../../assets/icons/screwdriver-and-wrench.svg";
import ImportExport from "../../assets/icons/import-export1.svg";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import { useAuth } from "../../context/auth";
import { Helmet } from "react-helmet";
const VendorServicePage = () => {
  // Navigation Hook
  const navigate = useNavigate();
  // destructuring Context variable
  // const {vendorCode} = useContext(dataContext)
  const [auth] = useAuth();
  // States
  const [selectedId, setSelectedId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // handle click Funtion
  const handleClick = (id) => {
    setSelectedId(id);
  };
  // handle Back Click Function
  const handleBackClick = (e) => {
    e.preventDefault();
    navigate("/dashboard/vender-signup");
  };
  // handle Next Click function
  const handleNextClick = async (e) => {
    e.preventDefault();
    e.target.setAttribute("disabled", true);
    setIsLoading(true);
    if (selectedId !== null) {
      const response = await axios.post(UpdateVendorService, {
        code: auth.user?.code,
        service: selectedId,
      });
      const { error } = response.data;
      if (error === false) {
        setIsLoading(false);
        localStorage.setItem("serviceId", selectedId);
        switch (selectedId) {
          case 1:
            navigate("/dashboard/vender-add-cars");
            break;
          case 2:
            navigate("/dashboard/vender-brands");
            break;
          case 3:
            navigate("/dashboard/vender-brands");
            break;
          case 4:
            navigate("/dashboard/vender-add-cars");
            break;
          case 5:
            navigate("/dashboard/vender-brands");
            break;
          case 6:
            navigate("/dashboard/vender-add-cars");
            break;

          default:
            break;
        }
      } else {
        setIsLoading(false);
        toast.error("Something went Wrong please Select again", toastOptions);
      }
    } else {
      toast.error("Please select a Service", toastOptions);
    }
    e.target.removeAttribute("disabled");
  };
  return (
    <>
      <Helmet>
        <title>Services | CARS'R'US</title>
      </Helmet>
      <div className="vendor-service">
        {!isLoading && (
          <div className="services-section">
            <div className="stepper">
              <Stepper
                activeStep={1}
                alternativeLabel
                sx={{ paddingTop: "2rem" }}
              >
                <Step>
                  <StepLabel></StepLabel>
                </Step>
                <Step>
                  <StepLabel></StepLabel>
                </Step>
                <Step>
                  <StepLabel></StepLabel>
                </Step>
                <Step>
                  <StepLabel></StepLabel>
                </Step>
              </Stepper>
            </div>
            <h4>Choose/Highlights Service and show what makes you unique</h4>
            <div className="services">
              <div
                className="card"
                id="card1"
                value={1}
                onClick={() => handleClick(1)}
                style={{
                  background: selectedId === 1 ? "#FDDFA7" : "",
                  borderColor: selectedId === 1 ? "#0070CC" : "",
                }}
              >
                <img src={Car} alt="" />
                <p>Buying or Selling Cars</p>
              </div>
              <div
                className="card"
                id="card2"
                value={2}
                onClick={() => handleClick(2)}
                style={{
                  background: selectedId === 2 ? "#FDDFA7" : "",
                  borderColor: selectedId === 2 ? "#0070CC" : "",
                }}
              >
                <img src={CarTireChange} alt="" />
                <p>Car Service Repair / Maintain</p>
              </div>
              <div
                className="card"
                id="card3"
                value={3}
                onClick={() => handleClick(3)}
                style={{
                  background: selectedId === 3 ? "#FDDFA7" : "",
                  borderColor: selectedId === 3 ? "#0070CC" : "",
                }}
              >
                <img src={ScrewdriverAndWrench} alt="" />
                <p>Parts</p>
              </div>
              <div
                className="card"
                id="card4"
                value={4}
                onClick={() => handleClick(4)}
                style={{
                  background: selectedId === 4 ? "#FDDFA7" : "",
                  borderColor: selectedId === 4 ? "#0070CC" : "",
                }}
              >
                <img src={PeopleInCar} alt="" />
                <p>Car Pool</p>
              </div>
              <div
                className="card"
                id="card5"
                value={5}
                onClick={() => handleClick(5)}
                style={{
                  background: selectedId === 5 ? "#FDDFA7" : "",
                  borderColor: selectedId === 5 ? "#0070CC" : "",
                }}
              >
                <img src={ImportExport} alt="" />
                <p>Importing or Exporting</p>
              </div>
              <div
                className="card"
                id="card6"
                value={6}
                onClick={() => handleClick(6)}
                style={{
                  background: selectedId === 6 ? "#FDDFA7" : "",
                  borderColor: selectedId === 6 ? "#0070CC" : "",
                }}
              >
                <CarRentalIcon />
                <p>Renting</p>
              </div>
              {/* <div
              className="card"
              id="card7"
              value={7}
              onClick={() => handleClick(7)}
            >
              <MoreHorizIcon />
              <p>Others</p>
            </div> */}
            </div>
            <div className="btn-section">
              <button onClick={(e) => handleBackClick(e)}>Back</button>
              <button onClick={(e) => handleNextClick(e)}>Next</button>
            </div>
          </div>
        )}
        {isLoading && <div className="vendor-loader"></div>}
      </div>
      <ToastContainer />
    </>
  );
};

export default VendorServicePage;
