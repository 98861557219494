import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import "./LiveSearchDetailedMain.scss";
import Welcome from "../../assets/images/welcome.png";
import Avatar from "../../assets/images/avatar.jpg";
import {
  InsertChatRequest,
  getChatMessage,
  insertChatMessage,
  profileImageRoute,
  searchRoute,
  userProfilePictureRoute,
  vendorMessageImageRoute,
} from "../../utils/ApiRoutes";
import SendIcon from "@mui/icons-material/Send";
import { useAuth } from "../../context/auth";
import { Rating } from "@mui/material";
import { dataContext } from "../../App";
import axios from "axios";

const LiveSearchDetailedMain = () => {
  const { maintenance, subMaintenance, parts } = useContext(dataContext);

  const timeoutRef = useRef();

  const [auth] = useAuth();
  const [loading] = useState(false);
  const [active, setActive] = useState(false);
  const [search, setSearch] = useState("");
  const [message, setMessage] = useState("");
  const [vendor, setVendor] = useState([]);
  const [messages, setMessages] = useState([]);
  const location = useLocation();
  const [vendors, setVendors] = useState(location.state.vendors);
  const [filteredVendors, setFilteredVendors] = useState(
    location.state.vendors
  );
  const [vendorStatus, setVendorStatus] = useState(location.state.vendorStatus);
  const [code] = useState(location.state.code);
  const [file] = useState(location.state.file);
  const [searchTerm] = useState(location.state.searchTerm);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearch(value);

    let filteredChat = vendors?.map((item)=> item.filter((temp) => {
        const data = temp.name?.toLowerCase();
        return data.includes(value.toLowerCase());
      }))
    setFilteredVendors(filteredChat);
  };
  useEffect(() => {
    timeoutRef.current = setInterval(function () {
      getMessages(vendor?.vendor_id);
      getVendors(code);
    }, 7000);
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
    // eslint-disable-next-line
  }, [active, code]);

  const getVendors = async (searchCode) => {
    if (searchCode) {
      const response = await axios.get(`${searchRoute}/${searchCode}`);
      setVendors(response.data.Vendor);
      setFilteredVendors(response.data.Vendor);
      setVendorStatus(response.data.status);
    } else {
      return;
    }
  };
  const getMessages = async (id) => {
    if (active === false) {
      return;
    } else {
      const { data } = await axios.post(getChatMessage, {
        key_1: `${auth.user.user_id} users`,
        key_2: `${id} vendor`,
        type: 1,
      });
      if (data?.Chat?.length !== messages?.length) {
        setMessages(data?.Chat);
      }
    }
  };

  const handleChatClick = async (id) => {
    const temp = vendorStatus.filter((item) => item?.vendor_id === id);
    if (temp[0].status === 1) {
      const { data } = await axios.post(getChatMessage, {
        key_1: `${auth.user.user_id} users`,
        key_2: `${id} vendor`,
        type: 1,
      });
      setMessages(data?.Chat);
      setVendor({ ...data?.Key2[0], type: 1 });
      setActive(true);
    }
  };

  const handleMessageSubmit = async (e, vendor_id) => {
    e.preventDefault();
    const currentDate = new Date();
    const temp = message;
    const messageData = {
      date: currentDate.toDateString(),
      message: temp,
      time: currentDate.getTime(),
      sender_id: auth.user?.user_id,
      receiver_id: vendor_id,
      receiver: "vendor",
      sender: "users",
      file: null,
      type: 1,
    };
    setMessages((prev) => [...prev, messageData]);
    setMessage("");
    const { data } = await axios.post(insertChatMessage, {
      sender_id: auth.user.user_id,
      receiver_id: vendor_id,
      message: temp,
      sender: "users",
      receiver: "vendor",
      type: 1,
    });
    console.log(data);
  };

  const currentUserType = useMemo(
    () => (auth.userType === 2 ? "users" : "vendor"),
    [auth.userType]
  );

  const handleRequestClick = async (e, vendor_id) => {
    if (e.target.innerText !== "Requested") {
      e.target.innerText = "Requested";
      const temp = [
        ...maintenance,
        ...subMaintenance,
        // ...subParts,
        ...parts,
      ].filter((item) => item.name === searchTerm.service);
      const formData = new FormData();
      formData.append("search_code", sessionStorage.getItem("searchCode"));
      formData.append("vendor_id", vendor_id);
      formData.append("user_id", auth.user?.user_id);
      formData.append(
        "search",
        `Customer ${auth.user.name} requested ${temp[0].name} for ${searchTerm.brand_id} ${searchTerm.model_id} ${searchTerm.model_year}`
      );
      formData.append("image", file.file);
      formData.append("has_image", file.has_image);

      await axios.post(InsertChatRequest, formData);
    } else {
      return;
    }
  };

  return (
    <div className="livaSearchDetailedMain">
      <div className="chat-area-main">
        {!loading && (
          <div className="chats-lists">
            <div
              className="search-box"
              style={{ paddingBottom: "1rem", paddingTop: ".4rem" }}
            >
              <input
                type="text"
                placeholder="Search Vendor by name..."
                value={search}
                onChange={handleSearchChange}
              />
            </div>
            {vendors?.length > 0 && (
              <div className="chat-list">
                {filteredVendors?.map((item) =>
                  item?.map((vendor) => (
                    <div className="chat-card" key={vendor.vendor_id}>
                      <a
                        href={`https://staging-carus.digitalelliptical.com/merchant/${vendor.url}`}
                        target="_blank"
                        rel="noreferrer"
                        className="profile-image"
                      >
                        <img
                          src={
                            vendor?.profile
                              ? `${profileImageRoute}/${vendor?.profile}`
                              : Avatar
                          }
                          alt="profile"
                        />
                      </a>
                      <div
                        className="chat-details"
                        onClick={() => handleChatClick(vendor.vendor_id)}
                      >
                        <div className="top">
                          <h4>{vendor.name}</h4>
                          <Rating value={Number(vendor?.rating)} readOnly />
                          {/* {vendorLastMessages
                            .filter(
                              (item) => item[0]?.vendor_id === vendor?.vendor_id
                            )
                            .map((item) => <p>{item[0]?.message}</p>) || null} */}
                        </div>
                        <div className="bottom" style={{ display: "flex" }}>
                          {vendorStatus
                            ?.filter(
                              (item) => item?.vendor_id === vendor?.vendor_id
                            )
                            .map((item) => (
                              <>
                                {item.status !== 0 && (
                                  <button
                                    className={
                                      Number(item.status) === 1
                                        ? "accept"
                                        : "pending"
                                    }
                                  >
                                    {Number(item.status) === 1
                                      ? "Accepted"
                                      : "Rejected"}
                                  </button>
                                )}
                                {item.status === 0 && (
                                  <button
                                    onClick={(e) =>
                                      handleRequestClick(e, vendor.vendor_id)
                                    }
                                  >
                                    Request
                                  </button>
                                )}
                              </>
                            ))}
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            )}
          </div>
        )}
        {loading && (
          <div
            className="chats-lists"
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <div className="chat-loader"></div>
          </div>
        )}
        {active && (
          <div className="chat-section">
            <div className="top">
              <div className="profile">
                {vendor.type === 1 ? (
                  vendor?.profile ? (
                    <img
                      src={`${profileImageRoute}/${vendor?.profile}`}
                      alt="Profile"
                    />
                  ) : (
                    <img src={Avatar} alt="profile" />
                  )
                ) : vendor?.user_image ? (
                  <img
                    src={`${profileImageRoute}/${vendor?.user_image}`}
                    alt="Profile"
                  />
                ) : (
                  <img src={Avatar} alt="profile" />
                )}
              </div>
              <div className="profile-details">
                <h4>
                  {vendor.type === 1 ? vendor.shop_name : vendor.user_name}
                </h4>
                {/* {vendor.type === 1 && (
                  <p>
                    
                  </p>
                )} */}
              </div>
            </div>
            <div
              className="middle"
              // onScroll={(e) => checkBottom(e)}
            >
              <div className="messages-section">
                {messages?.map((message) => (
                  <React.Fragment>
                    {message.sender_id === auth.user.user_id &&
                    message.sender === currentUserType ? (
                      <div className="sent">
                        <div className="profile">
                          {message?.user_image ? (
                            <img
                              src={`${userProfilePictureRoute}/${message?.user_image}`}
                              alt="Profile"
                            />
                          ) : (
                            <img src={Avatar} alt="Profile" />
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: ".5rem",
                          }}
                        >
                          <div className="message">{message?.message}</div>
                          {message.file !== null && (
                            <div className="message">
                              <img
                                src={`${vendorMessageImageRoute}/${message?.image}`}
                                alt="Search"
                                style={{ width: "100%" }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="reply">
                        <div className="profile">
                          {message?.user_image ? (
                            <img
                              src={`${userProfilePictureRoute}/${message?.user_image}`}
                              alt="Profile"
                            />
                          ) : (
                            <img src={Avatar} alt="Profile" />
                          )}
                        </div>
                        <div className="message">{message?.message}</div>
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>
              <div></div>
            </div>
            <div className="bottom">
              <div className="input">
                <form
                  onSubmit={(e) => handleMessageSubmit(e, vendor?.vendor_id)}
                >
                  <input
                    type="text"
                    name="message"
                    id="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <button type="submit">
                    <SendIcon />
                  </button>
                </form>
              </div>
            </div>
          </div>
        )}
        {!active && (
          <div className="welcome-screen">
            <img src={Welcome} alt="welcome" />
          </div>
        )}
      </div>
    </div>
  );
};

export default LiveSearchDetailedMain;
