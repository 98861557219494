import React, { useState, useContext, useEffect } from "react";
import { dataContext } from "../../App";
import { useNavigate } from "react-router-dom";
import "./PartsMenu.scss";
const PartsMenu = ({ setActive ,top }) => {
  const navigate = useNavigate();
  const [partsSubMenuFilterData, setPartsSubMenuFilterData] = useState([]);
  const [partsId, setPartsId] = useState("");
  const { parts, subParts } = useContext(dataContext);

  const PartsMenuFilter = () => {
    const temp = subParts.filter((data) => data.parts_type_id === partsId);
    setPartsSubMenuFilterData(temp);
  };

  useEffect(() => {
    PartsMenuFilter();
    // eslint-disable-next-line
  }, [partsId]);

  const handlePartsMenuClick = (id) => {
    navigate(`/merchant-search/parts/${id}`);
  };

  return (
    <div className="parts-menu" style={{top}} onMouseLeave={() => setActive("")}>
      <div className="left">
        <div className="menu-item" onMouseEnter={() => setPartsId(-1)}>
          <p style={{ color: "black" }}>NOT Sure Help-Me Choose!</p>
        </div>
        {parts?.map((type, i) => (
          <div
            key={i}
            className="menu-item"
            onMouseEnter={() => setPartsId(type.parts_type_id)}
          >
            <p
              onClick={() =>
                navigate(`/merchant-search/parts/${type.parts_type_id}`)
              }
              style={{ textTransform: "lower", color: "black" }}
            >
              {type.name}
            </p>
          </div>
        ))}
      </div>
      <div className="right">
        <div className="red-bar"></div>
        {partsSubMenuFilterData !== [] &&
          partsSubMenuFilterData.map((data) => (
            <p
              className="menu-item"
              key={data.part_id}
              onClick={() => handlePartsMenuClick(partsId)}
            >
              {data.name}
            </p>
          ))}
        {partsId === -1 && (
          <>
            <p className="menu-item">Vibration</p>
          </>
        )}
        {partsId === "" && <p className="menu-item">No Data to Show</p>}
      </div>
    </div>
  );
};

export default PartsMenu;
