import React from 'react'
import MainHeader from '../../components/Headers/MainHeader/MainHeader'
import HomeMainArea from '../../components/HomeMainArea/HomeMainArea'
import Footer from '../../components/Footer/Footer'
import {Helmet} from "react-helmet"
const Home = () => {
  return (
    <div className='Home'>
        <Helmet>
          <title>Home | CARS'R'US</title>
        </Helmet>
        <MainHeader/>
        <HomeMainArea/>
        <Footer/>
    </div>
  )
}

export default Home