import React from "react";
import DashboardLayout from "../../Layouts/DashboardLayout";
import BusinessProfile from "../../components/BusinessProfile/BusinessProfile";
import { Helmet } from "react-helmet";

const DashboardBusinessProfile = () => {
  return (
    <>
      <Helmet>
        <title>Edit Vendor Profile | CARS'R'US</title>
      </Helmet>
      <DashboardLayout>
        <BusinessProfile />
      </DashboardLayout>
    </>
  );
};

export default DashboardBusinessProfile;
