import React from "react";
import CarsHeader from "../../components/CarsHeader/CarsHeader";
import CarBackground from "../../assets/images/cars-background.png";
import Footer from "../../components/Footer/Footer";
import CarsHomeMainArea from "../../components/CarsHomeMainArea/CarsHomeMainArea";
import { Helmet } from "react-helmet";

const CarsHomepage = () => {
  return (
    <>
      <Helmet>
        <title>Cars | CARS'R'US</title>
      </Helmet>
      <CarsHeader background={CarBackground} slogan={"BUY & SELL CARS"} />
      <CarsHomeMainArea />
      <Footer />
    </>
  );
};

export default CarsHomepage;
