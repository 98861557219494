import React, { createContext, useCallback, useEffect, useMemo, useState } from "react";
import "./App.css";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import CarsHomepage from "./pages/CarsHomePages/CarsHomepage";
import Home from "./pages/Home/Home";
import LiveSearchHomePage from "./pages/LiveSearchHomePage/LiveSearchHomePage";
import PartsHomePage from "./pages/PartsHomePage/PartsHomePage";
import WorkshopHome from "./pages/WorkshopHome/WorkshopHome";
import AutoloanPage from "./pages/AutoLoanPage/AutoloanPage";
import InspectionFormPage from "./pages/InspectionFormPage/InspectionFormPage";
import CarBuyingLongPage from "./pages/CarBuyingLongPage/CarBuyingLongPage";
import CarBuyingShortPage from "./pages/CarBuyingShortPage/CarBuyingShortPage";
import Login from "./pages/Login/Login";
import UserSignup from "./pages/UserSignup/UserSignup";
import BlogPage from "./pages/BlogPage/BlogPage";
import ImportAndExportPage from "./pages/ImportAndExportPage/ImportAndExportPage";
import CarBuyingLongWithFilter from "./pages/CarBuyingLongWithFilter/CarBuyingLongWithFilter";
import CarPoolPage from "./pages/CarPoolPage/CarPoolPage";
import OTP from "./pages/OTP/OTP";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ForgotOTP from "./pages/ForgotOTP/ForgotOTP";
import NewPasswordPage from "./pages/NewPasswordPage/NewPasswordPage";
import CarRentingPage from "./pages/CarRentingPage/CarRentingPage";
import SingleProductPage from "./pages/SingleProductPage/SingleProductPage";
import MerchantSearchPage from "./pages/MerchantSearchPage/MerchantSearchPage";
import MerchantHomePage from "./pages/MerchantHomePage/MerchantHomePage";
import VendorSIgnup from "./pages/VendorSignup/VendorSIgnup";
import VendorServicePage from "./pages/VendorServicePage/VendorServicePage";
import VendorOTP from "./pages/VendorOTP/VendorOTP";
import VendorLogin from "./pages/VendorLogin/VendorLogin";
import VendorSignupMain from "./pages/VendorSignupMain/VendorSignupMain";
import CarBrandsVendor from "./pages/CarBrandVendor/CarBrandsVendor";
import CarPartsVendor from "./pages/CarPartsVendor/CarPartsVendor";
import IndividualUserSignup from "./pages/IndividualUserSignup.jsx/IndividualUserSignup";
import UserProfile from "./pages/UserProfile/UserProfile";
import UserAd from "./pages/UserAd/UserAd";
import PromoteAd from "./pages/PromoteAd/PromoteAd";
import AdPayment from "./pages/AdPayment/AdPayment";
import MySearch from "./pages/MySearch/MySearch";
import DashboardDataUploading from "./pages/DashboardDataUploading/DashboardDataUploading";
import DashboardBusinessProfile from "./pages/DashboardBusinessProfile/DashboardBusinessProfile";
import DashboardBusinessPromotion from "./pages/DashboardBusinessPromotion/DashboardBusinessPromotion";
import PromotionTopOfSearches from "./pages/PromotionTopOfSearches/PromotionTopOfSearches";
import DashboardBusinessCars from "./pages/DashboardBusinessCars/DashboardBusinessCars";
import VerifyBusiness from "./pages/VerifyBusiness/VerifyBusiness";
import VendorImportExportCountry from "./pages/VendorImportExportCountry/VendorImportExportCountry";
import VendorMaintenance from "./pages/VendorMaintenance/VendorMaintenance";
import AddCarsVendor from "./pages/AddCarsVendor/AddCarsVendor";
import IndividualUserSignupForm from "./pages/IndividualSignupFormPage/IndividualSignupFromPage";
import EditUserProfileDetails from "./pages/EditUserProfileDetails/EditUserProfileDetails";
import IndividualOTP from "./pages/IndividualOTP/IndividualOTP";
import AddServiceVendor from "./pages/AddServiceVendor/AddServiceVendor";
import VendorMyAds from "./pages/VendorMyAds/VendorMyAds";
import VendorForgotPassword from "./pages/VendorForgotPassword/VendorForgotPassword";
import VendorForgotOTP from "./pages/VendorForgotOTP/VendorForgotOTP";
import VendorNewPassword from "./pages/VendorNewPassword/VendorNewPassword";
import BlogDetailPage from "./pages/BlogDetailPage/BlogDetailPage";
import DashboardBusinessReviews from "./pages/DashboardBusinessReviews/DashboardBusinessReviews";
import BusinessProfileMain from "./pages/BusinessProfileMain/BusinessProfileMain";
import UserDashboardChat from "./pages/UserDashboardChat/UserDashboardChat";
import {
  merchantDetailsPage,
  productDetail,
  userAdPaymentRoute,
  vendorProfileEditRoute,
  vendorProfileRoute,
} from "./utils/Routes";
import UserProducts from "./pages/UserProducts/UserProducts";
import VendorDashboardChat from "./pages/VendorDashboardChat/VendorDashboardChat";
import InspectionReport from "./pages/InpectionReport/InspectionReport";
import CarComparisionPage from "./pages/CarComparisionPage/CarComparisionPage";
import UserBookmarks from "./pages/UserBookmarks/UserBookmarks";
import {
  GetBrandRoute,
  getCountry,
  getMaintenanceRoute,
  GetPartsRoute,
  getSubMaintenanceRoute,
  getSubPartsRoute,
} from "./utils/ApiRoutes";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { toastOptions } from "./utils/ToastOptions";
import { useAuth } from "./context/auth";
import CheckoutPage from "./pages/CheckoutPage/CheckoutPage";
import Pricing from "./pages/Pricing/Pricing";
import DashboardBussinessSearch from "./pages/DashboardBussinessSearch/DashboardBussinessSearch";
import DashboardBussinessDealsGifts from "./pages/DashboardBussinessDealsGifts/DashboardBussinessDealsGifts";
import AllResults from "./pages/AllResults/AllResults";
import LiveSearchDetailed from "./pages/LiveSearchDetailed/LiveSearchDetailed";
import UserChangePassword from "./pages/UserChangePassword/UserChangePassword";
import DashboardBussinessChangePassword from "./pages/DashboardBussinessChangePassword/DashboardBussinessChangePassword";



const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/parts",
    element: <PartsHomePage />,
  },
  {
    path: "/cars",
    element: <CarsHomepage />,
  },
  {
    path: "/search",
    element: <LiveSearchHomePage />,
  },
  {
    path: "/search/details",
    element: <LiveSearchDetailed />,
  },
  {
    path: "/workshop",
    element: <WorkshopHome />,
  },
  {
    path: "/loan",
    element: <AutoloanPage />,
  },
  {
    path: "/inspection",
    element: <InspectionFormPage />,
  },
  {
    path: "/inspection-report",
    element: <InspectionReport />,
  },
  {
    path: "/carbuying",
    element: <CarBuyingLongPage />,
  },
  {
    path: "/carshort",
    element: <CarBuyingShortPage />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/usersignup",
    element: <UserSignup />,
  },
  {
    path: "/blog",
    element: <BlogPage />,
  },
  {
    path: "/blog/:url",
    element: <BlogDetailPage />,
  },
  {
    path: "/import",
    element: <ImportAndExportPage />,
  },
  {
    path: "/carlong",
    element: <CarBuyingLongWithFilter />,
  },
  {
    path: "/carpool",
    element: <CarPoolPage />,
  },
  {
    path: "/otp",
    element: <OTP />,
  },
  {
    path: "/individual-otp",
    element: <IndividualOTP />,
  },
  {
    path: "/forgot",
    element: <ForgotPassword />,
  },
  {
    path: "/forgototp",
    element: <ForgotOTP />,
  },
  {
    path: "/newpassword",
    element: <NewPasswordPage />,
  },
  {
    path: "/renting",
    element: <CarRentingPage />,
  },
  {
    path: "/Compare",
    element: <CarComparisionPage />,
  },
  {
    path: `${productDetail}/*`,
    element: <SingleProductPage />,
  },
  {
    path: "/merchant-search/:type/:id",
    element: <MerchantSearchPage />,
  },
  {
    path: `${merchantDetailsPage}/*`,
    element: <MerchantHomePage />,
  },
  {
    path: "/dashboard/vender-signup",
    element: <VendorSIgnup />,
  },
  {
    path: "/dashboard/vender-service",
    element: <VendorServicePage />,
  },
  {
    path: "/dashboard/vender-otp",
    element: <VendorOTP />,
  },
  {
    path: "/dashboard/vender-login",
    element: <VendorLogin />,
  },
  {
    path: "/vender-forgot",
    element: <VendorForgotPassword />,
  },
  {
    path: "/vender-otp",
    element: <VendorForgotOTP />,
  },
  {
    path: "/vender-newpassword",
    element: <VendorNewPassword />,
  },
  {
    path: "/dashboard",
    element: <VendorSignupMain />,
  },
  {
    path: "/dashboard/vender-brands",
    element: <CarBrandsVendor />,
  },
  {
    path: "/dashboard/vender-parts",
    element: <CarPartsVendor />,
  },
  {
    path: "/dashboard/vender-country",
    element: <VendorImportExportCountry />,
  },
  {
    path: "/dashboard/vender-maintenance",
    element: <VendorMaintenance />,
  },
  {
    path: "/dashboard/vender-individual-signup",
    element: <IndividualUserSignup />,
  },
  {
    path: "/dashboard/vender-individual-signup-form",
    element: <IndividualUserSignupForm />,
  },
  {
    path: "/dashboard/vender-add-cars",
    element: <AddCarsVendor />,
  },
  {
    path: "/dashboard/vender-add-services",
    element: <AddServiceVendor />,
  },
  {
    path: "/profile",
    element: <UserProfile />,
  },
  {
    path: "/edit-profile",
    element: <EditUserProfileDetails />,
  },
  {
    path: "/my-ads",
    element: <UserAd />,
  },
  {
    path: "/my-products",
    element: <UserProducts />,
  },
  {
    path: "/my-bookmarks",
    element: <UserBookmarks />,
  },
  {
    path: "/my-chat",
    element: <UserDashboardChat />,
  },
  {
    path: "/my-change-password",
    element: <UserChangePassword />,
  },
  {
    path: "/promote-ad",
    element: <PromoteAd />,
  },
  {
    path: `${userAdPaymentRoute}/:id`,
    element: <AdPayment />,
  },
  {
    path: "/my-search",
    element: <MySearch />,
  },
  {
    path: "/dashboard/upload-data",
    element: <DashboardDataUploading />,
  },
  {
    path: `${vendorProfileRoute}/*`,
    element: <BusinessProfileMain />,
  },
  {
    path: `${vendorProfileEditRoute}/*`,
    element: <DashboardBusinessProfile />,
  },
  {
    path: "/dashboard/my-ads",
    element: <VendorMyAds />,
  },
  {
    path: "/dashboard/change-password",
    element: <DashboardBussinessChangePassword />,
  },
  {
    path: "/dashboard/business-promotion",
    element: <DashboardBusinessPromotion />,
  },
  {
    path: "/dashboard/business-promotion/recommended",
    element: <PromotionTopOfSearches />,
  },
  {
    path: "/dashboard/business-cars",
    element: <DashboardBusinessCars />,
  },
  { path: "/dashboard/business-gifts",
    element:<DashboardBussinessDealsGifts/>
  },
  {
    path: "/dashboard/business-verify",
    element: <VerifyBusiness />,
  },
  {
    path: "/dashboard/business-reviews",
    element: <DashboardBusinessReviews />,
  },
  {
    path: "/dashboard/search-history",
    element: <DashboardBussinessSearch />,
  },
  {
    path: "/dashboard/business-chats",
    element: <VendorDashboardChat />,
  },
  {
    path: "/payment-methods",
    element: <CheckoutPage />,
  },
  {
    path: "/pricing",
    element: <Pricing />,
  },
  {
    path: "/search/result",
    element: <AllResults/>,
  }
]);

export const dataContext = createContext();

function App() {
  const [auth, setAuth] = useAuth();
  const controller = useMemo(() => new AbortController(), [])
  const getData = useCallback(
    async () => {
      setIsLoading(true);
      if (auth.user && Number(auth.user.loginDate) !== new Date().getUTCDate()) {
        setAuth({
          user: null,
          userType: 0,
        });
        localStorage.removeItem("auth");
      }
      try {
        const maintenance = await axios.get(getMaintenanceRoute ,{signal: controller.signal});
        const subMaintenance = await axios.get(getSubMaintenanceRoute ,{signal: controller.signal});
        const Brand = await axios.get(GetBrandRoute ,{signal: controller.signal});
        const Part = await axios.get(GetPartsRoute ,{signal: controller.signal});
        const subParts = await axios.get(getSubPartsRoute ,{signal: controller.signal});
        const countries = await axios.get(getCountry ,{signal: controller.signal});
        setCountries(countries.data.country);
        setMaintenance(maintenance.data.maintenance);
        setSubMaintenance(subMaintenance.data.sub_maintenance);
        setBrands(Brand.data.brand);
        setParts(Part.data.partstypes);
        setSubParts(subParts.data.parts);
      } catch (error) {
        toast.error(error.message, toastOptions);
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line 
    [],
  )

  useEffect(() => {
    getData();
    return ()=> controller.abort();
  }, [getData ,controller]);  

  const [isLoading, setIsLoading] = useState(true);
  const [maintenance, setMaintenance] = useState([]);
  const [subMaintenance, setSubMaintenance] = useState([]);
  const [brands, setBrands] = useState([]);
  const [parts, setParts] = useState([]);
  const [subParts, setSubParts] = useState([]);
  const [otp, setOtp] = useState("");
  const [code, setCode] = useState("");
  const [countries, setCountries] = useState([]);
  const [vendorOTP, setVendorOTP] = useState("");
  const [vendorCode, setVendorCode] = useState("");
  return (
    <>
      {!isLoading && (
        <div className="App">
          <dataContext.Provider
            value={{
              otp,
              code,
              setCode,
              setOtp,
              vendorCode,
              vendorOTP,
              setVendorCode,
              setVendorOTP,
              maintenance,
              subMaintenance,
              parts,
              brands,
              subParts,
              countries,
            }}
          >
            <RouterProvider router={router} />
            </dataContext.Provider>
          <ToastContainer />
        </div>
      )}
      {isLoading && (
        <div
          style={{
            background: "black",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="app-loader" />
        </div>
      )}
    </>
  );
}

export default App;
