import React, { useState } from "react";

import "./BusinessCars.scss";
import { MedicalIcon } from "../../assets/icons/svg";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Rating from "@mui/material/Rating";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SearchIcon from "@mui/icons-material/Search";

const BusinessCars = () => {
  const [expanded, setExpanded] = useState(true);

  const handleChange = (panel) => (isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleHighlightClick = (e) => {
    const button = e.target;
    button.classList.contains("active")
      ? button.classList.remove("active")
      : button.classList.add("active");
  };

  return (
    <div className="business-cars">
      <div className="states-cards">
        <div className="card">
          <MedicalIcon />
          <div className="details">
            <h4>Number Of Cars</h4>
            <h6>125</h6>
          </div>
        </div>
        <div className="card">
          <MedicalIcon />
          <div className="details">
            <h4>Number Of Views</h4>
            <h6>1562</h6>
          </div>
        </div>
        <div className="card">
          <MedicalIcon />
          <div className="details">
            <h4>Number of Clicks</h4>
            <h6>256</h6>
          </div>
        </div>
        <div className="card">
          <MedicalIcon />
          <div className="details">
            <h4>Cost</h4>
            <h6>$0</h6>
          </div>
        </div>
      </div>
      <div className="accordian-section">
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <h4>Carss 256</h4>
          </AccordionSummary>
          <AccordionDetails>
            <div className="table-data">
              <div className="header-row">
                <div className="search-icon">
                  <SearchIcon />
                </div>
                <div className="search-input">
                  <input type="text" placeholder="Search" />
                </div>
                <div className="heading">Review</div>
                <div className="heading">Click</div>
                <div className="heading">Review Date</div>
                <div className="heading">Highlight</div>
                <div className="heading">Option</div>
              </div>
              <div className="row">
                <div className="checkbox">
                  <input type="checkbox" />
                </div>
                <div className="product">
                  <div className="image"></div>
                  <div className="details">
                    <h4>Toyoto Corolla</h4>
                    <p>2018, 2200KM</p>
                  </div>
                </div>
                <div className="review">
                  <Rating readOnly value={4} />
                </div>
                <div className="clicks">43</div>
                <div className="review-date">6/3/23</div>
                <div className="highlight">
                  <button
                    className="active"
                    onClick={(e) => handleHighlightClick(e)}
                  >
                    Highlight
                  </button>
                </div>
                <div className="options">
                  <MoreHorizIcon />
                </div>
              </div>
              <div className="row">
                <div className="checkbox">
                  <input type="checkbox" />
                </div>
                <div className="product">
                  <div className="image"></div>
                  <div className="details">
                    <h4>Toyoto Corolla</h4>
                    <p>2018, 2200KM</p>
                  </div>
                </div>
                <div className="review">
                  <Rating readOnly value={4} />
                </div>
                <div className="clicks">43</div>
                <div className="review-date">6/3/23</div>
                <div className="highlight">
                  <button onClick={(e) => handleHighlightClick(e)}>
                    Highlight
                  </button>
                </div>
                <div className="options">
                  <MoreHorizIcon />
                </div>
              </div>
              <div className="row">
                <div className="checkbox">
                  <input type="checkbox" />
                </div>
                <div className="product">
                  <div className="image"></div>
                  <div className="details">
                    <h4>Toyoto Corolla</h4>
                    <p>2018, 2200KM</p>
                  </div>
                </div>
                <div className="review">
                  <Rating readOnly value={4} />
                </div>
                <div className="clicks">43</div>
                <div className="review-date">6/3/23</div>
                <div className="highlight">
                  <button onClick={(e) => handleHighlightClick(e)}>
                    Highlight
                  </button>
                </div>
                <div className="options">
                  <MoreHorizIcon />
                </div>
              </div>
              <div className="row">
                <div className="checkbox">
                  <input type="checkbox" />
                </div>
                <div className="product">
                  <div className="image"></div>
                  <div className="details">
                    <h4>Toyoto Corolla</h4>
                    <p>2018, 2200KM</p>
                  </div>
                </div>
                <div className="review">
                  <Rating readOnly value={4} />
                </div>
                <div className="clicks">43</div>
                <div className="review-date">6/3/23</div>
                <div className="highlight">
                  <button onClick={(e) => handleHighlightClick(e)}>
                    Highlight
                  </button>
                </div>
                <div className="options">
                  <MoreHorizIcon />
                </div>
              </div>
              <div className="row">
                <div className="checkbox">
                  <input type="checkbox" />
                </div>
                <div className="product">
                  <div className="image"></div>
                  <div className="details">
                    <h4>Toyoto Corolla</h4>
                    <p>2018, 2200KM</p>
                  </div>
                </div>
                <div className="review">
                  <Rating readOnly value={4} />
                </div>
                <div className="clicks">43</div>
                <div className="review-date">6/3/23</div>
                <div className="highlight">
                  <button onClick={(e) => handleHighlightClick(e)}>
                    Highlight
                  </button>
                </div>
                <div className="options">
                  <MoreHorizIcon />
                </div>
              </div>
              <div className="row">
                <div className="checkbox">
                  <input type="checkbox" />
                </div>
                <div className="product">
                  <div className="image"></div>
                  <div className="details">
                    <h4>Toyoto Corolla</h4>
                    <p>2018, 2200KM</p>
                  </div>
                </div>
                <div className="review">
                  <Rating readOnly value={4} />
                </div>
                <div className="clicks">43</div>
                <div className="review-date">6/3/23</div>
                <div className="highlight">
                  <button onClick={(e) => handleHighlightClick(e)}>
                    Highlight
                  </button>
                </div>
                <div className="options">
                  <MoreHorizIcon />
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="accordian-section">
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <h4>Importing and Exporting 12</h4>
          </AccordionSummary>
          <AccordionDetails>
            <div className="table-data">
              <div className="header-row">
                <div className="search-icon">
                  <SearchIcon />
                </div>
                <div className="search-input">
                  <input type="text" placeholder="Search" />
                </div>
                <div className="heading">Review</div>
                <div className="heading">Click</div>
                <div className="heading">Review Date</div>
                <div className="heading">Highlight</div>
                <div className="heading">Option</div>
              </div>
              <div className="row">
                <div className="checkbox">
                  <input type="checkbox" />
                </div>
                <div className="product">
                  <div className="image"></div>
                  <div className="details">
                    <h4>Toyoto Corolla</h4>
                    <p>2018, 2200KM</p>
                  </div>
                </div>
                <div className="review">
                  <Rating readOnly value={4} />
                </div>
                <div className="clicks">43</div>
                <div className="review-date">6/3/23</div>
                <div className="highlight">
                  <button
                    className="active"
                    onClick={(e) => handleHighlightClick(e)}
                  >
                    Highlight
                  </button>
                </div>
                <div className="options">
                  <MoreHorizIcon />
                </div>
              </div>
              <div className="row">
                <div className="checkbox">
                  <input type="checkbox" />
                </div>
                <div className="product">
                  <div className="image"></div>
                  <div className="details">
                    <h4>Toyoto Corolla</h4>
                    <p>2018, 2200KM</p>
                  </div>
                </div>
                <div className="review">
                  <Rating readOnly value={4} />
                </div>
                <div className="clicks">43</div>
                <div className="review-date">6/3/23</div>
                <div className="highlight">
                  <button onClick={(e) => handleHighlightClick(e)}>
                    Highlight
                  </button>
                </div>
                <div className="options">
                  <MoreHorizIcon />
                </div>
              </div>
              <div className="row">
                <div className="checkbox">
                  <input type="checkbox" />
                </div>
                <div className="product">
                  <div className="image"></div>
                  <div className="details">
                    <h4>Toyoto Corolla</h4>
                    <p>2018, 2200KM</p>
                  </div>
                </div>
                <div className="review">
                  <Rating readOnly value={4} />
                </div>
                <div className="clicks">43</div>
                <div className="review-date">6/3/23</div>
                <div className="highlight">
                  <button onClick={(e) => handleHighlightClick(e)}>
                    Highlight
                  </button>
                </div>
                <div className="options">
                  <MoreHorizIcon />
                </div>
              </div>
              <div className="row">
                <div className="checkbox">
                  <input type="checkbox" />
                </div>
                <div className="product">
                  <div className="image"></div>
                  <div className="details">
                    <h4>Toyoto Corolla</h4>
                    <p>2018, 2200KM</p>
                  </div>
                </div>
                <div className="review">
                  <Rating readOnly value={4} />
                </div>
                <div className="clicks">43</div>
                <div className="review-date">6/3/23</div>
                <div className="highlight">
                  <button onClick={(e) => handleHighlightClick(e)}>
                    Highlight
                  </button>
                </div>
                <div className="options">
                  <MoreHorizIcon />
                </div>
              </div>
              <div className="row">
                <div className="checkbox">
                  <input type="checkbox" />
                </div>
                <div className="product">
                  <div className="image"></div>
                  <div className="details">
                    <h4>Toyoto Corolla</h4>
                    <p>2018, 2200KM</p>
                  </div>
                </div>
                <div className="review">
                  <Rating readOnly value={4} />
                </div>
                <div className="clicks">43</div>
                <div className="review-date">6/3/23</div>
                <div className="highlight">
                  <button onClick={(e) => handleHighlightClick(e)}>
                    Highlight
                  </button>
                </div>
                <div className="options">
                  <MoreHorizIcon />
                </div>
              </div>
              <div className="row">
                <div className="checkbox">
                  <input type="checkbox" />
                </div>
                <div className="product">
                  <div className="image"></div>
                  <div className="details">
                    <h4>Toyoto Corolla</h4>
                    <p>2018, 2200KM</p>
                  </div>
                </div>
                <div className="review">
                  <Rating readOnly value={4} />
                </div>
                <div className="clicks">43</div>
                <div className="review-date">6/3/23</div>
                <div className="highlight">
                  <button onClick={(e) => handleHighlightClick(e)}>
                    Highlight
                  </button>
                </div>
                <div className="options">
                  <MoreHorizIcon />
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="accordian-section">
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <h4>Renting 14</h4>
          </AccordionSummary>
          <AccordionDetails>
            <div className="table-data">
              <div className="header-row">
                <div className="search-icon">
                  <SearchIcon />
                </div>
                <div className="search-input">
                  <input type="text" placeholder="Search" />
                </div>
                <div className="heading">Review</div>
                <div className="heading">Click</div>
                <div className="heading">Review Date</div>
                <div className="heading">Highlight</div>
                <div className="heading">Option</div>
              </div>
              <div className="row">
                <div className="checkbox">
                  <input type="checkbox" />
                </div>
                <div className="product">
                  <div className="image"></div>
                  <div className="details">
                    <h4>Toyoto Corolla</h4>
                    <p>2018, 2200KM</p>
                  </div>
                </div>
                <div className="review">
                  <Rating readOnly value={4} />
                </div>
                <div className="clicks">43</div>
                <div className="review-date">6/3/23</div>
                <div className="highlight">
                  <button
                    className="active"
                    onClick={(e) => handleHighlightClick(e)}
                  >
                    Highlight
                  </button>
                </div>
                <div className="options">
                  <MoreHorizIcon />
                </div>
              </div>
              <div className="row">
                <div className="checkbox">
                  <input type="checkbox" />
                </div>
                <div className="product">
                  <div className="image"></div>
                  <div className="details">
                    <h4>Toyoto Corolla</h4>
                    <p>2018, 2200KM</p>
                  </div>
                </div>
                <div className="review">
                  <Rating readOnly value={4} />
                </div>
                <div className="clicks">43</div>
                <div className="review-date">6/3/23</div>
                <div className="highlight">
                  <button onClick={(e) => handleHighlightClick(e)}>
                    Highlight
                  </button>
                </div>
                <div className="options">
                  <MoreHorizIcon />
                </div>
              </div>
              <div className="row">
                <div className="checkbox">
                  <input type="checkbox" />
                </div>
                <div className="product">
                  <div className="image"></div>
                  <div className="details">
                    <h4>Toyoto Corolla</h4>
                    <p>2018, 2200KM</p>
                  </div>
                </div>
                <div className="review">
                  <Rating readOnly value={4} />
                </div>
                <div className="clicks">43</div>
                <div className="review-date">6/3/23</div>
                <div className="highlight">
                  <button onClick={(e) => handleHighlightClick(e)}>
                    Highlight
                  </button>
                </div>
                <div className="options">
                  <MoreHorizIcon />
                </div>
              </div>
              <div className="row">
                <div className="checkbox">
                  <input type="checkbox" />
                </div>
                <div className="product">
                  <div className="image"></div>
                  <div className="details">
                    <h4>Toyoto Corolla</h4>
                    <p>2018, 2200KM</p>
                  </div>
                </div>
                <div className="review">
                  <Rating readOnly value={4} />
                </div>
                <div className="clicks">43</div>
                <div className="review-date">6/3/23</div>
                <div className="highlight">
                  <button onClick={(e) => handleHighlightClick(e)}>
                    Highlight
                  </button>
                </div>
                <div className="options">
                  <MoreHorizIcon />
                </div>
              </div>
              <div className="row">
                <div className="checkbox">
                  <input type="checkbox" />
                </div>
                <div className="product">
                  <div className="image"></div>
                  <div className="details">
                    <h4>Toyoto Corolla</h4>
                    <p>2018, 2200KM</p>
                  </div>
                </div>
                <div className="review">
                  <Rating readOnly value={4} />
                </div>
                <div className="clicks">43</div>
                <div className="review-date">6/3/23</div>
                <div className="highlight">
                  <button onClick={(e) => handleHighlightClick(e)}>
                    Highlight
                  </button>
                </div>
                <div className="options">
                  <MoreHorizIcon />
                </div>
              </div>
              <div className="row">
                <div className="checkbox">
                  <input type="checkbox" />
                </div>
                <div className="product">
                  <div className="image"></div>
                  <div className="details">
                    <h4>Toyoto Corolla</h4>
                    <p>2018, 2200KM</p>
                  </div>
                </div>
                <div className="review">
                  <Rating readOnly value={4} />
                </div>
                <div className="clicks">43</div>
                <div className="review-date">6/3/23</div>
                <div className="highlight">
                  <button onClick={(e) => handleHighlightClick(e)}>
                    Highlight
                  </button>
                </div>
                <div className="options">
                  <MoreHorizIcon />
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default BusinessCars;
